import { useChannelsStore } from "~/store/channels";
import { useCustomersStore } from "~/store/customers";
import { usePosStore } from "~/store/pos";
import { CustomerType } from "~/types/customers";
import { Order } from "~/types/orders";

export async function useAssignPosOrder(
  order: Order,
  options: {
    isAssignCustomer: boolean;
  }
) {
  const posStore = usePosStore();
  const customersStore = useCustomersStore();
  const channelsStore = useChannelsStore();

  const {
    id,
    is_express,
    status,
    store: { id: store_id },
    type,
    channel,
  } = order;

  posStore.creatingOrder.id = id;
  posStore.creatingOrder.is_express = is_express;
  posStore.creatingOrder.status = status;
  posStore.creatingOrder.store_id = store_id;
  posStore.creatingOrder.type = type;
  posStore.creatingOrder.sales_manager_id = order.sales_manager?.id;

  posStore.creatingOrder.target_date = order?.target_date;

  posStore.creatingOrder.hold_notes = order?.hold_notes;

  // set active customer
  if (options.isAssignCustomer) {
    customersStore.activeCustomer = order.customer || {
      id: null,
      name: "Guest",
      type: CustomerType.INDIVIDUAL,
    };
  }

  if (!channelsStore.channels?.length) {
    try {
      await useChannelsStore().getChannelsList();
    } catch (error) {}
  }

  const channelValue = channelsStore.channels?.find(
    (c) => c.id === channel.id
  )?.value;

  posStore.creatingOrder.channel = channelValue;
  posStore.isThirdPartyChannel = channelValue && channelValue !== "pos";

  // assign table
  posStore.creatingOrder.table_id = order?.table?.id;
  posStore.selectedTable = order?.table;

  posStore.totals = order;
  posStore.viewingOrder = order;

  posStore.cart = order.items;
  localStorage.setItem("cart", JSON.stringify(posStore.cart));
}
