import { Filters, FilterType } from "~/types/filters";

/**
 * Set route query parameters from filters array
 * @param filters - The object containing all filter settings.
 * @param payload - The object containing additional data to be included in the query.
 * @param clear - Clear all filters.
 */
export const useNewSetFiltersQS = (
  filters: Filters,
  payload?,
  clear?: boolean
) => {
  const router = useRouter();
  const route = useRoute();

  const filtersParams = {};
  let taxonomiesValues = [];

  Object.entries(filters).forEach(([key, filter]) => {
    if (!filter.chosenOptions.length && !filter.toggle) return;

    const values = filter.chosenOptions.map((o) => o.value);

    // Price filter logic
    if (filter.type === FilterType.PRICE_RANGE) {
      filtersParams[`filter[${key}_from]`] = values[0];
      filtersParams[`filter[${key}_to]`] = values[1];

      return;
    }

    // Additional types filter
    if ("additional_types" === key) {
      values.forEach((i) => {
        filtersParams[`filter[${i}]`] = true;
      });

      return;
    }

    // Date Range filters
    if ([FilterType.DATE_RANGE, FilterType.MAIN_DATE].includes(filter.type)) {
      let date = "" + values[0],
        range = date.split("_");

      if (2 === range.length) {
        filtersParams[`filter[${key}_from]`] =
          `${useDateFormatLib(range[0], "yyyy-mm-dd")} 00:00:00`;
        filtersParams[`filter[${key}_to]`] =
          `${useDateFormatLib(range[1], "yyyy-mm-dd")} 23:59:59`;
      }

      return;
    }

    // Date Range filters
    if (FilterType.NEW_DATE_RANGE === filter.type) {
      let date = "" + values[0],
        range = date.split("_");

      if (2 === range.length) {
        filtersParams[`filter[${key}][from]`] = useDateFormatLib(
          range[0],
          "yyyy-mm-dd 00:00:00"
        );
        filtersParams[`filter[${key}][to]`] = useDateFormatLib(
          range[1],
          "yyyy-mm-dd 23:59:59"
        );
      }

      return;
    }

    // Taxonomies filter
    if (["taxonomies_categories", "taxonomies_tags"].includes(key)) {
      taxonomiesValues = taxonomiesValues.concat(values);
      filtersParams[`filter[taxonomies][]`] = taxonomiesValues;

      return;
    }

    // Base filter
    filtersParams[
      `filter[${key}]${filter.isSingle || FilterType.SWITCHER === filter.type ? "" : "[]"}`
    ] =
      filter.isSingle || FilterType.SWITCHER === filter.type
        ? values[0]
        : values;
  });

  setTimeout(() => {
    router.push({
      name: route.name as any,
      query: {
        ...(route.query?.sidebar && !clear && { sidebar: route.query.sidebar }),
        ...filtersParams,
        ...(payload && { ...payload }),
        ...((route.query["retrieve-draft-orders"] ||
          route.query["orders-overallocated-product"]) && { ...route.query }),
        ...(route.query.id && { id: route.query.id }),
        ...Object.keys(route.query)
          .filter((key) => key.includes("-id"))
          .reduce((obj, key) => {
            obj[key] = route.query[key];
            return obj;
          }, {}),
      },
    });
  }, 0);

  // if having some filters in the payload
  const remainingFilters =
    payload &&
    Object.fromEntries(
      Object.entries(payload).filter(([key]) => key.startsWith("filter"))
    );

  return { ...filtersParams, ...(remainingFilters && { ...remainingFilters }) };
};
