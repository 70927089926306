import Pusher from "pusher-js";
import { useSettingsStore } from "~/store/settings";

export const usePusher = () => {
  const {
    public: { baseURL, isElectron },
  } = useRuntimeConfig();
  const settingsStore = useSettingsStore();

  return new Pusher(settingsStore.settings.integrations.pusher.key, {
    cluster: settingsStore.settings.integrations.pusher.cluster,
    channelAuthorization: {
      endpoint: `${
        isElectron ? localStorage.getItem("electron-base-url") : baseURL
      }/api/broadcasting/auth`,
      transport: "ajax",
      params: {},
      headers: {
        Authorization: `Bearer ${useGetToken()}`,
      },
      customHandler: null,
    },
  });
};

// Enable pusher logging - don't include this in production
// Pusher.logToConsole = true;

// SUBSCRIBE TO TERMINAL EVENTS
// const channel = pusher.subscribe('private-pos.terminal.{{ $id }}');

// channel.bind('payment.succeed', function(data) {
//  ** do something with data**;
// });
// channel.bind('payment.declined', function(data) {
//  ** do something with data**;
// });
