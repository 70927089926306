export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.client) {
    const ps = document.querySelector(".ps") as HTMLElement;

    if (ps) {
      const scrollPositions = JSON.parse(
        localStorage.getItem("scrollPositions") || "{}"
      );
      scrollPositions[from.fullPath] = ps.scrollTop;
      localStorage.setItem("scrollPositions", JSON.stringify(scrollPositions));
    }
  }
});
