export const useAccentColor = (color) => {
  const JAFFLE_BRAND_COLOR = "#605AD0";

  const selectedColor =
    color || localStorage.getItem("brand-color") || JAFFLE_BRAND_COLOR;

  localStorage.setItem("brand-color", selectedColor);

  const lightenHexColor = (hex, factor) =>
    "#" +
    hex
      .slice(1)
      .match(/.{2}/g)
      .map((c) => {
        const color = parseInt(c, 16);
        const lightened = Math.round(
          color + (255 - color) * Math.min(1, Math.max(0, factor))
        );
        return lightened.toString(16).padStart(2, "0");
      })
      .join("");

  Object.entries({
    "--accent-color": selectedColor,
    "--menu-sidebar-accent-color": lightenHexColor(selectedColor, 0.1),
    "--lite-accent-color": lightenHexColor(selectedColor, 0.6),
    "--lite-accent-color-50": lightenHexColor(selectedColor, 0.75),
    "--accent-color-100": lightenHexColor(selectedColor, 0.9),
    "--accent-color-50": lightenHexColor(selectedColor, 0.95),
  }).forEach(([key, value]) =>
    document.documentElement.style.setProperty(key, value)
  );

  //////////////////////////

  // SET V-Calendar ACCENT COLORS
  // function to adjust a hex color by lightening or darkening
  const adjustHexColorCalendar = (hex, factor) => {
    return (
      "#" +
      hex
        .slice(1)
        .match(/.{2}/g)
        .map((c) => {
          const color = parseInt(c, 16);
          const modified =
            factor > 0
              ? Math.round(color + (255 - color) * factor) // Lighten
              : Math.round(color * (1 + factor)); // Darken
          return modified.toString(16).padStart(2, "0");
        })
        .join("")
    );
  };

  // generate the colors for v-calendar
  const colorsForVCalendar = {
    "--vc-accent-50": adjustHexColorCalendar(selectedColor, 0.95),
    "--vc-accent-100": adjustHexColorCalendar(selectedColor, 0.9),
    "--vc-accent-200": adjustHexColorCalendar(selectedColor, 0.75),
    "--vc-accent-300": adjustHexColorCalendar(selectedColor, 0.5),
    "--vc-accent-400": adjustHexColorCalendar(selectedColor, 0.25),
    "--vc-accent-500": selectedColor,
    "--vc-accent-600": adjustHexColorCalendar(selectedColor, -0.1),
    "--vc-accent-700": adjustHexColorCalendar(selectedColor, -0.25),
    "--vc-accent-800": adjustHexColorCalendar(selectedColor, -0.4),
    "--vc-accent-900": adjustHexColorCalendar(selectedColor, -0.55),
  };

  // function to inject CSS styles for .vc-accent-color
  const injectVCAccentColorStyles = (colors) => {
    let css = `.vc-accent-color {
      --vc-accent-50: ${colors["--vc-accent-50"]};
      --vc-accent-100: ${colors["--vc-accent-100"]};
      --vc-accent-200: ${colors["--vc-accent-200"]};
      --vc-accent-300: ${colors["--vc-accent-300"]};
      --vc-accent-400: ${colors["--vc-accent-400"]};
      --vc-accent-500: ${colors["--vc-accent-500"]};
      --vc-accent-600: ${colors["--vc-accent-600"]};
      --vc-accent-700: ${colors["--vc-accent-700"]};
      --vc-accent-800: ${colors["--vc-accent-800"]};
      --vc-accent-900: ${colors["--vc-accent-900"]};
    }`;

    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = css;
    document.head.appendChild(styleSheet);
  };

  // apply the .vc-accent-color styles
  injectVCAccentColorStyles(colorsForVCalendar);
};
