import { Filters } from "~/types/general";

// collecting all chosen options from 'filters' object -> setting QSs -> returning params for requests

export const useSetFiltersQS = (filters: Filters, payload?) => {
  const router = useRouter();
  const route = useRoute();

  const filtersParams = {};
  let taxonomiesValues = [];

  Object.values(filters).forEach((filter) => {
    if (!filter.chosenOptions.length && !filter.toggle) return;

    const values = filter.chosenOptions.map((o) => o.value);

    // price filter logic
    if (filter.type === "price" && !filter.isApplied) return;

    if (filter.type === "price") {
      filtersParams["filter[price_from]"] = values[0];
      filtersParams["filter[price_to]"] = values[1];

      return;
    }

    if (['date', 'target_date', 'payment_date'].includes(filter.type) && 0 < values.length) {
      let date = '' + values[0],
        range = date.split('_');

      if (2 === range.length) {
        filtersParams[`filter[${filter.type}_from]`] = `${useDateFormatLib(range[0], "yyyy-mm-dd")} 00:00:00`;
        filtersParams[`filter[${filter.type}_to]`] = `${useDateFormatLib(range[1], "yyyy-mm-dd")} 23:59:59`;
      }

      return;
    }

    if (filter.type === "payment_date_range" && 0 < values.length) {
      let date = '' + values[0],
        paymentDateRange = date.split('_');

      if (2 === paymentDateRange.length) {
        filtersParams["filter[payment_date_from]"] = `${useDateFormatLib(paymentDateRange[0], "yyyy-mm-dd")} 00:00:00`;
        filtersParams["filter[payment_date_to]"] = `${useDateFormatLib(paymentDateRange[1], "yyyy-mm-dd")} 23:59:59`;
      }

      return;
    }

    if (filter.type === "additional_types") {
      values.forEach((i) => {
        filtersParams[`filter[${i}]`] = true;
      });

      return;
    }

    if (['letter', 'customersLetter', 'suppliersLetter'].includes(filter.type) && 0 < values.length && '' !== values[0]) {
      filtersParams[`filter[${filter.type}]`] = values[0];

      return;
    }

    if (
      filter.type === "taxonomies_categories" ||
      filter.type === "taxonomies_tags"
    ) {
      taxonomiesValues = taxonomiesValues.concat(values);

      filtersParams[`filter[taxonomies][]`] = taxonomiesValues;
    } else {
      // for general filters
      filtersParams[`filter[${filter.type}]${filter.isSingle ? "" : "[]"}`] =
        values;
    }

    if (filter.toggle) {
      filtersParams[`filter[${filter.type}]`] = true;
    }
  });

  setTimeout(() => {
    router.push({
      name: route.name as any,
      query: {
        ...(route.query?.sidebar && { sidebar: route.query.sidebar }),
        ...filtersParams,
        ...(payload && { ...payload }),
        ...((route.query["retrieve-draft-orders"] || route.query["orders-overallocated-product"]) && { ...route.query }),
        ...(route.query.id && { id: route.query.id }),
        ...Object.keys(route.query)
            .filter(key => key.includes("-id"))
            .reduce((obj, key) => {
              obj[key] = route.query[key];
              return obj;
            }, {}),
      },
    });
  }, 0);

  // if having some filters in the payload
  const remainingFilters =
    payload &&
    Object.fromEntries(
      Object.entries(payload).filter(([key]) => key.startsWith("filter"))
    );

  return { ...filtersParams, ...(remainingFilters && { ...remainingFilters }) };
};
