<template>
  <div
    v-if="square"
    class="flex items-center border px-5 py-2.5 text-sm leading-base font-medium text-font-primary gap-2.5 rounded-x1 justify-between cursor-pointer"
    :class="[
      textWrap ? 'whitespace-normal' : 'whitespace-nowrap',
      backgroundColor ? backgroundColor : 'bg-white',
    ]"
  >
    <p
      v-if="symbol?.symbol_html?.length"
      v-html="symbol.symbol_html"
      class="flex-1"
    ></p>
    <p
      v-else
      class="flex-1 overflow-x-hidden"
      :class="{
        'w-full text-center': textCenter,
        [maxWidth]: maxWidth,
        'inline-flex': !textCenter,
      }"
      v-html="text"
    ></p>
    <div @click="emit('delete')" class="cursor-pointer -mr-x1" v-if="!disabled">
      <XIcon class="w-5 h-5 stroke-[#B4B4B4]" style="stroke-width: 1.5" />
    </div>
  </div>

  <div
    v-else
    class="border px-5 py-3px text-sm leading-base font-medium text-font-primary gap-2.5 flex items-center rounded-x10 cursor-pointer"
    :class="[
      textWrap ? 'whitespace-normal' : 'whitespace-nowrap',
      backgroundColor ? backgroundColor : 'bg-white',
    ]"
  >
    <p v-if="symbol?.symbol_html?.length" v-html="symbol.symbol_html"></p>
    <p :class="{ 'w-full text-center': textCenter }">{{ text }}</p>
  </div>
</template>

<script lang="ts" setup>
import { XIcon } from "@heroicons/vue/outline";
const props = defineProps<{
  text: string;
  symbol?: any;
  square?: boolean;
  disabled?: boolean;
  textWrap?: boolean;
  backgroundColor?: string;
  textCenter?: boolean;
  maxWidth?: string;
}>();

const emit = defineEmits(["delete"]);
</script>
