import { Media, OrderType, SidebarPrefix, Option } from "~/types/general";
import {
  Product,
  ProductSaleStatus,
  ServiceAvailability,
} from "~/types/products";
import { CustomerShort } from "./customers";
import { StoreShort } from "./stores";
import { PurchaseOrder, PurchasePricingMethod } from "./purchase";
import { Taxonomies } from "./taxonomies";
import { InventoryActionStatus } from "~/types/inventory-actions";

export interface InventoryItem {
  id: number;
  store: StoreShort;
  product: InventoryProduct;
  price: number;
  customer_price: number;
  sale_price?: number;
  sale_status: ProductSaleStatus;
  is_on_sale: boolean;
  sale_starts_at?: string;
  sale_ends_at?: string;
  stock_status: InventoryStatus;
  expected_stock?: number;
  order_types: OrderType[];
  code: string;
  area: string;
  notes: any;
  stock: number;
  reservedStock: number;
  media: Media[];
  [LotsType.REGULAR]: Lot[];
  [LotsType.UPCOMING]: Lot[];
  order_quantity: {
    min: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
    increment: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
    order_limit: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
  };
  tag?: {
    id: number;
    name: string;
  };
}

export interface InventoryProduct {
  id: number;
  type: string;
  name: string;
  ext_title?: string;
  slug: string;
  sku: string;
  price: number;
  original_price?: number;
  excerpt: number;
  sale_price?: number;
  conversion_ratio?: number;
  is_on_sale?: boolean;
  properties?: {
    name: string;
    slug: string;
    value: string;
    title: string;
    is_symbol?: boolean;
    symbol_html?: string;
    hide_in_preparation?: boolean;
  }[];
  media: {
    id: number;
    title: string;
    description: string;
    url: string;
    conversions: {
      thumbnail: string;
    };
  }[];
  variations?: Product[];
  description?: string;
}

export interface InventorySingle {
  id: number;
  store: StoreShort;
  product: InventoryProduct;
  price: number;
  customer_price: number;
  sale_price?: number;
  sale_status: ProductSaleStatus;
  sale_starts_at: string;
  is_on_sale: boolean;
  stock_status: InventoryStatus;
  order_types: OrderType[];
  code: string;
  area: string;
  order_units: InventoryUnit;
  order_increment: number;
  order_minimum: number;
  order_limit: number;
  has_custom_order_qty: boolean;
  order_quantity: {
    min: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
    increment: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
    order_limit: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
  };
  stock: number;
  expected_stock: number;
  reserved_stock: number;
  received_stock: number;
  media: Media[];
  per_unit_cost: number;
  gross_margin: number;
  total_value: number;
  [LotsType.REGULAR]: Lot[];
  [LotsType.UPCOMING]: Lot[];
  [LotsType.FAMILIAL_REGULAR]: LotFamilial[];
  [LotsType.FAMILIAL_UPCOMING]: LotFamilial[];
  upcoming_stock: number;
  tag?: {
    id: number;
    name: string;
  };
  tag_id?: number;
  reserve_deficit: number;
  deficit: number;
}

export interface ServiceInventoryItem {
  id: number;
  age_ranges?: string[];
  product: InventoryProduct;
  store: StoreShort;
  description: string;
  price: number;
  customer_price?: number;
  sale_price: number;
  sale_status: string;
  is_on_sale: boolean;
  type: string;
  types: ServiceAvailability[];
  status: ServicesInventoryStatus;
  units: number;
  max_units: number;
  appointment_hours_duration: any;
  appointment_minutes_duration: any;
  block_extra_hours_duration: any;
  block_extra_minutes_duration: any;
  is_available?: boolean;
  settings?: {
    travel_cost?: {
      method: TravelCostMethod;
      address: TravelCostAddress;
      cost_per_km: number | string;
    };
  };
  parent_product?: {
    name: string;
    id: number;
    ext_title?: string;
  };
  tag?: {
    id: number;
    name: string;
  };
  media?: Media[];
  taxonomies?: {
    id: number;
    name: string;
    slug: Taxonomies;
    taxons: {
      id: number;
      name: string;
      slug: string;
      parents?: any[];
    }[];
  }[];
  customers?: any[];
}

export interface ServiceInventoryFormated {
  id: number;
  description?: string;
  store?: Option[];
  hourly_rate?: number;
  price?: number;
  sale_price?: number;
  sale_status?: string;
  sale_starts_at?: string;
  sale_ends_at?: string;
  is_on_sale?: boolean;
  type?: string;
  units?: number;
  max_units?: number;
  status?: ServicesInventoryStatus;
  tag?: Option[];
  categories?: { name: string; value: number; parents?: number[] }[];
  numbers?: Option[];
  customers?: Option[];
  appointment_hours_duration?: any;
  appointment_minutes_duration?: any;
  block_extra_hours_duration?: any;
  block_extra_minutes_duration?: any;
  types?: Option[];
  age_ranges?: string[];
  settings?: {
    travel_cost?: {
      method: TravelCostMethod;
      address: TravelCostAddress;
      cost_per_km: number | string;
    };
  };
}

export interface ServiceInventoryPayload {
  service_id: number;
  description?: string;
  store_id: number;
  price?: number;
  sale_price?: number;
  sale_status?: string;
  sale_starts_at?: string;
  sale_ends_at?: string;
  is_on_sale?: boolean;
  type?: string;
  units?: number;
  status?: ServicesInventoryStatus;
  tag?: number[];
  taxonomies?: number[];
  customers?: number[];
  appointment_hours_duration?: any;
  appointment_minutes_duration?: any;
  block_extra_hours_duration?: any;
  block_extra_minutes_duration?: any;
  types?: ServiceAvailability[];
  settings?: {
    travel_cost?: {
      method: TravelCostMethod;
      address: TravelCostAddress;
      cost_per_km: number;
    };
  };
}

export enum TravelCostMethod {
  DISTANCE = "distance",
  MONASH_MODIFIED_MODEL = "mmm",
  INCLUDED_TRAVEL_COSTS = "included",
}

export enum TravelCostAddress {
  LOCATION = "location",
  MEMBER = "member",
}

export enum InventoryStatus {
  IN_STOCK = "in-stock",
  OUT_OF_STOCK = "out-of-stock",
  LOW = "low-stock",
  DRAFT = "draft",

  ARCHIVED = "archived",
  ALLOCATED = "allocated",
  OVER_ORDEDED = "over-ordeded",
  RED = "red",
  GREEN = "green",
  NEW = "new",
  READY = "ready",
  ARCHIVE = "archive",
  FAILED = "failed",
}

export enum ServicesInventoryStatus {
  AVAILABLE = "available",
  NOT_AVAILABLE = "not-available",
  DRAFT = "draft",
}

export enum InventoryAddType {
  SIMPLE = "simple",
  CATEGORY = "category",
  ALL = "all",
}

export interface InventoryAdding {
  product_id: number;
  store_id: number;
  price: number;
  sale_price: number;
  sale_status: ProductSaleStatus;
  is_on_sale: boolean;
  sale_starts_at?: string;
  sale_ends_at?: string;
  stock_status: InventoryStatus;
  order_types: OrderType[];
  code: string;
  area: string;
  notes: string;
  stock: number;
  lot_number: string;
  expires_at: string;
}

export interface InventoryImporting {
  store_id: number;
  stock_status: InventoryStatus;
  force: boolean;
  order_types: OrderType[];
  taxonomies: number[];
}

export interface InventoryEditing {
  price: number;
  sale_price: number;
  sale_status: ProductSaleStatus;
  is_on_sale: boolean;
  sale_starts_at?: string;
  sale_ends_at?: string;
  stock_status: InventoryStatus;
  order_types: OrderType[];
  code: string;
  area: string;
  notes: string;
}

export interface InventoryAvailabilityPayload {
  order_types: OrderType[];
  has_custom_order_qty: boolean;
  order_minimum: number;
  order_increment: number;
  order_units: InventoryUnit;
  order_limit: number;
}

export interface InventoryBarcode {
  id: number;
  store: StoreShort;
  product: InventoryProduct;
  price: number;
  stock_status: InventoryStatus;
  order_types: OrderType[];
  code: string;
  area: string;
  notes: any[];
  stock: number;
  reservedStock: number;
  media: any[];
}

export enum InventoryParamKey {
  PRICE = "price",
  AREA = "area",
}

export type InventoryParams = {
  [key in InventoryParamKey]: any;
};

// LOTS
export interface Lot {
  order?: PurchaseOrder;
  supplier?: CustomerShort;
  order_id?: number;
  supplier_id?: number;
  lots: LotItem[];
  reserved_stock?: number;
  received_stock?: number;
  stock?: number;
  base_stock?: number;
  stock_on_hand?: number;
}

export interface LotItem {
  id: number;
  base_stock: number;
  stock: number;
  reserved_stock: number;
  received_stock: number;
  stock_on_hand: number;
  sold_stock: number;
  lot_number: string;
  expires_at: string;
  created_at: string;
  upcoming_date: string;
  withdrawn_stock: number;
  supplier: CustomerShort;
  price: number;
  sell_price: number;
  pricing_method: PurchasePricingMethod;
  order_pricing_method?: PurchasePricingMethod;
}

export interface LotShort {
  base_stock: number;
  id: number;
  lot_number: string;
  price: number | string;
  reserved_stock: number;
  received_stock: number;
  stock_on_hand: number;
  sold_stock: number;
  stock: number;
  supplier: {
    id: number;
    name: string;
  };
  withdrawn_stock: number;
}

export interface LotFamilial {
  groups: Lot[];
  store: StoreShort;
}

export enum LotManageMode {
  ADJUST = "adjust",
  CONVERT = "convert",
}

export enum LotAdjustReason {
  DAMAGED = "damaged",
  STOLEN = "stolen",
  EVAPORATED = "evaporated",
  DETERIORATED = "deteriorated",
  EXPIRED = "expired",
  REVISED = "revised",
}

export interface LotAdjustPayload {
  amount: number;
  reason: LotAdjustReason;
  note: {
    text: string;
    is_private: boolean;
    is_pinned: boolean;
  };
  update_po?: boolean;
}
export interface LotConvertPayload {
  convert: LotConvertItem[];
  note: {
    text: string;
    is_private: boolean;
    is_pinned: boolean;
  };
  update_po?: boolean;
}

export enum InventoryUnit {
  UNIT = "unit",
  BOX = "box",
}

export interface LotConvertItem {
  amount: number;
  product_id: number;
}

export enum LotsType {
  REGULAR = "lots",
  UPCOMING = "upcoming_lots",
  FAMILIAL_REGULAR = "familial_lots",
  FAMILIAL_UPCOMING = "familial_upcoming_lots",
}

// NOTIFICATIONS
export interface InventoryNotification {
  id: number;
  stock_status: InventoryStatus;
  stock: number;
  expected_stock: number;
  reserved_stock: number;
  lots: {
    order_id?: number;
    supplier_id?: number;
    stock: number;
    reserved_stock: number;
    base_stock: number;
    lots: {
      id: number;
      stock: number;
      reserved_stock: number;
    }[];
  }[];
}

export interface LotActionPayload {
  status: InventoryActionStatus;
  note: string;
  customer_id: number;
}

// HISTORY
export interface InventoryHistory {
  id: number;
  type: InventoryHistoryType;
  reason: LotAdjustReason;
  amount: number;
  text: string;
  is_private: boolean;
  is_pinned: boolean;
  user: {
    id: number;
    name: string;
    image: {
      id: number;
      title: string;
      description: string;
      url: string;
      conversions: any[];
    };
  };
  created_at: string;
  from_product?: InventoryProduct;
  to_product?: InventoryProduct;
}

export enum InventoryHistoryType {
  ADJUSTMENT = "adjustment",
  CONVERSION = "conversion",
  NOTE = "note",
}

// SIDEBAR
export const SidebarPage = {
  INVENTORY_EDIT: `${SidebarPrefix.INVENTORY}-edit`,
  INVENTORY_LOCATIONS: `${SidebarPrefix.INVENTORY}-locations`,
  INVENTORY_HISTORY: `${SidebarPrefix.INVENTORY}-history`,
  INVENTORY_EDIT_AVAILABILITY: `${SidebarPrefix.INVENTORY}-edit-availability`,
  INVENTORY_NEW: `${SidebarPrefix.INVENTORY}-new`,
  INVENTORY_FILTERS: `${SidebarPrefix.INVENTORY}-filters`,
  INVENTORY_LOT_MANAGE: `${SidebarPrefix.INVENTORY}-lot-manage`,
  INVENTORY_REPORT: `${SidebarPrefix.INVENTORY}-report`,
  INVENTORY_ACTIONS: `${SidebarPrefix.INVENTORY}-actions`,
  INVENTORY_PURCHASE_ORDERS: `${SidebarPrefix.INVENTORY}-purchase-orders`,
};
