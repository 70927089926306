import { Media } from "./general";
import { Customer } from "./customers";

export interface InventoryActionItem {
  id: number;
  status: InventoryActionStatus;
  description: string | null;
  note?: string;
  is_new?: boolean;
  customer?: Customer;
  documents: Media[];
  inventory: {
    id: number;
    created_at: string;
  };
  created_at: string;
  created_by: {
    id: number;
    name: string;
    image: Media;
  };
  updated_at: string;
}

export enum InventoryActionStatus {
  NO_STATUS = "no-status",
  REVIEW = "review",
  CRITICAL = "critical",
  DONE = "done",
}

export interface InventoryActionManage {
  id?: number;
  status?: InventoryActionStatus;
  note?: string;
  created_at?: string;
  updated_at?: string;
  customer_id?: number;
}

export interface InventoryActionNotification {
  id: number;
  text: string;
  status: InventoryActionStatus;
  inventory: number;
}
