import { OrderItem } from "~/types/orders";
import { Media, OrderType, ReportExtension } from "~/types/general";
import { CustomerShort } from "~/types/customers";
import { Address } from "~/types/general";
import { StatementActivityType } from "./statements";
import { Invoice } from "~/types/invoices";
import { ProductProperty } from "~/types/products";

export enum PrintType {
  ORDER = "order",
  STATEMENT = "statement",
  PURCHASE = "purchase",
  INVOICE = "invoice",
}

export enum PrintTypeMode {
  RECEIVING = "receiving",
}

export enum ReportType {
  INVOICE = "invoice",
  SERVICE_AGREEMENT = "service-agreement",
  PREPARATION = "picklist",
  INVOICE_PICKLIST = "invoice-picklist",
  CONFIRMATION = "confirmation-report",
  SALES_SUMMARY = "sales-summary",
  ACTIVITY = "activities",
  OUTSTANDING = "outstanding",
  PURCHASE_ACTIVITY = "purchase-activities",
  PURCHASE_OUTSTANDING = "purchase-outstanding",
  PAYBACK_SUMMARY = "payback-summary",
  PURCHASE_PAYBACK_SUMMARY = "purchase-payback-summary",
  LABEL = "label",
  DELIVERY = "delivery",
  BATCH_DEPOSIT = "batch-deposit",
  REMITTANCE = "remittance",
}

export enum LabelType {
  BARCODE = "barcode",
  SUPPLIER = "supplier",
  LOT = "lot",
}

export enum PaperFormat {
  THERMAL = "thermal",
  A4 = "a4",
}

export enum ReceiptTemplate {
  DEFAULT = "default",
  SECONDARY = "secondary",
  BRANDED_WHOLESALE = "branded_wholesale",
}

export enum PrintOrder {
  ASC = "asc",
  DESC = "desc",
}

export enum PrintMode {
  AUTO_PRINT = "auto-print",
  PAY_BY_LINK = "pay-by-link",
  PAY_DEPOSIT_BY_LINK = "pay-deposit-by-link",
}

export enum InvoiceType {
  ORDER = "order",
  PURCHASE_ORDER = "purchaseOrder",
}

export enum BarcodeStandard {
  C39 = "C39",
  C39_PLUS = "C39+",
  C39E = "C39E",
  C39E_PLUS = "C39E+",
  C93 = "C93",
  S25 = "S25",
  S25_PLUS = "S25+",
  I25 = "I25",
  I25_PLUS = "I25+",
  C128 = "C128",
  C128A = "C128A",
  C128B = "C128B",
  C128C = "C128C",
  GS1_128 = "GS1-128",
  EAN2 = "EAN2",
  EAN5 = "EAN5",
  EAN8 = "EAN8",
  EAN13 = "EAN13",
  UPCA = "UPCA",
  UPCE = "UPCE",
  MSI = "MSI",
  MSI_PLUS = "MSI+",
  POSTNET = "POSTNET",
  PLANET = "PLANET",
  RMS4CC = "RMS4CC",
  KIX = "KIX",
  IMB = "IMB",
  CODABAR = "CODABAR",
  CODE11 = "CODE11",
  PHARMA = "PHARMA",
  PHARMA2T = "PHARMA2T",
}

export interface AddonValue {
  name?: string;
  slug: string;
  value: string;
}

export interface PrintLabelRequest {
  format?: string;
  type: LabelType;
  items: number[];
  content: string;
  barcode: {
    standard: string;
    height?: number;
    width?: number;
  };
}
export interface PickingListParams {
  enabled?: boolean | string;
  a4: boolean | string;
  areas: boolean | string;
  area_titles: boolean | string;
  barcodes: boolean | string;
  transport_units: boolean | string;
  show_unallocated?: boolean | string;
  display_secondary_name?: boolean | string;
  format?: ReportExtension;
}

export interface ConfirmationListParams {
  enabled?: boolean | string;
  confirmed_units: boolean | string;
  transport_units: boolean | string;
  product_parent_name: boolean | string;
  display_secondary_name: boolean | string;
  hide_zero_allocated: boolean | string;
  notice?: string;
  format: ReportExtension;
  date_from?: string;
  date_to?: string;
  ids?: string;
}

export interface StatementReportPayload {
  format: ReportExtension;
  date_from?: string;
  date_to?: string;
  ids?: string[] | number[];
}

export interface InvoiceReport {
  data: {
    order_item: OrderItem;
    product?: {
      parent_name?: string;
    };
    zero_allocated?: boolean;
    addons?: {
      slug: string;
      name: string;
      type: string;
      value: AddonValue[];
    }[];
  }[];
  meta: {
    date: string;
    store: {
      full_name: string;
      name: string;
      email: string;
      phone: string;
      abn: string;
      address_string: string;
      address: {
        country: {
          id: string;
          name: string;
        };
        province: {
          name: string;
          code: string;
        };
        postalcode: string;
        city: string;
        address: string;
        lat?: number;
        lng?: number;
      };
      image: Media;
      payment_detail_notes?: string;
    };
    address: {
      notes: any;
    };
    customer?: {
      name: string;
      bay_number: string;
      completed_orders: number;
      mobile: string;
      phone: string;
      hide_address_on_receipt: boolean;
    };
    order: {
      last_payment: {
        amount: number;
        method: string;
        date: string;
      };
      paid_amount?: number;
      payment_status?: string;
      payment_amount?: {
        amount?: number;
        fees?: {
          "in-person"?: number;
          stripe?: number;
        };
      };
      opening_customer_balance?: string;
      is_express?: boolean;
      number: number;
      payment_type?: string;
      type: OrderType;
      adjustments: any[];
      subtotal: number;
      tax: number;
      total: number;
      target_date: string[];
      address: string;
      city: string;
      province_code: string;
      postal_code: string;
      order_date: {
        datetime: string;
        formatted_date: string;
        formatted_time: string;
      };
      units: number;
      due_date?: string;
      sales_manager?: CustomerShort;
      sales_manager_firstname?: string;
      sales_manager_lastname?: string;
      general_notes: any;
      general_delivery_notes?: any;
      po_number?: string;
    };
    general_payment_detail_notes: string;
    settings: {
      format: string;
    };
  };
}

export interface BatchDepositReport {
  data: {
    id: number;
    amount: number;
    processing_fee: number;
    unpaid_amount: number;
    order: {
      id: number;
      type: string;
      is_express: boolean;
      is_zero_priced: boolean;
      number: string;
      po_number: string;
      payment_status: string;
      subtotal_value: number;
      total_value: number;
      credit_amount: number;
    };
  }[];
  meta: {
    date: {
      datetime: string;
      formatted_date: string;
      formatted_time: string;

    };
    customer?: {
      name: string;
      address: string;
      city: string;
      province_code: string;
      postal_code: string;
      bay_number: string;
      abn: string;
      mobile: string;
      phone: string;
      account_balance: number;
    };
    business?: {
      name: string;
      phone: string;
      email: string;
      abn: string;
      address: string;
      city: string;
      province_code: string;
      postal_code: string;
    };
    payment_details: string;
    total_payment: number;
    processing_fee: number;
    invoice: Invoice;
    unpaid_amount: number;
    sales_manager_firstname: string;
    invoice_type: string;
  };
}

export interface RemittanceReport {
  data: {
    id: number;
    amount: number;
    processing_fee: number;
    unpaid_amount: number;
    order: {
      id: number;
      type: string;
      pricing_method: string;
      is_express: boolean;
      is_zero_priced: boolean;
      number: string;
      payment_status: string;
      subtotal_value: number;
      total_value: number;
      paid_amount: number;
      levies_value: number;
      levies: number;
      target_date: number;
      created_at: string;
      credit_amount: number;
      items: {
        id: number;
        product_id: number;
        quantity: number;
        received: number;
        unit_price: number;
        total_price: number;
        levy: number;
        levy_total: number;
        name: number;
        ext_title: number;
        properties: ProductProperty[];
        media: File;
      }[];
    };
  }[];
  meta: {
    date: {
      datetime: string;
      formatted_date: string;
      formatted_time: string;

    };
    customer?: {
      name: string;
      address: string;
      city: string;
      province_code: string;
      postal_code: string;
      bay_number: string;
      abn: string;
      mobile: string;
      phone: string;
      account_balance: number;
    };
    business?: {
      name: string;
      phone: string;
      email: string;
      abn: string;
      address: string;
      city: string;
      province_code: string;
      postal_code: string;
    };
    payment_details: string;
    total_payment: number;
    processing_fee: number;
    invoice: Invoice;
    unpaid_amount: number;
    sales_manager_firstname: string;
    invoice_type: string;
  };
}

export interface PurchasePaybackSummaryReport {
  data: {
    id?: number;
    product: {
      name: string;
      properties: {
        symbol: string | null;
        title: string;
      }[];
      quantity: number;
      unit_price: number;
      total_price: number;
      received: number;
      levy: number;
      levy_total: number;
    }[];
  };
  meta: {
    name: string;
    date: string;
    store: {
      full_name: string;
      name: string;
      email: string;
      phone: string;
      abn: string;
      address_string: string;
      address: {
        country: {
          id: string;
          name: string;
        };
        province: {
          name: string;
          code: string;
        };
        postalcode: string;
        city: string;
        address: string;
        lat?: number;
        lng?: number;
      };
      image: Media;
      payment_detail_notes?: string;
    };
    address: {
      notes: any;
    };
    supplier?: {
      name: string;
      bay_number: string;
      completed_orders: number;
      mobile: string;
      phone: string;
      hide_address_on_receipt: boolean;
      billing_address: Address;
    };
    order: {
      last_payment: {
        amount: number;
        method: string;
        date: string;
      };
      paid_amount?: number;
      payment_status?: string;
      payment_amount?: {
        amount?: number;
        fees?: {
          "in-person"?: number;
          stripe?: number;
        };
      };
      opening_customer_balance?: string;
      is_express?: boolean;
      number: number;
      payment_type?: string;
      type: OrderType;
      adjustments: any[];
      subtotal: number;
      tax: number;
      total: number;
      target_date: string[];
      address: string;
      city: string;
      province_code: string;
      postal_code: string;
      order_date: {
        datetime: string;
        formatted_date: string;
        formatted_time: string;
      };
      units: number;
      due_date?: string;
      sales_manager?: CustomerShort;
      sales_manager_firstname?: string;
      sales_manager_lastname?: string;
      general_notes: any;
      general_delivery_notes?: any;
      po_number?: string;
    };
    general_payment_detail_notes: string;
    settings: {
      format: string;
    };
    business: {
      short_name: string;
      phone: string;
      email: string;
      abn: string;
      address?: string;
      city?: string;
      province_code?: string;
      postal_code?: string;
    };
  };
}

export interface PurchaseInvoiceReport {
  data: {
    product: {
      name: string;
      properties: any[];
    };
    quantity: number;
    unit_price: number;
    total_price: number;
    received: number;
    sold: number;
  }[];
  meta: {
    name: string;
    date: string;
    order: {
      id: number;
      number: string;
      type: string;
      status: string;
      pricing_method: string;
      created_at: string;
      target_date: string;
      due_date: string;
      total_value: number;
      subtotal_value: number;
      tax_value: number;
      adjustments: any[];
      units: number;
      levies_total: number;
    };
    store: {
      name: string;
      email: string;
      phone: string;
      abn: string;
      address: {
        address: string;
        city: string;
        postalcode: string;
        state: string;
      };
      image: Media;
    };
    supplier: {
      id: number;
      name: string;
      email: string;
      phone: string;
      billing_address?: {
        address: string;
        city: string;
        postalcode: string;
        state: string;
      };
      shipping_address?: {
        address: string;
        city: string;
        postalcode: string;
        state: string;
      };
    };
    settings: {
      format: string;
    };
  };
}
export interface BillReport {
  data: {
    product: {
      name: string;
      properties: {
        symbol: string | null;
        title: string;
      }[];
    };
    quantity: number;
    received: number | null;
    sold: number;
    total_price: number;
    unit_price: number;
  }[];
  meta: {
    name: string;
    date: string;
    order: {
      id: number;
      number: string;
      type: string;
      status: string;
      pricing_method: string;
      created_at: string;
      target_date: string | null;
      due_date: string | null;
      total_value: number;
      subtotal_value: number;
      tax_value: number;
      general_notes?: any;
      adjustments?: {
        amount: number;
        data: any;
        name: string;
        type: string;
        value?: any;
      }[];
      units: number;
    };
    general_payment_detail_notes?: any;
    settings: {
      format: string;
    };
    store: {
      name: string;
      email: string;
      phone: string;
      abn: string;
      address: Address;
      image: Media;
      payment_detail_notes?: any;
    };
    supplier: {
      billing_address: string | null;
      email: string;
      id: number;
      name: string;
      phone: string;
      shipping_address: {
        postalcode: string;
        city: string;
        address: string;
        state: string;
      };
    };
  };
}

export interface PickingReport {
  data: {
    products: PicklistReportProduct[] | PicklistReportProductsSet;
    actual_quantity: number;
    total_lines: number;
  };
  meta: {
    store_fullname: string;
    store_name: string;
    is_delivery: boolean;
    is_express: boolean;
    sales_manager_firstname?: string;
    preparation_docket: {
      label: string;
      order_id: number;
      table_number: number;
      table_group_number: number;
    };
    address_note: string;
    po_number: string;
    customer_name: string;
    customer_firstname: string;
    customer_lastname: string;
    customer_phone: string;
    customer_mobile: string;
    address: string;
    city: string;
    province_code: string;
    postal_code: string;
    bay_number: string;
    hide_address_on_receipt: boolean;
    order_date: {
      date: string;
      time: string;
    };
    target_date: {
      date: string;
      time: string;
    };
    notices: {
      comments: any;
      picker_notes: any;
    };
    units: any[];
    picker: {
      name: string;
      firstname: string;
      lastname: string;
    };
    the_other_printers?: {
      id: number;
      name: string;
      is_preparation: boolean;
    }[];
  };
}

export interface PicklistReportProduct {
  name: string;
  ext_title: string;
  barcode: string;
  picker_notes: any;
  area: string;
  transport_units: number;
  units: number;
  lots?: any[];
  addons?: {
    slug: string;
    name: string;
    type: string;
    value: AddonValue[];
  }[];
}

export interface PicklistReportProductsSet {
  [key: string]: PicklistReportProduct[];
}

export interface ConfirmationReport {
  data: {
    products: {
      units_ordered: number;
      product: string;
      productHtml?: string;
      product_secondary?: string;
      product_parent?: string;
      product_parent_secondary?: string;
      product_calculated?: string;
      units_confirmed: number;
      transport_units: number;
      zero_allocated?: boolean;
      price: number;
      total: number;
    }[];
    paid_amount: number;
    outstanding_amount: number;
    subtotal: number;
    tax: number;
    total: number;
    total_quantity: number;
    total_lines: number;
  };
  meta: {
    order_id: number;
    order_type: string;
    is_express: boolean;
    is_delivery: boolean;
    order_po_number: string;
    customer_name: string;
    address: string;
    city: string;
    postal_code: string;
    province_code: string;
    bay_number?: string;
    hide_address_on_receipt?: boolean;
    order_date: { date: string; time: string };
    target_date: string[];
    notice: string;
    notes: { text: string }[];
    units: any[];
    adjustments?: {
      amount: number;
      data: any;
      name: string;
      type: string;
    }[];
    address_note: string;
    store_name?: string;
    store_short_name?: string;
    store_phone?: string;
    store_email?: string;
    store_address?: string;
    store_abn?: string;
  };
}

export interface SalesSummaryReport {
  data: [
    {
      product: string;
      quantity: number;
      unit_price: number;
      addons?: {
        slug: string;
        name: string;
        type: string;
        value: AddonValue[];
      }[];
    },
  ];
  meta: {
    date: string;
    store: {
      name: string;
      email: string;
      phone: string;
      abn: string;
      address: string;
      image: Media;
    };
    customer: {
      name: string;
      bay_number: string;
      mobile: string;
      phone: string;
    };
    order: {
      number: number;
      type: OrderType;
      subtotal: number;
      tax: number;
      total: number;
      adjustments: any[];
      address: string;
      city: string;
      province_code: string;
      postal_code: string;
      order_date: {
        datetime: string;
        formatted_date: string;
        formatted_time: string;
      };
      general_notes: any[];
    };
  };
}

export interface StatementReport {
  data: {
    activity_id: number;
    activity_type: StatementActivityType;
    invoice_id: string;
    reference: string;
    invoice_due_date: string;
    invoice_amount: number;
    payment_amount: number;
    balance: number;
    date: string;
  }[];
  meta: {
    opening_balance?: number;
    due_balance?: number;
    date: string;
    customer: {
      name: string;
      address: string;
      city: string;
      province_code: string;
      postal_code: string;
    };
    supplier?: {
      name: string;
      address: string;
      city: string;
      province_code: string;
      postal_code: string;
    };
    business: {
      name: string;
      phone: string;
      email: string;
      abn: string;
      address: string;
      city: string;
      province_code: string;
      postal_code: string;
    };
    date_from: string;
    date_to: string;
    payment_details: string;
  };
}

export interface OrdersReport {
  data: OrdersExport[];
  meta: {
    date: string;
    date_from: string;
    date_to: string;
  };
}

export interface OrdersExport {
  id: number;
  payment_method_gateway: string;
  type: string;
  customer_name: string;
  status: string;
  target_date: string;
  address: string;
  total_value: number;
  fulfilment: boolean;
  tag: string;
}
