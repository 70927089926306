<template>
  <div
    class="text-xs leading-base-sm px-2.5 py-[1px] cursor-pointer text-center max-w-min"
    :class="[
      {
        'bg-blaze-green text-white': orderGreen,
        'bg-blaze-purple text-white': orderPurple,
        'bg-blaze-orange text-white': orderOrange,
        'bg-blaze-red text-white': orderRed,
        'bg-blaze-grey text-white': orderGrey,
        'bg-grey-400 text-font-secondary font-medium border': orderLightGrey,
        'text-accent-color bg-accent-color/20':
          variant === ProductCatalogStatus.SPECIAL,
        'bg-light-background text-accent-color': !variant,
        [textColor]: textColor,
        'rounded-x1': !orderLightGrey,
        'rounded-x10': orderLightGrey,
        [minWidth]: minWidth,
      },
      textWeight ? `${textWeight}` : 'font-bold',
    ]"
  >
    <p class="truncate max-w-[160px] w-auto">{{ text }}</p>
  </div>
</template>

<script lang="ts" setup>
import { InventoryStatus, ServicesInventoryStatus } from "~/types/inventory";
import { ProductStatus as ProductCatalogStatus } from "~/types/products";
import { OrderStatus } from "~/types/orders";
import { OrderActionStatus } from "~/types/orders-actions";
import { PillVariant } from "~/types/general";
import { PurchaseOrderStatus } from "~/types/purchase";
import { InvoiceStatus } from "~/types/invoices";
import { CustomerStatus } from "~/types/customers";
import { ManagementLocationsStatus } from "~/types/management-locations";
import { InventoryActionStatus } from "~/types/inventory-actions";

const props = defineProps<{
  text: string;
  variant?:
    | PillVariant
    | OrderStatus
    | PurchaseOrderStatus
    | InvoiceStatus
    | OrderActionStatus
    | InventoryStatus
    | InventoryActionStatus
    | ProductCatalogStatus
    | ServicesInventoryStatus;
  textColor?: string;
  textWeight?: string;
  minWidth?: string;
}>();

// GREEN
const orderGreen = computed(() =>
  [
    OrderStatus.PROCESSING,
    OrderStatus.COMPLETED,
    OrderActionStatus.DONE,
    InventoryStatus.IN_STOCK,
    ServicesInventoryStatus.AVAILABLE,
    InventoryStatus.GREEN,
    ProductCatalogStatus.AVAILABLE,
    PillVariant.GREEN,
    CustomerStatus.APPLICATION,
    "recent",
  ].includes(props.variant)
);

// PURPLE
const orderPurple = computed(() =>
  [
    OrderStatus.PREPARING,
    OrderStatus.SCHEDULE,
    OrderStatus.IN_TRANSIT,
    OrderStatus.PICKUP_READY,
    PurchaseOrderStatus.RECEIVING,
    PurchaseOrderStatus.SELLING,
    PurchaseOrderStatus.PENDING_APPROVAL,
    ProductCatalogStatus.SPECIAL,
    PillVariant.PURPLE,
    PillVariant.WHITE,
  ].includes(props.variant as any)
);

// ORANGE
const orderOrange = computed(() =>
  [
    OrderActionStatus.REVIEW,
    InventoryStatus.ALLOCATED,
    InventoryStatus.LOW,
    PillVariant.ORANGE,
    PurchaseOrderStatus.PARTIALLY_COMPLETED,
  ].includes(props.variant as any)
);

// RED
const orderRed = computed(() =>
  [
    OrderStatus.REFUNDED,
    OrderStatus.ON_HOLD,
    OrderStatus.CANCELLED,
    OrderStatus.PENDING,
    OrderActionStatus.CRITICAL,
    InventoryStatus.OUT_OF_STOCK,
    ServicesInventoryStatus.NOT_AVAILABLE,
    InventoryStatus.OVER_ORDEDED,
    InventoryStatus.RED,
    InvoiceStatus.OVERDUE,
    PillVariant.RED,
    "overdue",
  ].includes(props.variant)
);

// GREY
const orderGrey = computed(() =>
  [
    OrderStatus.DRAFT,
    OrderStatus.ARCHIVED,
    InventoryStatus.DRAFT,
    ServicesInventoryStatus.DRAFT,
    InventoryStatus.ARCHIVED,
    ProductCatalogStatus.DRAFT,
    ProductCatalogStatus.ARCHIVE,
    PillVariant.GREY,
    ManagementLocationsStatus.DECLINED,
  ].includes(props.variant as any)
);

// LIGHT GREY
const orderLightGrey = computed(() =>
  [PillVariant.LIGHT_GREY].includes(props.variant as any)
);
</script>
