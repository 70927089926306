import { SidebarPrefix } from "./general";
export interface Notification {
  id: number;
  name?: string;
  active?: boolean;
  email?: string;
  email_subject: string;
  email_content: string;
  triggers?: {
    type: TriggerType;
    options: any[];
  }[];
  sms: boolean;
  sms_content: string;
  deposit_amount?: number;
  deposit_percent?: number;
  due_date?: string;
  parameters?: any;
  customer_email?: string;
  customer_phone?: string;
  bcc?: boolean;
  bcc_recipients?: string[];
  count_message?: string;
  next_offer_message?: string;
}

export interface MergeTag {
  name: string;
  value: string;
}

export interface NotificationTrigger {
  name: string;
  value: string;
  optionName: string;
  optionValue: string;
  options: any[];
}

export enum TriggerType {
  //platform triggers
  DEPOSIT_REQUEST = "deposit-request",
  PAYMENT_LINK_REQUEST = "payment-link-request",
  RECEIPT_REQUEST = "receipt-request",
  EVENT_REMINDER = "event-reminder",
  EVENT_INVITATION = "event-invitation",
  PASSWORD_REQUEST = "password-reset",
  ACCOUNT_CONFIRMATION = "account-confirmation",
  SEND_STATEMENT = "send-statement",
  SEND_PAYBACK_SUMMARY = "send-payback-summary",
  SEND_ONBOARD_INVITE = "send-onboard-invite",
  SEND_ONBOARD_STAFF_INVITE = "send-onboard-staff-invite",
  SEND_ADMIN_NOTIFICATION_AFTER_ONBOARDING = "send-admin-notification-after-onboarding",
  MFA_CHALLENGE = "mfa-challenge",
  PROFILE_APPROVED = "profile-approved",
  BOOKING = "booking",
  ORDER_CONFIRMATION = "order-confirmation",

  //custom triggers
  NEW_ORDER = "new-order",
  ORDER_STATUS_CHANGE = "order-status-change",
  ORDER_TYPE_CHANGE = "order-type-change",
  ORDER_XERO_INVOICE_CREATED = "order-xero-invoice-created",
  ORDER_NOTE_CREATED = "order-note-created",
  CUSTOMER_NOTE_CREATED = "customer-note-created",
  NOTIFY_NEW_ONLINE_ORDER = "notify-new-online-order",
}

export enum NotificationType {
  DEPOSIT_REQUEST = "deposit",
  PAYMENT_LINK_REQUEST = "payment-link",
  RECEIPT_REQUEST = "order-receipt",
  EVENT_REMINDER = "event-reminder",
  EVENT_INVITATION = "event-invitation",
  BULK_CONTACT_SEND_NOTIFICATION = "bulk-contact-send-notification",
  BULK_ORDER_SEND_NOTIFICATION = "bulk-order-send-notification",
  BULK_PURCHASE_ORDER_SEND_NOTIFICATION = "bulk-purchase-order-send-notification",
  CUSTOM_NOTIFICATION = "custom-notification",
  STATEMENT = "statement",
  BULK_MANAGEMENT_LOCATION_SEND_NOTIFICATION = "bulk-management-location-send-notification",
  BULK_MANAGEMENT_BRAND_SEND_NOTIFICATION = "bulk-management-brand-send-notification",
  ONBOARD_INVITE = "onboard-invite",
}

export enum NotificationTag {
  DOWNLOAD = "{Download}",
  USER_EMAIL = "{User Email}",
  USER_NAME = "{User Name}",
  DEFAULT_STORE = "{Default Store}",
  LOCATION = "{Location}",
  CUSTOMER = "{Customer}",
  SUPPLIER = "{Supplier}",
  ORDER_STATUS = "{Order Status}",
  ORDER_TYPE = "{Order Type}",
  ORDER_NUMBER = "{Order Number}",
  ORDER_DETAILS = "{Order Details}",
  LOGIN_LINK = "{Login Link}",
  DELIVERY_ADDRESS = "{Delivery Address}",
  BILLING_ADDRESS = "{Billing Address}",
  SHIPPING_ADDRESS = "{Shipping Address}",
  STATEMENT = "{Statement}",
  PERIOD = "{Period}",
  DUE_DATE = "{Due Date}",
  PAYMENT_LINK = "{Payment Link}",
  DEPOSIT_AMOUNT = "{Deposit Amount}",
  INVOICE_LINK = "{Invoice Link}",
  RECEIPT_LINK = "{Receipt Link}",
  BILL_LINK = "{Bill Link}",
  EMAIL = "{Email}",
  RECEIVED_DATE = "{Received Date}",
  ONBOARD_LINK = "{Onboard Link}",
  OUTSTANDING_STATEMENT = "{Outstanding Statement}",
  ACTIVITIES_STATEMENT = "{Activities Statement}",
  PAYBACK_SUMMARY = "{Payback Summary}",
  ESTIMATED_TIME = "{Estimated Time}",
}

export const SidebarPage = {
  NOTIFICATIONS_ORDER_NOTIFICATION: `${SidebarPrefix.NOTIFICATIONS}-order-notification`,
  NOTIFICATIONS_SEND_STATEMENT: `${SidebarPrefix.NOTIFICATIONS}-send-statement`,
  NOTIFICATIONS_PURCHASE_ORDER_NOTIFICATION: `${SidebarPrefix.NOTIFICATIONS}-purchase-order-notification`,
  NOTIFICATIONS_CONTACT_NOTIFICATION: `${SidebarPrefix.NOTIFICATIONS}-contact-notification`,
  NOTIFICATIONS_SEND_INVOICE: `${SidebarPrefix.NOTIFICATIONS}-send-invoice`,
  NOTIFICATIONS_SEND_ONBOARD_INVITE: `${SidebarPrefix.NOTIFICATIONS}-send-onboard-invite`,
  NOTIFICATIONS_SEND_BILL: `${SidebarPrefix.NOTIFICATIONS}-send-bill`,
  NOTIFICATIONS_DEPOSIT_REQUEST: `${SidebarPrefix.NOTIFICATIONS}-deposit-request`,
  NOTIFICATIONS_PAYMENT_LINK_REQUEST: `${SidebarPrefix.NOTIFICATIONS}-payment-link-request`,
  NOTIFICATIONS_RECEIPT_REQUEST: `${SidebarPrefix.NOTIFICATIONS}-receipt-request`,
  NOTIFICATIONS_MANAGEMENT_LOCATION_NOTIFICATION: `${SidebarPrefix.NOTIFICATIONS}-management-location-notification`,
  NOTIFICATIONS_MANAGEMENT_BRAND_NOTIFICATION: `${SidebarPrefix.NOTIFICATIONS}-management-brand-notification`,
};
