import { CustomerType } from "./customers";
import { Media, ReportExtension, SidebarPrefix } from "./general";
import { Product } from "./products";
import { StoreShort } from "./stores";
import { User } from "./users";

export interface Count {
  id: number;
  name: string;
  type: CountType;
  status: CountStatus;
  store: StoreShort;
  is_grouped_by_supplier: boolean;
  person: {
    id: number;
    type: CustomerType;
    name: string;
    email: string;
    phone: string;
    title: null | string;
    user: User;
    image: Media;
  };
  scheduled_at: string;
  created_at: string;
  completed_at: string;
}

export interface CountSingle extends Count {
  settings: {
    include_archived: boolean;
  };
}

export interface CountReviewItem {
  id: number;
  store: StoreShort;
  product: Product;
  counts: CountReviewItemCount[];
}

export interface CountReviewItemCount {
  lot: {
    id: number;
    stock: number;
    reserved_stock: number;
    sold_stock: number;
    base_stock: number;
    withdrawn_stock: number;
  };
  ordered: number;
  expected: number;
  counted: number;
  difference: number;
  cost: number;
  reason: number;
  note: number;
}

export interface CountReviewItemFlattened {
  id: number;
  store: StoreShort;
  product: Product;
  lot: {
    id: number;
    stock: number;
    reserved_stock: number;
    sold_stock: number;
    base_stock: number;
    withdrawn_stock: number;
    supplier?: {
      id: number;
      name: string;
    };
  };
  supplier?: {
    id: number;
    name: string;
  };
  ordered: number;
  expected: number;
  estimated: number;
  counted: number;
  difference: number;
  cost: number;
  reason: number;
  note: number;
  is_checked?: boolean;
  countItemId?: number;
}

export interface CountStartPayload {
  name: string;
  store_id: number;
  person_id: number;
  type: CountType;
  settings: {
    include_archived?: boolean;
    suppliers?: (number | string)[];
    tags?: (number | string)[];
    categories?: (number | string)[];
    attribute_values?: (number | string)[];
  };
}

export interface CountUpdatePayload {
  inventory_id: number;
  count: number;
  lot_id?: number;
  supplier_id?: number;
  reason?: CountUpdateReason;
  note?: string;
}

export interface EstimatedUpdatePayload {
  inventory_id: number;
  estimated: number;
  lot_id?: number;
  supplier_id?: number;
}

export interface CountAdjustItemPayload {
  amount: number;
  reason: CountUpdateReason;
  note: {
    text: string;
    is_private: boolean;
    is_pinned: boolean;
  };
}

export interface CountReportPayload {
  format?: ReportExtension;
  barcodes: boolean;
  cost: boolean;
}

export interface CountNotification {
  count_id: number;
  name: string;
  status: CountStatus;
}

export enum CountActionStep {
  MANAGE = "manage",
  PREVIEW = "preview",
  SUMMARY = "summary",
}

export enum CountType {
  PARTIAL = "partial",
  FULL = "full",
  BALANCE = "balance",
}

export enum CountFilter {
  MATCHED = "matched",
  UNMATCHED = "unmatched",
  EXCLUDED = "excluded",
  OUTDATED = "outdated",
}

export enum CountStatus {
  NEW = "new",
  PREPARING = "preparing",
  SCHEDULED = "scheduled",
  IN_PROGRESS = "in-progress",
  PROCESSING = "processing",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export enum CountUpdateReason {
  DAMAGED = "damaged",
  STOLEN = "stolen",
  EVAPORATED = "evaporated",
  DETERIORATED = "deteriorated",
  EXPIRED = "expired",
  REVISED = "revised",
}

export enum CountItemOption {
  COUNT = "count",
  SKIP = "skip",
  INCLUDE = "include",
  CONVERT = "convert",
  ADJUST = "adjust",
  RESET_COUNT = "reset-count",
}

// SIDEBAR
export const SidebarPage = {
  COUNTS_START: `${SidebarPrefix.COUNTS}-start`,
  COUNTS_COUNT: `${SidebarPrefix.COUNTS}-count`,
  COUNTS_FILTERS: `${SidebarPrefix.COUNTS}-filters`,
};
