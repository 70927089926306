import { openDB, IDBPDatabase } from "idb";
import {
  ElectronDBSchema,
  IDBTransactionMode,
  IndexRules,
  IndexedDBStore,
  ResponseList,
  StoreValue,
} from "~/types/bff";
import pkg from "~/package.json";

interface IIndexedDB {
  getDB(): Promise<IDBPDatabase<ElectronDBSchema>>;
  getAll<S extends IndexedDBStore>(
    store: S
  ): Promise<ResponseList<StoreValue<S>>>;
  add<S extends IndexedDBStore>(
    store: S,
    items: StoreValue<S> | StoreValue<S>[]
  ): Promise<void>;
  getItem<S extends IndexedDBStore>(
    store: S,
    id: string | IDBKeyRange
  ): Promise<StoreValue<S> | undefined>;
  getItemsByIndex<S extends IndexedDBStore>(
    storeName: S,
    indexName: string,
    queryValue: any,
    page: number,
    limit: number
  ): Promise<ResponseList<StoreValue<S>>>;
  updateItem<S extends IndexedDBStore>(
    store: S,
    item: StoreValue<S>
  ): Promise<void>;
  search<S extends IndexedDBStore>(options: {
    store: IndexedDBStore;
    term: string;
    fields: string[];
    filterFn?: (item: StoreValue<S>) => boolean;
    page?: number;
    limit?: number;
  }): Promise<ResponseList<StoreValue<S>>>;
  deleteItem(store: IndexedDBStore, id: string | IDBKeyRange): Promise<void>;
  clearStore(store: IndexedDBStore): Promise<void>;
  closeDB(): Promise<void>;
  deleteStore(store: IndexedDBStore): Promise<void>;
}

export default class IndexedDB implements IIndexedDB {
  private static instance: IndexedDB;
  private static dbPromise: Promise<IDBPDatabase<ElectronDBSchema>> | null =
    null;

  constructor() {}

  // singleton pattern
  public static async getInstance(dbName = "electron-idb"): Promise<IndexedDB> {
    if (!this.instance) {
      this.instance = new IndexedDB();

      // initialize DB and potentially upgrade schema, handling all stores
      this.dbPromise = openDB<ElectronDBSchema>(
        dbName,
        parseInt(pkg.idbVersion) ?? 1,
        {
          upgrade(db, oldVersion, newVersion) {
            // dynamically create all necessary stores if they don't exist
            Object.values(IndexedDBStore).forEach((storeName) => {
              if (!db.objectStoreNames.contains(storeName)) {
                const store = db.createObjectStore(storeName, {
                  // TODO: provide keyPath rules for each store
                  keyPath:
                    storeName === IndexedDBStore.POS_PRODUCTS
                      ? "product.id"
                      : "id",
                });

                // create indexes for the store
                IndexRules[storeName]?.forEach(({ name, keyPath, options }) => {
                  store.createIndex(name as never, keyPath, options);
                });
              }
            });
          },
        }
      );
    }
    await this.dbPromise; // ensure the DB is initialized before returning the instance

    return this.instance;
  }

  ///////////////////////////////////////////////////////////////////////////

  async getDB(): Promise<IDBPDatabase<ElectronDBSchema>> {
    return IndexedDB.dbPromise;
  }

  // get all items from a store
  async getAll<S extends IndexedDBStore>(
    store: S,
    page?: number,
    limit?: number
  ): Promise<ResponseList<StoreValue<S>>> {
    try {
      const db = await this.getDB();
      const total_items = await this.countItems(store);

      return {
        data: await db.getAll(store),
        meta: {
          page: page ?? 1,
          total_pages: total_items ? Math.ceil(total_items / (limit ?? 1)) : 1,
          total_items,
        },
      };
    } catch (error) {
      console.error(`Error getting all items from store: ${store}`, error);
      throw new Error(`Error getting all items from store: ${store}`);
    }
  }

  // add an item or items to a store
  async add<S extends IndexedDBStore>(
    store: S,
    items: StoreValue<S> | StoreValue<S>[]
  ): Promise<void> {
    try {
      const db = await this.getDB();
      const tx = db.transaction(store, IDBTransactionMode.READWRITE);
      if (Array.isArray(items)) {
        for (const item of items) {
          await tx.store.put(item);
        }
      } else {
        await tx.store.put(items);
      }
      await tx.done;
    } catch (error) {
      console.error(`Error adding items to store: ${store}`, error);
      throw new Error(`Error adding items to store: ${store}`);
    }
  }

  // get an item from a store
  async getItem<S extends IndexedDBStore>(
    store: S,
    id: string | IDBKeyRange
  ): Promise<StoreValue<S> | undefined> {
    try {
      const db = await this.getDB();
      return db.get(store, id) as Promise<StoreValue<S> | undefined>;
    } catch (error) {
      console.error(`Error getting item: ${id} from store: ${store}`, error);
      throw new Error(`Error getting item: ${id} from store: ${store}`);
    }
  }

  // query by index
  async getItemsByIndex<S extends IndexedDBStore>(
    storeName: S,
    indexName: string,
    queryValue: any,
    page: number,
    limit: number
  ): Promise<ResponseList<StoreValue<S>>> {
    try {
      const db = await this.getDB();
      const tx = db.transaction(storeName, "readonly");
      const store = tx.objectStore(storeName);
      const index = store.index(indexName as never);

      const allResults = await index.getAll(queryValue);

      const total_items = allResults.length;
      const total_pages = Math.ceil(total_items / limit);

      const data = allResults.slice((page - 1) * limit, page * limit);

      return {
        data,
        meta: {
          page,
          total_pages,
          total_items,
        },
      };
    } catch (error) {
      console.error(
        `Error getting item by index: ${indexName} from store: ${storeName}`,
        error
      );
      throw new Error(
        `Error getting item by index: ${indexName} from store: ${storeName}`
      );
    }
  }

  // update an item in a store
  async updateItem<S extends IndexedDBStore>(
    store: S,
    item: StoreValue<S>
  ): Promise<void> {
    try {
      const db = await this.getDB();
      const tx = db.transaction(store, IDBTransactionMode.READWRITE);
      await tx.store.put(item);
      await tx.done;
    } catch (error) {
      console.error(`Error updating item: ${item} in store: ${store}`, error);
      throw new Error(`Error updating item: ${item} in store: ${store}`);
    }
  }

  // search items in a store
  async search<S extends IndexedDBStore>({
    store,
    term,
    fields,
    filterFn,
    page = 1,
    limit = 50,
  }: {
    store: IndexedDBStore;
    term: string;
    fields: string[];
    filterFn?: (item: StoreValue<S>) => boolean;
    page?: number;
    limit?: number;
  }): Promise<ResponseList<StoreValue<S>>> {
    try {
      const db = await this.getDB();
      const tx = db.transaction(store, "readonly");
      const objectStore = tx.objectStore(store);

      const termLowerCase = term.toLowerCase();
      const results = new Map<string, StoreValue<S>>();

      for (const field of fields) {
        const indexField = field.split(".").pop();

        // INDEX search
        if (objectStore.indexNames.contains(indexField)) {
          const index = objectStore.index(indexField);
          let cursor = await index.openCursor(
            IDBKeyRange.bound(termLowerCase, termLowerCase + "\uffff")
          );

          while (cursor) {
            const item = cursor.value as StoreValue<S>;

            if (!filterFn || filterFn(item)) {
              results.set(item.id as string, item);
            }

            cursor = await cursor.continue();
          }
        } else {
          // FULL search
          let cursor = await objectStore.openCursor();

          while (cursor) {
            const item = cursor.value as StoreValue<S>;

            if (this.matchesSearchCriteria(item, termLowerCase, [field])) {
              if (!filterFn || filterFn(item)) {
                results.set(item.id as string, item);
              }
            }

            cursor = await cursor.continue();
          }
        }
      }

      // Convert results map to an array
      const resultsArray = Array.from(results.values());

      // Paginate results
      const paginatedResults = resultsArray.slice(
        (page - 1) * limit,
        page * limit
      );
      const total_items = resultsArray.length;
      const total_pages = Math.ceil(total_items / limit);

      return {
        data: paginatedResults,
        meta: {
          page,
          total_pages,
          total_items,
        },
      };
    } catch (error) {
      console.error(`Error searching store: ${store}`, error);
      throw new Error(`Error searching store: ${store}`);
    }
  }

  private matchesSearchCriteria<T>(
    item: T,
    searchTerm: string,
    searchFields: string[]
  ): boolean {
    for (const field of searchFields) {
      const fieldValue = this.getNestedValue(item, field);

      if (Array.isArray(fieldValue)) {
        if (
          fieldValue.some((value) =>
            String(value).toLowerCase().includes(searchTerm)
          )
        ) {
          return true;
        }
      } else if (
        fieldValue &&
        String(fieldValue).toLowerCase().includes(searchTerm)
      ) {
        return true;
      }
    }

    return false;
  }

  private getNestedValue(object: any, path: string) {
    const keys = path.split(".");
    let value = object;

    for (const key of keys) {
      if (Array.isArray(value)) {
        value = value.map((item) => item[key]).filter(Boolean);
        if (value.length === 0) {
          return undefined;
        }
      } else if (value && typeof value === "object" && key in value) {
        value = value[key];
      } else {
        return undefined;
      }
    }

    return value;
  }

  async countItems<S extends IndexedDBStore>(
    store: IndexedDBStore
  ): Promise<number> {
    try {
      const db = await this.getDB();
      const tx = db.transaction(store, "readonly");
      const objectStore = tx.objectStore(store);

      const count = await objectStore.count();

      return count;
    } catch (error) {
      console.error(`Error counting items in store: ${store}`, error);
      throw new Error(`Error counting items in store: ${store}`);
    }
  }

  // delete an item from a store
  async deleteItem(
    store: IndexedDBStore,
    id: string | IDBKeyRange
  ): Promise<void> {
    try {
      const db = await this.getDB();
      await db.delete(store, id);
    } catch (error) {
      console.error(`Error deleting item: ${id} from store: ${store}`, error);
      throw new Error(`Error deleting item: ${id} from store: ${store}`);
    }
  }

  ///////////////////////////////////////////////////////////////////////////

  // clear a store
  async clearStore(store: IndexedDBStore): Promise<void> {
    try {
      const db = await this.getDB();
      await db.clear(store);
    } catch (error) {
      console.error(`Error clearing store: ${store}`, error);
      throw new Error(`Error clearing store: ${store}`);
    }
  }

  // close the DB
  async closeDB(): Promise<void> {
    try {
      const db = await this.getDB();
      db.close();
    } catch (error) {
      console.error("Error closing DB", error);
      throw new Error("Error closing DB");
    }
  }

  // delete a store
  async deleteStore(store: IndexedDBStore): Promise<void> {
    try {
      const db = await this.getDB();
      db.deleteObjectStore(store);
    } catch (error) {
      console.error(`Error deleting store: ${store}`, error);
      throw new Error(`Error deleting store: ${store}`);
    }
  }
}
