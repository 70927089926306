let stop;

let throttle = (cb, time) => {
  if (stop) return;

  stop = true;

  setTimeout(() => {
    cb();
    stop = false;
  }, time);
};

export const useInfiniteScroll = (e, cb, isLoading?) => {
  throttle(() => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    // const scrollPosition = Math.round(scrollTop + clientHeight);

    if (scrollTop + clientHeight + 500 > scrollHeight && !isLoading) {
      cb();
    }
  }, 500);
};
