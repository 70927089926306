import { defineStore } from "pinia";
import { usePaymentStore } from "./payment";
import { useGeneralStore } from "./general";
import { useSettingsStore } from "./settings";
import { useNotesStore } from "./notes";
import { useChannelsStore } from "./channels";
import { useTasksStore } from "~/store/tasks";
import {
  ConfirmationListParams,
  ConfirmationReport,
  KanbanLane,
  Order,
  OrderCustomTag,
  OrderInvoice,
  OrderItem,
  OrderManage,
  OrderOption,
  OrderStatus,
  OrdersBulkPrintPayload,
  OrdersHistoryChangesAttribute,
  OrdersStatistics,
  SingleOrderReportType,
} from "~/types/orders";
import {
  DateRange,
  Meta,
  Option,
  OrderType,
  ReportExtension,
  ResponseType,
  SortType,
  SyncStatus,
  ToastType,
  Filters as OldFilters,
} from "~/types/general";
import { Filters, FilterType } from "~/types/filters";
import { Customer, CustomerSingle } from "~/types/customers";
import { InvoiceReport, PickingListParams } from "~/types/reports";
import { Totals } from "~/types/payment";
import { merge } from "chart.js/dist/helpers/helpers.core";
import { FulfilmentStatus } from "~/types/orders";
import { InvoiceDateTypes, InvoicePaymentStatus } from "~/types/invoices";
import { NotificationTag } from "~/types/notifications";

export const useOrdersStore = defineStore("orders", () => {
  // Order Logic
  const orders = ref<Order[]>([]);
  const addons = ref([]);
  const meta = ref<Meta>({} as Meta);
  const viewingOrder = ref<Order>({} as Order);
  const cachedOrder = ref<Order>({} as Order);
  const isLoading = ref(false);
  const editMode = ref(false);
  const files = ref<Document[]>([]);
  const payloadPayLinkForNewOrder = ref({});
  const payloadPayLinkForDeposit = ref({});
  const downloadReportLink = ref("");
  const creatingOrder = ref<OrderManage>({} as OrderManage);
  const creatingOrderCustomer = ref<Customer | CustomerSingle>(
    {} as Customer | CustomerSingle
  );
  const isShowModalCreate = ref(false);
  const editingOrder = ref<OrderManage>({} as OrderManage);
  const editingOrderCustomer = ref<Customer | CustomerSingle>(
    {} as Customer | CustomerSingle
  );
  const viewingTag = ref<OrderCustomTag>({} as OrderCustomTag);
  const viewingOrderForPrint = ref<Order>({} as Order);
  const viewingPreparationForPrint = ref<any>({} as any);
  const viewingInvoiceForPrint = ref<InvoiceReport>({} as InvoiceReport);
  const statusTags = ref<Option[]>([
    {
      name: "New",
      value: OrderStatus.PROCESSING,
    },
    {
      name: "Preparing",
      value: OrderStatus.PREPARING,
    },
    {
      name: "Scheduled",
      value: OrderStatus.SCHEDULE,
    },
    {
      name: "In Transit",
      value: OrderStatus.IN_TRANSIT,
    },
    {
      name: "Pick Up Ready",
      value: OrderStatus.PICKUP_READY,
    },
    {
      name: "On Hold",
      value: OrderStatus.ON_HOLD,
    },
    {
      name: "Refunded",
      value: OrderStatus.REFUNDED,
    },
    {
      name: "Pending",
      value: OrderStatus.PENDING,
    },
    {
      name: "Completed",
      value: OrderStatus.COMPLETED,
    },
    {
      name: "Cancelled",
      value: OrderStatus.CANCELLED,
    },
    { name: "Archived", value: OrderStatus.ARCHIVED },
    {
      name: "Draft",
      value: OrderStatus.DRAFT,
    },
    {
      name: "Active",
      value: OrderStatus.ACTIVE,
    },
    {
      name: "Awaiting Payment",
      value: OrderStatus.AWAITING_PAYMENT,
    },
    {
      name: "Paid",
      value: OrderStatus.PAID,
    },
    {
      name: "Overdue",
      value: OrderStatus.OVERDUE,
    },
  ]);
  const orderOptions = ref<Option[]>([
    {
      name: "Quick View",
      value: OrderOption.QUICK_VIEW,
    },
    {
      name: "View Customer Profile",
      value: OrderOption.VIEW_CUSTOMER,
    },
    {
      name: "Remove from Payment",
      value: OrderOption.REMOVE_FROM_PAYMENT,
    },
    {
      name: "View Details",
      value: OrderOption.VIEW_DETAILS,
    },
    {
      name: "Refund",
      value: OrderOption.REFUND,
    },
  ]);
  const fulfilmentStatuses = ref<Option[]>([
    { name: "Picked", value: FulfilmentStatus.PICKED },
    { name: "Invoice Sent", value: FulfilmentStatus.INVOICE_SENT },
    { name: "Invoice Viewed", value: FulfilmentStatus.INVOICE_VIEWED },
    { name: "Zero Priced", value: FulfilmentStatus.HAS_ZERO_PRICED_PRODUCTS },
    { name: "Priority", value: FulfilmentStatus.PRIORITY },
    {
      name: "Non-Allocated",
      value: FulfilmentStatus.HAS_NON_ALLOCATED_PRODUCTS,
    },
  ]);
  const orderHistoryOptions = ref<Option[]>([
    {
      name: "Quick View",
      value: OrderOption.QUICK_VIEW,
    },
    {
      name: "View Details",
      value: OrderOption.VIEW_DETAILS,
    },
    {
      name: "Refund",
      value: OrderOption.REFUND,
    },
  ]);
  const orderHistoryAttributes = ref<Option[]>([
    {
      name: "status",
      value: OrdersHistoryChangesAttribute.STATUS,
    },
    {
      name: "target date",
      value: OrdersHistoryChangesAttribute.TARGET_DATE,
    },
    {
      name: "lane",
      value: OrdersHistoryChangesAttribute.LANE,
    },
    {
      name: "customer",
      value: OrdersHistoryChangesAttribute.CUSTOMER,
    },
    {
      name: "type",
      value: OrdersHistoryChangesAttribute.TYPE,
    },
  ]);
  const viewingInvoice = ref<OrderInvoice>({} as OrderInvoice);
  const viewingOrderInvoice = ref<any>({} as any);
  const customOrderTags = ref([]);
  const selectedStatuses = ref<Option[]>([]);
  const paymentStatuses = ref<Option[]>([
    {
      name: "Paid",
      value: "paid",
    },
    {
      name: "Refunded",
      value: "refunded",
    },
    {
      name: "Partial Payment",
      value: "partially_paid",
    },
    {
      name: "Overdue",
      value: "overdue",
    },
  ]);

  const invoicePaymentStatuses = ref<Option[]>([
    { name: "Unpaid", value: InvoicePaymentStatus.UNPAID },
    { name: "Credit", value: InvoicePaymentStatus.CREDIT },
    { name: "Paid", value: InvoicePaymentStatus.PAID },
    { name: "Refunded", value: InvoicePaymentStatus.REFUNDED },
    { name: "Gifted", value: InvoicePaymentStatus.GIFTED },
  ]);

  const dateTypes = ref<Option[]>([
    { name: "Creation Date", value: InvoiceDateTypes.CREATION },
    { name: "Target Date", value: InvoiceDateTypes.TARGET },
  ]);
  const selectedPaymentStatuses = ref<Option[]>([]);
  const targetDateRange = ref<string[]>([]);
  const paymentDateRange = ref<string[]>([]);
  const filtersAddCustomer = ref<OldFilters>({
    type: {
      type: "type",
      title: "Types",
      options: [],
      chosenOptions: [],
    },
    group: {
      type: "group",
      title: "Groups",
      options: [],
      chosenOptions: [],
    },
    store: {
      type: "store",
      title: "Locations",
      options: [],
      chosenOptions: [],
    },
    taxonomies: {
      type: "taxonomies",
      title: "Tags",
      options: [],
      chosenOptions: [],
    },
  });
  const filtersSearchProduct = ref<OldFilters>({
    taxonomies_categories: {
      type: "taxonomies_categories",
      title: "Category",
      options: [],
      chosenOptions: [],
    },
    taxonomies_tags: {
      type: "taxonomies_tags",
      title: "Tags",
      options: [],
      chosenOptions: [],
    },
    attribute: {
      type: "attribute",
      title: "Attributes",
      options: [],
      chosenOptions: [],
    },
    store: {
      type: "store",
      title: "Locations",
      options: [],
      chosenOptions: [],
    },
    stock: {
      type: "stock",
      title: "Hide out of stock products",
      options: [
        { name: "in-stock", value: "in-stock" },
        { name: "low-stock", value: "low-stock" },
        { name: "out-of-stock", value: "out-of-stock" },
      ],
      chosenOptions: [],
      toggle: false,
    },
    show_hidden: {
      type: "show_hidden",
      title: useConfig().isMultilocation
        ? "Show hidden products"
        : "Show customer’s hidden products",
      options: [],
      chosenOptions: [],
      toggle: false,
    },
  });
  const showDraftInventoryProducts = ref(false);
  const isGuestFilter = ref(false);
  const pageTable = ref(1);
  const perPageTable = ref(10);
  const viewingReport = ref<ConfirmationReport>({} as ConfirmationReport);
  const selectedRowsIds = ref([]);
  const baseTableKey = ref(0);
  const isShowModalDiscountOrder = ref(false);
  const isShowModalDiscountOrderItem = ref(false);
  const movedToNewOrderProducts = ref(false);

  const mergeTagsList = ref([
    { name: "User Email", value: NotificationTag.USER_EMAIL },
    { name: "User Name", value: NotificationTag.USER_NAME },
    { name: "Default Store", value: NotificationTag.DEFAULT_STORE },
    { name: "Location", value: NotificationTag.LOCATION },
    { name: "Customer", value: NotificationTag.CUSTOMER },
    { name: "Order Status", value: NotificationTag.ORDER_STATUS },
    { name: "Order Type", value: NotificationTag.ORDER_TYPE },
    { name: "Order Number", value: NotificationTag.ORDER_NUMBER },
    { name: "Order Details", value: NotificationTag.ORDER_DETAILS },
    { name: "Login Link", value: NotificationTag.LOGIN_LINK },
    { name: "Delivery Address", value: NotificationTag.DELIVERY_ADDRESS },
    { name: "Billing Address", value: NotificationTag.BILLING_ADDRESS },
    { name: "Due Date", value: NotificationTag.DUE_DATE },
    { name: "Payment Link", value: NotificationTag.PAYMENT_LINK },
    { name: "Invoice Link", value: NotificationTag.INVOICE_LINK },
    { name: "Receipt Link", value: NotificationTag.RECEIPT_LINK },
    { name: "Estimated Time", value: NotificationTag.ESTIMATED_TIME },
    // { name: "Onboard Link", value: NotificationTag.ONBOARD_LINK },
  ]);

  const customerFilterOptions = computed((): Option[] => {
    const customers = orders.value?.map((order) => ({
      name: order.customer?.name,
      value: order.customer?.id,
    }));

    return [
      ...new Map(customers.map((item) => [item.value, item])).values(),
    ].filter((customer) => customer.name);
  });

  const isOverdueSelected = computed(
    () => !!selectedPaymentStatuses.value.find((o) => o.value === "overdue")
  );

  const isAccountSelected = computed(
    () => !!selectedPaymentStatuses.value.find((o) => o.value === "account")
  );

  async function getOrdersList(payload?): Promise<ResponseType<Order[]>> {
    try {
      const response = await useVaniloApi("/orders", {
        params: {
          ...(payload && payload),
        },
      });

      return response as ResponseType<Order[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleOrder(
    id: number | string,
    sort?: SortType,
    sortName?: SortType
  ): Promise<ResponseType<Order>> {
    try {
      const response = (await useVaniloApi(`/orders/${id}`, {
        params: {
          ...(sort && { sort }),
          ...(sortName && { "sort-name": sortName }),
        },
      })) as ResponseType<Order>;

      viewingOrder.value = response?.data;

      return response as ResponseType<Order>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createOrder(
    order?: OrderManage
  ): Promise<ResponseType<Order>> {
    const body = {
      ...(order || creatingOrder.value),
      ...(useNotesStore().notes?.length && {
        notes: useNotesStore().notes.map((i) => ({
          is_pinned: i.is_pinned,
          is_private: i.is_private,
          text: i.text,
        })),
      }),
    };

    try {
      const response = await useVaniloApi(`/orders`, {
        method: "POST",
        body,
      });

      return response as ResponseType<Order>;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function editOrder(
    id,
    order?: OrderManage | Object
  ): Promise<ResponseType<Order>> {
    const {
      shipping_address,
      billing_address,
      shipping_address_id,
      billing_address_id,
      loading_order,
      delivery_run_id,
      freight_company_id,
      ...other
    } = editingOrder.value;

    const body = (order as OrderManage) || {
      ...other,
      ...(freight_company_id
        ? {
            freight_company_id,
            delivery_run_id,
          }
        : {
            freight_company_id: null,
            delivery_run_id: null,
          }),
      ...(delivery_run_id && loading_order && { loading_order }),
      ...(shipping_address_id && {
        shipping_address_id,
      }),
      ...(billing_address_id && { billing_address_id }),
    };

    if (typeof body.channel !== "string") {
      delete body.channel;
    }

    if (!body.items?.length) {
      delete body.items;
    }

    if (body.type !== OrderType.MOBILE) {
      delete body.travel_cost;
    }

    delete body.created_by;

    try {
      const response = await useVaniloApi(`/orders/${id}`, {
        method: "POST",
        body,
      });

      return response as ResponseType<Order>;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function deleteOrder(id: number | string) {
    try {
      const response = await useVaniloApi(`/orders/${id}`, {
        method: "DELETE",
      });

      orders.value = orders.value?.filter((order) => order.id !== +id);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getOrderTargetDayTimeSlots(id, params) {
    try {
      const response = await useVaniloApi(`/orders/${id}/time-slots`, {
        params,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getOrderTags(
    params?
  ): Promise<ResponseType<OrderCustomTag[]>> {
    try {
      const response = (await useVaniloApi("/order-tags", {
        params,
      })) as ResponseType<OrderCustomTag[]>;

      customOrderTags.value = response?.data.map((i) => ({
        name: i.name,
        value: i.id,
      }));

      return response as ResponseType<OrderCustomTag[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleOrderTag(id, params?) {
    try {
      const response = await useVaniloApi(`/order-tags/${id}`, {
        params,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createOrderTag(body) {
    try {
      const response = await useVaniloApi(`/order-tags`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function updateOrderTag() {
    try {
      const response = await useVaniloApi(
        `/order-tags/${viewingTag.value.id}`,
        {
          method: "POST",
          body: { ...viewingTag.value },
        }
      );

      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function deleteOrderTag(id: number | string) {
    try {
      const response = await useVaniloApi(`/order-tags/${id}`, {
        method: "DELETE",
      });

      customOrderTags.value = customOrderTags.value?.filter(
        (i) => +i.value !== +id
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateStatus(
    orderId: number | string,
    status?: OrderStatus,
    lane_id?: number
  ): Promise<ResponseType<Order>> {
    try {
      const response = await useVaniloApi(`/orders/${orderId}/status`, {
        method: "POST",
        body: {
          ...(status && { status }),
          ...(lane_id && { lane_id }),
        },
      });

      return response as ResponseType<Order>;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function updateStaff(
    orderId: number,
    sales_manager_id: number
  ): Promise<ResponseType<Order>> {
    try {
      const response = await useVaniloApi(`/orders/${orderId}`, {
        method: "POST",
        body: {
          sales_manager_id,
        },
      });

      return response as ResponseType<Order>;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function clearFilters() {
    for (let d in filters.value) {
      filters.value[d].chosenOptions = [];
    }
  }

  async function calculateTotals(
    order: OrderManage,
    items: { product_id: number; quantity: number }[],
    originalOrderId?: string | number
  ) {
    const paymentStore = usePaymentStore();
    const channelsStore = useChannelsStore();
    const settingsStore = useSettingsStore();

    if (!items.length) return [];

    !channelsStore.channels?.length && (await channelsStore.getChannelsList());

    try {
      useIsLoading(true);
      const totalsResponse = await paymentStore.calculateTotals({
        original_order_id: originalOrderId,
        store_id: order.store_id,
        type: order.type || OrderType.IN_PERSON,
        is_express: order.is_express,
        ...(order.channel?.id &&
          channelsStore.channels?.length && {
            channel: channelsStore.channels?.find(
              (channel) => channel.id === order.channel?.id
            )?.slug,
          }),
        payment_method_id: order.payment_method_id,
        customer_id: order.customer_id,
        items,
        ...(order.type === OrderType.DELIVERY &&
          settingsStore.settings.taxes.enabled &&
          order.delivery_amount?.amount !== null && {
            delivery_amount: order.delivery_amount,
          }),
        with_express_service: true,
        with_time_slots:
          order.target_date?.[0]?.split(" ")[0] ??
          order.created_at.split(" ")[0],
        // DISCOUNTS
        ...(order.discount && {
          discount: -Math.abs(order.discount),
        }),
        ...(order.discount_percent && {
          discount_percent: order.discount_percent,
        }),
      });

      return totalsResponse;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function applyBonusToOrder(payload) {
    const body = {
      loyalty_reward_type: payload.loyaltyRewardType,
    };

    if (payload.loyaltyRewardType === "points") {
      body["amount"] = payload.amount;
    }

    try {
      const response = await useVaniloApi(
        `/orders/${payload.orderId}/apply-bonus`,
        {
          method: "POST",
          body,
        }
      );

      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function getOrdersStats(
    payload: DateRange,
    storeId?: number | string
  ): Promise<ResponseType<OrdersStatistics>> {
    try {
      const response = await useVaniloApi("/orders/statistics", {
        params: {
          "filter[store][]": storeId,
          "filter[targetDateFrom]": payload?.start,
          "filter[targetDateTo]": payload?.end,
        },
      });

      return response as ResponseType<OrdersStatistics>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function printOrderConfirmation(
    orderId: string | number,
    body: ConfirmationListParams
  ) {
    try {
      const response = (await useVaniloApi(
        `/orders/${orderId}/${SingleOrderReportType.ORDER_CONFIRMATION}`,
        {
          method: "POST",
          body,
          ...(body.format !== ReportExtension.JSON && {
            responseType: "blob",
          }),
        }
      )) as any;

      let blobUrl;

      if (body.format !== ReportExtension.JSON) {
        const blob = new Blob([response as BlobPart], { type: response.type });

        blobUrl = URL.createObjectURL(blob);
      }

      return body.format !== ReportExtension.JSON
        ? {
            url: blobUrl,
            type: response.type.split("/")[1],
          }
        : response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function printOrderPreparation(
    orderId: string | number,
    params: PickingListParams
  ) {
    try {
      const response = (await useVaniloApi(
        `/orders/${orderId}/${SingleOrderReportType.ORDER_PREPARATION}`,
        {
          params,
          responseType: params?.format ? ReportExtension.JSON : "blob",
        }
      )) as any;

      let blobUrl;

      if (!params?.format) {
        const blob = new Blob([response as BlobPart], { type: response.type });

        blobUrl = URL.createObjectURL(blob);
      }

      return !params?.format
        ? {
            url: blobUrl,
            type: response.type.split("/")[1],
          }
        : response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function printSalesSummary(orderId: string | number) {
    try {
      const response = (await useVaniloApi(
        `/orders/${orderId}/sales-summary`
      )) as any;

      const blob = new Blob([response as BlobPart], { type: response.type });

      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;

      link.setAttribute("target", "_blank");
      link.click();

      return {
        url: blobUrl,
        type: response.type.split("/")[1],
      };
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleInvoice(id: number | string) {
    try {
      const response = (await useVaniloApi(`/invoices/${id}`)) as any;

      viewingInvoice.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  function copyProducts(products: OrderItem[]) {
    localStorage.setItem("products-clipboard", JSON.stringify(products));

    useToast("Products have been copied to clipboard", {
      type: ToastType.SUCCESS,
      duration: 2000,
    });
  }

  function copySingleProduct(product: OrderItem[]) {
    localStorage.setItem("products-clipboard", JSON.stringify([product]));

    useToast("The product has been copied to clipboard", {
      type: ToastType.SUCCESS,
      duration: 2000,
    });
  }

  async function getOrderHistory(orderId?: number, params?: any) {
    try {
      const response = await useVaniloApi(
        `/orders/${orderId || viewingOrder.value?.id}/notes`,
        {
          params,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getPaymentHistory(orderId: number, params?: any) {
    try {
      const response = await useVaniloApi(
        `/orders/${orderId}/payments-history`,
        {
          params,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updatePrice(productIds?) {
    try {
      const response = await useVaniloApi(`/orders/update-price`, {
        method: "POST",
        body: {
          ...(productIds && { products: productIds }),
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkUpdate(orders, payload) {
    try {
      const response = (await useVaniloApi(`/orders/bulk-update`, {
        method: "POST",
        body: {
          orders,
          ...payload,
        },
      })) as any;

      useTasksStore().taskId = response.data.id;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkOrdersSendNotification(notification, orders?: number[]) {
    try {
      const response = (await useVaniloApi(`/orders/bulk-notify`, {
        method: "POST",
        body: {
          notification,
          ...(selectedRowsIds.value?.length && {
            orders: selectedRowsIds.value,
          }),
          ...(orders?.length && { orders }),
        },
      })) as any;

      useTasksStore().taskId = response?.data?.id;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkPrint(body: OrdersBulkPrintPayload) {
    try {
      const response = (await useVaniloApi(`/orders/bulk-print`, {
        method: "POST",
        body,
      })) as any;

      useTasksStore().taskId = response.data?.id;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkOrdersDelete(orderIds?) {
    try {
      const response = (await useVaniloApi(`/orders/bulk-delete`, {
        method: "POST",
        body: {
          ...(orderIds && { orders: orderIds }),
        },
      })) as any;

      useTasksStore().taskId = response.data.id;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function undoPayments(orders: { id: number; amount: number }[]) {
    try {
      const response = await useVaniloApi("/orders/undo-payments", {
        method: "POST",
        body: {
          orders,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function undoPaymentsById(paymentsId) {
    try {
      const response = await useVaniloApi(`/payments/${paymentsId}/undo`, {
        method: "POST",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function downloadOrdersProducts(order_items) {
    try {
      const response = await useVaniloApi("/orders/export-order-items", {
        method: "POST",
        body: {
          order_items,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function sendOrderInvoice(orderInvoice) {
    try {
      const response = await useVaniloApi("/business/send-invoices", {
        method: "POST",
        body: orderInvoice,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function editOrderMerge(payload: {
    id: string | number;
    store_id?: string | number;
    order?: any;
    items?: any[];
    merge?: { subtotal?: number; tax?: number; delete_items?: number[] };
  }): Promise<ResponseType<Order>> {
    delete payload.order?.channel;

    try {
      const response = await useVaniloApi(`/orders/${payload?.id}`, {
        method: "POST",
        body: {
          store_id: payload.store_id,
          ...payload.order,
          merge: payload.merge || {},
          items: payload.items,
        },
      });

      return response as ResponseType<Order>;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function calculateTotalsMerge(payload: {
    order: OrderManage;
    items?: any[];
    originalOrderId?: string | number;
    merge?: { subtotal?: number; tax?: number; delete_items?: number[] };
  }): Promise<ResponseType<Totals>> {
    const channelsStore = useChannelsStore();
    const settingsStore = useSettingsStore();

    !channelsStore.channels?.length && (await channelsStore.getChannelsList());

    try {
      useIsLoading(true);
      const response = await usePaymentStore().calculateTotals({
        merge: payload.merge,
        original_order_id: payload.originalOrderId,
        store_id: payload.order.store_id,
        type: payload.order.type || OrderType.IN_PERSON,
        is_express: payload.order.is_express,
        ...(payload.order.channel?.id &&
          channelsStore.channels?.length && {
            channel: channelsStore.channels?.find(
              (channel) => channel.id === payload.order.channel?.id
            )?.slug,
          }),
        payment_method_id: payload.order.payment_method_id,
        customer_id: payload.order.customer_id,
        items: payload.items,
        ...(payload.order.type === OrderType.DELIVERY &&
          settingsStore.settings.taxes.enabled &&
          payload.order.delivery_amount?.amount !== null && {
            delivery_amount: payload.order.delivery_amount,
          }),
        with_express_service: true,
        with_time_slots:
          payload.order.target_date?.[0]?.split(" ")[0] ??
          payload.order.created_at.split(" ")[0],
        // DISCOUNTS
        ...(payload.order.discount && {
          discount: -Math.abs(payload.order.discount),
        }),
        ...(payload.order.discount_percent && {
          discount_percent: payload.order.discount_percent,
        }),
      });

      return response as ResponseType<Totals>;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function createOrdersAction(body, orderId) {
    try {
      const response = await useVaniloApi(`/orders/${orderId}/actions`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function createOrdersChangeProductAction(body, orderId) {
    try {
      const response = await useVaniloApi(`/orders/${orderId}/change-product`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  // Kanban Logic
  const kanbanLanes = ref<KanbanLane[]>([]);
  const editingKanbanLane = ref<KanbanLane>({} as KanbanLane);
  const creatingKanbanLane = ref<KanbanLane>({} as KanbanLane);
  const isEditModeKanban = ref(false);
  const kanbanComponentKey = ref(0);

  async function getKanbanLanes() {
    try {
      const response = (await useVaniloApi("/kanban-lanes")) as any;

      kanbanLanes.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateKanbanLane(lane?: KanbanLane) {
    const body = lane
      ? {
          ...lane,
          id: undefined,
        }
      : {
          ...editingKanbanLane.value,
          id: undefined,
        };

    try {
      useIsLoading(true);
      const response = (await useVaniloApi(
        `/kanban-lanes/${lane?.id || editingKanbanLane.value?.id}`,
        {
          method: "POST",
          body,
        }
      )) as any;

      kanbanLanes.value = kanbanLanes.value?.map((lane) =>
        lane.id === response?.data.id ? response?.data : lane
      );

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function updateKanbanLanesBulk(lanes: KanbanLane[]) {
    // remove 'position' and 'type' from lanes
    lanes.forEach((lane) => {
      delete lane.position;
      delete lane.type;
      delete lane.meta;
    });

    const body = {
      lanes: lanes.map((lane, index) => {
        if (index === 0 || index === lanes.length - 1) {
          return {
            id: lane.id,
            name: lane.name,
          };
        } else {
          return lane;
        }
      }),
    };

    try {
      useIsLoading(true);
      const response = (await useVaniloApi("/kanban-lanes/bulk-update", {
        method: "POST",
        body,
      })) as any;

      kanbanLanes.value = response?.data;

      return response;
    } catch (error: any) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function createKanbanLane(lane?: KanbanLane) {
    try {
      useIsLoading(true);
      const response = (await useVaniloApi("/kanban-lanes", {
        method: "POST",
        body: creatingKanbanLane.value,
      })) as any;

      kanbanLanes.value?.splice(
        kanbanLanes.value.length - 1,
        0,
        response?.data
      );
    } catch (error: any) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function deleteKanbanLane() {
    try {
      useIsLoading(true);
      const response = await useVaniloApi(
        `/kanban-lanes/${editingKanbanLane.value.id}`,
        {
          method: "DELETE",
        }
      );

      kanbanLanes.value = kanbanLanes.value.filter(
        (lane) => lane.id !== editingKanbanLane.value.id
      );

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function exportOrders(body) {
    try {
      const response = await useVaniloApi("/orders/export", {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Sidebar Logic
  const isOpenSidebar = ref(false);
  const isOpenSidebarAddress = ref(false);
  const isOpenSidebarKanban = ref(false);

  // Modal Logic
  const isShowModalAccept = ref(false);
  const isShowModalDecline = ref(false);
  const isShowModalSuccess = ref(false);
  const isShowModalAddress = ref(false);

  // Filters Logic
  const filters = ref<Filters>({
    tag: {
      type: FilterType.DEFAULT,
      title: "Custom Tag",
      options: [],
      chosenOptions: [],
    },
    type: {
      type: FilterType.DEFAULT,
      title: "Order Type",
      options: [],
      chosenOptions: [],
    },
    fulfilmentStatus: {
      type: FilterType.DEFAULT,
      title: "Fulfilment Status",
      options: fulfilmentStatuses.value,
      chosenOptions: [],
    },
    ...(!useConfig().isParticipant && {
      customer: {
        type: FilterType.DEFAULT,
        title: "Customers",
        options: [],
        chosenOptions: [],
      },
    }),
    payment_method: {
      type: FilterType.DEFAULT,
      title: "Payment Type",
      options: [],
      chosenOptions: [],
    },
    status: {
      type: FilterType.TAG_LIST,
      title: "Status",
      options: statusTags.value,
      chosenOptions: [],
    },
    period: {
      type: FilterType.PERIOD,
      title: "Period",
      options: useGeneralStore().periods,
      chosenOptions: [],
      isSingle: true,
    },
    sync_statuses: {
      type: FilterType.TAG_LIST,
      title: "Sync Status",
      options: [
        { name: "Pending", value: SyncStatus.PENDING },
        { name: "Synced", value: SyncStatus.SYNCED },
        { name: "Error", value: SyncStatus.ERROR },
      ],
      chosenOptions: [],
    },
    creditService: {
      type: FilterType.SWITCHER,
      title: "Credit Service",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    date: {
      type: FilterType.MAIN_DATE,
      title: "Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    paymentCondition: {
      type: FilterType.DEFAULT,
      title: "Payment Status",
      subtitle: "Select the payment status you would like included",
      options: [
        { name: "Paid", value: "paid" },
        { name: "Refunded", value: "refunded" },
        { name: "Partial Payment", value: "partially_paid" },
        { name: "Overdue", value: "overdue" },
      ],
      chosenOptions: [],
    },
    target_date: {
      type: FilterType.DATE_RANGE,
      title: "Target Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    payment_date_range: {
      type: FilterType.NEW_DATE_RANGE,
      title: "Payment Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    letter: {
      type: FilterType.LETTER,
      title: "Alphabet letter",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    search: {
      type: FilterType.SEARCH,
      title: "Search",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
  });

  return {
    // Order Logic
    orders,
    addons,
    meta,
    viewingOrder,
    cachedOrder,
    isLoading,
    editMode,
    files,
    payloadPayLinkForNewOrder,
    payloadPayLinkForDeposit,
    downloadReportLink,
    creatingOrder,
    creatingOrderCustomer,
    isShowModalCreate,
    editingOrder,
    editingOrderCustomer,
    viewingTag,
    viewingOrderForPrint,
    viewingPreparationForPrint,
    viewingInvoiceForPrint,
    statusTags,
    orderOptions,
    orderHistoryOptions,
    orderHistoryAttributes,
    viewingInvoice,
    viewingOrderInvoice,
    customOrderTags,
    selectedStatuses,
    paymentStatuses,
    selectedPaymentStatuses,
    targetDateRange,
    paymentDateRange,
    filtersAddCustomer,
    filtersSearchProduct,
    showDraftInventoryProducts,
    isGuestFilter,
    pageTable,
    perPageTable,
    viewingReport,
    selectedRowsIds,
    baseTableKey,
    isShowModalDiscountOrder,
    isShowModalDiscountOrderItem,
    movedToNewOrderProducts,
    customerFilterOptions,
    isOverdueSelected,
    isAccountSelected,
    dateTypes,
    mergeTagsList,
    invoicePaymentStatuses,
    getOrdersList,
    getSingleOrder,
    createOrder,
    editOrder,
    deleteOrder,
    getOrderTargetDayTimeSlots,
    getOrderTags,
    getSingleOrderTag,
    createOrderTag,
    updateOrderTag,
    deleteOrderTag,
    updateStatus,
    updateStaff,
    clearFilters,
    calculateTotals,
    applyBonusToOrder,
    getOrdersStats,
    printOrderConfirmation,
    printOrderPreparation,
    printSalesSummary,
    getSingleInvoice,
    copyProducts,
    copySingleProduct,
    getOrderHistory,
    getPaymentHistory,
    updatePrice,
    bulkUpdate,
    bulkOrdersSendNotification,
    bulkPrint,
    bulkOrdersDelete,
    undoPayments,
    undoPaymentsById,
    downloadOrdersProducts,
    sendOrderInvoice,
    editOrderMerge,
    calculateTotalsMerge,
    createOrdersAction,
    createOrdersChangeProductAction,
    exportOrders,

    // Kanban Logic
    kanbanLanes,
    editingKanbanLane,
    creatingKanbanLane,
    isEditModeKanban,
    kanbanComponentKey,
    getKanbanLanes,
    updateKanbanLane,
    updateKanbanLanesBulk,
    createKanbanLane,
    deleteKanbanLane,

    // Sidebar Logic
    isOpenSidebar,
    isOpenSidebarAddress,
    isOpenSidebarKanban,

    // Modal Logic
    isShowModalAccept,
    isShowModalDecline,
    isShowModalSuccess,
    isShowModalAddress,

    // Filters Logic
    filters,
    fulfilmentStatuses,
  };
});
