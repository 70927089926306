<template>
  <perfect-scrollbar
    :options="options"
    :class="{ 'visible-scroll': visibleScroll }"
    ref="scrollbarApi"
  >
    <div ref="slotContent">
      <slot />
    </div>
  </perfect-scrollbar>
</template>

<script lang="ts" setup>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import type { PerfectScrollbarExpose } from "vue3-perfect-scrollbar";

export interface Settings {
  switcher?: boolean;
  tagname?: string;
  wheelSpeed?: number;
  wheelPropagation?: boolean;
  swipeEasing?: boolean;
  minScrollbarLength?: number;
  maxScrollbarLength?: number;
  scrollingThreshold?: number;
  useBothWheelAxes?: boolean;
  suppressScrollX?: boolean;
  suppressScrollY?: boolean;
  scrollXMarginOffset?: number;
  scrollYMarginOffset?: number;
}

const props = defineProps<{
  options?: Settings;
  visibleScroll?: boolean;
}>();

const scrollbarApi = ref<PerfectScrollbarExpose | null>(null);
const slotContent = ref<HTMLElement | null>(null);

const observer = new MutationObserver(() => {
  if (scrollbarApi.value) {
    scrollbarApi.value.ps.update();
  }
});

onMounted(() => {
  if (slotContent.value) {
    observer.observe(slotContent.value, { childList: true, subtree: true });
  }
});

onBeforeUnmount(() => {
  observer.disconnect();
});
</script>
<style>
.visible-scroll .scrolling-y > .ps__rail-y {
  opacity: 1 !important;
  display: block !important;
}
.visible-scroll .ps__rail-y {
  display: block !important;
  opacity: 1 !important;
  background-color: #ffffff !important;
  width: 4px !important;
  border-radius: 5px;
}
.visible-scroll .ps__thumb-y {
  width: 4px !important;
  background-color: #e7e7e9;
  margin-right: -2px;
  cursor: pointer;
}
</style>
