import {
  Customer,
  CustomerManage,
  CustomerSingle,
  CustomerShort,
} from "~/types/customers";
import { User } from "~/types/users";

export const useGetCustomerName = (
  customer: Customer | CustomerSingle | CustomerManage | CustomerShort | User
) =>
  (customer as Customer | User | CustomerShort)?.name ||
  (customer as CustomerManage)?.company_name ||
  `${(customer as CustomerManage)?.firstname || ""} ${
    (customer as CustomerManage)?.lastname || ""
  }` ||
  "";
