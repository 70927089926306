import { defineStore } from "pinia";
import {
  Option,
  ResponseType,
  ToastType,
  Document,
  Media,
} from "~/types/general";
import {
  InventoryActionItem,
  InventoryActionManage,
  InventoryActionNotification,
  InventoryActionStatus,
} from "~/types/inventory-actions";
import { LotActionPayload } from "~/types/inventory";
import {} from "~/store/inventory";

export const useInventoryActionsStore = defineStore("inventory-actions", () => {
  // State
  const isNewNotifications = ref(false);
  const actions = ref<InventoryActionItem[]>([]);
  const actionsList = ref<InventoryActionItem[]>([]);
  const actionsNotifications = ref<InventoryActionNotification[]>([]);
  const viewingAction = ref<InventoryActionItem>({} as InventoryActionItem);
  const files = ref<Document[] | Media[]>([]);

  const statusTags = ref<Option[]>([
    {
      name: "Review",
      value: InventoryActionStatus.REVIEW,
    },
    {
      name: "Critical",
      value: InventoryActionStatus.CRITICAL,
    },
    {
      name: "Done",
      value: InventoryActionStatus.DONE,
    },
  ]);

  // API Calls
  async function getActionsList(
    id,
    payload?
  ): Promise<ResponseType<InventoryActionItem[]>> {
    let response: any;
    const params = {
      ...(payload && payload),
    };

    try {
      response = await useVaniloApi(`/inventories/${id}/actions`, { params });
    } catch (error) {
      console.log(error);
    }
    actions.value = response?.data;

    return response;
  }

  async function createAction(id, body: LotActionPayload) {
    try {
      const response = await useVaniloApi(`/inventories/${id}/actions`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateAction(
    id,
    body?: InventoryActionManage
  ): Promise<ResponseType<InventoryActionItem>> {
    let response: any;

    try {
      response = await useVaniloApi(`/inventories/actions/${id}`, {
        method: "POST",
        body,
      });
    } catch (error: any) {
      console.log(error);
      if (error) {
        Object.values(error.data.errors).forEach((error) =>
          useToast(error[0], {
            type: ToastType.ERROR,
            duration: 3000,
          })
        );
      }
    }

    return response;
  }

  async function deleteAction(id) {
    let response: any;

    try {
      response = await useVaniloApi(`/inventories/actions/${id}`, {
        method: "DELETE",
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  async function markAsSeen(payload) {
    let response: any;

    try {
      response = await useVaniloApi(`/actions/mark-seen`, {
        method: "POST",
        body: { ...payload },
      });
    } catch (error) {
      console.log(error);
    }

    return response;
  }

  // Return state and methods
  return {
    // State
    isNewNotifications,
    actions,
    actionsList,
    actionsNotifications,
    viewingAction,
    files,
    statusTags,
    // Methods
    getActionsList,
    createAction,
    updateAction,
    deleteAction,
    markAsSeen,
  };
});
