import { defineStore } from "pinia";
import {
  Product,
  ProductType,
  ProductStatus,
  ProductFilterOption,
  ProductFilter,
  ProductSingleImage,
  ProductSaleStatus,
  ProductProperty,
  ProductPos,
  ProductsExportPayload,
  ProductManage,
  ServiceType,
  ServiceAvailability,
  ServiceScheduleType,
  ServiceDurationType,
  ProductComposite,
  ProductMetrics,
} from "~/types/products";
import { Filters as OldFilters, Media, Document, ToastType } from "~/types/general";
import { Filters, FilterType } from "~/types/filters";
import { Meta, ResponseType } from "~/types/general";
import { useStoresStore } from "./stores";
import { useNotesStore } from "./notes";
import { useUsersStore } from "./users";
import { StorageService } from "~/services/StorageService";
import { IndexedDBStore, ResponseList } from "~/types/bff";

export const useProductsStore = defineStore("products", () => {
  // Options
  const productStatusOptions = [
    { name: "Available", value: ProductStatus.AVAILABLE },
    { name: "Archive", value: ProductStatus.ARCHIVE },
    { name: "Special", value: ProductStatus.SPECIAL },
  ];

  const productTypeOptions = [
    { name: "Simple", value: ProductType.SIMPLE },
    { name: "Variant", value: ProductType.VARIANT },
    { name: "Addon", value: ProductType.ADDON },
    { name: "Variable", value: ProductType.MASTER },
    { name: "Bulk", value: ProductType.BULK },
  ];
  const productStatuses = [
    { name: "Available", value: ProductStatus.AVAILABLE },
    { name: "Archive", value: ProductStatus.ARCHIVE },
    { name: "Draft", value: ProductStatus.DRAFT },
    { name: "Special", value: ProductStatus.SPECIAL },
  ];
  const productSaleStatuses = [
    { name: "Scheduled", value: ProductSaleStatus.SCHEDULED },
    { name: "Ongoing", value: ProductSaleStatus.ONGOING },
  ];
  const createProductTypes = [
    { name: "Simple", value: ProductType.SIMPLE },
    { name: "Variable", value: ProductType.MASTER },
    { name: "Service", value: ProductType.SERVICE },
    { name: "Composite", value: ProductType.SET },
    { name: "Bulk", value: ProductType.BULK },
  ];
  const shippingClass = [{ name: "Class 200", value: "class-200" }];
  const serviceTypes = [
    { name: "Hourly", value: ServiceType.HOURLY },
    { name: "Group", value: ServiceType.GROUP },
  ];
  const serviceAvailabilityTypes = [
    { name: "In Clinic", value: ServiceAvailability.IN_CLINIC },
    { name: "Virtual", value: ServiceAvailability.VIRTUAL },
    { name: "Mobile", value: ServiceAvailability.MOBILE },
  ];
  const serviceScheduleTypes = [
    { name: "Once Off", value: ServiceScheduleType.ONCE_OFF },
    { name: "Fixed", value: ServiceScheduleType.FIXED },
    { name: "Recurring", value: ServiceScheduleType.RECURRING },
  ];
  const serviceDurationTypes = [
    { name: "Hours", value: ServiceDurationType.HOURS },
    { name: "Days", value: ServiceDurationType.DAYS },
    { name: "Weeks", value: ServiceDurationType.WEEKS },
  ];
  const productMetrics = [
    { name: "Liters", value: ProductMetrics.LITERS },
    { name: "Milliliters", value: ProductMetrics.MILLILITERS },
    { name: "Centiliters", value: ProductMetrics.CENTILITERS },
    { name: "Cubic Meters", value: ProductMetrics.CUBIC_METERS },
    { name: "Kilograms", value: ProductMetrics.KILOGRAMS },
    { name: "Grams", value: ProductMetrics.GRAMS },
    { name: "Milligrams", value: ProductMetrics.MILLIGRAMS },
    { name: "Metric Tonnes", value: ProductMetrics.METRIC_TONNES },
    { name: "Meters", value: ProductMetrics.METERS },
    { name: "Centimeters", value: ProductMetrics.CENTIMETERS },
    { name: "Millimeters", value: ProductMetrics.MILLIMETERS },
    { name: "Boxes", value: ProductMetrics.BOXES },
    { name: "Cartons", value: ProductMetrics.CARTONS },
    { name: "Crates", value: ProductMetrics.CRATES },
    { name: "Decks", value: ProductMetrics.DECKS },
    { name: "Bins", value: ProductMetrics.BINS },
    { name: "Cases", value: ProductMetrics.CASES },
    { name: "Dozens", value: ProductMetrics.DOZENS },
    { name: "Packs", value: ProductMetrics.PACKS },
    { name: "Pieces", value: ProductMetrics.PIECES },
    { name: "Single Items", value: ProductMetrics.SINGLE_ITEMS },
    { name: "Trays", value: ProductMetrics.TRAYS },
  ];

  // Product settings
  const isEditProductsSettings = ref(false);
  const settingsSearchValue = ref("");

  // Main page (catalog)
  const variationView = ref(false);
  const variantMode = ref(false);
  const isLoading = ref(false);
  const isOpenSidebar = ref(false);
  const page = ref(1);
  const perPage = ref(40);
  const totalItems = ref<number | null>(null);
  const meta = ref<Meta>({} as Meta);
  const pageTable = ref(1);
  const perPageTable = ref(10);

  // Products
  const products = ref<Product[]>([]);
  const productsSearch = ref("");

  // Services
  const services = ref<Product[]>([]);
  const servicesSearch = ref("");

  // Composite
  const compositeList = ref<Product[]>([]);
  const compositeSearch = ref("");

  // Single page
  const editMode = ref(false);
  const sellProducts = ref<Product[]>([]);

  // Head product
  const viewingProduct = ref<Product>({} as Product);
  const editingProduct = ref<ProductManage>({} as ProductManage);
  const tags = ref([]);
  const categories = ref([]);
  const itemNumbers = ref([]);
  const modifiers = ref([]);
  const upsell = ref([]);
  const cross_sell = ref([]);
  const variations = ref([]);
  const service_packages = ref<Product[]>([]);
  const composite_products = ref<ProductComposite[]>([]);
  const composite_deal = ref<ProductComposite[]>([]);
  const selectedAttributes = ref([]);
  const selectedAttributesValues = ref([]);
  const attributesGenerateVariations = ref<number[]>([]);
  const image = ref<ProductSingleImage>({} as ProductSingleImage);
  const gallery = ref<Media[]>([]);
  const galleryNewImage = ref<ProductSingleImage>({} as ProductSingleImage);
  const galleryNew = ref<ProductSingleImage[]>([]);
  const files = ref<Document[] | Media[]>([]);
  const selectedRecipeComponents = ref([]);
  const aliasBarcodes = ref([]);
  const variationAliasBarcodes = ref([]);

  // Product variant
  const viewingVariationProduct = ref<Product>({} as Product);
  const editingVariationProduct = ref<ProductManage | Product>(
    {} as ProductManage | Product
  );
  const viewingCompositeProduct = ref<ProductComposite>({} as ProductComposite);
  const editingCompositeProduct = ref<ProductComposite>({} as ProductComposite);
  const varitation_image = ref<ProductSingleImage[]>([]);
  const variation_media = ref([]);
  const variation_tags = ref([]);
  const variation_categories = ref([]);
  const variation_modifiers = ref([]);
  const variation_properties = ref<ProductProperty[]>([]);
  const variation_availability = ref([]); // for service
  const variation_team_members = ref([]); // for service
  const variation_opening_hours = ref([]); // for service

  // Filters
  const filters = ref({
    taxonomies: {
      type: "taxonomies",
      title: "Category",
      options: [] as ProductFilterOption[],
      chosenOptions: [],
    } as ProductFilter,
    type: {
      type: "type",
      title: "Product Type",
      options: productTypeOptions as ProductFilterOption[],
      chosenOptions: [],
    } as ProductFilter,
    status: {
      type: "status",
      title: "Status",
      options: productStatusOptions as ProductFilterOption[],
      chosenOptions: [],
    } as ProductFilter,
  });

  const filtersProductCatalogue = ref<Filters>({
    is_on_sale: {
      type: FilterType.SWITCHER,
      title: "View Products On Sale",
      options: [],
      chosenOptions: [],
      toggle: false,
    },
    taxonomies_categories: {
      type: FilterType.DEFAULT,
      title: "Category",
      options: [],
      chosenOptions: [],
    },
    type: {
      type: FilterType.DEFAULT,
      title: "Type",
      options: productTypeOptions,
      chosenOptions: [],
    },
    state: {
      type: FilterType.TAG_LIST,
      title: "Status",
      options: productStatuses,
      chosenOptions: [],
    },
    taxonomies_tags: {
      type: FilterType.TAG_LIST,
      title: "Tags",
      options: [],
      chosenOptions: [],
    },
    search: {
      type: FilterType.SEARCH,
      title: "Search",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    letter: {
      type: FilterType.LETTER,
      title: "Alphabet letter",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
  });

  // Getters
  const channelNameReports = computed(
    () => `private-reports.${useUsersStore().currentUser?.id?.toString()}`
  );
  const channelNamePrivateDataProcessing = computed(
    () =>
      `private-data-processing.${useUsersStore().currentUser?.id?.toString()}`
  );

  // Actions
  async function getProducts(payload?): Promise<ResponseType<Product[]>> {
    const params = {
      page: page.value,
      limit: perPage.value,
      ...(payload && { ...payload }),
    };

    try {
      const response = await useVaniloApi("/products", { params });

      return response as ResponseType<Product[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getProductsBff(payload?: {
    params?: any;
    filters?: any;
    noFetchMain?: boolean;
    force?: boolean;
    page?: number;
    limit?: number;
  }): Promise<ResponseList<Product>> {
    const { params, filters, noFetchMain, force, page, limit } = payload || {};

    try {
      const response = await new StorageService(
        useRuntimeConfig()
      ).fetchList<IndexedDBStore.PRODUCTS>({
        endpoint: "products",
        store: IndexedDBStore.PRODUCTS,
        params,
        filters,
        noFetchMain,
        force,
        page,
        limit,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleProduct(
    id?: number | string
  ): Promise<ResponseType<Product>> {
    try {
      const response = await useVaniloApi(`/products/${id}`);

      return response as ResponseType<Product>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleProductBff(id?: number | string): Promise<Product> {
    try {
      const response = await new StorageService(
        useRuntimeConfig()
      ).fetchItem<IndexedDBStore.PRODUCTS>({
        endpoint: "products",
        store: IndexedDBStore.PRODUCTS,
        id,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createProduct(payload): Promise<ResponseType<Product>> {
    const { notes, ...other } = payload;

    const body = {
      ...other,
      ...(useNotesStore().notes?.length && {
        notes: useNotesStore().notes.map((i) => ({
          is_pinned: i.is_pinned,
          is_private: i.is_private,
          text: i.text,
        })),
      }),
    };

    try {
      const response = (await useVaniloApi("/products", {
        method: "POST",
        body,
      })) as ResponseType<Product>;

      if (
        response?.data &&
        response?.data?.type !== ProductType.SERVICE &&
        response?.data?.type !== ProductType.SET
      ) {
        products.value.push(response?.data);

        // update BFF products list
        await new StorageService(
          useRuntimeConfig()
        ).addItem<IndexedDBStore.PRODUCTS>({
          store: IndexedDBStore.PRODUCTS,
          body: response.data,
        });
      }

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateProduct(body): Promise<ResponseType<Product>> {
    try {
      const response = (await useVaniloApi(`/products/${body.id}`, {
        method: "POST",
        body,
      })) as ResponseType<Product>;

      viewingProduct.value = response?.data;
      editMode.value = false;

      // update BFF products list
      await new StorageService(
        useRuntimeConfig()
      ).updateItem<IndexedDBStore.PRODUCTS>({
        store: IndexedDBStore.PRODUCTS,
        id: response.data?.id,
        body: response.data,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteProduct(id: number | string) {
    try {
      const response = await useVaniloApi(`/products/${id}`, {
        method: "DELETE",
      });

      products.value = products.value.filter((product) => product.id !== +id);

      // delete from BFF products list
      await new StorageService(
        useRuntimeConfig()
      ).deleteItem<IndexedDBStore.PRODUCTS>({
        store: IndexedDBStore.PRODUCTS,
        id,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkUpdateProducts(body) {
    try {
      const response = await useVaniloApi(`/products/bulk-update`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkDeleteProducts(body) {
    try {
      const response = await useVaniloApi(`/products/bulk-delete`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateProductModifiers(body) {
    try {
      const response = await useVaniloApi(`/products/${body.id}`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getTaxes() {
    try {
      const response = await useVaniloApi("/taxes");

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateVariation(body) {
    try {
      const response = await useVaniloApi(`/products/variation/${body.id}`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function generateVariations(id, body) {
    try {
      const response = await useVaniloApi(
        `/products/${id}/generate-variations`,
        {
          method: "POST",
          body,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createVariation(parentId, body) {
    try {
      const response = await useVaniloApi(
        `/products/${parentId}/create-variation`,
        {
          method: "POST",
          body,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function copyProduct(
    productId: number
  ): Promise<ResponseType<Product>> {
    try {
      const response = await useVaniloApi(`/products/${productId}/copy`, {
        method: "POST",
      });

      return response as ResponseType<Product>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createCompositeProduct(
    payload
  ): Promise<ResponseType<Product>> {
    const { notes, ...other } = payload;

    const body = {
      ...other,
      ...(useNotesStore().notes?.length && {
        notes: useNotesStore().notes.map((i) => ({
          is_pinned: i.is_pinned,
          is_private: i.is_private,
          text: i.text,
        })),
      }),
    };

    try {
      const response = (await useVaniloApi("/products", {
        method: "POST",
        body,
      })) as ResponseType<Product>;

      compositeList.value.push(response.data);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateCompositeProduct(body): Promise<ResponseType<Product>> {
    try {
      const response = (await useVaniloApi(`/products/${body.id}`, {
        method: "POST",
        body,
      })) as ResponseType<Product>;

      viewingProduct.value = response?.data;
      editMode.value = false;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateProductImage(id, type?): Promise<ResponseType<Media[]>> {
    const formData = new FormData();

    type === "variation"
      ? variation_media.value.forEach((i) => {
          formData.append("media[]", i.media);
        })
      : galleryNew.value.forEach((i) => {
          formData.append("media[]", i.media);
        });

    try {
      const response = (await useVaniloApi(`/products/${id}/gallery`, {
        method: "POST",
        body: formData,
      })) as ResponseType<Media[]>;

      if (!type && type !== "gallery" && type !== "variation") {
        gallery.value = response?.data;
      }
      if (type && type === "variation") {
        variation_media.value = response?.data;
      }

      return response as ResponseType<Media[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteProductImage(id) {
    try {
      const response = await useVaniloApi(`/products/gallery/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function uploadDocument(id) {
    const formData = new FormData();

    (files.value as Document[])
      .filter((i) => i.file)
      .forEach((i) => {
        formData.append("media[]", i.file);
        formData.append("meta[][title]", i.title);
      });

    try {
      const response = await useVaniloApi(`/products/${id}/documents`, {
        method: "POST",
        body: formData,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteDocument(id) {
    try {
      const response = await useVaniloApi(`/products/documents/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function exportProducts(body: ProductsExportPayload) {
    try {
      const response = await useVaniloApi(`/tasks/export`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function importProducts(data) {
    try {
      const response = await useVaniloApi(`/tasks/import`, {
        method: "POST",
        body: data,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function importAliasBarcodes(data) {
    try {
      const response = await useVaniloApi(`/products/upload-alias-barcodes`, {
        method: "POST",
        body: data,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getProductsPos(payload?): Promise<ResponseType<ProductPos[]>> {
    try {
      const response = await useVaniloApi("/products/pos", {
        params: {
          ...payload,
          ...(!payload.store_id && {
            store_id: useStoresStore().stores?.[0]?.id,
          }),
        },
      });

      return response as ResponseType<ProductPos[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getProductsPosBff(payload: {
    filters?: any;
    search?: any;
    params?: any;
    noFetchMain?: boolean;
    force?: boolean;
  }): Promise<ResponseList<ProductPos>> {
    const { params, noFetchMain, force, filters, search } = payload || {};

    try {
      const response = await new StorageService(
        useRuntimeConfig()
      ).fetchList<IndexedDBStore.POS_PRODUCTS>({
        endpoint: "products/pos",
        store: IndexedDBStore.POS_PRODUCTS,
        filters,
        search,
        params,
        noFetchMain,
        force,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function clearFilters(): Promise<ResponseType<Product[]>> {
    Object.values(filters.value).forEach((filter: ProductFilter) => {
      filter.chosenOptions = [];
    });

    try {
      const response = await getProducts();

      return response as ResponseType<Product[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function searchProducts(
    page = 1,
    perPage = 10,
    params = null
  ): Promise<ResponseType<Product[]>> {
    try {
      const response = (await useVaniloApi("/products/search", {
        params: {
          ...(page && { page: page }),
          ...(perPage && { limit: perPage }),
          ...params,
        },
      })) as ResponseType<Product[]>;

      products.value = response?.data;
      meta.value = response.meta;

      return response;
    } catch (error) {
      useErrorNotifications(error);
    } finally {
      useIsLoading(false);
    }
  }

  return {
    // Product settings
    isEditProductsSettings,
    settingsSearchValue,

    // Main page (catalog)
    variationView,
    variantMode,
    isLoading,
    isOpenSidebar,
    page,
    perPage,
    totalItems,
    meta,
    pageTable,
    perPageTable,

    // Products
    products,
    productsSearch,

    // Services
    services,
    servicesSearch,

    // Composite
    compositeList,
    compositeSearch,

    // Single page
    editMode,
    sellProducts,

    // Head product
    viewingProduct,
    editingProduct,
    tags,
    categories,
    itemNumbers,
    modifiers,
    upsell,
    cross_sell,
    variations,
    service_packages,
    composite_products,
    composite_deal,
    selectedAttributes,
    selectedAttributesValues,
    attributesGenerateVariations,
    image,
    gallery,
    galleryNewImage,
    galleryNew,
    files,
    selectedRecipeComponents,
    aliasBarcodes,
    variationAliasBarcodes,

    // Product variant
    viewingVariationProduct,
    editingVariationProduct,
    viewingCompositeProduct,
    editingCompositeProduct,
    varitation_image,
    variation_media,
    variation_tags,
    variation_categories,
    variation_modifiers,
    variation_properties,
    variation_availability,
    variation_team_members,
    variation_opening_hours,

    // Filters
    filters,
    filtersProductCatalogue,

    // Options
    productTypeOptions,
    productStatuses,
    productSaleStatuses,
    createProductTypes,
    shippingClass,
    serviceTypes,
    serviceAvailabilityTypes,
    serviceScheduleTypes,
    serviceDurationTypes,
    productMetrics,

    // Getters
    channelNameReports,
    channelNamePrivateDataProcessing,

    // Actions
    getProducts,
    getProductsBff,
    getSingleProduct,
    getSingleProductBff,
    createProduct,
    updateProduct,
    deleteProduct,
    bulkUpdateProducts,
    updateProductModifiers,
    getTaxes,
    updateVariation,
    generateVariations,
    createVariation,
    copyProduct,
    createCompositeProduct,
    updateCompositeProduct,
    updateProductImage,
    deleteProductImage,
    uploadDocument,
    deleteDocument,
    exportProducts,
    importProducts,
    getProductsPos,
    getProductsPosBff,
    clearFilters,
    searchProducts,
    importAliasBarcodes,
    bulkDeleteProducts,
  };
});
