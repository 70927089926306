export default {
  agreement: () => import("/vercel/path0/layouts/agreement.vue"),
  blank: () => import("/vercel/path0/layouts/blank.vue"),
  clean: () => import("/vercel/path0/layouts/clean.vue"),
  default: () => import("/vercel/path0/layouts/default.vue"),
  flow: () => import("/vercel/path0/layouts/flow.vue"),
  pos: () => import("/vercel/path0/layouts/pos.vue"),
  print: () => import("/vercel/path0/layouts/print.vue"),
  auth: () => import("/vercel/path0/node_modules/.c12/github_Jaffle_io_0vvtmvrKLt/layouts/auth.vue"),
  "jaffle-design": () => import("/vercel/path0/node_modules/.c12/github_Jaffle_io_0vvtmvrKLt/layouts/jaffle-design.vue")
}