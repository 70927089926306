export const useFormatPrice = (price: string | number, noSymbol?: boolean) => {
  const originPrice = (+price).toFixed(2);

  const formatCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return !isNaN(+originPrice)
    ? formatCurrency.format(+originPrice).replace("$", noSymbol ? "" : "$")
    : "";
};
