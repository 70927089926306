import {
  Customer,
  CustomerType,
  CustomerSubtype,
  CustomerShort,
} from "./customers";
import {
  Address,
  OrderType,
  Option,
  SidebarPrefix,
  Media,
  Meta,
  ReportExtension,
  Country,
  Province,
} from "./general";
import { InventoryStatus, InventoryUnit } from "./inventory";
import { PaymentMethodGateway } from "./payment";
import { ProductType } from "./products";
import { UserType, User } from "./users";
import { Note } from "./general";
import {
  PurchaseOrder,
  PurchaseOrderStatus,
  PurchaseOrderType,
} from "./purchase";
import { StoreShort } from "./stores";
import { Table } from "./table-ordering";
import { Notification } from "./notifications";

export interface Order {
  id: number;
  order_number?: number | string;
  payment_link?: string;
  xero_invoice?: {
    xero_invoice_id?: string;
    status?: XeroInvoiceStatus;
  };
  received_at?: string;
  preparation_duration?: number;
  payment_token?: string;
  supplier?: {
    name: string;
    id: number;
    email: string;
    notifications?: Notification[];
  };
  paid_amount?: number;
  xero_invoice_id?: number;
  invoice_due_date?: string;
  invoice_is_sent?: boolean;
  invoice_is_viewed?: boolean;
  is_bill_sent?: boolean;
  is_zero_priced?: boolean;
  is_bill_viewed?: boolean;
  calculated_invoice_due_date?: string;
  store: StoreShort;
  type: OrderType;
  tag?: {
    name: string;
    id: number;
  };
  is_priority?: boolean;
  is_express: boolean;
  is_overdue: boolean;
  is_partially_paid: boolean;
  po_number?: string;
  is_inclusive_tax: boolean;
  created_by?: {
    id: number;
    name: string;
    image: Media;
  };
  picked_by?: User;
  number: string;
  status: OrderStatus;
  credit_amount: number;
  credit_payment_amount: number;
  paid_with_credit: number;
  credited_days: number;
  tax_value: number;
  subtotal_value: number;
  total_value: number;
  target_date?: string[];
  created_at: string;
  age: string;
  delivery_run?: {
    id: number;
    name: string;
    delivery_date: string;
    loading_at: string;
    customer: null;
  };
  loading_order?: number;
  freight_company?: {
    id: number;
    type: string;
    name: string;
    email: string;
    phone: string;
    subtypes: CustomerSubtype[];
    image?: {
      id: number;
      title: string;
      description: string;
      url: string;
      conversions: any[];
    };
  };
  unit_checks?: OrderUnitChecks;
  customer: CustomerShort;
  billing_address: Address;
  shipping_address: Address;
  shipping_recipient?: {
    email: string;
    phone: string;
    firstname: string;
    lastname: string;
  };
  units?: number;
  lane?: {
    id: number;
    name: string;
  };
  picker_lane?: {
    id: number;
    name: string;
  };
  receiver_lane?: {
    id: number;
    name: string;
  };
  items: OrderItem[];
  travel_cost?: number;
  transitions: {
    lane_id: number;
    status: OrderStatus;
  }[];
  picker_transitions: {
    lane_id: number;
    status: OrderStatus;
  }[];
  payment_method: {
    id: number;
    gateway: string;
    name: string;
  };
  payment_status: OrderPaymentStatus;
  adjustments?: {
    amount: number;
    data: any;
    name: string;
    type: string;
  }[];
  sales_manager?: {
    id: number;
    type: CustomerType;
    name: string;
    email: string;
    phone: string;
    title: string;
    image?: {
      conversions: {
        thumbnail: string;
      };
      description: string;
      id: number;
      title: string;
      url: string;
    };
    user: {
      id: number;
      name: string;
      email: string;
      type: UserType;
      is_active: boolean;
      stores: StoreShort[];
    };
  };
  is_picked?: boolean;
  channel?: {
    id: number;
    name: string;
    disable_express_service_rules: boolean;
    disable_lead_time_rules: boolean;
    hide_creator: boolean;
    surcharge: number;
    surcharge_percent: number;
  };
  table: Table;
  application_fee: number;
  net_payout: number;
  hold_notes?: OrderHoldNote[];
  levies_value: number;
  sync_status: string;
  notes?: Note[];
}
export interface OrderCustomTag {
  id?: number;
  name: string;
  description?: string;
}

export enum OrderStatus {
  ACTIVE = "active",
  AWAITING_PAYMENT = "awaiting_payment",
  PAID = "paid",
  OVERDUE = "overdue",
  PROCESSING = "processing",
  PREPARING = "preparing",
  SCHEDULE = "schedule",
  IN_TRANSIT = "in-transit",
  PICKUP_READY = "pickup-ready",
  ON_HOLD = "on-hold",
  REFUNDED = "refunded",
  PENDING = "pending",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  DRAFT = "draft",
  ARCHIVED = "archived",

  // ALLOCATED = "allocated",
  // OVER_ORDEDED = "over-ordeded",
  // RED = "red",
  // GREEN = "green",
  // LOW = "low",
  // NEW = "new",
  // READY = "ready",
  // ARCHIVE = "archive",
  // FAILED = "failed",
}

export enum OrderPaymentStatus {
  PAID = "paid",
  UNPAID = "unpaid",
  DECLINED = "declined",
  PENDING = "pending",
  CREDIT = "credit",
}

export interface OrderItem extends Option {
  name: string;
  product_type: ProductType;
  product_id: number;
  inventory_id: number;
  product_parent_id: number;
  quantity: number;
  allocated: number | string;
  auto_allocated?: number | string;
  price: number;
  old_price?: number;
  addons_price: number;
  price_total: number;
  discount_total?: number;
  is_sale_price: boolean;
  addons?: {
    name: string;
    value: number | string;
  }[];
  notes?: string;
  picker_notes?: string;
  id?: number;
  area?: string;
  ext_title?: string;
  media?: {
    id: number;
    title: string;
    description: string;
    url: string;
    conversions: {
      thumbnail: string;
    };
  }[];
  properties?: {
    name: string;
    slug: string;
    title: string;
    value: string;
    symbol_html?: string;
  }[];
  order_quantity: {
    min: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
    increment: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
    order_limit: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
  };
  is_picked?: boolean;
  is_deleted?: boolean;
  is_printed?: boolean;
  picked?: number | string;
  received?: number;
  order_units?: InventoryUnit;
  source_store_id?: number;
  lot_id?: number;
  supplier_id?: number;
  supplier?: {
    name: string;
    id: number;
  };
  lot?: {
    base_stock: number;
    id: number;
    lot_number: string;
    price: number;
    reserved_stock: number;
    sold_stock: number;
    stock: number;
    supplier: CustomerShort;
    withdrawn_stock: number;
  };
  discount?: number;
  discount_percent?: number;
  index?: number;
  composite_items?: OrderItemCompositePayload[];
  set_items?: any[];
}

export interface OrderItemOption extends Option {
  id: number;
  image?: string;
  quantity: number;
  price: number;
  total: number;
  isChanged?: boolean;
  tax: number;
  description?: string;
  subtext?: string;
  symbols?: string;
  area?: string;
  pricing?: any;
  expected_stock?: number;
  stock_status?: InventoryStatus;
  order_quantity?: {
    min: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
    increment: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
    order_limit: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
  };
}

export interface OrderManage {
  id?: number;
  created_at: string;
  travel_cost?: number;
  created_by: {
    id: number;
    name: string;
    image: Media;
  };
  status: OrderStatus;
  store_id: number;
  tag_id?: number;
  table_id?: number;
  table_group_id?: number;
  paid_amount?: number;
  is_priority?: boolean;
  sales_manager_id: number;
  type: OrderType;
  is_express: boolean;
  is_picked?: boolean;
  target_date: string[];
  po_number?: string;
  items?: {
    id?: number;
    product_id: number;
    quantity: number;
    description?: string;
    is_picked?: boolean;
    composite_items?: OrderItemCompositePayload[];
  }[];
  delivery_amount?: {
    amount: number;
    is_inclusive_tax: boolean;
  };
  picked_by?: User;
  picked_by_id?: number;
  customer_id: number;
  billing_address_id?: number;
  shipping_address_id?: number;
  billing_address?: OrderManageAddressBilling;
  shipping_address?: OrderManageAddressShipping;
  shipping_recipient?: {
    firstname?: string;
    lastname?: string;
    email?: string;
    phone?: string;
  };
  freight_company_id?: number;
  delivery_run_id?: number;
  loading_order?: number;
  payment_method_id: number;
  lane_id?: number;
  picker_lane?: {
    id: number;
    name: string;
  };
  picker_lane_id?: number;
  notes?: Note[];
  unit_checks?: OrderUnitChecks;
  register_id?: number;
  invoice_due_date?: string;
  channel?: string;
  application_fee: number;
  net_payout: number;
  discount?: number;
  discount_percent?: number;
  hold_notes?: OrderHoldNote[];
}

export interface OrderManageAddressBilling {
  id?: number;
  country?: string | Country;
  province: string | Province;
  name?: string;
  postalcode: string;
  city: string;
  address: string;
  tag?: string;
  notes?: string;
  billpayer?: {
    email?: string;
    phone?: string;
    mobile?: string;
    firstname?: string;
    lastname?: string;
    company_name?: string;
    tfn?: string;
    abn?: string;
    billing_contact_id?: number;
  };
  billing_contacts?: number[] | Option[];
  lat?: number | string;
  lng?: number | string;
}

export interface OrderManageAddressShipping {
  id?: number;
  country?: string | Country;
  province: string | Province;
  postalcode: string;
  city: string;
  address: string;
  tag?: string;
  name?: string;
  notes?: string;
  freight_company_id?: number;
  lat?: number | string;
  lng?: number | string;
  bay_number?: string;
  hide_address_on_receipt?: boolean;
  is_default?: boolean;
}

export interface OrdersHistory {
  id: number;
  category: string;
  created_at: string;
  is_pinned: boolean;
  is_private: boolean;
  meta: OrdersHistoryMeta;
  user: User;
  text: string;
}

export interface OrderUnitChecks {
  lines?: number;
  products?: number;
  units?: {
    id: number;
    name: string;
    count: number;
  }[];
}

export interface OrdersHistoryMeta {
  on_account?: boolean;
  payment_status?: string;
  payment_method?: string;
  payment_amount?: number;
  transaction_amount?: number;
  dashboard_link?: string;
  transaction_id?: number;
  changes?: OrdersHistoryChanges[];
}

export interface OrdersHistoryChanges {
  attribute?: string;
  new?: string;
  old?: string;
  quantity?: number;
  name?: string;
  price?: number;
  type: OrdersHistoryChangesType;
}

export interface OrderItemCompositePayload {
  set_item_id: number;
  id?: number;
  product_id?: number;
  notes?: string;
  addons?: {
    name: string;
    value: string | string[];
  }[];
}

export interface AllocationValidationError {
  message: string;
  overallocation: boolean;
  products: {
    product_id: number;
    needed: number;
    available: number;
  }[];
}

export enum OrderItemType {
  CARD = "card",
  POS_ORDER = "pos-order",
}

export enum OrdersHistoryChangesType {
  ADDED = "item_added",
  REMOVED = "item_removed",
  ITEM_CHANGED = "item_changed",
  ATTRIBUTE_CHANGED = "attr_changed",
  ITEM_PICKED = "item_picked",
}

export enum OrdersHistoryChangesAttribute {
  STATUS = "status",
  LANE = "lane",
  TYPE = "type",
  CUSTOMER = "customer",
  TARGET_DATE = "target_date",
  PAYMENT_METHOD = "payment_method",
  PRICE = "price",
  QUANTITY = "quantity",
  ALLOCATED = "allocated",
}

export enum FulfilmentStatus {
  PICKED = "picked",
  INVOICE_SENT = "invoice_sent",
  INVOICE_VIEWED = "invoice_viewed",
  PRIORITY = "priority",
  HAS_ZERO_PRICED_PRODUCTS = "has_zero_priced_products",
  HAS_NON_ALLOCATED_PRODUCTS = "has_non_allocated_products",
}

export interface OrdersStatistics {
  [key: string]: {
    quantity: number;
  };
}

// mode
export enum OrderMode {
  NEW = "new",
  VIEW = "view",
  REFUND = "refund",
}

// options
export enum OrderOption {
  QUICK_VIEW = "quick-view",
  VIEW_CUSTOMER = "view-customer",
  REMOVE_FROM_PAYMENT = "remove-order",
  VIEW_DETAILS = "view-details",
  REFUND = "refund",
}

// xero invoice
export enum XeroInvoiceStatus {
  PAID = "PAID",
  FAILED = "FAILED",
}

// INVOICES
export interface OrderInvoice {
  id: number;
  customer: Customer;
  payment_method: {
    id: number;
    name: string;
    gateway: PaymentMethodGateway;
  };
  total_amount: number;
  subtotal_amount: number;
  status: string;
  notes: any;
  metadata: any;
  items: {
    item_type: string;
    item_id: number;
    amount: number;
  }[];
  adjustments: any[];
}

// HOLDS
export interface OrderHoldNote {
  id?: number;
  note: string;
  order: number;
  is_printed: boolean;
}

// REPORTS
export interface ConfirmationListParams {
  enabled?: boolean;
  notice: string;
  confirmed_units: boolean;
  transport_units: boolean;
  product_parent_name: boolean;
  display_secondary_name: boolean;
  hide_zero_allocated: boolean;
  format: ReportExtension;
}

export enum SingleOrderReportType {
  ORDER_INVOICE = "invoice",
  ORDER_CONFIRMATION = "confirmation-report",
  ORDER_PREPARATION = "picklist",
}

export interface ConfirmationReport {
  data: {
    products: {
      units_ordered: number;
      product: string;
      productHtml?: string;
      units_confirmed: number;
      transport_units: number;
      price: number;
      total: number;
    }[];
    subtotal: number;
    tax: number;
    total: number;
    total_quantity: number;
    total_lines: number;
  };
  meta: {
    adjustments?: {
      amount: number;
      data: any;
      name: string;
      type: string;
    }[];
    bay_number?: string;
    address: string;
    city: string;
    customer_name: string;
    notes: { text: string }[];
    notice: string;
    order_date: { date: string; time: string };
    order_id: number;
    order_po_number: string;
    order_type: string;
    postal_code: string;
    province_code: string;
    units: any[];
    store_phone?: string;
    store_email?: string;
    store_address?: string;
  };
}

export enum OrderReportMode {
  RUN = "delivery-run",
  FREIGHT = "freight-company",
}

export interface OrdersBulkPrintPayload {
  orders: number[];
  print_invoice?: boolean;
  print_picklist?: boolean;
  print_confirmation?: boolean;
}

// BULK ACTIONS
export enum BulkActions {
  DOWNLOAD = "download",
  COPY = "copy",
  CREDIT = "credit",
  CUT = "cut",
  MOVE_TO_A_NEW_ORDER = "move-to-a-new-order",
  DELETE = "delete",
  PRINT = "print",
  DISCOUNT = "discount",
}

// SIDEBARS
export const SidebarPage = {
  ORDERS_VIEW: `${SidebarPrefix.ORDERS}-view`,
  ORDERS_PRINT: `${SidebarPrefix.ORDERS}-print`,
  ORDERS_PRINT_REPORT: `${SidebarPrefix.ORDERS}-print-report`,
  ORDERS_REPORTS_LIST: `${SidebarPrefix.ORDERS}-reports`,
  ORDERS_STAFF_LIST: `${SidebarPrefix.ORDERS}-staff`,
  ORDERS_HISTORY: `${SidebarPrefix.ORDERS}-history`,
  ORDERS_PRODUCTS_SEARCH_SETTINGS: `${SidebarPrefix.ORDERS}-search-settings`,
  ORDERS_FILTERS: `${SidebarPrefix.ORDERS}-filters`,
  ORDERS_SCHEDULE: `${SidebarPrefix.ORDERS}-schedule`,
  ORDERS_PRICE: `${SidebarPrefix.ORDERS}-price`,
  ORDERS_CONTACT_ADDRESSES: `${SidebarPrefix.ORDERS}-contact-addresses`,
  ORDERS_ACTIONS: `${SidebarPrefix.ORDERS}-actions`,
  ORDERS_ADD_PAYMENT: `${SidebarPrefix.ORDERS}-add-payment`,
  ORDERS_PAYMENT_HISTORY: `${SidebarPrefix.ORDERS}-payment-history`,
  ORDERS_IMPORT_EXPORT: `${SidebarPrefix.ORDERS}-import-export`,
  ORDERS_BATCH_SYNC: `${SidebarPrefix.ORDERS}-batch-sync`,
  ORDERS_BILLS_BATCH_SYNC: `${SidebarPrefix.ORDERS}-bills-batch-sync`,
};

export const SidebarPageKanban = {
  KANBAN_LANE_EDIT: `${SidebarPrefix.KANBAN}-lane-edit`,
  KANBAN_LANE_ADD: `${SidebarPrefix.KANBAN}-lane-add`,
  KANBAN_SETTINGS: `${SidebarPrefix.KANBAN}-settings`,
};

// KANBAN
export interface KanbanLane {
  name: string;
  allow_types: OrderType[] | PurchaseOrderType[];
  preceding_statuses: OrderStatus[] | PurchaseOrderStatus[];
  following_statuses: OrderStatus[] | PurchaseOrderStatus[];
  id?: number;
  type?: KanbanLaneType;
  position?: number;
  target_time?: number;
  items?: Order[] | PurchaseOrder[];
  meta?: Meta;
}

export enum KanbanLaneType {
  FIRST = "first",
  MID = "middle",
  LAST = "last",
}

export enum KanbanLaneInterval {
  MINUTES = "minutes",
  HOURS = "hours",
}
