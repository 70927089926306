/**
 * Filtering logic and functions
 */
import { useNewClearFilters } from "~/composables/filters/useNewClearFilters";

export interface Filters {
  [key: string]: FilterValue;
}

export interface FilterValue {
  type: FilterType;
  title: string;
  subtitle?: string;
  options: Array<FilterOption>;
  chosenOptions: Array<FilterOption>;
  isApplied?: boolean;
  isSingle?: boolean;
  toggle?: boolean;
}

export interface FilterOption {
  name: string;
  value: string | number;
  type?: any;
}

export interface DateRangesList {
  [key: string]: DateRange;
}

export interface DateRange {
  from?: Date;
  to?: Date;
}

export enum FilterType {
  DEFAULT = "default",
  TAG_LIST = "tag-list",
  STATUS = "status",
  SWITCHER = "switcher",
  BUTTONS = "buttons",
  DATE_RANGE = "date-range", // filterKey_from: Date, filterKey_to: Date
  NEW_DATE_RANGE = "new-date-range", // filterKey: { from: Date, to: Date }
  MAIN_DATE = "main-date",
  LETTER = "letter",
  PRICE_RANGE = "price-range",
  PERIOD = "period",
  SEARCH = "search",
}

// Set filter options
export function setOptions(
  filters: Filters,
  key: string | number,
  options: Array<FilterOption>,
  callback?: () => void
): void {
  if (!filters || !filters[key]) return;

  filters[key].chosenOptions = options;

  callback?.();
}

// Toggle filter option
export function toggleOption(
  filters: Filters,
  key: string | number,
  option: FilterOption,
  callback?: () => void
): void {
  if (!filters || !filters[key] || !option) return;

  if (!filters[key].chosenOptions.some((o) => o.value === option.value)) {
    if (filters[key].type === FilterType.LETTER) {
      filters[key].chosenOptions = [option];
    } else {
      filters[key].chosenOptions.push(option);
    }
  } else {
    if (filters[key].type === FilterType.LETTER) {
      filters[key].chosenOptions = [];
    } else {
      filters[key].chosenOptions = filters[key].chosenOptions.filter(
        (o) => o.value !== option.value
      );
    }
  }

  callback?.();
}

// Toggle boolean filter
export function toggleBooleanFilter(
  filters: Filters,
  key: string | number,
  value: boolean,
  callback?: () => void
): void {
  if (
    !filters ||
    !filters[key] ||
    !filters[key].type ||
    filters[key].type !== FilterType.SWITCHER
  )
    return;

  filters[key].chosenOptions = value
    ? [
        {
          name: filters[key].title,
          value: "true",
        },
      ]
    : [];

  callback?.();
}

// Remove filter option
export function removeOption(
  filters: Filters,
  key: string | number,
  option: FilterOption,
  callback?: () => void
): void {
  if (!filters || !filters[key] || !option) return;

  filters[key].chosenOptions = filters[key].chosenOptions.filter(
    (o) => o.value !== option.value
  );

  callback?.();
}

// Clear filters
export function clearFilters(filters: Filters, callback?: () => void): void {
  useNewClearFilters(filters);

  callback?.();
}

// Set Date Range filter
export function setDateRangeOption(
  filters: Filters,
  key: string,
  dateRange: DateRange,
  callback?: () => void
): void {
  if (!filters || !filters[key]) return;

  if (dateRange) {
    if (validateDateRange(dateRange)) {
      filters[key].chosenOptions = [
        {
          name: `${filters[key].title}: ${useDateFormatLib(dateRange.from, "dd/mm/yyyy")} - ${useDateFormatLib(dateRange.to, "dd/mm/yyyy")}`,
          value: `${useDateFormatLib(dateRange.from, "yyyy-mm-dd")}_${useDateFormatLib(dateRange.to, "yyyy-mm-dd")}`,
        },
      ];
    } else {
      return;
    }
  } else {
    filters[key].chosenOptions = [];
  }

  callback?.();
}

// Validate Date Range
function validateDateRange(dateRange: DateRange): boolean {
  const { from, to } = dateRange;

  if (!from || (from && !(from instanceof Date))) {
    return false;
  }

  if (!to || (to && !(to instanceof Date))) {
    return false;
  }

  return true;
}

export const letterFilterOptions: Array<FilterOption> = [
  { name: "A", value: "a" },
  { name: "B", value: "b" },
  { name: "C", value: "c" },
  { name: "D", value: "d" },
  { name: "E", value: "e" },
  { name: "F", value: "f" },
  { name: "G", value: "g" },
  { name: "H", value: "h" },
  { name: "I", value: "i" },
  { name: "J", value: "j" },
  { name: "K", value: "k" },
  { name: "L", value: "l" },
  { name: "M", value: "m" },
  { name: "N", value: "n" },
  { name: "O", value: "o" },
  { name: "P", value: "p" },
  { name: "Q", value: "q" },
  { name: "R", value: "r" },
  { name: "S", value: "s" },
  { name: "T", value: "t" },
  { name: "U", value: "u" },
  { name: "V", value: "v" },
  { name: "W", value: "w" },
  { name: "X", value: "x" },
  { name: "Y", value: "y" },
  { name: "Z", value: "z" },
];
