import { defineStore } from "pinia";
import {
  RequestParams,
  Option,
  ResponseType,
  Filters,
  ReportExtension,
} from "~/types/general";
import {
  InventoryAdding,
  InventoryAddType,
  InventoryImporting,
  InventoryItem,
  InventoryParams,
  InventoryStatus,
  InventoryBarcode,
  InventorySingle,
  LotManageMode,
  LotAdjustReason,
  LotAdjustPayload,
  LotConvertPayload,
  InventoryHistory,
  SidebarPage as InventorySidebar,
  InventoryUnit,
  LotItem,
} from "~/types/inventory";
import { useGeneralStore } from "./general";
import { useStoresStore } from "./stores";
import { CustomerShort } from "~/types/customers";
import { IndexedDBStore, ResponseList } from "~/types/bff";
import { StorageService } from "~/services/StorageService";
import { FilterType } from "~/types/filters";

export const useInventoryStore = defineStore("inventory", () => {
  // State
  const inventoryList = ref<InventoryItem[]>([]);
  const page = ref(1);
  const perPage = ref(40);
  const meta = ref<RequestParams>({});
  const totalItems = ref<number | null>(null);
  const lastPage = ref<number | null>(null);
  const isLoading = ref(false);
  const isEditMode = ref(false);
  const inventoryNotifications = ref<any[]>([]);

  const inventoryOptions = ref<Option[]>([
    {
      name: "Adjust",
      value: InventorySidebar.INVENTORY_LOT_MANAGE,
    },
    {
      name: "Convert",
      value: InventorySidebar.INVENTORY_LOT_MANAGE,
    },
    {
      name: "History",
      value: InventorySidebar.INVENTORY_HISTORY,
    },
    {
      name: "Locations",
      value: InventorySidebar.INVENTORY_LOCATIONS,
    },
  ]);

  const parameters = ref<InventoryParams>({} as InventoryParams);
  const statusList = ref<Option[]>([
    { name: "In Stock", value: InventoryStatus.IN_STOCK },
    { name: "Low Stock", value: InventoryStatus.LOW },
    { name: "Out of Stock", value: InventoryStatus.OUT_OF_STOCK },
    { name: "Draft", value: InventoryStatus.DRAFT },
  ]);
  const unitOptions = ref<Option[]>([
    { name: "Unit", value: InventoryUnit.UNIT },
    { name: "Box", value: InventoryUnit.BOX },
  ]);

  const addingType = ref<InventoryAddType | null>(null);
  const addingTypesList = ref<Option[]>([
    {
      name: "Simple",
      value: InventoryAddType.SIMPLE,
    },
    {
      name: "Category",
      value: InventoryAddType.CATEGORY,
    },
    {
      name: "All",
      value: InventoryAddType.ALL,
    },
  ]);
  const addingInventory = ref<InventoryAdding>({} as InventoryAdding);
  const importingInventory = ref<InventoryImporting>({} as InventoryImporting);

  const viewingInventory = ref<InventoryItem | InventorySingle>(
    {} as InventoryItem | InventorySingle
  );

  const lotManageMode = ref<LotManageMode | null>(null);
  const selectedLot = ref<LotItem>({} as LotItem);
  const selectedLotSupplier = ref<
    CustomerShort & {
      stock: number;
      reserved_stock: number;
      base_stock: number;
      received_stock: number;
      stock_on_hand: number;
    }
  >(
    {} as CustomerShort & {
      stock: number;
      reserved_stock: number;
      base_stock: number;
      received_stock: number;
      stock_on_hand: number;
    }
  );
  const lotAdjustReasons = ref<Option[]>([
    {
      name: "Damaged",
      value: LotAdjustReason.DAMAGED,
    },
    {
      name: "Stolen",
      value: LotAdjustReason.STOLEN,
    },
    {
      name: "Evaporated",
      value: LotAdjustReason.EVAPORATED,
    },
    {
      name: "Deteriorated",
      value: LotAdjustReason.DETERIORATED,
    },
    {
      name: "Expired",
      value: LotAdjustReason.EXPIRED,
    },
    {
      name: "Revised",
      value: LotAdjustReason.REVISED,
    },
  ]);

  const isOpenSidebar = ref(false);

  const filters = ref<Filters>({
    running_balance: {
      type: FilterType.SWITCHER,
      title: "Running Balance",
      options: [],
      chosenOptions: [],
      toggle: false,
    },
    is_on_sale: {
      type: FilterType.SWITCHER,
      title: "View Products On Sale",
      options: [],
      chosenOptions: [],
      toggle: false,
    },
    is_overallocated: {
      type: FilterType.SWITCHER,
      title: "Overallocated",
      options: [],
      chosenOptions: [],
      toggle: false,
    },
    taxonomies_categories: {
      type: FilterType.DEFAULT,
      title: "Category",
      options: [],
      chosenOptions: [],
    },
    stock: {
      type: FilterType.DEFAULT,
      title: "Stock Status",
      options: [],
      chosenOptions: [],
    },
    type: {
      type: FilterType.TAG_LIST,
      title: "Order Type",
      options: useGeneralStore().orderTypeList,
      chosenOptions: [],
    },
    taxonomies_tags: {
      type: FilterType.TAG_LIST,
      title: "Tag",
      options: [],
      chosenOptions: [],
    },
    tag: {
      type: FilterType.DEFAULT,
      title: "Custom Tag",
      options: [],
      chosenOptions: [],
    },
    store: {
      type: FilterType.DEFAULT,
      title: "Location",
      options: [],
      chosenOptions: [],
    },
    price: {
      type: FilterType.PRICE_RANGE,
      title: "Price",
      options: [
        { name: "Price From", value: 0 },
        { name: "Price To", value: 0 },
      ],
      chosenOptions: [],
      isApplied: false,
    },
    product: {
      type: FilterType.SEARCH,
      title: "Search",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    letter: {
      type: FilterType.LETTER,
      title: "Alphabet letter",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
  });

  const inventoryTags = ref<Option[]>([]);
  const viewingTag = ref<any>({});

  const pageTable = ref(1);
  const perPageTable = ref(10);

  // Getters
  const areas = computed((): Option[] => {
    return (
      parameters.value?.area?.map((area) => ({
        name: area,
        value: area,
      })) || []
    );
  });

  // ACTIONS
  async function getInventoryList(
    payload?: any
  ): Promise<ResponseType<InventoryItem[]>> {
    const params = {
      page: page.value,
      limit: perPage.value,
      ...(payload && { ...payload }),
    };

    try {
      const response = await useVaniloApi("/inventories", {
        params,
      });

      return response as ResponseType<InventoryItem[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getInventoryListBff(payload?: {
    params?: any;
    filters?: any;
    search?: {
      term: string;
      fields: string[];
      filterFn?: (item: InventorySingle) => boolean;
    };
    noFetchMain?: boolean;
    force?: boolean;
    page?: number;
    limit?: number;
  }): Promise<ResponseList<InventorySingle>> {
    const { params, filters, search, noFetchMain, force, page, limit } =
      payload || {};

    try {
      const response = await new StorageService(
        useRuntimeConfig()
      ).fetchList<IndexedDBStore.INVENTORY>({
        endpoint: "inventories",
        store: IndexedDBStore.INVENTORY,
        params,
        filters,
        search,
        noFetchMain,
        force,
        page,
        limit,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getInventoryParams(params?: any): Promise<InventoryParams> {
    try {
      const response = await useVaniloApi("/inventories/parameters", {
        ...(params && { params }),
      });

      return response as InventoryParams;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getInventoryHistory(
    productId: number,
    params?: any
  ): Promise<ResponseType<InventoryHistory[]>> {
    try {
      const response = await useVaniloApi(
        `/inventories/${productId || viewingInventory.value.id}/notes`,
        {
          params,
        }
      );

      return response as ResponseType<InventoryHistory[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getInventoryVariations(
    productId: number,
    params?: any
  ): Promise<ResponseType<InventorySingle[]>> {
    try {
      const response = await useVaniloApi(`/inventories/product/${productId}`, {
        params,
      });

      return response as ResponseType<InventorySingle[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getInventoryById(
    productId: number
  ): Promise<ResponseType<InventorySingle>> {
    try {
      const response = await useVaniloApi(`/inventories/${productId}`);

      return response as ResponseType<InventorySingle>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getInventoryByIdBff(
    id: number,
    payload?: {
      params?: any;
      noFetchMain?: boolean;
      force?: boolean;
    }
  ): Promise<InventorySingle> {
    const { params, noFetchMain, force } = payload || {};

    try {
      const response = await new StorageService(
        useRuntimeConfig()
      ).fetchItem<IndexedDBStore.INVENTORY>({
        store: IndexedDBStore.INVENTORY,
        id,
        params,
        noFetchMain,
        force,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getInventoryByBarcode(
    barcode: string,
    storeId?: number
  ): Promise<ResponseType<InventoryBarcode>> {
    try {
      const response = await useVaniloApi(`/inventories/by-code/${barcode}`, {
        params: {
          store_id:
            storeId ||
            localStorage.getItem("pos-location") ||
            useStoresStore().stores[0]?.id,
        },
      });

      return response as ResponseType<InventoryBarcode>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function addInventory(): Promise<ResponseType<InventoryItem>> {
    try {
      const response = (await useVaniloApi("/inventories", {
        method: "POST",
        body: addingInventory.value,
      })) as ResponseType<InventoryItem>;

      inventoryList.value.unshift(response?.data);
      addingInventory.value = {} as InventoryAdding;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function importInventory() {
    try {
      const response = await useVaniloApi("/inventories/import", {
        method: "POST",
        body: {
          ...importingInventory.value,
          force: false,
        },
      });

      const inventoryRes = await getInventoryList();

      inventoryList.value = inventoryRes.data;

      importingInventory.value = {} as InventoryImporting;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateInventory(inventoryId: number, body: any) {
    try {
      const response = (await useVaniloApi(`/inventories/${inventoryId}`, {
        method: "POST",
        body,
      })) as ResponseType<InventoryItem>;

      inventoryList.value = inventoryList.value.map((item) =>
        item.id === response?.data.id ? response?.data : item
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteInventory(id: number | string) {
    try {
      const response = await useVaniloApi(`/inventories/${id}`, {
        method: "DELETE",
      });

      inventoryList.value = inventoryList.value.filter(
        (item) => item.id !== +id
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function resetInventory(store_id: number) {
    try {
      const response = await useVaniloApi(`/inventories/reset`, {
        method: "POST",
        body: {
          store_id,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function adjustLot(body: LotAdjustPayload) {
    const id = useConfig().isLotsSupplierGrouped
      ? viewingInventory.value.id || useRoute().query["inventory-id"]
      : selectedLot.value.id;

    try {
      const response = await useVaniloApi(
        `/inventories/${id}` +
          (useConfig().isLotsSupplierGrouped
            ? `/supplier/${selectedLotSupplier.value.id}`
            : "") +
          "/adjust",
        {
          method: "POST",
          body,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function convertLot(body: LotConvertPayload) {
    const id = useConfig().isLotsSupplierGrouped
      ? viewingInventory.value.id || useRoute().query["inventory-id"]
      : selectedLot.value.id;

    try {
      const response = await useVaniloApi(
        `/inventories/${id}` +
          (useConfig().isLotsSupplierGrouped
            ? `/supplier/${selectedLotSupplier.value.id}`
            : "") +
          "/convert",
        {
          method: "POST",
          body,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function editCurrentStock(id, stock) {
    try {
      const response = await useVaniloApi(`/inventories/${id}/edit-stock`, {
        method: "POST",
        body: { stock: stock },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateInventoryImages(id, images) {
    const formData = new FormData();
    formData.append("media", images.media);

    try {
      const response = await useVaniloApi(`/inventories/${id}/gallery`, {
        method: "POST",
        body: formData,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteInventoryImage(id) {
    try {
      const response = await useVaniloApi(`/inventories/gallery/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSiblings(
    productId: number,
    payload?: any
  ): Promise<ResponseType<InventoryItem[]>> {
    try {
      const response = await useVaniloApi("/inventories", {
        params: {
          "filter[siblings]": productId,
          ...payload,
        },
      });

      return response as ResponseType<InventoryItem[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getInventoryTags(payload?) {
    try {
      const response = (await useVaniloApi("/inventory-tags", {
        params: {
          ...(payload && payload),
        },
      })) as ResponseType<any>;

      inventoryTags.value = response?.data.map((i) => ({
        name: i.name,
        value: i.id,
      }));

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleInventoryTag(id, payload?) {
    try {
      const response = await useVaniloApi(`/inventory-tags/${id}`, {
        params: {
          ...(payload && payload),
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createInventoryTag(body: {
    name: string;
    description: string;
  }) {
    try {
      const response = await useVaniloApi(`/inventory-tags`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function updateInventoryTag() {
    try {
      const response = await useVaniloApi(
        `/inventory-tags/${viewingTag.value.id}`,
        {
          method: "POST",
          body: { ...viewingTag.value },
        }
      );

      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function deleteInventoryTag(id: number | string) {
    try {
      const response = await useVaniloApi(`/inventory-tags/${id}`, {
        method: "DELETE",
      });

      inventoryTags.value = inventoryTags.value.filter((i) => +i.value !== +id);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Report
  async function printInventoryReport(body?: {
    format: ReportExtension;
    filter;
    sort?: String[];
  }) {
    try {
      const response = await useVaniloApi("/inventories/report", {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function printDiscrepancyReport() {
    try {
      const response = await useVaniloApi("/inventories/invalid-lots", {
        method: "GET",
      });

      const blob = new Blob([response as BlobPart], {
        type: "text/plain",
      });

      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");

      link.href = blobUrl;
      link.download = `discrepancy-report.csv`;
      document.body.appendChild(link);

      link.click();

      URL.revokeObjectURL(blobUrl);

      return {
        url: blobUrl,
      };
    } catch (error) {
      console.log(error);
      useErrorNotifications(error);
    } finally {
      useIsLoading(false);
    }
  }

  // Bulk Update
  async function bulkUpdateInventories(inventoriesIds, updatesValue) {
    const payload = {};

    if (updatesValue.type === "change-status") {
      payload["stock_status"] = updatesValue.value;
    }
    if (updatesValue.type === "change-tag") {
      payload["tag_id"] = updatesValue.value;
    }
    if (updatesValue.type === "change-area") {
      payload["area"] = updatesValue.value;
    }

    try {
      const response = await useVaniloApi("/inventories/bulk-update", {
        method: "POST",
        body: {
          ...(inventoriesIds && { inventories: inventoriesIds }),
          ...payload,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkUpdateInventoryPrice(body) {
    try {
      const response = await useVaniloApi(`/inventories/bulk-update-price`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Bulk Delete
  async function bulkDeleteInventories(inventoriesIds) {
    try {
      const response = await useVaniloApi("/inventories/bulk-delete", {
        method: "POST",
        body: {
          ...(inventoriesIds && { inventories: inventoriesIds }),
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkExportInventories(body) {
    try {
      const response = await useVaniloApi("/inventories/export-with-filter", {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    inventoryList,
    page,
    perPage,
    meta,
    totalItems,
    lastPage,
    isLoading,
    isEditMode,
    inventoryNotifications,
    inventoryOptions,
    parameters,
    statusList,
    unitOptions,
    addingType,
    addingTypesList,
    addingInventory,
    importingInventory,
    viewingInventory,
    lotManageMode,
    selectedLot,
    selectedLotSupplier,
    lotAdjustReasons,
    isOpenSidebar,
    filters,
    inventoryTags,
    viewingTag,
    pageTable,
    perPageTable,
    areas,
    getInventoryList,
    getInventoryListBff,
    getInventoryParams,
    getInventoryHistory,
    getInventoryVariations,
    getInventoryById,
    getInventoryByIdBff,
    getInventoryByBarcode,
    addInventory,
    importInventory,
    updateInventory,
    deleteInventory,
    resetInventory,
    adjustLot,
    convertLot,
    editCurrentStock,
    updateInventoryImages,
    deleteInventoryImage,
    getSiblings,
    getInventoryTags,
    getSingleInventoryTag,
    createInventoryTag,
    updateInventoryTag,
    deleteInventoryTag,
    printInventoryReport,
    bulkUpdateInventories,
    bulkUpdateInventoryPrice,
    bulkDeleteInventories,
    bulkExportInventories,
    printDiscrepancyReport,
  };
});
