import { defineStore } from "pinia";
import { ResponseType } from "~/types/general";
import { RuleCreatePayload, Rule } from "~/types/rules";

export const useRulesStore = defineStore("rules", () => {
  // Rules state
  const rules = ref<Rule[]>([]);

  // Get rules
  async function getRules(): Promise<ResponseType<Rule[]>> {
    let response: any;

    try {
      response = await useVaniloApi("/rules");
      rules.value = response?.data;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  // Create rule
  async function createRule(
    payload: RuleCreatePayload
  ): Promise<ResponseType<Rule>> {
    let response: any;

    try {
      response = await useVaniloApi("/rules", {
        method: "POST",
        body: payload,
      });
      rules.value.push(response?.data);
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  // Update rule
  async function updateRule(
    ruleId: number,
    payload: RuleCreatePayload
  ): Promise<ResponseType<Rule>> {
    let response: any;

    try {
      response = await useVaniloApi(`/rules/${ruleId}`, {
        method: "POST",
        body: payload,
      });
      rules.value = rules.value.map((rule) =>
        rule.id === ruleId ? response?.data : rule
      );
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  // Delete rule
  async function deleteRule(ruleId: number) {
    let response: any;

    try {
      response = await useVaniloApi(`/rules/${ruleId}`, {
        method: "DELETE",
      });
      rules.value = rules.value.filter((rule) => rule.id !== ruleId);
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  return {
    rules,
    getRules,
    createRule,
    updateRule,
    deleteRule,
  };
});
