import { Address, OrderType } from "~/types/general";
import { CustomerShort, CustomerPlanManagement } from "./customers";
import { SidebarPrefix, Media, Option } from "./general";
import { Agreement } from "./agreements";


export interface Store {
  abn: string;
  description: string;
  id: number;
  name: string;
  order_type: string[];
  short_name: string;
  timezone: string;
  distance: number;
  addresses?: Address[];
}

export interface StoreShort {
  id: number;
  name: string;
  short_name: string;
}

export interface StoreSingle {
  id: number;
  name: string;
  short_name: string;
  description: string;
  timezone: string;
  team_members?: any[];
  abn: string;
  order_type: OrderType[];
  settings: StoreSettings;
  phone: string | null;
  email: string | null;
  hours: Hour[];
  delivery_hours: any[];
  booking_hours: any[];
  addresses: Address[];
  areas: any[];
  blockouts: Blockout[];
  delivery_zones: DeliveryZone[];
  printers: StorePrinter[];
  registers?: Register[];
  image?: Media;
  companies?: any[];
  distance?: number;
  agreements?: Agreement[];
  support_managements?: CustomerPlanManagement[];
}

export interface StoreManage {
  id?: number;
  name: string;
  short_name: string;
  description: string;
  payment_detail_notes?: string;
  timezone: string;
  abn: string;
  order_type?: OrderType[];
  settings?: StoreSettings;
  users?: number[];
  phone: string | null;
  email: string | null;
  hours?: Hour[];
  delivery_hours?: any[];
  booking_hours?: any[];
  addresses?: StoreAddressEditing[];
  areas?: any[];
  blockouts?: Blockout[];
  delivery_zones?: any[];
  image?: SingleAvatar;
  printers?: any[];
  registers?: Register[];
  taxonomies?: any[];
  brands?: any[];
  agreements?: Agreement[];
  support_managements?: CustomerPlanManagement[];
}

export interface NewStoreManage {
  id?: number;
  name: string;
  short_name: string;
  areas: string[];
  timezone?: string;
  abn: string;
  regional_code: string;
  order_type?: OrderType[];
  settings?: StoreSettings;
  status?: string;
  phone?: string;
  mobile?: string;
  email?: string;
  website?: string;
  type?: string;
  tags?: Option[];
  categories?: Option[];
  support_managements?: CustomerPlanManagement[];

  payment_detail_notes: string;
  brands?: Option[];
  addresses: {
    name: string;
    adderess: string;
    city: string;
    country: {
      id: string;
      name: string;
    };
    postalcode: string;
    province: {
      code: string;
      name: string;
    };

    lat: number | null;
    lng: number | null;
  }[];

  description: string;
  hours?: Hour[];
  delivery_zones: DeliveryZone[];
  delivery_hours?: any[];
  team_members?: any[];
  printers?: any[];
  registers?: Register[];
}
export interface StoreSingleAvatar {
  media: any;
  title: string;
  description: string;
}
export interface SingleAvatar {
  id: number;
  title: string;
  url: string;
  description: string;
  conversions: any[];
}

export interface StoreSettings {
  regional_id?: string;
  closed_to_public?: boolean;
  is_hidden?: boolean;
  order?: {
    acceptance?: {
      type: string;
      minutes?: number;
      intervals?: number;
    };
    checkout_date?: {
      enabled: boolean;
      order_types?: OrderType[];
    };
    target_date?: {
      lead_time: boolean;
    };
    online_orders?: boolean;
    enable_delivery_hours?: boolean;
    pick_up_fee?: {
      enabled: boolean;
      value: number | string;
    };
    pick_up_limit?: {
      enabled: boolean;
      max: number | string;
    };
  };
  booking?: {
    seatings: number;
    minimum_mins: number;
    enable_notice_period: boolean;
    notice_period: number;
    enable_booking_hours: boolean;
  };
  cloud_print?: {
    enabled: boolean;
  };
}

export interface Hour {
  day: number;
  is_enabled: boolean;
  is_round_the_clock: boolean;
  time: string[];
  cut_off_time: string[];
}

// blockout
export interface Blockout {
  name: string;
  type: BlockoutType;
  date: string[];
  time: string[];
  repeat: BlockoutRepeat;
  enabled: boolean;
}

export enum BlockoutType {
  FULL_DAY = "full_day",
  PART_DAY = "part_day",
  PERIOD = "period",
}

export enum BlockoutRepeat {
  NONE = "none",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  ANNUALLY = "annually",
}

export interface StoreAddressEditing {
  type?: StoreAddressType;
  name?: string;
  country?: string;
  province: string;
  postalcode: string;
  city: string;
  address: string;
  lat: number;
  lng: number;
}

export enum StoreType {
  DEFAULT = "default",
  AGENT = "agent",
}

export enum StoreAddressType {
  BILLING = "billing",
  BUSINESS = "business",
  CONTRACT = "contract",
  MAILING = "mailing",
  PICKUP = "pickup",
  RESIDENTIAL = "residential",
  SHIPPING = "shipping",
}

export enum TimeRangeType {
  FIRST_PART_START = "first-part-start",
  FIRST_PART_END = "first-part-end",
  SECOND_PART_START = "second-part-start",
  SECOND_PART_END = "second-part-end",
}

// delivery zone
export interface DeliveryZone {
  id: number;
  name: string;
  availability?: {
    id?: number;
    type: DeliveryZoneAvailabilityType;
    weekdays?: number[];
    date?: string;
    date_to?: string;
  }
  fee_type: DeliveryZoneFeeType;
  fee_value: number;
  no_fee_threshold?: number;
  inclusion_type: InclusionExclusionType;
  inclusion_subjects?: string[];
  exclusion_type?: InclusionExclusionType;
  exclusion_subjects?: string[];
  lat?: number;
  lng?: number;
  radius?: number;
}

export enum DeliveryZoneFeeType {
  FIXED = "fixed",
  PER_KG = "per_kg",
  PER_KM = "per_km",
}

export enum DeliveryZoneAvailabilityType {
  DAY = "day",
  DATE = "date",
  RANGE = "range",
  NONE = "none",
}

export enum InclusionExclusionType {
  RADIUS = "radius",
  POSTCODE = "postcode",
  CITY = "city",
  STATE = "state",
  COUNTRY = "country",
}

// terminal
export interface Register {
  id?: string | number;
  name: string;
  is_terminal_enabled: boolean;
  re_connect_terminal?: boolean;
  terminal?: Terminal;
}

export interface Terminal {
  id?: string | number;
  name: string;
  reg_code: string;
  is_enabled?: boolean;
  is_registered?: boolean;
  device_type?: string;
}

//printers
export interface StorePrinter {
  id: number;
  name: string;
  system_name: string;
  is_online_order: boolean;
  is_preparation: boolean;
  is_pos: boolean;
  is_auto_print: boolean;
  copies: number;
  cloud_printer?: boolean;
  register?: {
    id: number;
    name: string;
    terminal: {
      id: number;
      name: string;
      reg_code: string;
      is_enabled: boolean;
      is_registered: boolean;
      ip_address: string;
      device_type: string;
      serial_number: string;
    };
  };
  print_preparation_receipt: boolean;
  client_status?: {
    client_request: {
      statusCode: string;
      printingInProgress: boolean;
      clientAction: {
        request: string;
        result: any;
      }[];
      display: {
        name: string;
        status: {
          connected: boolean;
        };
      }[];
      raw_status: string;
      json_status: {
        Online: boolean;
        CoverOpen: boolean;
        CompulsionSwitch: boolean;
        OverTemperature: boolean;
        Recoverable: boolean;
        CutterError: boolean;
        MechanicalError: boolean;
        ReceiveBufferOverflow: boolean;
        BlackMarkError: boolean;
        PresenterPaperJam: boolean;
        VoltageError: boolean;
        PaperEmpty: boolean;
        PaperLow: boolean;
      };
    };
    server_response: {
      jobReady: boolean;
      mediaTypes: string[];
      clientAction: {
        request: string;
        options: string;
      }[];
    };
    get_params: {
      mac: string;
      type: string;
    };
    delete_params: {
      mac: string;
      code: string;
      delete: any;
    };
  };
  excluded_taxons: StorePrinterTaxon[];
  included_taxons: StorePrinterTaxon[];
  excluded_properties: any[];
  included_properties: any[];
  updated_at: string;
  jobs?: {
    id: number;
    order: {
      id: number;
      loading_order: number;
      store: StoreShort;
      type: string;
      is_express: boolean;
      number: string;
      po_number: string;
      status: string;
      payment_status: string;
      subtotal_value: number;
      total_value: number;
      credit_amount: number;
      customer: CustomerShort;
      target_date: string[];
      created_at: string;
    };
    type: string;
    created_at: string;
  }[];
}

export interface StorePrinterManage {
  id?: number;
  name: string;
  system_name: string;
  cloud_printer: boolean;
  is_online_order: boolean;
  is_preparation: boolean;
  is_pos: boolean;
  is_auto_print: boolean;
  copies: number;
  print_preparation_receipt?: boolean;
  register_id?: number;
  categories?: {
    id: number;
    type: string;
    is_exclusion: boolean;
  }[];
}

export interface StorePrinterTaxon {
  id: number;
  name: string;
  slug: string;
  parent_id: number;
  priority: any;
  ext_title: string;
  description: string;
  settings: any;
  meta_keywords: string;
  meta_description: string;
}

export interface QRCode {
  id: number;
  url: string;
  scans: number;
  name: string;
  color: string;
  background_color: string;
  display_logo: boolean;
  display_label: boolean;
  qr_code_url: string;
}

export const SidebarPage = {
  STORE_ADDRESS: `${SidebarPrefix.STORE}-address`,
  STORE_CONFIGURATION: `${SidebarPrefix.STORE}-configuration`,
  STORE_TABLES: `${SidebarPrefix.STORE}-tables`,
  STORE_TABLES_SINGLE: `${SidebarPrefix.STORE}-tables-single`,
  STORE_TABLES_TAG: `${SidebarPrefix.STORE}-tables-tag`,
  STORE_TEAM_MEMBERS_ADD: `${SidebarPrefix.STORE}-team-members-add`,
  STORE_OPENING_HOURS: `${SidebarPrefix.STORE}-opening-hours`,
  STORE_DELIVERY_TIMEZONES: `${SidebarPrefix.STORE}-delivery-timezones`,
};
