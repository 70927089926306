import { useGeneralStore } from "~/store/general";
import { ToastType } from "~/types/general";

export const useToast = (
  toast: { message: string; submessage?: string } | string,
  options?: { type: ToastType; duration?: number }
): void => {
  const generalStore = useGeneralStore();

  const newToast = {
    id: `toast-${Date.now()}`,
    message: typeof toast === "string" ? toast : toast.message,
    submessage: typeof toast === "string" ? "" : toast.submessage,
    type: options?.type || ToastType.INFO,
  };

  generalStore.toastsList.push(newToast);

  setTimeout(() => {
    generalStore.toastsList = generalStore.toastsList.filter(
      (t) => t.id !== newToast.id
    );
  }, options?.duration || 3000);
};
