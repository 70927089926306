<template>
  <div
    class="rounded-x1 divide-y divide-border-color"
    :class="[
      white ? 'bg-white' : 'bg-primary-grey-background',
      noContainer ? '' : 'border',
    ]"
  >
    <div
      v-for="(option, index) in options"
      :key="index"
      class="flex items-center justify-between cursor-pointer py-x3"
      :class="{
        'px-x5': !noContainer,
      }"
      @click="emit('click', option)"
    >
      <div>
        <p class="text-sm leading-base text-font-primary font-medium">
          {{ option.name }}
        </p>
        <p
          v-if="option.subtext"
          class="text-font-secondary text-xs mt-2.5 font-medium"
        >
          {{ option.subtext }}
        </p>
      </div>
      <button>
        <ChevronRightIcon
          class="w-[22px] h-[22px] stroke-font-primary -mr-[7px] -my-[2px]"
          style="stroke-width: 2.5"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Option } from "~/types/general";
import { ChevronRightIcon } from "@heroicons/vue/outline";

const props = defineProps<{
  options: Option[];
  white?: boolean;
  noContainer?: boolean;
}>();

const emit = defineEmits(["click"]);
</script>
