<template>
  <button
    :disabled="disabled"
    class="rounded-x12 whitespace-nowrap border text-sm leading-base max-w-min"
    :class="{
      'text-font-primary bg-inactive': disabled,
      'cursor-not-allowed': disabled && !mini,
      'border hover:border-accent-color': !disabled,
      '!bg-light-background !border-accent-color': active,
      'py-[3px] px-5 text-font-primary': small,
      'py-[2px] px-2.5 text-font-primary text-xs min-w-[60px]': mini,
      'py-x1 px-5 text-font-primary font-semibold': !small && !mini,
      'bg-light-background border-grey-100': grey,
      'bg-white': !grey,
    }"
    @click="emit('click')"
  >
    <template v-if="text">
      <p class="w-auto max-w-[160px] truncate">{{ text }}</p>
    </template>

    <slot />
  </button>
</template>

<script setup lang="ts">
const props = defineProps({
  disabled: { type: Boolean, default: false },
  text: { type: String, default: "" },
  active: { type: Boolean, default: false },
  small: { type: Boolean, default: false },
  mini: { type: Boolean, default: false },
  grey: { type: Boolean, default: false },
});

const emit = defineEmits(["click"]);
</script>
