import { SidebarPrefix } from "./general";

export const SidebarPage = {
  MANAGEMENT_LOCATIONS_FILTERS: `${SidebarPrefix.MANAGEMENT_LOCATIONS}-filters`,
  MANAGEMENT_LOCATIONS_IMPORT_EXPORT: `${SidebarPrefix.MANAGEMENT_LOCATIONS}-import-export`,
  MANAGEMENT_LOCATIONS_SETTINGS: `${SidebarPrefix.MANAGEMENT_LOCATIONS}-settings`,
};

export enum InBulkActions {
  CHANGE_STATUS = "change-status",
  ASSIGN_CATEGORY = "assign-category",
  UPDATE_TAG = "update-tag",
  SEND_NOTIFICATION = "send-notification",
  DELETE = "delete",
}

export enum ManagementLocationsStatus {
  ACTIVE = "active",
  PENDING = 'pending',
  DECLINED = 'declined',
  APPLICATION = 'application'
}

export enum LocationsTaxonomiesSlugs {
  STORE_CATEGORY = "store-category",
  STORE_TAG = "store-tag"
}