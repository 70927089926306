import { CustomerSubtype, CustomerType } from "./customers";
import { Address, Media, SyncStatus } from "./general";
import { Note } from "./general";
import { InventoryStatus, InventoryUnit } from "./inventory";
import { ProductProperty } from "./products";
import { StoreShort } from "./stores";
import { UserType } from "./users";
import { XeroInvoiceStatus } from "~/types/orders";

export interface PurchaseOrder {
  id: number;
  store: StoreShort;
  pricing_method: PurchasePricingMethod;
  type: PurchaseOrderType;
  is_sold_out: boolean;
  is_bill_sent: boolean;
  is_bill_viewed: boolean;
  is_express: boolean;
  number: string;
  po_number: string;
  status: PurchaseOrderStatus;
  comments: string;
  subtotal_value: number;
  tax_value: number;
  is_inclusive_tax: boolean;
  is_zero_priced: boolean;
  tag: {
    id: number;
    name: string;
  };
  is_priority: boolean;
  total_value: number;
  supplier: {
    id: number;
    type: CustomerType;
    name: string;
    email: string;
    phone: string;
    subtypes: CustomerSubtype[];
    image: Media;
  };
  lane: {
    id: number;
    name: string;
  };
  receiver_lane: {
    id: number;
    name: string;
  };
  billing_address: Address;
  shipping_address: Address;
  shipping_recipient: {
    email: string;
    phone: string;
    firstname: string;
    lastname: string;
  };
  freight_company: {
    id: number;
    type: CustomerType;
    name: string;
    email: string;
    phone: string;
    title: string;
    user: {
      id: number;
      name: string;
      email: string;
      roles: UserType[];
      is_active: boolean;
    };
  };
  target_date: string[];
  invoice_due_date: string;
  created_at: string;
  created_by: {
    id: number;
    name: string;
    image: Media;
  };
  items: PurchaseOrderItem[];
  adjustments: any[];
  unit_checks: {
    lines: number;
    products: number;
    units: {
      id: number;
      name: string;
      count: number;
    }[];
  };
  purchase_manager: {
    id: number;
    type: CustomerType;
    name: string;
    email: string;
    phone: string;
    title: string;
    user: {
      id: number;
      name: string;
      email: string;
      roles: UserType[];
      is_active: boolean;
    };
    image: Media;
  };
  transitions: {
    lane_id: number;
    status: PurchaseOrderStatus;
  }[];
  receiver_transitions: {
    lane_id: number;
    status: PurchaseOrderStatus;
  }[];
  net_revenue: number;
  net_percentage: number;
  levies?: PurchaseOrderLevyItem[];
  sync_status: SyncStatus;
  xero_invoice?: {
    xero_invoice_id?: string;
    status?: XeroInvoiceStatus;
  };
}

export interface PurchaseOrderLevyItem {
  product: string;
  quantity: number;
  unit_price: number;
  total_price: number;
}

export interface PurchaseOrderItem {
  id: number;
  product_id: number;
  is_deleted: boolean;
  quantity: number;
  order_units: InventoryUnit;
  order_quantity?: {
    min: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
    increment: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
    order_limit: {
      units: number;
      unit: InventoryUnit;
      quantity: number;
    };
  };
  symbols: string;
  subtext: string;
  price: number; // CONSIGNMENT
  price_total: number;
  retail_price: number;
  grower_return: number; // PRICED
  total_grower_return: number;
  tax: number;
  tax_total: number;
  margin: number; // PRICED
  commission: number; // CONSIGNMENT
  multiplier: number;
  notes: string;
  receiver_notes: string;
  name: string;
  ext_title: string;
  properties: ProductProperty[];
  media: Media[];
  inventory: {
    id: number;
    stock: number;
    stock_status: InventoryStatus;
  };
  received?: number;
  is_received?: boolean;
  is_sold_out?: boolean;
  is_bill_sent: boolean;
  is_bill_viewed: boolean;
  is_inclusive_tax: boolean;
  sold_quantity: number;
  levy?: number;
  levy_total?: number;
}

export interface PurchaseOrderManage {
  store_id?: number;
  supplier_id?: number;
  purchase_manager_id?: number;
  billing_address_id?: number;
  shipping_address_id?: number;
  billing_address?: any;
  shipping_address?: any;
  shipping_recipient?: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
  };
  pricing_method?: PurchasePricingMethod;
  type?: PurchaseOrderType;
  is_priority?: boolean;
  is_express?: boolean;
  is_inclusive_tax?: boolean;
  target_date?: string[];
  number?: string;
  status?: PurchaseOrderStatus;
  items?: {
    id: number;
    product_id: number;
    quantity: number;
    price: number;
    order_units: InventoryUnit;
    notes?: string;
    receiver_notes?: string;
    received?: number;
    is_received?: boolean;
    is_sold_out?: boolean;
  }[];
  comments?: string;
  freight_company_id?: number;
  tag_id?: number;
  notes?: Note;
  paid_amount?: number;
  levies?: PurchaseOrderLevyItem[];

  // ?????
  lane_id?: number;
  receiver_lane_id?: number;
  created_at?: string;
  payment_method_id?: number;
}

export interface PurchaseCalculatePayload {
  original_order_id?: string | number;
  store_id: number;
  supplier_id: number;
  purchase_manager_id: number;
  pricing_method: PurchasePricingMethod;
  type: PurchaseOrderType;
  is_express: boolean;
  is_inclusive_tax?: boolean;
  items: {
    product_id: number;
    price: number;
    quantity: number;
    order_units: InventoryUnit;
  }[];
  // if 'merge' is present -- "items" consists only items to be updated/deleted
  merge?: {
    subtotal?: number;
    tax?: number;
    delete_items?: number[];
  };
}

export enum PurchasePricingMethod {
  PRICED = "priced",
  CONSIGNMENT = "consignment",
}

export enum PurchaseOrderType {
  DELIVERY = "delivery",
  PICKUP = "pick-up",
}

export enum FulfilmentStatus {
  SOLD_OUT = "sold_out",
  BILL_SENT = "bill_sent",
  BILL_VIEWED = "bill_viewed",
  HAS_ZERO_PRICED_PRODUCTS = "has_zero_priced_products",
}

export enum PurchaseOrderStatus {
  DRAFT = "draft",
  ON_HOLD = "on-hold",
  CANCELLED = "cancelled",
  PENDING = "pending",
  PROCESSING = "processing",
  PENDING_APPROVAL = "pending-approval",
  CONFIRMED = "confirmed",
  RECEIVING = "receiving",
  COMPLETED = "completed",
  PARTIALLY_COMPLETED = "partially-completed",
  REFUNDED = "refunded",
  SELLING = "selling",
  RETURNED = "returned",
  DELAYED = "delayed",
  BACKORDERED = "backordered",
  ARCHIVED = "archived",
}
