import { defineStore } from "pinia";
import { ref } from "vue";
import { PrintReportPayload, OrderReportType, TaskEvent } from "~/types/tasks";
import { useStoresStore } from "./stores";
import { useUsersStore } from "./users";
import { ReportExtension, ToastType } from "~/types/general";

// Define Tasks Store using the Setup API
export const useTasksStore = defineStore("tasks", () => {
  // State
  const tasks = ref([]);
  const viewingTask = ref("");
  const taskId = ref(null);
  const taskIdFromReport = ref(null);
  const processedPercent = ref(null);
  const isError = ref(false);
  const isProcessing = ref(false);
  const taskEvent = ref(null);
  const dataEvent = ref(null);
  const bulkActionsErrors = ref([]);

  // Actions

  // Fetch Task List
  async function getTasksList() {
    let response;
    try {
      response = await useVaniloApi(`/tasks`);
      tasks.value = response?.data;
    } catch (error) {
      console.log(error);
    }
    return response;
  }

  // Fetch a Task by ID
  async function getTask(id) {
    let response;
    try {
      response = await useVaniloApi(`/tasks/get/${id}`);
    } catch (error) {
      console.log(error);
    }
    if (response?.data?.url) {
      let data = await getJsonFromUrl(response?.data?.url);
      return data;
    }
    return response;
  }

  // Fetch JSON from URL
  async function getJsonFromUrl(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // Print Task
  async function printTask(
    payload: PrintReportPayload,
    type: OrderReportType,
    storeId?: number
  ) {
    let response;
    const { useToast } = useNuxtApp();
    isProcessing.value = true;
    try {
      response = await useVaniloApi(
        `${
          type === "product-sales-report"
            ? "/orders/sales-report"
            : `/tasks/${type}`
        }/${
          storeId ||
          useStoresStore().stores[0]?.id ||
          useUsersStore().currentUser?.stores[0]?.id
        }`,
        {
          method: "POST",
          body: payload,
        }
      );
    } catch (error) {
      isProcessing.value = false;
      processedPercent.value = null;
      useToast(`${error}`, {
        type: ToastType.ERROR,
        duration: 3000,
      });
    }
    return response;
  }

  // Print Day Task
  async function printDayTask(date, storeId?: number) {
    let response;
    const { useToast } = useNuxtApp();
    isProcessing.value = true;
    try {
      response = await useVaniloApi(
        `/tasks/loading-manifest-report/${
          storeId ||
          useStoresStore().stores[0]?.id ||
          useUsersStore().currentUser?.stores[0]?.id
        }`,
        {
          method: "POST",
          body: {
            quantity: true,
            lines: true,
            date: date,
            format: ReportExtension.PDF,
          },
        }
      );
    } catch (error) {
      isProcessing.value = false;
      processedPercent.value = null;
      useToast(`${error}`, {
        type: ToastType.ERROR,
        duration: 3000,
      });
    }
    return response;
  }

  // Download Task
  async function downloadTask(downloadLink, filename) {
    let response;
    try {
      response = await $fetch(downloadLink, {
        method: "GET",
        responseType: "blob",
      });
      const blob = new Blob([response as BlobPart], { type: response.type });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      link.click();
    } catch (error) {
      console.log(error);
    }
    return response;
  }

  // Delete a Task by ID
  async function deleteTask(id) {
    let response;
    try {
      response = await useVaniloApi(`/tasks/${id}`, {
        method: "DELETE",
      });
      tasks.value = tasks.value.filter((task) => task.id !== id);
    } catch (error) {
      console.log(error);
    }
    return response;
  }

  // Delete All Tasks
  async function deleteAllTasks() {
    let response;
    try {
      response = await useVaniloApi(`/tasks/empty`, {
        method: "DELETE",
      });
    } catch (error) {
      console.log(error);
    }
    return response;
  }

  // Return state and actions
  return {
    tasks,
    viewingTask,
    taskId,
    taskIdFromReport,
    processedPercent,
    isError,
    isProcessing,
    taskEvent,
    dataEvent,
    bulkActionsErrors,
    getTasksList,
    getTask,
    getJsonFromUrl,
    printTask,
    printDayTask,
    downloadTask,
    deleteTask,
    deleteAllTasks,
  };
});
