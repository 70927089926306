import { usePaymentStore } from "~/store/payment";
import { PaymentMethodGateway, PaymentMethodOption } from "~/types/payment";

export const useGetPaymentMethod = async (
  gateway: PaymentMethodGateway
): Promise<PaymentMethodOption> => {
  const paymentStore = usePaymentStore();

  if (
    useIsEmpty(paymentStore.paymentMethods) ||
    !paymentStore.paymentMethodsList.length
  ) {
    await paymentStore.getPaymentMethods();
  }

  return paymentStore.paymentMethodsList?.find(
    (method) => method.value === gateway
  );
};
