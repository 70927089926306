import { SidebarPrefix, Media } from "./general";
import { ConfirmationListParams, OrderStatus } from "./orders";
import {
  PickingListParams,
  PaperFormat,
  ReceiptTemplate,
  PrintOrder,
} from "./reports";
import {
  XeroAccountMappingSettings,
  XeroIntegrationSettings,
  XeroAccountSettings,
  InvoiceIssueDateTypes,
} from "./xero";
import { User } from "~/types/users";

export interface SettingsManage {
  general: {
    business_type: BusinessType;
    payment_detail_notes?: string;
    phone?: string;
    abn?: any;
    code?: string;
    meta_data?: string;
    email?: string;
    currency: Currency;
    time_format?: string;
    time_zone?: string;
    features?: SettingsFeaturesParams;
    address?: {
      country: string;
      province: string;
      postalcode: string;
      city: string;
      address: string;
      lat?: number;
      lng?: number;
    };
    name?: string;
    regional_code?: string;
    superadmin_domain?: string;
    meta_title: string;
    home_page: string;
    brand_color: string;
    background_color: string;
    pos_type?: PosType;
  };
  products: {
    secondary_name: boolean;
    secondary_name_label: string;
    features?: {
      automatic_barcodes: boolean;
      custom_sorting: boolean;
    };
  };
  pos?: {
    enabled: boolean;
    sales_method: SalesMethod;
    quick_add: boolean;
    search: boolean;
    keypad: boolean;
    date: boolean;
    reset_team_member: boolean;
    pin: boolean;
    cashier: boolean;
    quick_order: boolean;
    guest_default_customer: boolean;
    variations: boolean;
    variation_search: boolean;
    lot_confirmation: boolean;
    custom_price: boolean;
    swap_price_and_quantity: boolean;
    dollar_input: boolean;
    tiles_layout_level: PosTilesLevel;
    table_ordering?: boolean;
  };
  inventory?: {
    enabled: boolean;
    auto_sell_out_stock: boolean;
    auto_add_products: boolean;
    barcode_method: string;
    centralised_pricing: boolean;
    required_fields: {
      price: boolean;
      area: boolean;
    };
    stock_count: {
      show_ordered_quantity: boolean;
      show_cost: boolean;
    };
    auto_stock: {
      enabled: boolean;
      reservation: {
        enabled: boolean;
        order_status: string[];
      };
    };
    group_lots_by_supplier: boolean;
    purchase_confirmation: boolean;
    adjustment_notes: boolean;
  };
  loyalty_reward?: {
    enabled: boolean;
    points_rate: number;
  };
  contacts: {
    allow_create: boolean;
    features: {
      bay_number: boolean;
      levy_calculation: boolean;
    };
  };
  customers: {
    features: {
      credit_service: boolean;
      protection: boolean;
    };
    roles_labels: {
      individual: string;
      staff: string;
      company: string;
      contact: string;
    };
    set_default_group: boolean;
  };
  order: {
    services?: {
      enabled: boolean;
    };
    print_defaults?: {
      default_paper_type: PaperFormat;
      hide_zero_allocated: boolean;
      confirmed_units: boolean;
      hide_target_date: boolean;
      receipt_template_style: ReceiptTemplate;
      hide_supplier: boolean;
      print_order: PrintOrder;
    };
    // order_workflow: boolean;
    order_types: {
      enabled: string[];
      allowed: string[];
    };
    workflow: {
      enabled: boolean;
      auto_accept: boolean;
      auto_print: boolean;
      print_action: boolean;
      options: any[];
    };
    allocations: {
      enabled: boolean;
      display_lots: boolean;
    };
    booking_services: {
      enabled: boolean;
    };
    print_on_acceptance: boolean;
    acceptance: {
      type: AcceptanceType;
      intervals: number;
      minutes: number;
    };
    auto_accept: boolean;
    target_date: {
      lead_time: boolean;
      location_specific: boolean;
    };
    checkout_date: {
      enabled: boolean;
      order_types: any[];
    };
    credit: {
      order_status: string;
    };
    // lead_time: {
    //   type: LeadTimeType;
    //   pick_up: {
    //     enabled: boolean;
    //     hours: number;
    //     minutes: number;
    //   };
    //   delivery: {
    //     enabled: boolean;
    //     hours: number;
    //     minutes: number;
    //   };
    // };
    picklist: PickingListParams;
    confirmation: ConfirmationListParams;
    set_price_allocation?: boolean;
    zero_price?: boolean;
    business?: {
      email_message: string;
      invoice_due_status: {
        account: OrderStatus;
        stripe: OrderStatus;
      };
      invoice_mail_message: string;
      invoice_send_sms: boolean;
      invoice_sms_message: string;
      invoice_subject: string;
      send_sms: boolean;
      sms_message: string;
      subject: string;
      sync_xero: boolean;
    };
  };
  purchase_order?: {
    features: {
      auto_receiving_status: boolean;
    };
  };
  onboarding?: {
    enabled: boolean;
    company: {
      enabled: boolean;
      qr_code?: {
        id?: string;
        url: string;
        qr_code_url?: string;
        scans?: number;
        name: string;
        color: string;
        background_color: string;
        display_logo: boolean;
        display_label: boolean;
      };
    };
    individual: {
      enabled: boolean;
      qr_code?: {
        id?: string;
        url: string;
        qr_code_url?: string;
        scans?: number;
        name: string;
        color: string;
        background_color: string;
        display_logo: boolean;
        display_label: boolean;
      };
    };
    terms_of_service: any;
  };
  taxes: {
    enabled: boolean;
    method: TaxesMethod;
  };
  integrations: {
    pusher: {
      enabled: boolean;
      app_id: string;
      key: string;
      secret: string;
      cluster: string;
    };
    facebook_login: {
      enabled: boolean;
      app_id: string;
      secret: string;
    };
    microsoft: {
      enabled: boolean;
      client_id: string;
      client_secret: string;
    };
    google_login: {
      enabled: boolean;
      app_id: string;
      secret: string;
    };
    google_maps: {
      enabled: boolean;
      key: string;
    };
    mailgun: {
      enabled: boolean;
      sender_name: string;
      sender_email: string;
      domain: string;
      key: string;
      region: string;
    };
    twilio: {
      enabled: boolean;
      account_id: string;
      secret: string;
      sender: string;
    };
    xero: {
      enabled: boolean;
      client_id: string;
      client_secret: string;
      webhook_secret: string;
      level?: string;
      invoices: {
        account_mapping?: {
          fees: string;
          payments: string;
          rounding: string;
          sales: string;
          shipping: string;
          discounts: string;
        };
        account: {
          draft_status: boolean;
          inventory_item: boolean;
          invoice_prefix: string;
          sequence_number: number;
          order_with_zero_totals: boolean;
          use_alphabetical_order: boolean;
          exclude_processing_fee: boolean;
          invoice_issue_date: InvoiceIssueDateTypes;
          disable_auto_update_sync: boolean;
        };
        integration_settings?: {
          fees: string;
          invoices: string;
          payments: string;
          shipping: string;
        };
      };
      bills: {
        account_mapping?: {
          fees: string;
          payments: string;
          rounding: string;
          sales: string;
          shipping: string;
          discounts: string;
          levies: string;
        };
        account: {
          draft_status: boolean;
          inventory_item: boolean;
          invoice_prefix: string;
          sequence_number: number;
          order_with_zero_totals: boolean;
          use_alphabetical_order: boolean;
          invoice_issue_date: InvoiceIssueDateTypes;
          disable_auto_update_sync: boolean;
        };
        integration_settings?: {
          fees: string;
          invoices: string;
          payments: string;
          shipping: string;
        };
      };
      purchase_orders: {
        account_mapping?: {
          levies: string;
        };
        account: {
          draft_status: boolean;
          inventory_item: boolean;
          order_with_zero_totals: boolean;
          use_alphabetical_order: boolean;
          sync_with_order_date: string;
        };
        integration_settings?: {
          invoices: string;
          payments: string;
        };
      };
      // redirect_uri: string;
    };
    openai: {
      enabled: false;
      api_key: string;
      organization: string;
      model: string;
      generation: {
        temperature: number;
        top_p: number;
        max_tokens: number;
      };
      stop_sequence: string;
      penalties: {
        frequency: number;
        presence: number;
      };
      context_prompt: string;
    };
  };
  processing_fee: {
    online: {
      enabled: boolean;
      base: number;
      label: FeesLabels;
      percent: number;
    };
    in_person: {
      enabled: boolean;
      base: number;
      label: FeesLabels;
      percent: number;
    };
  };
  reports: {
    default_period: string;
    excluded_order_statuses: string[];
    recognition_method: string;
    count_confirmed_units: boolean;
    date_range: {
      from: string;
      to: string;
    };
  };
  rostering?: {
    enabled: boolean;
    clocks: boolean;
    scheduling: boolean;
  };
}

export interface Settings {
  general: {
    business_type: BusinessType;
    phone?: string;
    abn?: any;
    code?: string;
    meta_data?: string;
    email?: string;
    payment_detail_notes?: string;
    currency: Currency;
    time_format?: string;
    time_zone?: string;
    image?: Media;
    fav_icon?: Media;
    login_logo?: Media;
    contrast_logo?: Media;
    features?: SettingsFeaturesParams;
    address?: {
      country?: {
        id: string;
        name: string;
      };
      province: {
        name: string;
        code: string;
      };
      postalcode: string;
      city: string;
      address: string;
      lat: string;
      lng: string;
    };
    name?: string;
    regional_code?: string;
    superadmin_domain?: string;
    meta_title: string;
    home_page: string;
    brand_color: string;
    background_color: string;
    pos_type?: PosType;
    welcome_message?: {
      message: string;
      posted_at: string | null;
      posted_by: {
        email: string;
        id: number | string;
        image: {
          conversions: {
            display: string;
            preview: string;
            thumbnail: string;
          };
          description: string;
          id: number;
          is_protected: boolean;
          title: string;
          url: string;
        };
        name: string;
        notifications: [];
        phone: string;
        short_name: null | string;
        subtypes: [];
        title: string;
        type: string;
        user: User;
      };
    };
  };
  products: {
    secondary_name: boolean;
    secondary_name_label: string;
    features?: {
      automatic_barcodes: boolean;
      custom_sorting: boolean;
    };
  };
  pos?: {
    enabled: boolean;
    sales_method: SalesMethod;
    quick_add: boolean;
    search: boolean;
    keypad: boolean;
    date: boolean;
    reset_team_member: boolean;
    pin: boolean;
    cashier: boolean;
    quick_order: boolean;
    guest_default_customer: boolean;
    variations: boolean;
    variation_search: boolean;
    lot_confirmation: boolean;
    custom_price: boolean;
    swap_price_and_quantity: boolean;
    dollar_input: boolean;
    tiles_layout_level: PosTilesLevel;
    table_ordering?: boolean;
  };
  printing?: {
    logo: Media;
  };
  emailing?: {
    display_logo?: boolean;
    logo?: Media;
  };
  inventory?: {
    barcode_method: string;
    auto_add_products: boolean;
    centralised_pricing: boolean;
    enabled: boolean;
    required_fields: {
      price: boolean;
      area: boolean;
    };
    stock_count: {
      show_ordered_quantity: boolean;
      show_cost: boolean;
    };
    auto_stock: {
      enabled: boolean;
      reservation: {
        enabled: boolean;
        order_status: string[];
      };
    };
    group_lots_by_supplier: boolean;
    purchase_confirmation: boolean;
    adjustment_notes: boolean;
  };
  locations?: {
    location_management: boolean;
    order_admin_email: null | string;
  };
  contacts: {
    allow_create: boolean;
    features: {
      bay_number: boolean;
      levy_calculation: boolean;
    };
  };
  customers: {
    features: {
      credit_service: boolean;
      protection: boolean;
    };
    roles_labels: {
      individual: string;
      staff: string;
      company: string;
      contact: string;
    };
    set_default_group: boolean;
  };
  order: {
    services?: {
      enabled: boolean;
    };
    custom_terms?: any;
    hide_order_status?: boolean;
    print_defaults?: {
      default_paper_type: PaperFormat;
      hide_zero_allocated: boolean;
      confirmed_units: boolean;
      hide_target_date: boolean;
      receipt_template_style: ReceiptTemplate;
      hide_supplier: boolean;
      print_order: PrintOrder;
    };
    workflow: {
      enabled: boolean;
      auto_accept: boolean;
      auto_print: boolean;
      print_action: boolean;
      options: any[];
    };
    allocations: {
      enabled: boolean;
      display_lots: boolean;
    };
    booking_services: {
      enabled: boolean;
    };
    print_on_acceptance: boolean;
    acceptance: {
      type: AcceptanceType;
      intervals: number;
      minutes: number;
    };
    auto_accept: boolean;
    target_date: {
      lead_time: boolean;
      location_specific: boolean;
    };
    checkout_date: {
      enabled: boolean;
      order_types: any[];
    };
    order_types: {
      enabled: string[];
      allowed: string[];
    };
    credit: {
      order_status: string;
    };
    // lead_time: {
    //   type: LeadTimeType;
    //   pick_up: {
    //     enabled: boolean;
    //     hours: number;
    //     minutes: number;
    //   };
    //   delivery: {
    //     enabled: boolean;
    //     hours: number;
    //     minutes: number;
    //   };
    // };
    picklist: PickingListParams;
    confirmation: ConfirmationListParams;
    set_price_allocation?: boolean;
    zero_price?: boolean;
    business?: {
      email_message: string;
      invoice_due_status: {
        account: OrderStatus;
        stripe: OrderStatus;
      };
      invoice_mail_message: string;
      invoice_send_sms: boolean;
      invoice_sms_message: string;
      invoice_subject: string;
      send_sms: boolean;
      sms_message: string;
      subject: string;
      sync_xero: boolean;
    };
  };
  purchase_order?: {
    features: {
      auto_receiving_status: boolean;
    };
  };
  onboarding?: {
    enabled: boolean;
    company: {
      enabled: boolean;
      qr_code?: {
        id?: string;
        url: string;
        qr_code_url?: string;
        scans?: number;
        name: string;
        color: string;
        background_color: string;
        display_logo: boolean;
        display_label: boolean;
      };
    };
    individual: {
      enabled: boolean;
      qr_code?: {
        id?: string;
        url: string;
        qr_code_url?: string;
        scans?: number;
        name: string;
        color: string;
        background_color: string;
        display_logo: boolean;
        display_label: boolean;
      };
    };
    terms_of_service: any;
  };
  taxes: {
    enabled: boolean;
    method: TaxesMethod;
  };
  integrations: {
    pusher: {
      enabled: boolean;
      app_id: string;
      key: string;
      secret: string;
      cluster: string;
    };
    facebook_login: {
      enabled: boolean;
      app_id: string;
      secret: string;
    };
    google_login: {
      enabled: boolean;
      app_id: string;
      secret: string;
    };
    microsoft: {
      enabled: boolean;
      client_id: string;
      client_secret: string;
    };
    google_maps: {
      enabled: boolean;
      key: string;
    };
    mailgun: {
      enabled: boolean;
      sender_name: string;
      sender_email: string;
      domain: string;
      key: string;
      region: string;
    };
    twilio: {
      enabled: boolean;
      account_id: string;
      secret: string;
      sender: string;
    };
    xero: {
      enabled: boolean;
      client_id: string;
      client_secret: string;
      webhook_secret: string;
      level?: string;
      invoices: {
        account_mapping?: {
          fees: string;
          payments: string;
          rounding: string;
          sales: string;
          shipping: string;
        };
        account: {
          draft_status: boolean;
          inventory_item: boolean;
          invoice_prefix: string;
          sequence_number: number;
          order_with_zero_totals: boolean;
          use_alphabetical_order: boolean;
        };
        integration_settings?: {
          fees: string;
          invoices: string;
          payments: string;
          shipping: string;
        };
      };
      bills: {
        account_mapping?: {
          fees: string;
          payments: string;
          rounding: string;
          sales: string;
          shipping: string;
        };
        account: {
          draft_status: boolean;
          inventory_item: boolean;
          invoice_prefix: string;
          sequence_number: number;
          order_with_zero_totals: boolean;
          use_alphabetical_order: boolean;
        };
        integration_settings?: {
          fees: string;
          invoices: string;
          payments: string;
          shipping: string;
        };
      };

      // draft_status?: boolean;

      // redirect_uri: string;
      // account: XeroAccountSettings;
      // integration_settings?: XeroIntegrationSettings;
      // account_mapping?: XeroAccountMappingSettings;
    };
    cube_js: {
      enabled: boolean;
      secret: string;
    };
    openai: {
      enabled: false;
      api_key: string;
      organization: string;
      model: string;
      generation: {
        temperature: number;
        top_p: number;
        max_tokens: number;
      };
      stop_sequence: string;
      penalties: {
        frequency: number;
        presence: number;
      };
      context_prompt: string;
    };
  };
  processing_fee: {
    online: {
      enabled: boolean;
      base: number;
      label: FeesLabels;
      percent: number;
    };
    in_person: {
      enabled: boolean;
      base: number;
      label: FeesLabels;
      percent: number;
    };
  };
  reports: {
    default_period: string;
    excluded_order_statuses: string[];
    recognition_method: string;
    count_confirmed_units: boolean;
    date_range: {
      from: string;
      to: string;
    };
  };
  rostering?: {
    enabled: boolean;
    clocks: boolean;
    scheduling: boolean;
  };
  loyalty_reward?: {
    points_rate: number;
    enabled: boolean;
  };
}

export interface PublicSettings {
  general: {
    meta_title: string;
    home_page: string;
    business_type: BusinessType;
    currency: Currency;
    time_format: string;
    time_zone: string;
    brand_color: string;
    background_color: string;
    payment_detail_notes?: string;
    abn?: any;
    phone?: string;
    email?: string;
    address: {
      country: {
        id: string;
        name: string;
      };
      province: {
        name: string;
        code: string;
      };
      postalcode: string;
      city: string;
      address: string;
      lat: number;
      lng: number;
    };
    image: Media;
    name: string;
    login_logo?: Media;
    contrast_logo?: Media;
    fav_icon?: Media;
  };
  integrations: {
    pusher: {
      enabled: boolean;
    };
    facebook_login: {
      enabled: boolean;
    };
    google_login: {
      enabled: boolean;
    };
    google_maps: {
      enabled: boolean;
    };
    mailgun: {
      enabled: boolean;
    };
    twilio: {
      enabled: boolean;
    };
    xero: {
      enabled: boolean;
    };
    openai: {
      enabled: boolean;
    };
    microsoft: {
      enabled: boolean;
    };
  };
  onboarding?: {
    enabled: boolean;
    company: {
      enabled: boolean;
      qr_code?: {
        id?: string;
        url: string;
        qr_code_url?: string;
        scans?: number;
        name: string;
        color: string;
        background_color: string;
        display_logo: boolean;
        display_label: boolean;
      };
    };
    individual: {
      enabled: boolean;
      qr_code?: {
        id?: string;
        url: string;
        qr_code_url?: string;
        scans?: number;
        name: string;
        color: string;
        background_color: string;
        display_logo: boolean;
        display_label: boolean;
      };
    };
    terms_of_service: any;
  };
  payment_methods: {
    cash: {
      id: number;
      name: string;
      gateway: string;
      is_enabled: boolean;
      stores?: {
        id: number;
        name: string;
        short_name: string;
      }[];
    };
    account: {
      id: number;
      name: string;
      gateway: string;
      is_enabled: boolean;
      stores?: {
        id: number;
        name: string;
        short_name: string;
      }[];
    };
    stripe: {
      public_key: string;
      live_mode: boolean;
      id: number;
      name: string;
      gateway: string;
      is_enabled: boolean;
      stores?: {
        id: number;
        name: string;
        short_name: string;
      }[];
    };
    "in-person": {
      id: number;
      name: string;
      gateway: string;
      is_enabled: boolean;
      stores?: {
        id: number;
        name: string;
        short_name: string;
      }[];
    };
    credit: {
      id: number;
      name: string;
      gateway: string;
      is_enabled: boolean;
      stores?: {
        id: number;
        name: string;
        short_name: string;
      }[];
    };
    cheque: {
      id: number;
      name: string;
      gateway: string;
      is_enabled: boolean;
      stores?: {
        id: number;
        name: string;
        short_name: string;
      }[];
    };
    "bank-transfer": {
      id: number;
      name: string;
      gateway: string;
      is_enabled: boolean;
      stores?: {
        id: number;
        name: string;
        short_name: string;
      }[];
    };
  };
  loyalty_reward?: {
    points_rate: number;
    enabled: boolean;
  };
  order?: {
    booking_services: {
      enabled: boolean;
    };
  };
}

export enum BusinessType {
  FOOD = "food",
  RETAIL = "retail",
  // MARKETPLACE = "marketplace",
  // PROFESSIONAL = "professional",
}
export enum FeesLabels {
  ONLINE = "Online processing fee",
  IN_PERSON = "In-person processing fee",
}

export enum AcceptanceType {
  INTERVALS = "intervals",
  MINUTES = "minutes",
}

export enum TaxesMethod {
  INCLUSIVE = "inclusive",
  EXCLUSIVE = "exclusive",
}

export enum LeadTimeType {
  NONE = "none",
  MINIMUM = "minimum",
  REQUIRED = "required",
  FIXED = "fixed",
}

export enum ConditionType {
  CATEGORIES = "categories",
  TAGS = "tags",
  ATTRIBUTES = "attributes",
  CHANNELS = "channels",
  ORDER_TYPES = "order_types",
  PRICING_METHODS = "pricing_methods",
}

export enum LoyaltyPointsCountMethodsType {
  UNIT_CHECK = "unit_check",
  ORDER_VALUE = "order_value",
  ORDER_VALUE_EXCLUDING_FEES = "order_value_excluding_fees",
}

export enum LoyaltyPointsConditionType {
  PRODUCT_CATEGORY = "product-category",
  PRODUCT_TAGS = "product-tags",
  ORDER_TYPE = "order-type",
  ORDER_STATUS = "order-status",
  LOCATION = "location",
}

export enum Currency {
  AUD = "aud",
  USD = "usd",
}

export enum InputType {
  PRICE = "price",
  DEFAULT = "",
}

export enum SettingsManageType {
  CREATED = "created",
  UPDATED = "updated",
}

export enum SalesMethod {
  SESSION = "session",
  REGISTER = "register",
}

export enum PosType {
  HOSPITALITY = "hospitality",
  RETAIL = "retail",
  WHOLESALE = "wholesale",
}

export enum PosTilesLevel {
  PLATFORM = "platform",
  LOCATION = "location",
  USER = "user",
}

export interface SettingsFeaturesParams {
  online_sales: boolean;
  orders: boolean;
  pos: boolean;
  dashboard: boolean;
  multilocation: boolean;
  location_brands: boolean;
  reporting: boolean;
  mfa: boolean;
}

export const SidebarPage = {
  SETTINGS_MAIN: `${SidebarPrefix.SETTINGS}-main`,

  SETTINGS_ACCOUNT_INFORMATION: `${SidebarPrefix.SETTINGS}-account-information`,
  SETTINGS_ACCOUNT_INFORMATION_XERO: `${SidebarPrefix.SETTINGS}-account-xero`,
  SETTINGS_ACCOUNT_INFORMATION_JAFFLE_PAYMENTS: `${SidebarPrefix.SETTINGS}-account-jaffle-payments`,
  SETTINGS_ACCOUNT_INFORMATION_XERO_INVOICES: `${SidebarPrefix.SETTINGS}-account-xero-invoices`,
  SETTINGS_ACCOUNT_INFORMATION_XERO_BILLS: `${SidebarPrefix.SETTINGS}-account-xero-bills`,
  SETTINGS_ACCOUNT_INFORMATION_XERO_CONTACTS: `${SidebarPrefix.SETTINGS}-account-xero-contacts`,

  PLATFORM_SETUP: `${SidebarPrefix.SETTINGS}-platform-setup`,
  SETTINGS_APP: `${SidebarPrefix.SETTINGS}-app`,
  SETTINGS_APP_FUNCTION: `${SidebarPrefix.SETTINGS}-app-function`,
  SETTINGS_PLATFORM: `${SidebarPrefix.SETTINGS}-platform`,
  SETTINGS_ONLINE: `${SidebarPrefix.SETTINGS}-online`,
  SETTINGS_INTEGRATIONS: `${SidebarPrefix.SETTINGS}-integrations`,
  SETTINGS_INTEGRATIONS_TWILIO: `${SidebarPrefix.SETTINGS}-twilio`,
  SETTINGS_INTEGRATIONS_PUSHER: `${SidebarPrefix.SETTINGS}-pusher`,
  SETTINGS_INTEGRATIONS_MAILGUN: `${SidebarPrefix.SETTINGS}-mailgun`,
  SETTINGS_INTEGRATIONS_XERO: `${SidebarPrefix.SETTINGS}-xero`,
  SETTINGS_INTEGRATIONS_GOOGLE_ANALYTICS: `${SidebarPrefix.SETTINGS}-google-analytics`,
  SETTINGS_PAYMENT_METHODS: `${SidebarPrefix.SETTINGS}-payment-methods`,
  SETTINGS_JAFFLE_PAYMENTS: `${SidebarPrefix.SETTINGS}-jaffle-payments`,
  SETTINGS_POS: `${SidebarPrefix.SETTINGS}-pos`,
  SETTINGS_THIRD_PARTY: `${SidebarPrefix.SETTINGS}-3rd-party`,
  SETTINGS_THIRD_PARTY_CHANNEL: `${SidebarPrefix.SETTINGS}-3rd-party-channel`,

  SETTINGS_LEAD_TIMES_AND_TERGETS: `${SidebarPrefix.SETTINGS}-lead-times-and-targets`,
  SETTINGS_LEAD_TIMES_AND_TERGETS_RULE: `${SidebarPrefix.SETTINGS}-lead-times-and-targets-rule`,
  SETTINGS_ORDER_EXPRESS_SERVICE: `${SidebarPrefix.SETTINGS}-express-service`,
  SETTINGS_ORDER_MINIMUM: `${SidebarPrefix.SETTINGS}-minimum`,

  SETTINGS_ORDERS: `${SidebarPrefix.SETTINGS}-orders`,
  SETTINGS_TARGET_DATES: `${SidebarPrefix.SETTINGS}-target-dates`,
  SETTINGS_ORDERS_WORKFLOW: `${SidebarPrefix.SETTINGS}-workflow`,
  SETTINGS_ORDERS_ALLOCATIONS: `${SidebarPrefix.SETTINGS}-allocations`,
  SETTINGS_ORDERS_TAG: `${SidebarPrefix.SETTINGS}-order-tag`,
  SETTINGS_CHECKOUT: `${SidebarPrefix.SETTINGS}-checkout`,

  SETTINGS_TAXES: `${SidebarPrefix.SETTINGS}-taxes`,
  SETTINGS_SINGLE_TAXES: `${SidebarPrefix.SETTINGS}-taxes-new`,

  SETTINGS_BLOCK_OUT: `${SidebarPrefix.SETTINGS}-block-out-periods`,
  SETTINGS_SINGLE_BLOCK_OUT: `${SidebarPrefix.SETTINGS}-block-out-period`,
  SETTINGS_SINGLE_TEAM_MEMBERS: `${SidebarPrefix.SETTINGS}-team-members`,
  SETTINGS_SINGLE_QR_CODES: `${SidebarPrefix.SETTINGS}-qr-codes`,
  SETTINGS_SINGLE_TEAM_MEMBERS_ADD: `${SidebarPrefix.SETTINGS}-add-member`,
  SETTINGS_DELIVERY: `${SidebarPrefix.SETTINGS}-delivery`,
  SETTINGS_PICK_UP: `${SidebarPrefix.SETTINGS}-pick-up`,
  SETTINGS_SINGLE_DELIVERY: `${SidebarPrefix.SETTINGS}-delivery-new`,

  SETTINGS_CONTACTS: `${SidebarPrefix.SETTINGS}-contacts`,
  SETTINGS_CONTACTS_ONBOARD: `${SidebarPrefix.SETTINGS}-contacts-onboard`,
  SETTINGS_CONTACTS_GROUP: `${SidebarPrefix.SETTINGS}-contacts-group`,
  SETTINGS_CONTACTS_GROUP_ORDER_FORM: `${SidebarPrefix.SETTINGS}-order-form`,
  SETTINGS_CONTACTS_TAG: `${SidebarPrefix.SETTINGS}-contacts-tag`,
  SETTINGS_CONTACTS_ROLES: `${SidebarPrefix.SETTINGS}-contacts-roles`,
  SETTINGS_CONTACTS_SINGLE_ROLE: `${SidebarPrefix.SETTINGS}-contacts-role`,
  SETTINGS_CONTACTS_SINGLE_SUBTYPE: `${SidebarPrefix.SETTINGS}-contacts-subtype`,

  SETTINGS_ONBOARDING: `${SidebarPrefix.SETTINGS}-onboarding`,
  SETTINGS_ONBOARDING_SECTION: `${SidebarPrefix.SETTINGS}-onboarding-section`,
  SETTINGS_ONBOARDING_TERMS: `${SidebarPrefix.SETTINGS}-terms`,
  SETTINGS_ONBOARDING_TYPE: `${SidebarPrefix.SETTINGS}-type`,

  SETTINGS_REWARDS_AND_SPECIAL_OFFERS: `${SidebarPrefix.SETTINGS}-rewards-and-special-offers`,
  SETTINGS_SINGLE_REWARDS_AND_SPECIAL_OFFERS: `${SidebarPrefix.SETTINGS}-special-offer`,

  SETTINGS_LOYALTY: `${SidebarPrefix.SETTINGS}-loyalty`,
  SETTINGS_LOYALTY_ITEM: `${SidebarPrefix.SETTINGS}-loyalty-item`,

  SETTINGS_PAYMENT_TYPES: `${SidebarPrefix.SETTINGS}-payment-types`,
  SETTINGS_NOTIFICATIONS: `${SidebarPrefix.SETTINGS}-notifications`,

  SETTINGS_NOTIFICATIONS_ITEM: `${SidebarPrefix.SETTINGS}-notifications-item`,

  SETTINGS_LOCATIONS_BRANDS: `${SidebarPrefix.SETTINGS}-location-brands`,
  SETTINGS_LOCATIONS_SINGLE_ADD_NEW_BRANDS: `${SidebarPrefix.SETTINGS}-location-add-new-brands`,
  SETTINGS_LOCATIONS_SINGLE_ADD_NEW_BRANDED_LOCATIONS: `${SidebarPrefix.SETTINGS}-location-add-new-branded-locations`,
  SETTINGS_LOCATIONS: `${SidebarPrefix.SETTINGS}-locations`,
  SETTINGS_LOCATIONS_SINGLE: `${SidebarPrefix.SETTINGS}-location-information`,
  SETTINGS_LOCATIONS_SINGLE_PICK_UP: `${SidebarPrefix.SETTINGS}-location-pick-up`,
  SETTINGS_LOCATIONS_SINGLE_OPENING_HOURS: `${SidebarPrefix.SETTINGS}-opening-hours`,
  SETTINGS_LOCATIONS_SINGLE_BLOCK_OUT: `${SidebarPrefix.SETTINGS}-block-out-periods`,
  SETTINGS_LOCATIONS_SINGLE_BLOCK_OUT_MANAGE: `${SidebarPrefix.SETTINGS}-block-out-manage`,
  SETTINGS_LOCATIONS_SINGLE_LEAD_TIMES: `${SidebarPrefix.SETTINGS}-location-lead-times`,
  SETTINGS_LOCATIONS_SINGLE_DELIVERY: `${SidebarPrefix.SETTINGS}-location-delivery`,
  SETTINGS_LOCATIONS_SINGLE_DELIVERY_ZONE: `${SidebarPrefix.SETTINGS}-delivery-zone`,
  SETTINGS_LOCATIONS_SINGLE_TIMES_DRIVERS: `${SidebarPrefix.SETTINGS}-delivery-operating-times-and-drivers`,
  SETTINGS_LOCATIONS_SINGLE_REGISTERS: `${SidebarPrefix.SETTINGS}-registers`,
  SETTINGS_LOCATIONS_SINGLE_AREAS: `${SidebarPrefix.SETTINGS}-areas`,
  SETTINGS_LOCATIONS_SINGLE_REGISTER_SINGLE: `${SidebarPrefix.SETTINGS}-register-single`,
  SETTINGS_LOCATIONS_SINGLE_PRINTERS: `${SidebarPrefix.SETTINGS}-printers`,
  SETTINGS_LOCATIONS_SINGLE_PRINTER: `${SidebarPrefix.SETTINGS}-printer`,
  SETTINGS_LOCATIONS_SINGLE_ADD_NEW_PRINTER: `${SidebarPrefix.SETTINGS}-add-new-printer`,
  SETTINGS_LOCATIONS_SINGLE_XERO: `${SidebarPrefix.SETTINGS}-location-xero`,
  SETTINGS_LOCATIONS_SINGLE_XERO_INVOICES: `${SidebarPrefix.SETTINGS}-location-xero-invoices`,
  SETTINGS_LOCATIONS_SINGLE_XERO_BILLS: `${SidebarPrefix.SETTINGS}-location-xero-bills`,
  SETTINGS_LOCATIONS_SINGLE_XERO_CONTACTS: `${SidebarPrefix.SETTINGS}-location-xero-contacts`,
  SETTINGS_LOCATIONS_SINGLE_JAFLLE_PAYMENTS: `${SidebarPrefix.SETTINGS}-location-jaffle-payments`,
  SETTINGS_LOCATIONS_PAYMENT_METHODS: `${SidebarPrefix.SETTINGS}-payment-methods`,

  SETTINGS_INVENTORY_PRODUCTS: `${SidebarPrefix.SETTINGS}-products`,
  SETTINGS_INVENTORY_UNIT_CHECKS_SINGLE: `${SidebarPrefix.SETTINGS}-unit-checks-settings`,
  SETTINGS_INVENTORY_CUSTOM_LABELS: `${SidebarPrefix.SETTINGS}-custom-labels`,
  SETTINGS_INVENTORY_TAG: `${SidebarPrefix.SETTINGS}-inventory-tag`,

  SETTINGS_XERO_EXCLUSION_RULE: `${SidebarPrefix.SETTINGS}-xero-exclusion-rule`,

  // ROSTERING
  SETTINGS_ROSTERING: `${SidebarPrefix.SETTINGS}-rostering`,
  SETTINGS_ROSTERING_DEPARTMENT: `${SidebarPrefix.SETTINGS}-rostering-department`,
  SETTINGS_ROSTERING_SHIFT: `${SidebarPrefix.SETTINGS}-rostering-shift`,
  SETTINGS_ROSTERING_BREAK: `${SidebarPrefix.SETTINGS}-rostering-break`,
};
