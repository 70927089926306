import { CountNotification } from "./counts";
import {
  AddressType,
  CompanySubtype,
  CustomerType,
  CustomerSubtype,
} from "./customers";
import { ReportType } from "./reports";
import { InventoryNotification } from "./inventory";
import { User } from "./users";

// REQUESTS
export interface RequestParams {
  filter?: any[];
  sort?: any[];
  page?: number;
  limit?: number;
}

export interface Meta {
  current_page?: number;
  per_page?: number;
  from?: number;
  last_page?: number;
  to?: number;
  total?: number;
  total_balance?: number;
  opening_balance?: number;
  due_balance?: number;
  overdue_balance?: number;
  total_paid?: number;
  total_value?: number;
}

export interface ResponseType<T> {
  data: T;
  meta?: Meta;
}

export enum FetchWorkerEvent {
  CHUNK = "chunk",
  ERROR = "error",
  COMPLETED = "completed",
}

//

export enum View {
  GRID = "grid",
  // LIST = "list",
  TABLE = "table",
  KANBAN = "kanban",
}

export enum OptionAction {
  EDIT = "edit",
  DELETE = "delete",
}

export enum OrderType {
  DELIVERY = "delivery",
  PICKUP = "pick-up",
  IN_PERSON = "in-person",
  UNKNOWN = "unknown",
  MOBILE = "mobile",
  VIRTUAL = "virtual", // like in-person
  // DRAFT = "draft",
}

export interface Option {
  name: string;
  value: any;
  image?: string;
  subtext?: string;
  key?: string | number;
  type?: any;
  symbols?: string;
  count?: number;
  tag?: string;
  item_number_name?: string;
  code?: string;
}

export interface Toast {
  id: string;
  message: string;
  submessage: string;
  type: ToastType;
}

export enum ToastType {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export interface Time {
  hours: string | number;
  minutes: string | number;
  seconds?: string | number;
}

export enum ReportExtension {
  PDF = "pdf",
  CSV = "csv",
  JSON = "json",
}

export interface Address {
  address: string;
  city: string;
  country: Country;
  id: number;
  is_default: boolean;
  tag?: string;
  name: string;
  postalcode: string;
  province: Province;
  lat: null | string;
  lng: null | string;
  type: AddressType;
  notes?: string;
  hide_address_on_receipt?: boolean;
  bay_number?: string;
  freight_company?: any;
  freight_company_id?: number;
  billing_contacts?: any;
  billpayer?: {
    email?: string;
    phone?: string;
    mobile?: string;
    is_organization?: boolean;
    is_third_party?: boolean;
    firstname?: string;
    lastname?: string;
    tfn?: string;
    abn?: string;
    billing_contact_id?: number;
    company_name?: string;
  };
}

export interface AddressShort {
  province: string;
  postalcode: string;
  city: string;
  address: string;
  lat: number;
  lng: number;
}

export interface Country {
  id?: string;
  name: string;
}
export interface Province {
  id?: number;
  name: string;
  code: string;
}
export interface Brand {
  id: number;
  name: string;
  type: string;
}

export interface Image {
  media: any;
  title: string;
  description: string;
}

export interface Media {
  id: number;
  title: string;
  description: string;
  url: string;
  conversions: MediaConversions;
}
export interface MediaConversions {
  thumbnail: string;
}

export interface Document {
  id?: number;
  file?: any;
  media: any;
  title: string;
}

export interface MazPhoneResult {
  isValid: boolean;
  isPossible?: boolean;
  countryCode?: string;
  countryCallingCode?: string;
  nationalNumber?: string;
  type?: any;
  formatInternational?: string;
  formatNational?: string;
  uri?: string;
  e164?: string;
  rfc3966?: string;
}

export interface GeneratePDFPayload {
  html: string;
  options?: {
    base64?: boolean;
  };
}

// SIDEBARS
export enum SidebarPrefix {
  SETTINGS = "settings",
  CUSTOMERS = "customers",
  CONTACTS = "contacts",
  ORDER = "single-order",
  ORDERS = "orders",
  PRICING = "pricing",
  KANBAN = "kanban",
  POS = "pos",
  PRODUCT = "product",
  INVENTORY = "inventory",
  ALLOCATIONS = "allocations",
  PICKING = "picking",
  RECEIVING = "receiving",
  MODIFIERS = "modifiers",
  TRANSFERS = "transfers",
  COUNTS = "counts",
  RUNS = "runs",
  ATTRIBUTES = "attributes",
  ONBOARDING = "onboarding",
  STATEMENTS = "statements",
  LOYALTY = "loyalty",
  STORE = "store",
  TAXONOMIES = "taxonomies",
  INVOICES = "invoices",
  ANALYTICS = "analytics",
  BILLS = "bills",
  PURCHASE_OVERVIEW_ITEM = "purchase-overview",
  TRANSPORT_UNITS = "transport-units",
  PAYMENT = "payment",
  NOTIFICATIONS = "notifications",
  CALENDAR = "calendar",
  BOOKING = "booking",
  MANAGEMENT_LOCATIONS = "management-locations",
  MANAGEMENT_BRANDS = "management-brands",
  PROFILE_CONTACT = "profile-contact",
  PROFILE_LOCATION = "profile-location",
}

// FILTERS
export interface Filters {
  [key: string]: FilterValue;
}

export interface FilterValue<T = FilterOption> {
  type: string;
  title: string;
  subtitle?: string;
  options: Array<T>;
  chosenOptions: Array<T>;
  isApplied?: boolean;
  isSingle?: boolean;
  toggle?: boolean;
}
// FOR PRICE OPTIONS USE THIS FORMAT
// options: [
//   { name: "Price From", value: 0 },
//   { name: "Price To", value: 0 },
// ],
// chosenOptions: [
//   { name: "Price From", value: 0 },
//   { name: "Price To", value: 0 },
// ],

export interface FilterOption {
  name: string;
  value: string | number;
  type?: any;
}

export enum FilterLayout {
  DEFAULT = "default",
  TAG_LIST = "tag-list",
  STATUS = "status",
  SWITCHER = "switcher",
  BUTTONS = "buttons",
  // TODO: add date range layout on range filter
  // DATE_RANGE = "date-range",
}

export interface FilterPriceRange {
  min: string | number;
  max: string | number;
}

export interface FilterPriceMinMax {
  min_price: string | number;
  max_price: string | number;
}

export enum Period {
  WEEK = "week",
  YESTERDAY = "yesterday",
  RECENT = "recent",
}

export enum DateStepAction {
  NEXT = "next",
  PREV = "prev",
}

export enum PlaylistAction {
  PREV = "prev",
  NEXT = "next",
}

export enum SyncStatus {
  PENDING = "pending",
  ERROR = "error",
  SYNCED = "synced",
}

export interface ToggleListOption extends Option {
  inputValue?: string | number;
  isBreak?: boolean;
  day?: number;
  is_round_the_clock?: boolean;
  time?: string[];
  cut_off_time?: string[];
  text?: string;
}

export enum PillVariant {
  RED = "red",
  ORANGE = "orange",
  GREEN = "green",
  GREY = "grey",
  PURPLE = "purple",
  WHITE = "white",
  LIGHT_GREY = "light-grey",
}

// NAV ITEMS
export interface NavItem {
  title: NavItemTitle;
  key?: NavItemKey;
  route?: string;
  link?: string;
  functions?: NavSubitem[][];
  // icon?: string;
  // query?: Record<string, string>;
  // subitems?: NavSubitem[];
}

export interface NavSubitem {
  link: string;
  name: string;
  id: number;
  is_active: boolean;
  is_protected: boolean;
  position: number;
}

export enum NavItemKey {
  DASHBOARD = "dashboard",
  POS = "pos",
  ORDERS = "orders",
  CONTACTS = "contacts",
  INVENTORY = "inventory",
  PURCHASING = "purchasing",
  PRICING = "pricing",
  PRODUCTS = "products",
  REPORTS = "reports",
  CATEGORIES = "categories",
  MODIFIERS = "modifiers",
  ANALYTICS = "analytics",
  TAGS = "tags",
  PROFILE = "profile",
  MANAGEMENT = "management",
}

export enum NavItemTitle {
  DASHBOARD = "Dashboard",
  POS = "Point of Sale",
  ORDERS = "Orders",
  BOOKINGS = "Bookings",
  CONTACTS = "Contacts",
  INVENTORY = "Inventory",
  PURCHASING = "Purchasing",
  PRICING = "Pricing",
  PRODUCTS = "Products",
  REPORTS = "Reports",
  CATEGORIES = "Categories",
  MODIFIERS = "Modifiers",
  ANALYTICS = "Business Analytics",
  TAGS = "Tag",
  PROFILE = "Profile",
  MANAGEMENT = "Management",
}

// TABLES
export interface TableHeadCol {
  name: string;
  sortable: boolean;
  field?: string;
  type?: SortType;
  param?: string;
  styles?: object;
  align?: string;
  headerClass?: string;
  label?: string;
}

export interface TableRow {
  id: number;
  cols: TableCol[];
  product_id?: number;
  is_checked?: boolean;
  options?: Option[];
  quantity?: number;
}

export interface TableCol {
  text?: string | number | TableColText[];
  subtext?: string | number | TableColText[];
  textClass?: string;
  subtextClass?: string;
  maxColWidth?: number;
  image?: string;
  link?: string;
  type?: TableColType;
  noInputMode?: boolean;
  variant?: string;
  useDateFormatLib?: string;
  tagType?: TagType;
  inputType?: string;
  html_text?: string;
  tags?: string[];
  symbols?: string;
  colWidth?: number;
  colFullWidth?: boolean;
  inputWidth?: number;
  customText?: number;
  icon?: TableIconType;
  iconText?: string;
  notice?: string;
  truncate?: boolean;
}

export interface TableColText {
  text: string;
  textClass?: string;
}

export interface TableSettings {
  isActions?: boolean;
  isCheckAction?: boolean;
  noFirstColImg?: boolean;
  isQuantity?: boolean;
}

export interface RangesList {
  [key: string]: Range;
}

export interface Range {
  from?: string;
  to?: string;
}

// FILTERS
export enum SortType {
  ASC = "asc",
  DESC = "desc",
  NO_SORT = "no-sort",
  // HIDDEN = "hidden",
}

export enum TableColType {
  TEXT = "text",
  TAG = "tag",
  TAG_MULTIPLE = "tag-multiple",
  DATE = "date",
  INPUT = "input",
  ICON = "icon",
  ICON_TEXT = "icon-text",
}

export enum TableIconType {
  CHECK = "check",
  CROSS = "cross",
  PERCENT = "percent",
}

export enum TagType {
  DEFAULT = "default",
  ORDER = "order",
  TRANSPORT_UNIT = "transport-unit",
}

// DATE FORMAT
export interface DateRange {
  start: string;
  end: string;
}

export enum DateFormat {
  "mmmm d, yyyy 'at' hh:MMtt" = "mmmm d, yyyy 'at' hh:MMtt",

  "dd mmm yy 'at' hh:MM" = "dd mmm yy 'at' hh:MM",
  "ddd, d mmm, yyyy" = "ddd, d mmm, yyyy",
  "ddd, d mmm, yyyy 'at' hh:MM TT" = "ddd, d mmm, yyyy 'at' hh:MM TT",
  "dddd, d/m/yyyy" = "dddd, d/m/yyyy",

  "hh:MMtt, d mmm yy" = "hh:MMtt, d mmm yy",
  "hh:MMtt ddd, d mmm, yyyy" = "hh:MMtt ddd, d mmm, yyyy",
  "hh:MMtt 'on the' dS 'of' mmmm, yyyy" = "hh:MMtt 'on the' dS 'of' mmmm, yyyy",

  "ddd dd, mmm yy" = "ddd dd, mmm yy",
}

export enum DateFormatMoment {
  "MMMM D, YYYY [at] hh:mmA" = "MMMM D, YYYY [at] hh:mmA",
  "hh:mma, D MMM YY" = "hh:mma, D MMM YY",
  "ddd, D MMM, YYYY" = "ddd, D MMM, YYYY",
  "DD MMM, YYYY" = "DD MMM, YYYY",
}

export enum TimeFormat {
  "hh:MMtt" = "hh:MMtt",
  "hh:MM" = "hh:MM",
  "hh:MM:ss" = "hh:MM:ss",
}

export enum DatePickerMode {
  ALL = "all",
  YEAR = "year",
  MONTH = "month",
  WEEK = "week",
  QUARTER = "quarter",
  DAY = "day",
}

export enum CalendarViewOptions {
  MONTH = "month",
  WEEK = "week",
  DAY = "day",
  LIST = "list",
}

// NOTES
export interface Note {
  created_at?: string;
  id?: number;
  category?: NoteCategory;
  change?: string;
  is_private: boolean;
  is_pinned: boolean;
  type?: NoteType;
  text: string;
  media?: any[];
  text_edited_at?: string;
  user?: { id: number; name: string; image?: Media };
  meta?: {
    changes?: {
      type?: string;
      attribute?: string;
      name?: string;
      old?: any;
      new?: any;
    }[];
  };
}

export enum NoteCategory {
  PICKING = "picking",
  PAYMENT = "payment",
  TRANSPORT_UNIT = "transport-unit",
}

export enum NoteType {
  NOTE = "note",
  TRACKING = "tracking",
  ADJUSTMENT = "adjustment",
}

// SENDING TEMPLATE
export enum SendTemplateType {
  EMAIL = "email",
  SMS = "sms",
}

// NOTIFICATIONS
export interface NotificationEvent {
  category: NotificationEventCategory;
  type: NotificationEventType;
  data: CountNotification | InventoryNotification | any;
}

export enum NotificationEventCategory {
  COUNT = "count",
}

export enum NotificationEventType {
  STATUS_UPDATE = "status_update",
  STOCK_UPDATE = "stock_update",
}

// QR CODES
export enum QRType {
  STORES = "stores",
  ONBOARDING_COMPANY = "company",
  ONBOARDING_INDIVIDUAL = "individual",
  TABLES = "tables",
}

// REPORTS
export interface ReportPayload {
  layout: string;
  title?: string;
  options?: any;
  format?: string;
  reportType?: ReportType;
}

export interface ReportResponse {
  id: number;
  user: User;
  status: string;
  type: string;
}

// PRINTERS
export interface PrinterPhysical {
  name: string;
  displayName: string;
  description: string;
  status: number;
  isDefault: boolean;
  options: {
    copies: string;
    "device-uri": string;
    finishings: string;
    "job-cancel-after": string;
    "job-hold-until": string;
    "job-priority": string;
    "job-sheets": string;
    "marker-change-time": string;
    "marker-colors": string;
    "marker-high-levels": string;
    "marker-levels": string;
    "marker-low-levels": string;
    "marker-names": string;
    "marker-types": string;
    "number-up": string;
    "printer-commands": string;
    "printer-info": string;
    "printer-is-accepting-jobs": string;
    "printer-is-shared": string;
    "printer-is-temporary": string;
    "printer-location": string;
    "printer-make-and-model": string;
    "printer-state": string;
    "printer-state-change-time": string;
    "printer-state-reasons": string;
    "printer-type": string;
    "printer-uri-supported": string;
    system_driverinfo: string;
  };
}

export enum OS {
  WINDOWS = "win32",
  MACOS = "darwin",
  LINUX = "linux",
}

export interface PrintAssets {
  print_logo: string;
  symbols: {
    trademark: string;
    registered: string;
    pbr: string;
    "#": string;
    $: string;
  };
}

export enum SingleOrderItemAction {
  DELETE = "delete",
  SPLIT = "split",
  SET_SOLD_OUT = "set-sold-out",
  COPY = "copy",
  CUT = "cut",
  DISCOUNT = "discount",
}

// TIPTAP
export interface TiptapItem {
  label: string;
  method: string;
  key: string;
  level?: number;
  icon: any;
}

// TABS FOR PAGES
export interface Tab {
  name: string;
  tab: TabType;
  types: CustomerType[] | null;
  subtypes: CompanySubtype[] | CustomerSubtype[];
  sections: TabSection[];
}
export interface TabSection {
  section: TabSectionType;
  types: CustomerType[] | null;
  title: string;
  subtitle: string;
  isAction?: boolean;
  noDivider?: boolean;
  keywords?: string;
  modules?: TabSectionModule[];
}
export interface TabSectionModule {
  module: TabSectionModuleType;
  types: CustomerType[];
  keywords?: string;
}

export enum TabType {
  PROFILE = "profile",
  ADDITIONAL_INFORMATION = "information",
  CALENDAR = "calendar",
  ORDERS = "orders-list",
  COMPANIES = "companies",
  CONTACTS = "contacts",
  LOCATIONS = "locations",
  FILES = "files",
  PRODUCTS = "products",
  RUNS = "runs",
  SETTINGS = "settings",
  SEARCH = "search",
  OPENING_HOURS = "opening",
  SERVICE_AREAS = "areas",
  TEAM_MEMBERS = "team",

  TRAVEL_COSTS = "travel-costs",
}

export enum TabSectionType {
  PROFILE = "profile",
  GENERAL_INFO = "general-info",
  ADDRESS_DETAILS = "address-details",
  ORGANISE_AND_CLASSIFY = "organise_and_classify",
  CONTACT_NOTES = "contact_notes",
  STAFF_DETAILS = "staff-details",
  ADDITIONAL_DATA = "additional_data",
  ORDERS = "orders",
  COMPANIES = "companies",
  CONTACTS = "contacts",
  LOCATIONS = "locations",
  PRODUCTS = "products",
  DOCUMENTS = "documents",
  AGREEMENTS = "agreements",
  RUNS = "runs",
  XERO = "sync_xero",
  NOTIFICATIONS = "notifications",
  CONFIGURATION = "configuration",
  ACCOUNT_ACCESS = "account_access",
  LEAD_TIMES_AND_TARGETS = "lead-times-and-targets",
  BLOCK_OUT_PERIODS_CLOSED = "block-out-periods-closed",

  QR_CODE = "qr-code",
  OPENING_HOURS = "opening-hours",
  BLOCK_OUT = "block-out",
  SERVICE_AREAS = "service-areas",
  SERVICE_SCHEDULE = "service-schedule",
  LOCATION_JAFFLE_PAYMENT_ACCOUNT = "jaffle-payment-account",
  LEAD_TIMES = "lead-times",
  TABLES = "tables",
  JAFFLE_PAYMENTS = "jaffle-payments",
  DELETE_LOCATION = "delete-location",
  ONLINE_ORDERING = "online-ordering",
  POINT_OF_SALE_REGISTERS = "point-of-sale-registers",
  PRINTER_RULES = "printer-rules",

  DETAILS = "details",
  AVAILABILITY = "availability",

  COST_CALCULATION = "cost-calculation",
}

export enum TabSectionModuleType {
  PROFILE_ACTIONS = "profile-actions",

  // general info
  CONTACT_INFO = "contact-info",
  SUBTYPES = "subtypes",
  ROLES = "roles",

  // address
  BILLING_ADDRESS = "billing-address",
  DELIVERY_ADDRESS = "delivery-address",

  // organise and classify description
  PROFILE_DETAILS = "profile-details",
  CONTACT_TAGS = "contact-tags",

  // assosiated
  ADDITIONAL_DATA = "additional-data",

  // assosiated
  ORDERS = "orders",
  COMPANIES = "companies",
  CONTACTS = "contacts",
  LOCATIONS = "locations",
  PRODUCTS = "products",
  RUNS = "runs",

  NOTES = "notes",
  PRIMARY_BANKING_ACCOUNT = "primary-banking-account",
  SUPERANNUATION = "superannuation",
  DOCUMENTS = "documents",

  // settings
  XERO = "sync-xero",
  NOTIFICATIONS = "notifications",
  CONFIGURATION = "configuration",
  PASSWORD = "password",
  PIN = "pin",

  // LOCATION
  LOCATION_INFO = "location-info",
  LOCATION_TYPE = "location-type",
  INVOICE_AND_PAYMENT_DETAILS = "invoice-and-payment-details",
  BRANDS = "brands",
  LOCATION_ADDRESS = "location-address",
  LOCATION_CATEGORIES = "location-categories",
  LOCATION_TAGS = "location-tags",
  LOCATION_PLAN_MANAGEMENT = "location-plan-management",
  LOCATION_AREAS = "location-areas",
  QR_CODE = "qr-code",
  OPENING_HOURS = "opening-hours",
  DATE_OVERRIDES = "date-overrides",
  SERVICE_AREAS = "service-areas",
  SERVICE_SCHEDULE = "service-schedule",
  ONLINE_ORDERING = "online-ordering",
  POINT_OF_SALE_REGISTERS = "point-of-sale-registers",
  PRINTER_RULES = "printer-rules",
  TABLES = "tables",
  TABLES_TAGS = "tables-tags",
  LEAD_TIME_RULES = "lead-time-rules",
  INTERVALS = "intervals",
  JAFFLE_PAYMENTS = "jaffle-payments",
  DELETE_LOCATION = "delete-location",
  LOCATION_FILES = "location-files",

  // INVENTORY
  SERVICE_INVENTORY_INFO = "service-inventory-info",
  INVENTORY_TAG = "inventory-tag",
  PRODUCT_CATEGORIES = "product-categories",
  ITEM_NUMBERS = "item-numbers",
  AGE_RANGE = "age-range",
  SERVICE_OPTIONS = "service-options",
  COST_CALCULATION = "cost-calculation",
}

export const TabsSidebarPage = {
  PROFILE_CONTACT_ADD_CREDIT: `${SidebarPrefix.PROFILE_CONTACT}-add-credit`,
  PROFILE_CONTACT_CREDIT_HISTORY: `${SidebarPrefix.PROFILE_CONTACT}-credit-history`,
  PROFILE_CONTACT_ATTACH_CONTACTS: `${SidebarPrefix.PROFILE_CONTACT}-attach-exist-contacts`,
  PROFILE_CONTACT_ATTACH_COMPANIES: `${SidebarPrefix.PROFILE_CONTACT}-attach-exist-companies`,
  PROFILE_CONTACT_ATTACH_LOCATIONS: `${SidebarPrefix.PROFILE_CONTACT}-add-locations`,
  PROFILE_CONTACT_SUPPLIER_PRODUCTS: `${SidebarPrefix.PROFILE_CONTACT}-supplier-products`,
  PROFILE_CONTACT_DELIVERY_RUNS: `${SidebarPrefix.PROFILE_CONTACT}-delivery-runs`,

  PROFILE_CONTACT_CONTACTS_FILTERS: `${SidebarPrefix.PROFILE_CONTACT}-contacts-filters`,
  PROFILE_CONTACT_ORDERS_FILTERS: `${SidebarPrefix.PROFILE_CONTACT}-orders-filters`,
  PROFILE_CONTACT_LOCATIONS_FILTERS: `${SidebarPrefix.PROFILE_CONTACT}-location-filters`,
  PROFILE_CONTACT_QUICK_VIEW: `${SidebarPrefix.PROFILE_CONTACT}-quick-view`,
  PROFILE_CONTACT_QUICK_VIEW_ORDERS_HISTORY: `${SidebarPrefix.PROFILE_CONTACT}-orders-history`,
  PROFILE_CONTACT_QUICK_VIEW_ORDERS_DRAFTED: `${SidebarPrefix.PROFILE_CONTACT}-orders-drafted`,
  PROFILE_CONTACT_ORDERS_ACCOUNTS: `${SidebarPrefix.PROFILE_CONTACT}-orders-accounts`,
  PROFILE_CONTACT_ADD_LOYALTY_POINTS: `${SidebarPrefix.PROFILE_CONTACT}-add-loyalty-points`,

  // LOCATION
  PROFILE_LOCATION_ADDRESS: `${SidebarPrefix.PROFILE_LOCATION}-address`,
  PROFILE_LOCATION_ADD_TEAM_MEMBER: `${SidebarPrefix.PROFILE_LOCATION}-add-team-member`,
  PROFILE_LOCATION_REGISTER: `${SidebarPrefix.PROFILE_LOCATION}-register`,
  PROFILE_LOCATION_PRINTER: `${SidebarPrefix.PROFILE_LOCATION}-printer`,
  PROFILE_LOCATION_DELIVERY_ZONE: `${SidebarPrefix.PROFILE_LOCATION}-delivery-zone`,
  PROFILE_LOCATION_TABLE: `${SidebarPrefix.PROFILE_LOCATION}-table`,
  PROFILE_LOCATION_TABLE_TAG: `${SidebarPrefix.PROFILE_LOCATION}-table-tag`,
  PROFILE_LOCATION_QR_CODE: `${SidebarPrefix.PROFILE_LOCATION}-qr-code`,
  PROFILE_LOCATION_BLOCKOUT: `${SidebarPrefix.PROFILE_LOCATION}-blockout`,
  PROFILE_LOCATION_LEAD_TIMES_AND_TERGETS_RULE: `${SidebarPrefix.PROFILE_LOCATION}-lead-times-and-targets-rule`,
  PROFILE_LOCATION_IMMEDIATE_ORDERING: `${SidebarPrefix.PROFILE_LOCATION}-immediate-ordering`,
  PROFILE_LOCATION_MINIMUM_ORDER_RULE: `${SidebarPrefix.PROFILE_LOCATION}-minimum-order-rule`,
};
