import { defineStore } from "pinia";

import { Customer, CustomerSingle } from "~/types/customers";
import {
  FilterOption,
  Filters,
  Meta,
  Option,
  PillVariant,
  ToastType,
  ResponseType,
} from "~/types/general";
import { Order, OrderManage, KanbanLane, OrderStatus } from "~/types/orders";
import { PickingOption, PickingPayload } from "~/types/picking";
import { useGeneralStore } from "./general";

// Picking Store
export const usePickingStore = defineStore("picking", () => {
  // State

  // Orders List
  const ordersList = ref<Order[]>([]);
  const meta = ref<Meta>({});
  const playlist = ref<Order[]>([]);

  // Sidebar
  const isOpenSidebar = ref(false);

  // Filters
  const filters = ref<Filters>({
    type: {
      type: "type",
      title: "Order Type",
      options: useGeneralStore().orderTypeList,
      chosenOptions: [],
    },
    "action-status": {
      type: "action-status",
      title: "Action Status Filter",
      options: [
        { name: "Normal", value: "normal", type: PillVariant.GREEN },
        { name: "Review", value: "review", type: PillVariant.ORANGE },
        { name: "Critical", value: "critical", type: PillVariant.RED },
      ],
      chosenOptions: [],
    },
    tag: { type: "tag", title: "Custom Tag", options: [], chosenOptions: [] },
    store: {
      type: "store",
      title: "Locations",
      subtitle: "Select the locations you would like included",
      options: [],
      chosenOptions: [],
    },
    freightCompany: {
      type: "freightCompany",
      title: "Freight and Driver",
      subtitle: "Select the freight company or drivers you would like included",
      options: [],
      chosenOptions: [],
    },
    delivery_run: {
      type: "delivery_run",
      title: "Delivery Run",
      subtitle: "Select the delivery runs you would like included",
      options: [],
      chosenOptions: [],
    },
  });

  // Dates and Search
  const dateRange = ref<string[]>([]);
  const targetDate = ref(null);
  const targetDateRange = ref<string[]>([]);
  const search = ref("");
  const activeLetter = ref("");

  // Options
  const options = ref<Option[]>([
    { name: "Quick View", value: PickingOption.QUICK_VIEW },
    { name: "Prepare", value: PickingOption.PREPARE },
  ]);

  // Table Pagination
  const pageTable = ref(1);
  const perPageTable = ref(10);

  // Kanban
  const kanbanLanes = ref<KanbanLane[]>([]);
  const editingKanbanLane = ref<KanbanLane>({} as KanbanLane);
  const creatingKanbanLane = ref<KanbanLane>({} as KanbanLane);
  const kanbanComponentKey = ref(0);
  const currentKanbanLane = ref<KanbanLane | null>(null);

  // Preparing
  const viewingOrder = ref<Order>({} as Order);
  const preparingOrder = ref<Order | OrderManage>({} as OrderManage);
  const preparingOrderCustomer = ref<Customer | CustomerSingle>(
    {} as CustomerSingle
  );
  const preparingOrderNotes = ref<any[]>([]);
  const preparingOrderNotifications = ref<any[]>([]);

  const pickingOrderTabs = ref([
    {
      name: "Order",
      tab: null,
      route_name: useRoutesNames().ordersPreparingPickingId,
    },
    {
      name: "Details",
      tab: "details",
      route_name: useRoutesNames().ordersPreparingPickingIdTab,
    },
    {
      name: "Fulfilment",
      tab: "fulfilment",
      route_name: useRoutesNames().ordersPreparingPickingIdTab,
    },
    {
      name: "Notes",
      tab: "notes",
      route_name: useRoutesNames().ordersPreparingPickingIdTab,
    },
    {
      name: "History",
      tab: "history",
      route_name: useRoutesNames().ordersPreparingPickingIdTab,
    },
  ]);

  // Actions

  // Kanban Actions
  async function getKanbanLanes() {
    let response: any;
    try {
      response = await useVaniloApi("/picker-kanban-lanes");
      kanbanLanes.value = response?.data;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    return response;
  }

  async function updateKanbanLane(lane?: KanbanLane) {
    let response: any;
    useIsLoading(true);
    const body = lane
      ? { ...lane, id: undefined }
      : { ...editingKanbanLane.value, id: undefined };
    try {
      response = await useVaniloApi(
        `/picker-kanban-lanes/${lane?.id || editingKanbanLane.value.id}`,
        {
          method: "POST",
          body,
        }
      );
      kanbanLanes.value = kanbanLanes.value.map((lane) =>
        lane.id === response?.data.id ? response?.data : lane
      );
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    useIsLoading(false);
    return response;
  }

  async function updateKanbanLanesBulk(lanes: KanbanLane[]) {
    let response: any;
    useIsLoading(true);
    lanes.forEach((lane) => {
      delete lane.position;
      delete lane.type;
      delete lane.meta;
    });
    const body = {
      lanes: lanes.map((lane, index) => {
        if (index === 0 || index === lanes.length - 1) {
          return { id: lane.id, name: lane.name };
        } else {
          return lane;
        }
      }),
    };
    try {
      response = await useVaniloApi("/picker-kanban-lanes/bulk-update", {
        method: "POST",
        body,
      });
      kanbanLanes.value = response?.data;
      kanbanComponentKey.value++;
    } catch (error: any) {
      useToast(error.data.message, { type: ToastType.ERROR, duration: 3000 });
      console.log(error);
    }
    useIsLoading(false);
    return response;
  }

  async function createKanbanLane() {
    let response: any;
    useIsLoading(true);
    try {
      response = await useVaniloApi("/picker-kanban-lanes", {
        method: "POST",
        body: creatingKanbanLane.value,
      });
      kanbanLanes.value.splice(kanbanLanes.value.length - 1, 0, response?.data);
      kanbanComponentKey.value++;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    useIsLoading(false);
    return response;
  }

  async function deleteKanbanLane() {
    let response: any;
    useIsLoading(true);
    try {
      response = await useVaniloApi(
        `/picker-kanban-lanes/${editingKanbanLane.value.id}`,
        { method: "DELETE" }
      );
      kanbanLanes.value = kanbanLanes.value.filter(
        (lane) => lane.id !== editingKanbanLane.value.id
      );
      kanbanComponentKey.value++;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    kanbanComponentKey.value++;
    return response;
  }

  // Picking Actions
  async function updatePickingStatus(
    orderId: number | string,
    status?: OrderStatus,
    lane_id?: number
  ): Promise<ResponseType<Order>> {
    let response: any;
    try {
      response = await useVaniloApi(`/orders/${orderId}/picker-status`, {
        method: "POST",
        body: {
          ...(status && { status }),
          ...(lane_id && { lane_id }),
        },
      });
    } catch (error: any) {
      console.log(error);
      useToast(error.data.message, { type: ToastType.ERROR, duration: 3000 });
    }
    return response;
  }

  async function pickOrder(orderId: number, payload: PickingPayload) {
    try {
      const response = await useVaniloApi(`/orders/${orderId}/pick`, {
        method: "POST",
        body: payload,
      });
      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    ordersList,
    meta,
    playlist,
    isOpenSidebar,
    filters,
    dateRange,
    targetDate,
    targetDateRange,
    search,
    activeLetter,
    options,
    pageTable,
    perPageTable,
    kanbanLanes,
    editingKanbanLane,
    creatingKanbanLane,
    kanbanComponentKey,
    currentKanbanLane,
    viewingOrder,
    preparingOrder,
    preparingOrderCustomer,
    preparingOrderNotes,
    preparingOrderNotifications,
    pickingOrderTabs,
    getKanbanLanes,
    updateKanbanLane,
    updateKanbanLanesBulk,
    createKanbanLane,
    deleteKanbanLane,
    updatePickingStatus,
    pickOrder,
  };
});
