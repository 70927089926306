<template>
  <div
    class="border borer-border-color text-sm leading-base font-medium text-font-primary py-3px px-5 gap-2.5 w-max-content rounded-x8 flex items-center cursor-pointer whitespace-nowrap"
    :class="{
      'bg-white': !bgGrey,
      'bg-light-background': bgGrey,
    }"
  >
    <template v-if="text">
      {{ text }}
    </template>
    <template v-if="icon">
      <button @click="emit('delete')">
        <img
          v-if="icon === 'close-small-icon'"
          src="~/assets/img/close-small-icon.svg"
          alt=""
        />
      </button>
    </template>
  </div>
</template>

<script setup>
const props = defineProps({
  text: { type: String, default: " " },
  icon: { type: String, default: "close-small-icon" },
  bgGrey: { type: Boolean, default: false },
});

const emit = defineEmits(["delete"]);
</script>
