export interface Rule {
  id: number;
  type: RuleType;
  rulable_type: RulableType;
  rulable_id: number;
  conditions: {
    tags: number[];
    regions: number[];
    customer_groups: number[];
  };
}

export interface RuleCreatePayload {
  rulable: {
    [RulableType.INVENTORY]?: number;
    [RulableType.PRODUCT]?: number;
  };
  type: RuleType;
  conditions: {
    tags?: number[];
    customer_groups?: number[];
    regions?: number[];
  };
}

export enum RuleType {
  INCLUDE = "include",
  EXCLUDE = "exclude",
}

export enum RulableType {
  INVENTORY = "inventory",
  PRODUCT = "product",
}
