import { Address, SidebarPrefix, Media, Document, Option } from "./general";
import { User } from "./users";
import { Store, StoreShort } from "~/types/stores";
import { Taxonomy } from "~/types/taxonomies";
import { Product } from "~/types/products";
import { UserType } from "~/types/users";
import { OrderStatus } from "./orders";
import { Notification } from "./notifications";

export interface CustomerManage {
  id?: number;
  type?: CustomerType;
  subtypes?: number[];
  group_id?: number;
  has_xero_contact?: boolean;
  credit_service?: boolean;
  status: CustomerStatus;
  gender?: CustomerGender;
  description?: string;
  profile_details?: string;
  plan_type?: CustomerPlanType;
  plan_number?: string;
  plan_start_date?: string;
  plan_end_date?: string;
  plan_management?: CustomerPlanManagement | null;
  settings?: {
    hide_variation: boolean;
    secondary_name: boolean;
    hide_in_web_derictory?: boolean;
    calendar?: {
      time_zone: string;
      color: string;
      show_weekends: boolean;
      notifications?: any[];
    };
  };
  apply_levies?: boolean;
  default_store?: StoreShort;
  default_store_id?: number;
  roles?: UserType[];
  stores?: number[];
  email?: string;
  phone?: string;
  mobile?: string;
  display_name?: string;
  firstname?: string;
  lastname?: string;
  title?: string;
  birth_date?: string;
  company_name?: string;
  short_name?: string;
  website?: string;
  tfn?: string;
  code?: string;
  abn?: string;
  comission?: string;
  rebate?: string;
  //finance
  has_custom_credit?: boolean;
  has_account_orders?: boolean;
  credit_amount?: string | number;
  credit_term?: string | number;
  credit_term_type?: CustomerCreditTermType;
  manager_can_override_credit?: boolean;
  has_custom_purchase_credit?: boolean;
  purchase_credit_amount?: string | number;
  purchase_credit_term?: string | number;
  purchase_credit_term_type?: CustomerCreditTermType;
  manager_can_override_purchase_credit?: boolean;
  //tags
  shares_stores?: number[] | Option[];
  taxonomies?: number[] | Option[];
  //attachments
  companies?: number[];
  contacts?: number[];
  //stores
  company_stores?: number[];
  //addresses
  addresses?: any[];
  tag?: any;
  name?: string;
  country?: string;
  province?: string;
  postalcode?: string;
  city?: string;
  address?: string;
  is_default?: boolean;
  notes?: any[];
  billpayer?: string;
  notifications?: CustomerNotification[];
  user?: {
    id: number;
    name: string;
    email: string;
    type: string;
    is_active: boolean;
    stores?: StoreShort[];
  };
  supplier_products?: number[];
}

export interface NewCustomerManage {
  id?: number;
  type: CustomerType;
  addresses: any[];
  group_id?: number;
  email: string;
  status: CustomerStatus;
  phone?: string;
  mobile?: string;
  code?: string;
  user?: User;
  pin?: string;
  gender?: CustomerGender | string;
  description?: string;
  profile_details?: string;
  plan_type?: CustomerPlanType;
  plan_number?: string;
  plan_start_date?: string;
  plan_end_date?: string;
  plan_management?: CustomerPlanManagement | null;
  pin_confirmation?: string;
  has_xero_contact?: boolean;
  manager_can_override_credit?: boolean;
  has_custom_credit?: boolean;
  has_account_orders?: boolean;
  credit_amount?: string | number;
  credit_term?: string | number;
  credit_term_type?: CustomerCreditTermType;
  has_custom_purchase_credit?: boolean;
  purchase_credit_amount?: string | number;
  purchase_credit_term?: string | number;
  purchase_credit_term_type?: CustomerCreditTermType;
  manager_can_override_purchase_credit?: boolean;
  credit_service?: boolean;
  taxonomies?: Option[];
  shares_stores?: Option[];
  settings?: CustomerSettings | null;
  firstname?: string;
  lastname?: string;
  birth_date?: string;
  display_name?: string;
  has_pin?: boolean;
  title?: string;
  tfn?: string;
  roles?: UserType[];
  subtypes?: Option[];

  contacts?: number[];
  apply_levies?: boolean;
  company_name?: string;
  short_name?: string;
  abn?: string;
  website?: string;
  comission?: string;
  rebate?: string;
  supplier_products?: any[];
  companies?: number[];
  company_stores?: StoreShort[];

  notifications?: NotificationsManage[];

  default_store?: StoreShort;
  default_store_id?: number;
  stores?: number[];
}

export interface CustomerSettings {
  hide_variation: boolean;
  secondary_name: boolean;
  hide_in_web_directory?: boolean;
  use_multiple_codes: boolean;
  calendar?: {
    time_zone: string;
    color: string;
    show_weekends: boolean;
    notifications?: any[];
  };
}

export interface NotificationsManage {
  name: string;
  value: string;
  is_primary_email: boolean;
}

export interface Customer {
  email: string;
  id: number;
  mobile?: string;
  name: string;
  phone: string;
  firstname?: string;
  lastname?: string;
  company_name?: string;
  notifications?: Notification[];
  group: {
    id: number;
    name: string;
  };
  status: CustomerStatus;
  shares_stores?: StoreShort[];
  recent_purchase_status: OrderStatus | "none";
  primary_contact?: any;
  has_xero_contact?: boolean;
  subtypes: CustomerSubtype[];
  has_pin?: boolean;
  type: CustomerType;
  title?: string;
  default_store?: Store;
  billing_address?: Address;
  has_overdue_orders?: boolean;
  pin?: string;
  settings?: {
    hide_variation?: boolean;
    secondary_name?: boolean;
    hide_in_web_directory?: boolean;
    use_multiple_codes: boolean;
    calendar?: {
      time_zone: string;
      color: string;
      show_weekends: boolean;
      notifications?: any[];
    };
  };
  user?: {
    id: number;
    name: string;
    email: string;
    type: string;
    is_active: boolean;
    stores?: StoreShort[];
  };
  taxonomies?: {
    id: number;
    name: string;
    slug: string;
    taxons: {
      id: number;
      name: string;
      slug: string;
    }[];
  }[];
  last_purchase_at?: string;
  shipping_address?: Address;
  image?: Media;
  companies?: {
    id: number;
    type: CustomerType;
    subtypes: CompanySubtype | CustomerSubtype[];
    name: string;
    email: string;
    phone: string;
    image: {
      id: number;
      title: string;
      description: string;
      url: string;
      conversions: any[];
    };
  }[];
  contacts?: CompanyContacts[];
  loyalty_points?: number;
}

export interface CustomerSingle {
  id: number;
  credit_service?: boolean;
  has_xero_contact?: boolean;
  gender?: CustomerGender;
  description?: string;
  profile_details?: string;
  plan_type?: CustomerPlanType;
  plan_number?: string;
  plan_start_date?: string;
  plan_end_date?: string;
  plan_management?: CustomerPlanManagement | null;
  abn?: string;
  tfn?: string;
  type: CustomerType;
  status: CustomerStatus;
  shares_stores?: StoreShort[];
  subtypes?: number[] | CustomerSubtype[];
  has_pin?: boolean;
  pin?: string;
  comission?: string;
  rebate?: string;
  email: string;
  phone: string;
  apply_levies?: boolean;
  mobile: string;
  website?: string;
  primary_contact?: any;
  settings?: CustomerSettings;
  account_manager: CustomerShort;
  code: string;
  roles?: UserType[];
  firstname: string;
  lastname: string;
  title: string;
  birth_date: string;
  user: User;
  addresses: Address[];
  credit_amount: number;
  credit_term: number;
  credit_term_type: CustomerCreditTermType;
  credit: Credit;
  finance: CustomerFinance;
  company_name?: string;
  short_name?: string;
  taxonomies?: Taxonomy[];
  contacts?: CompanyContacts[];
  companies?: CustomerCompany[];
  image: Media;
  group: {
    id: number;
    name: string;
  };
  manager_can_override_credit: boolean;
  has_custom_credit: boolean;
  has_account_orders?: boolean;
  has_custom_purchase_credit?: boolean;
  purchase_credit_amount?: string | number;
  purchase_credit_term?: string | number;
  purchase_credit_term_type?: CustomerCreditTermType;
  manager_can_override_purchase_credit?: boolean;
  notifications: CustomerNotification[];
  documents?: Document[];
  supplier_products?: Product[];
  stores?: StoreShort[];
  loyalty_points?: number;
  opening_balance?: string;
  super_fund?: string;
  usi?: string;
  member_number?: string;
  bank?: string;
  bsb?: string;
  account_number?: string;
}

export interface CustomerShort {
  id: number;
  name: string;
  short_name?: string;
  image: Media;
  type?: CustomerType;
  email?: string;
  phone?: string;
  subtypes?: CustomerSubtype[];
  title?: string;
  user?: User;
  notifications?: Notification[];
}

export interface CustomerNotification {
  email?: string;
  is_primary_email?: boolean;
  contact?: {
    email: string;
    id: number;
    name: string;
    phone: string;
    title: string;
    type: string;
    user: User;
  } | null;
}

export interface CustomerCompany {
  id: number;
  type: CustomerType;
  subtypes: CustomerSubtype[];
  name: string;
  email: string;
  phone: string;
  image: {
    id: number;
    title: string;
    description: string;
    url: string;
    conversions: any[];
  };
}

export interface CompanyContacts {
  email: string;
  id: number;
  name: string;
  phone: string;
  subtype: string;
  type: CustomerType;
  title?: string;
}

export interface CustomerFinance {
  id?: number;
  type: CustomerType;
  name: string;
  credit?: {
    amount: number;
    term: number;
    due: number;
    overdue: number;
  };
  credit_balance?: any;
  total_spending: number;
  last_year_spending: number;
  last_purchase_at: string;
}
export interface CustomerSingleAvatar {
  media: any;
  title: string;
  description: string;
}

export interface CustomerSubtype {
  id: number;
  name: string;
  label: string;
  type: string;
  is_active: boolean;
  is_protected: boolean;
}

export enum CustomerStatus {
  ACTIVE = "active",
  PENDING = "pending",
  BLOCKED = "blocked",
  ARCHIVE = "archived",
  APPLICATION = "application",
}

export enum CustomerType {
  INDIVIDUAL = "individual",
  STAFF = "staff",
  COMPANY = "company",
  CONTACT = "contact",
}

export enum CompanySubtype {
  BUYER = "buyer",
  SUPPLIER = "supplier",
  FREIGHT = "freight",
  SERVICE_PROVIDER = "service_provider",
  LOCATION = "location",
}

export interface SortedCustomerItem {
  items: Customer[];
  initial: string;
}

export interface Credit {
  amount: number;
  term: number;
  due: number;
  overdue: number;
}

export enum CustomerCreditTermType {
  DAYS = "days",
  FOLLOWING_MONTH = "following_month",
  LAST_DAY_OF_MONTH = "last_day_of_month",
}

export enum CustomerGender {
  MALE = "male",
  FEMALE = "female",
  NON_BINARY = "non-binary",
  PREFER_NOT_TO_SAY = "prefer-not-to-say",
}

export enum CustomerPlanType {
  NONE = "none",
  HCP = "hcp",
  CHSP = "chsp",
  NDIS = "ndis",
}

export enum CustomerPlanManagement {
  PLAN_MANAGEMENT = "plan-management",
  SELF_MANAGEMENT = "self-management",
  AGENCY_MANAGEMENT = "agency-management",
}

export interface Company {
  id: number;
  is_company: boolean;
  type: CustomerType;
  status: CustomerStatus;
  name: string;
  email: string;
  code: string;
  phone: string;
  mobile: string;
  subtypes: {
    id: number;
    name: string;
    label: string;
    is_active: boolean;
  }[];
  primary_contact: CustomerShort;
  website: string;
  taxonomies: any[];
}

export interface TeamMember {
  id: number;
  type: CustomerType;
  name: string;
  short_name: string;
  email: string;
  phone: string;
  staff_store: {
    id: number;
    name: string;
    short_name: string;
  }[];
  next_shift: any;
  image: Media;
}

// sidebar pages list
export const SidebarPage = {
  CUSTOMERS_LIST: `${SidebarPrefix.CUSTOMERS}-list`,
  CUSTOMERS_SINGLE_DETAILS: `${SidebarPrefix.CUSTOMERS}-single-details`,
  CUSTOMERS_SINGLE_EDIT: `${SidebarPrefix.CUSTOMERS}-single-edit`,
  CUSTOMERS_NEW: `${SidebarPrefix.CUSTOMERS}-new`,

  CUSTOMERS_ORDERS_HISTORY: `${SidebarPrefix.CUSTOMERS}-orders-history`,
  CUSTOMERS_ORDERS_DRAFTED: `${SidebarPrefix.CUSTOMERS}-orders-drafted`,
  CUSTOMERS_ORDERS_PRINT: `${SidebarPrefix.CUSTOMERS}-orders-print`,
  CUSTOMERS_ORDERS_SINGLE_DETAILS: `${SidebarPrefix.CUSTOMERS}-orders-single-details`,

  CONTACTS_ADD_CREDIT: `${SidebarPrefix.CONTACTS}-add-credit`,
  CONTACTS_ADD_PAYMENT: `${SidebarPrefix.CONTACTS}-add-payment`,
  CONTACTS_CREDIT_HISTORY: `${SidebarPrefix.CONTACTS}-credit-history`,

  CONTACTS_ADD_LOYALTY_POINTS: `${SidebarPrefix.CONTACTS}-add-loyalty-points`,

  CONTACTS_FILTERS: `${SidebarPrefix.CONTACTS}-filters`,

  CONTACTS_ORDERS_ACCOUNT: `${SidebarPrefix.CONTACTS}-orders-account`,

  CONTACTS_ADD_ADDRESS: `${SidebarPrefix.CONTACTS}-add-address`,
  CONTACTS_ALL_ADDRESS: `${SidebarPrefix.CONTACTS}-all-address`,

  CONTACTS_ATTACHMENTS: `${SidebarPrefix.CONTACTS}-attachments`,
  CONTACTS_LOCATIONS: `${SidebarPrefix.CONTACTS}-locations`,
  CONTACTS_SUPPLIER_PRODUCTS: `${SidebarPrefix.CONTACTS}-supplier-products`,

  CUSTOMERS_CONFIGURATION: `${SidebarPrefix.CONTACTS}-configuration`,
  COMPANY_DELIVERY_RUNS: `${SidebarPrefix.CONTACTS}-delivery-runs`,

  CONTACTS_IMPORT_EXPORT: `${SidebarPrefix.CONTACTS}-import-export`,

  ORDER_NOTIFICATIONS_ITEM: `${SidebarPrefix.SETTINGS}-notifications-item`,
};

// dropdown options
export enum OrderOption {
  PAY = "Pay",
  DETAILS_ORDER = "View Details",
  REPEAT = "Repeat Order",
}

export enum CustomerOption {
  DETAILS = "View Details",
  ORDERS_HISTORY = "Orders History",
  DRAFTED_ORDERS = "Drafted Orders",
  EDIT = "Edit Details",
}

export enum AddressTag {
  HOME = "home",
  WORK = "work",
  PROJECT = "project",
  OTHER = "other",
}

export enum AddressType {
  BILLING = "billing",
  BUSINESS = "business",
  CONTRACT = "contract",
  MAILING = "mailing",
  PICKUP = "pickup",
  RESIDENTIAL = "residential",
  SHIPPING = "shipping",
}

export interface CustomerAddressNew {
  city: string;
  country: string;
  delivery_note: string;
  is_default: string;
  lat: number;
  lng: number;
  location_position: string;
  name: string;
  postcode: string;
  route: string;
  state: string;
  state_name: string;
  street_address: string;
  street_address2: string;
  street_number: string;
  type: number;
  user_id: number;
}

export interface Group {
  id: number;
  name: string;
  is_default: boolean;
  rounding_rule: RoundingRulesOption;
  rounding_base: string;
  pricing_method: string;
  pricing_rule: GroupRulesOption;
  price_shift: number;
  volume_price_threshold: number;
  volume_price_shift: number;
  brands: {
    id: number;
    name: string;
    type: string;
  }[];
  payment_methods: {
    id: number;
    name: string;
    gateway: string;
  }[];
  has_loyalty_rewards: boolean;
  excluded_taxonomies: {
    id: number;
    name: string;
    slug: string;
    taxons: {
      id: number;
      name: string;
      slug: string;
    }[];
  }[];

  exclude_sale_items: boolean;
  credit_term: number;
  credit_term_type: CustomerCreditTermType;
  credit_amount: number;
  manager_can_override_credit: boolean;
  manager_can_override_purchase_credit?: boolean;
  purchase_credit_term: number;
  purchase_credit_term_type: CustomerCreditTermType;
  purchase_credit_amount: number;
}

export interface GroupManage {
  id?: number;
  name: string;
  rounding_rule: RoundingRulesOption;
  rounding_base: string;
  pricing_method: string;
  pricing_rule: GroupRulesOption;
  price_shift: number;
  volume_price_threshold: number;
  volume_price_shift: number;
  brands: number[];
  payment_methods: number[];
  has_loyalty_rewards: boolean;
  excluded_taxonomies: number[];
  exclude_sale_items: boolean;
  credit_term: number;
  credit_term_type: CustomerCreditTermType;
  credit_amount: number;
  manager_can_override_credit: boolean;
  manager_can_override_purchase_credit?: boolean;
  purchase_credit_term: number;
  purchase_credit_term_type: CustomerCreditTermType;
  purchase_credit_amount: number;
  is_default: boolean;
}

export enum GroupRulesOption {
  NONE = "none",
  CUSTOM = "custom",
  PERCENT = "percent",
  FIXED = "fixed",
}

export interface AddressBillpayerManage {
  firstname?: string;
  lastname?: string;
  phone?: string;
  email?: string;
  mobile?: string;
}
export enum RoundingRulesOption {
  ROUND_UP = "round-up",
  ROUND_DOWN = "round-down",
}
export enum PricingMethodOption {
  INCREASE = "surcharge",
  DECREASE = "discount",
}

export interface DeliveryRun {
  id?: number;
  name: string;
  loading_at: string;
  delivery_date: string;
  freight_company: {
    id: number;
    type: string;
    name: string;
    email?: string;
    phone?: string;
    subtype?: CustomerSubtype[];
  };
  orders?: any[];
  count_orders?: number;
}

export interface DeliveryRunManage {
  name: string;
  loading_at: string;
  delivery_date: string;
  freight_company_id: number;
}
