<template>
  <div>
    <label
      v-if="label"
      class="mb-x1 inline-block"
      :class="{
        [labelFont]: labelFont,
        'text-xs leading-base-sm font-medium': !labelFont,
      }"
    >
      {{ label }}
    </label>
    <div class="relative">
      <div v-if="barcode" class="absolute flex gap-5 top-2.5 left-5">
        <button class="min-w-5 focus:outline-accent-color">
          <img src="~/assets/img/scan-icon.svg" alt="" />
        </button>
        <svg
          width="1"
          height="24"
          viewBox="0 0 1 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="0.500488"
            y1="4.189e-08"
            x2="0.500486"
            y2="24"
            stroke="#E7E7E9"
          />
        </svg>
      </div>

      <form @submit.stop.prevent="">
        <input
          :type="type"
          class="py-2.5 px-5 w-full border rounded-x1 flex-grow focus:outline-none text-sm leading-base font-medium text-font-primary"
          :class="{
            [bgColor]: bgColor,
            'pl-x16': barcode,
            [minHeight]: minHeight,
            'min-h-[46px]': !minHeight,
            [minWidth]: minWidth,
            [placeholderFont]: placeholderFont,
          }"
          :placeholder="placeholder"
          :value="modelValue"
          @input="onInput"
          ref="searchInput"
        />
      </form>

      <button
        class="absolute focus:outline-none top-0 right-[10px] h-full pl-2.5 border-l"
        @click="clickButton"
      >
        <template v-if="!modelValue && !isNearestLocation">
          <LocationMarkerIcon
            v-if="withLocationIcon"
            class="w-[22px] h-[22px]stroke-font-primary"
            style="stroke-width: 1.5"
          />
          <SearchIcon
            v-else-if="!withoutSearchIcon"
            class="w-[22px] h-[22px] stroke-font-primary"
            style="stroke-width: 1.5"
          />
        </template>

        <XIcon
          v-else-if="
            modelValue ||
            withoutSearchIcon ||
            (withLocationIcon && isNearestLocation)
          "
          class="w-[22px] h-[22px] fill-font-primary"
          style="stroke-width: 1.5"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { XIcon, SearchIcon, LocationMarkerIcon } from "@heroicons/vue/outline";

const props = defineProps<{
  modelValue: string | number;
  type?: string;
  placeholder?: string;
  label?: string;
  labelFont?: string;
  bgColor?: string;
  withoutSearchIcon?: boolean;
  barcode?: boolean;
  minHeight?: string;
  minWidth?: string;
  iconPosition?: string;
  noDebounce?: boolean;
  placeholderFont?: string;
  withLocationIcon?: boolean;
  isNearestLocation?: boolean;
}>();

const emit = defineEmits(["update:modelValue", "sort-by-nearest"]);

function onInput(e) {
  if (props.noDebounce) {
    emit("update:modelValue", e.target.value);
  } else {
    useDebounce(() => {
      emit("update:modelValue", e.target.value);
    }, 1000);
  }
}

const searchInput = ref(null);

function clickButton() {
  if (props.withLocationIcon && !props.modelValue) {
    emit("sort-by-nearest");

    return;
  }

  if (props.modelValue) {
    emit("update:modelValue", "");

    searchInput.value.blur();

    return;
  }

  searchInput.value.focus();
}
</script>
