import { defineStore } from "pinia";

import { ResponseType, Note, Document } from "~/types/general";

// Notes Actions and Links
export enum NoteLink {
  PURCHASE = "purchase-orders",
  ORDERS = "orders",
  CUSTOMERS = "customers",
  PRODUCTS = "products",
  INVENTORIES = "inventories",
  COUNTS = "counts",
  STORES = "stores",
  RUNS = "delivery-runs",
  DAY_RUNS = "delivery-runs/delivery-days",
  EVENTS = "events",
}

// Notes Store
export const useNotesStore = defineStore("notes", () => {
  // State
  const notes = ref([] as Note[]);
  const files = ref([] as Document[]);
  const noteManage = ref({} as Note);

  // Get Notes
  async function getNotes(
    type: NoteLink,
    id,
    withoutParams?,
    onlyUsersComments?,
    onlyChanges?,
    transportUnits?
  ): Promise<ResponseType<Note[]>> {
    const params = {
      "sort[]": ["pinned-asc", "date-desc"],
    };

    if (withoutParams) {
      delete params["sort[]"];
    }

    if (onlyUsersComments) {
      params["filter[is_private]"] = 1;
    }

    if (onlyChanges) {
      params["filter[note]"] = 1;
    }

    if (transportUnits) {
      params["filter[transportUnit]"] = true;
    }

    try {
      const response = await useVaniloApi(`/${type}/${id}/notes`, {
        params,
      });

      return response as ResponseType<Note[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Create Note
  async function createNote(type: NoteLink, id, body) {
    try {
      const response = await useVaniloApi(`/${type}/${id}/notes`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Create Note with Attachments
  async function createNoteWithAttachments(type: NoteLink, id, body) {
    const formData = new FormData();

    formData.append("text", body?.text);
    formData.append("is_private", Number(body?.is_private)?.toString());

    if (body?.category) {
      formData.append("category", body.category);
    }

    if (body?.files?.length) {
      body.files
        .filter((i) => i.file)
        .forEach((i) => {
          formData.append("media[]", i.file);
        });
    }

    try {
      const response = await useVaniloApi(`/${type}/${id}/notes`, {
        method: "POST",
        body: formData,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Update Note
  async function updateNote(type: NoteLink, noteId, body) {
    try {
      const response = await useVaniloApi(`/${type}/notes/${noteId}`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Delete Note
  async function deleteNote(type: NoteLink, noteId) {
    try {
      const response = await useVaniloApi(`/${type}/notes/${noteId}`, {
        method: "DELETE",
      }).then(() => true);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Attach Note Documents
  async function attachNoteDocuments(type: NoteLink, noteId, files?) {
    const formData = new FormData();

    if (files) {
      files
        .filter((i) => i.file)
        .forEach((i) => {
          formData.append("media[]", i.file);
          formData.append("meta[][title]", i.title);
        });
    } else {
      files.value
        .filter((i) => i.file)
        .forEach((i) => {
          formData.append("media[]", i.file);
          formData.append("meta[][title]", i.title);
        });
    }

    try {
      const response = await useVaniloApi(
        `/${type}/notes/${noteId}/documents`,
        {
          method: "POST",
          body: formData,
        }
      ).then(() => true);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Detach Note Document
  async function detachNoteDocument(type: NoteLink, docId) {
    try {
      const response = await useVaniloApi(`/${type}/notes/documents/${docId}`, {
        method: "DELETE",
      }).then(() => true);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Create Day Note
  async function createDayNote(payload) {
    try {
      const response = await useVaniloApi(`/delivery-runs/delivery-day`, {
        method: "POST",
        body: payload,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    notes,
    files,
    noteManage,
    getNotes,
    createNote,
    createNoteWithAttachments,
    updateNote,
    deleteNote,
    attachNoteDocuments,
    detachNoteDocument,
    createDayNote,
  };
});
