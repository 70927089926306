import { Toast, ToastType } from "~/types/general";
import { useGeneralStore } from "~/store/general";

export const useErrorNotifications = (error) => {
  const generalStore = useGeneralStore();

  const errorMessages = [];

  if (!error.hasOwnProperty("data")) {
    return;
  }

  if (error.data?.errors) {
    for (let err in error.data.errors) {
      const toast: Toast = {
        id: error.data.errors[err][0],
        message: error.data.errors[err][0],
        submessage: "",
        type: ToastType.ERROR,
      };

      errorMessages.push(toast);
    }
  } else {
    const toast: Toast = {
      id: error.data.message,
      message: error.data.message,
      submessage: "",
      type: ToastType.ERROR,
    };

    errorMessages.push(toast);
  }

  generalStore.toastsList = errorMessages;

  setTimeout(() => {
    generalStore.toastsList = [];
  }, 3000);
};
