import { useUsersStore } from "~/store/users";

export const useSetGeolocation = () => {
  if ("navigator" in window && "geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      useUsersStore().userCoords.lat = position.coords.latitude;
      useUsersStore().userCoords.lng = position.coords.longitude;
    }),
      (error) => {
        console.error("Error getting coordinates:", error.message);
      };
  } else {
    console.error("Geolocation is not supported by your browser");
  }
};
