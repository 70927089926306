import { Media, Document } from "~/types/general";
import { SidebarPrefix } from "./general";
import { InventoryItem } from "./inventory";
import {LevyUnit, Taxonomy} from "~/types/taxonomies";
import { Addon } from "~/types/modifiers";
import { CustomerShort } from "./customers";

export interface ProductManage {
  id?: number | string;
  type: string;
  name: string;
  sku?: number | string;
  price?: number;
  price_from?: number;
  price_to?: number;
  excerpt?: string;
  description?: string;
  state?: string;
  length?: number;
  width?: number;
  height?: number;
  weight?: number;
  ext_title?: string;
  is_on_sale?: boolean;
  slug?: string;
  hide_in_picklist?: boolean;
  ignore_in_unit_checks?: boolean;
  sale_status?: ProductSaleStatus;
  sale_price?: number | string;
  sale_starts_at?: string;
  sale_ends_at?: string;
  gtin?: string;
  tax_id?: string;
  upsell?: any[];
  is_allocated_barcode?: boolean;
  accounting_code?: string;
  modifiers?: {
    id: number;
    name: string;
    description: string;
    addons: Addon[];
  }[];
  service?: VariationService;
  levy_units_ratio: number;
  levy: {
    name: string,
    rate: number,
    units: LevyUnit
  };
  cross_sell?: any[];
  order_types?: string[];
  shipping_class?: string;
  published_at?: string;
  code?: number | string;
  excluded_groups?: any[];
  taxonomies?: number[];
  composite_products?: ProductComposite[];
  composite_deal?: ProductComposite[];
  composite_deal_price?: number;
  conversion_ratio?: number;
  unit_name?: string;
  sell_unit_name?: string;
}

export interface Product {
  id: number;
  type: ProductType;
  state: ProductStatus;
  hide_in_picklist?: boolean;
  ignore_in_unit_checks?: boolean;
  is_allocated_barcode?: boolean;
  accounting_code?: string;
  modifiers?: {
    id: number;
    name: string;
    description: string;
    addons: Addon[];
  }[];
  name: string;
  product_stock_status?: string;
  variations?: ProductVariation[] | Product[];
  related_products?: any;
  service?: VariationService;
  composite_products?: ProductComposite[];
  set_deal?: {
    id: number;
    price: number;
    items?: ProductComposite[];
  };
  parent?: {
    id: number;
    type: ProductType;
    name: string;
    ext_title: string;
    slug: string;
    sku: number;
    price: number;
    sale_price: number;
    is_on_sale?: boolean;
    excerpt: string;
    properties: ProductProperty[];
    media?: Media[];
  };
  master?: Product;
  sku?: number | string;
  price: number;
  sale_price?: number | string;
  sale_status?: ProductSaleStatus;
  sale_starts_at?: string;
  sale_ends_at?: string;
  pricing?: ProductPrice;
  tax: {
    id: number;
    name: string;
  };
  excerpt?: string;
  description?: string;
  length?: number;
  width?: number;
  height?: number;
  weight?: number;
  ext_title?: string;
  meta_keywords?: string;
  meta_description?: string;
  is_on_sale?: boolean;
  low_stock_threshold?: number;
  properties?: ProductProperty[];
  slug: string;
  taxonomies?: Taxonomy[];
  media?: Media[];
  documents: Document[];
  gtin: string;
  upsell?: number[] | Product[];
  cross_sell?: number[] | Product[];
  order_types: string[];
  shipping_class: string;
  is_published: boolean;
  published_at: string;
  code?: number | string;
  excluded_groups?: ExcludedCustomerGroups[];
  price_from?: number;
  price_to?: number;
  picker_notes?: string;
  conversion_ratio?: number;
  unit_name?: string;
  sell_unit_name?: string;
  components?: Product[];
  levy_units_ratio: number;
  alias_barcodes?: AliasBarcode[];
}

export interface VariationService {
  id: number;
  types: ServiceAvailability[];
  session_type: ServiceType;
  units: number;
  appointment_hours_duration: number;
  appointment_minutes_duration: number;
  block_extra_hours_duration: number;
  block_extra_minutes_duration: number;
  schedule_type?: ServiceScheduleType;
  duration?: number;
  duration_type?: ServiceDurationType;
  starts_at?: string;
  ends_at?: string;
  max_group_size?: number;
  min_group_size?: number;
  is_holidays_availability?: boolean;
  customers?: CustomerShort[];
  recurring_days: {
    day: number;
    is_enabled?: boolean;
    schedules: {
      starts_at?: string;
      ends_at?: string;
    }[];
    time?: string[];
    is_round_the_clock?: boolean;
  }[];
}

export enum ServiceAvailability {
  IN_CLINIC = "in-clinic",
  VIRTUAL = "virtual",
  MOBILE = "mobile",
}
export enum ServiceType {
  HOURLY = "hourly",
  GROUP = "group",
}

export enum ServiceScheduleType {
  ONCE_OFF = "once-off",
  FIXED = "fixed",
  RECURRING = "recurring",
}

export enum ServiceDurationType {
  DAYS = "days",
  HOURS = "hours",
  WEEKS = "weeks",
}

export interface ProductPos {
  product: Product;
  modifiers?: {
    id: number;
    name: string;
    description: string;
    addons: Addon[];
  }[];
  inventory?: InventoryItem;
  variations?: ProductVariation[];
  composite_products?: ProductComposite[];
  price_from?: number;
  price_to?: number;
}

export interface ProductVariation {
  product: Product;
  inventory?: InventoryItem;
}

export interface ProductPrice {
  exclusive_price: number;
  tax: number;
  total: number;
}

export interface ProductProperty {
  id?: number;
  slug?: string;
  value: string;
  name?: string;
  title?: string;
  symbol_html?: string;
  is_symbol?: boolean;
}

export enum ProductSaleStatus {
  NONE = "none",
  SCHEDULED = "scheduled",
  ONGOING = "ongoing",
}

export enum ProductType {
  SIMPLE = "simple",
  VARIANT = "variant",
  ADDON = "addon",
  MASTER = "master",
  SERVICE = "service",
  SET = "set", // composite
  BULK = "component",
}

export enum ProductStatus {
  DRAFT = "draft",
  AVAILABLE = "active",
  INACTIVE = "inactive",
  UNAVAILABLE = "unavailable",
  ARCHIVE = "retired",
  SPECIAL = "special",
}

export enum ProductMetrics {
  LITERS = "Liters",
  MILLILITERS = "Milliliters",
  CENTILITERS = "Centiliters",
  CUBIC_METERS = "Cubic Meters",
  KILOGRAMS = "Kilograms",
  GRAMS = "Grams",
  MILLIGRAMS = "Milligrams",
  METRIC_TONNES = "Metric Tonnes",
  METERS = "Meters",
  CENTIMETERS = "Centimeters",
  MILLIMETERS = "Millimeters",
  BOXES = "Boxes",
  CARTONS = "Cartons",
  CRATES = "Crates",
  DECKS = "Decks",
  BINS = "Bins",
  CASES = "Cases",
  DOZENS = "Dozens",
  PACKS = "Packs",
  PIECES = "Pieces",
  SINGLE_ITEMS = "Single Items",
  TRAYS = "Trays",
}

export interface ProductFilter {
  type: string;
  title: string;
  options: ProductFilterOption[];
  chosenOptions: [];
}

export interface ProductFilterOption {
  name: string;
  value: string | number;
}

export interface ProductSingleImage {
  media: any;
  title: string;
  description: string;
}

//change later, when add on backend
export interface ProductVariationCard {
  name: string;
  variety?: string;
  size?: string;
  properties?: ProductVariationProperty[];
  price: string;
  media?: Media[];
}
//change later, when add on backend
export interface ProductVariationProperty {
  title?: string;
  value?: string;
  symbol_html?: string;
}

export interface ProductComposite {
  id?: number;
  sort_order?: number;
  report_price?: any;
  price_allowance?: any;
  item_type?: CompositeItemType;
  item?: {
    id: number;
    type?: string;
    name: string;
    ext_title?: string;
    slug?: string;
    sku?: string;
    price?: number;
    sale_price?: number;
    is_on_sale?: boolean;
    excerpt?: string;
    properties?: any[];
    media?: Media[];
    image?: Media;
    taxonomy?: {
      id?: number;
      name?: string;
      slug: string;
    };
  };
  product?: Product;
  products?: Product[];
}

export interface ExcludedCustomerGroups {
  id: number;
  name: string;
}

export interface AliasBarcode {
  id: number;
  alias_sku: string;
}

// IMPORT/EXPORT
export interface ProductsExportPayload {
  types: ProductType[];
  columns: ProductColumns[];
  taxonomies: number[];
}
export interface ProductsImportPayload {
  update: boolean;
  file: any;
}

export enum CompositeType {
  DEAL = "deal",
  PRODUCTS = "products",
}

export enum CompositeItemType {
  PRODUCT = "product",
  TAXON = "taxon",
}

export enum ProductColumns {
  ID = "id",
  PRODUCT_NAME = "product name",
  SECONDARY_NAME = "secondary name",
  TYPE = "type",
  STATE = "state",
  PUBLISHED_AT = "published at",
  LINK = "link",
  CATEGORIES = "categories",
  TAGS = "tags",
  DESCRIPTION = "description",
  SKU = "sku",
  GTIN = "gtin",
  CODE = "code",
  WEIGHT = "weight",
  LENGTH = "length",
  HEIGHT = "height",
  WIDTH = "width",
  DELIVERY = "delivery",
  PICK_UP = "pick up",
  IN_PERSON = "in person",
  ATTRIBUTES = "attributes",
  PRODUCT_PRICE = "price",
}

// SIDEBAR
export const SidebarPage = {
  PRODUCT_FILTERS: `${SidebarPrefix.PRODUCT}-filters`,
  PRODUCT_VIEW: `${SidebarPrefix.PRODUCT}-view`,
  PRODUCT_SERVICE_VIEW: `${SidebarPrefix.PRODUCT}-service-view`,
  PRODUCT_COMPOSITE_VIEW: `${SidebarPrefix.PRODUCT}-composite-view`,
  PRODUCT_ATTRIBUTE: `${SidebarPrefix.PRODUCT}-attribute`,
  PRODUCT_UPSELL: `${SidebarPrefix.PRODUCT}-upsell`,
  PRODUCT_CROSS_SELL: `${SidebarPrefix.PRODUCT}-cross-sell`,
  PRODUCT_VARIATION_SETTINGS: `${SidebarPrefix.PRODUCT}-variation-settings`,
  PRODUCT_VARIATION_VIEW: `${SidebarPrefix.PRODUCT}-variation`,
  PRODUCT_IMPORT_EXPORT: `${SidebarPrefix.PRODUCT}-import-export`,
  PRODUCT_CONFIGURATION: `${SidebarPrefix.PRODUCT}-configuration`,
  PRODUCT_COMPOSITE_PRODUCTS: `${SidebarPrefix.PRODUCT}-composite-products`,
  PRODUCT_COMPOSITE_CATEGORIES: `${SidebarPrefix.PRODUCT}-composite-categories`,
  PRODUCT_COMPOSITE_TAGS: `${SidebarPrefix.PRODUCT}-composite-tags`,
  PRODUCT_BULK_PRODUCTS: `${SidebarPrefix.PRODUCT}-bulk-products`,
};
