<template>
  <div
    v-if="isLoading"
    id="the-loading-bar"
    :style="{
      width: loadingProgress + '%',
      background: useConfig().brandColor || '#645ED6',
    }"
  ></div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  isLoading: boolean;
}>();

// const posStore = usePosStore();
// const purchaseStore = usePurchaseStore();

// const accentColorBarRoutes = ref([
//   useRoutesNames().loginScreen,
//   useRoutesNames().posOrders,
//   useRoutesNames().posOrdersCustomerId,
//   useRoutesNames().posCustomers,
//   useRoutesNames().tableOrdering,
//   useRoutesNames().printIdTypeReport,
//   useRoutesNames().checkoutNewStepId,
//   useRoutesNames().posCheckout,
//   useRoutesNames().posCheckoutThankyouId,
//   useRoutesNames().agreementsId,
// ]);

// const isAccentColorBarRoute = computed(() =>
//   accentColorBarRoutes.value.includes(route.name as any)
// );

// const calculatedAccentColor = computed(() =>
//   posStore.isShowModalProduct ||
//   purchaseStore.isOpenSupplierProductsModal ||
//   isAccentColorBarRoute.value
//     ? useConfig().brandColor || "#645ED6"
//     : "#ffffff"
// );

const loadingProgress = ref(0);

function progress() {
  let i = 0;
  let j = 1;

  const interval = setInterval(() => {
    i += j;
    j = j - j / 11;

    const num = i;

    loadingProgress.value = num * 10 - 10;

    if (!props.isLoading) {
      loadingProgress.value = 0;

      clearInterval(interval);
    }
  }, 30);
}

// function progress() {
//   let startTime = Date.now();
//   let duration = 2000; // Duration in milliseconds

//   const interval = setInterval(() => {
//     let timeElapsed = Date.now() - startTime;
//     let progress = timeElapsed / duration;

//     if (progress > 1) progress = 1; // Ensure progress doesn't exceed 100%

//     // Quadratic ease-out
//     loadingProgress.value = 100 * (1 - Math.pow(1 - progress, 2));

//     if (!props.isLoading || progress >= 1) {
//       loadingProgress.value = 0;
//       clearInterval(interval);
//     }
//   }, 30);
// }

onMounted(() => {
  progress();
});
</script>

<style>
#the-loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #ffffff;
  z-index: 99999999;
}
</style>
