<template>
  <div class="flex flex-col w-full relative" @click.stop="" :id="id">
    <label
      v-if="label"
      :class="
        labelFont
          ? [labelFont]
          : 'text-font-primary text-xs leading-base-sm mb-x1 font-medium'
      "
    >
      {{ label }}
      <span v-if="requiredSymbol" class="text-error font-bold">*</span></label
    >

    <Datepicker
      ref="datePicker"
      :model-value="date"
      :range="range"
      month-name-format="long"
      :placeholder="placeholder"
      :enableTimePicker="false"
      :text-input="textInput"
      :highlight="highlightedDates"
      :disabled-dates="highlightedDates"
      highlight-disabled-days
      autoApply
      :inline="inline"
      v-bind="{
        [`${type}Picker`]: !!type,
        minDate: !birth && !range && !prev ? new Date() : '',
        maxDate,
        format: selectedFormat,
      }"
      :required="required"
      :disabled="disabled"
      inputmode="none"
      @update:modelValue="updateModelValue"
      @open="selectFirst"
      @update-month-year="emit('update-month-year', $event)"
    />

    <p v-if="invalid && invalidText" class="text-xs text-error">
      {{ invalidText }}
    </p>

    <div
      v-if="posTag || (posTag as number) === 0"
      class="absolute w-auto top-0 bottom-0 right-0 p-2.5 border-l"
      :class="{
        [tagPaddings]: tagPaddings,
        'p-2.5': !tagPaddings,
      }"
    >
      <div class="text-xs rounded-full bg-grey-400 px-2.5 py-1">
        {{ posTag }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { DatePickerMode } from "~/types/general";
import { addDays } from "date-fns";
import { DateRange } from "~/types/filters";

const props = defineProps({
  placeholder: String,
  type: String,
  birth: {
    type: Boolean,
    default: false,
  },
  modelValue: String,
  class: String,
  invalid: Boolean,
  range: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: true,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  observable: {
    type: Boolean,
    default: false,
  },
  observableDays: {
    type: Boolean,
    default: false,
  },
  maxDate: {
    type: [String, Date],
    default: "",
  },
  daysOff: {
    type: Array as PropType<String[]>,
    default: [],
  },
  prev: {
    type: Boolean,
    default: false,
  },
  today: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  noInitial: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  labelFont: { type: String, default: "" },
  pos: {
    type: Boolean,
    default: false,
  },
  textInput: {
    type: Boolean,
    default: true,
  },
  greyDisabled: {
    type: Boolean,
    default: false,
  },
  posTag: {
    type: [Number, String],
  },
  format: {
    type: String,
  },
  minHeight: {
    type: String,
    default: "",
  },
  maxHeight: {
    type: String,
    default: "",
  },
  tagPaddings: {
    type: String,
    default: "",
  },
  bgColor: {
    type: String,
    default: "",
  },
  textColor: {
    type: String,
    default: "",
  },
  fontWeight: {
    type: String,
    default: "",
  },
  fontSize: {
    type: Number,
    default: 13,
  },
  textAlign: {
    type: String,
    default: "",
  },
  maxWidth: {
    type: String,
    default: "",
  },
  border: {
    type: Boolean,
    default: false,
  },
  withIcon: {
    type: Boolean,
    default: false,
  },
  invalidText: {
    type: String,
    default: "",
  },
  iconLeft: {
    type: Boolean,
    default: false,
  },
  iconPaddings: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  requiredSymbol: {
    type: Boolean,
    default: false,
  },
  noAddDaysOffDays: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "update:modelValue",
  "change",
  "update-month-year",
  "changeDateRange",
]);

const datePicker = ref(null);
const date = ref(
  props.range ? [new Date(), new Date()] : props.today ? new Date() : ""
);
const isFirstClick = ref(true);

const highlightedDates = ref([]);

const selectedFormat = computed(() => {
  switch (props.type) {
    case DatePickerMode.MONTH:
      return "MMMM, yy";
    case DatePickerMode.YEAR:
      return "yyyy";
    default:
      return props.format
        ? props.format
        : props.pos &&
            props.type !== DatePickerMode.WEEK &&
            !props.type &&
            !props.range
          ? "EEE dd, MMM yy"
          : "dd/MM/yyyy";
  }
});

watch(
  () => props.daysOff,
  (newVal) => {
    if (newVal && (props.observableDays || props.observable)) {
      highlightedDates.value = newVal.map((d, index) =>
        props.noAddDaysOffDays
          ? new Date(d as string)
          : addDays(new Date(d as string), index)
      );
    }
  }
);

function selectFirst() {
  if (isFirstClick.value && !props.noInitial) {
    isFirstClick.value = false;
    date.value = new Date();

    // setTimeout(() => datePicker.value.closeMenu(), 500);
  }
}

function updateModelValue(value) {
  let dateRange: DateRange =
    value && value[0] && value[1]
      ? {
          from: new Date(value[0]),
          to: new Date(value[1]),
        }
      : null;

  date.value = value;
  emit("update:modelValue", value);
  emit("change", value);
  emit("changeDateRange", dateRange);
}

watch(
  () => props.invalid,
  (v) => {
    let input;

    if (props.id) {
      let container = document.querySelector("#" + props.id);
      input = container.querySelector(".dp__input");
    } else {
      input = document.querySelector(".dp__input");
    }

    v
      ? input?.classList.add("dp_invalid")
      : input?.classList.remove("dp_invalid");
  }
);

onMounted(() => {
  date.value = props.modelValue;

  highlightedDates.value = props.daysOff.map((d, index) =>
    props.noAddDaysOffDays
      ? new Date(d as string)
      : addDays(new Date(d as string), index + 1)
  );

  setTimeout(() => {
    let root = document.documentElement;

    let dateInput;
    let input;
    let icon;
    let iconsWrap;
    let clearIcon;

    if (props.id) {
      let container = document.querySelector("#" + props.id);
      dateInput = container.querySelector(".dp__input_wrap");
      input = container.querySelector(".dp__input");

      icon = container.querySelector(".dp__input_icon");
      iconsWrap = container.querySelector(".dp__input_icons");
    } else {
      dateInput = document.querySelector(".dp__input_wrap");
      input = document.querySelector(".dp__input");

      icon = document.querySelector(".dp__input_icon");
      iconsWrap = document.querySelector(".dp__input_icons");
    }

    if (props.pos) {
      clearIcon = document.querySelector(".dp__clear_icon");
      if (clearIcon) {
        clearIcon.style.display = "none";
      }

      if (props.pos && props.withIcon && props.iconLeft) {
        icon.style.left = "0px";
        icon.style.right = "auto";
        icon.style.border = "none";
        iconsWrap.style.padding = "6px 15px";
        icon.style.borderLeft = "none !important";
        input.style.paddingLeft = "40px";
        input.style.paddingRight = "15px";
      }
      if (props.withIcon && !props.iconLeft) {
        icon.style.borderLeft = "1px solid #c6c6c6 !important";
        if (!props.iconPaddings?.length) {
          iconsWrap.style.padding = "6px 10px";
        }
      }
      // if (props.pos && !props.withIcon) {
      //   icon.style.display = "none";
      // }

      if (!props.border) {
        input.style.border = "none";
      }

      input.style.background = props.bgColor || "#F9F9FB";
      input.style.color = props.textColor || "#242424";
      input.style.fontWeight = props.fontWeight || "500";
      input.style.fontSize = `${props.fontSize / 14}rem` || "14px";

      if (props.maxWidth) {
        input.style.maxWidth = props.maxWidth;
      }

      if (props.textAlign) {
        input.style.textAlign = props.textAlign;
      }

      if (props.maxHeight) {
        input.style.maxHeight = props.maxHeight;
      }

      if (props.minHeight) {
        input.style.minHeight = props.minHeight;
      } else {
        input.style.minHeight = "46px";
      }
    }

    if (props.iconPaddings?.length) {
      iconsWrap.style.padding = props.iconPaddings;
    }

    root.style.setProperty("--popover-width", dateInput?.offsetWidth + "px");

    if (props.greyDisabled) {
      root.style.setProperty("--disabled-color", "#ededed");
    } else {
      root.style.setProperty("--disabled-color", "#ffffff");
    }

    if (props.id && input) {
      input.id = props.id;
    }
  }, 0);
});
</script>

<style lang="scss">
:root {
  --popover-width: 367px;
  --disabled-color: #ffffff;
}
.dp {
  &--menu-wrapper {
    z-index: 30 !important;
    width: 285px;
  }

  &__cell_highlight {
    background-color: transparent !important;
  }
  &__arrow_top,
  &__arrow_bottom {
    display: none;
  }

  &__clear_icon {
    @apply absolute right-8 top-6;
  }

  &__month_year_col_nav {
    &:focus {
      @apply outline-accent-color;
    }
  }
  &__disabled {
    background: var(--disabled-color) !important;
    color: #242424 !important;
    &:hover {
      @apply border border-border-color;
    }
  }
  &__menu {
    width: var(--popover-width);
    @apply px-2.5 pt-5 pb-2.5 border rounded-x2 md:w-[367px] max-w-[367px] max-h-[400px];
  }
  &__input {
    padding: 10px 20px;
    font-family: inherit;
    border-radius: 5px;
    max-height: 42px;
    border: 1px solid #c6c6c6;

    &::-webkit-input-placeholder {
      font-weight: 500;
      color: #242424 !important;
      opacity: 1;
    }
    &:-moz-placeholder {
      font-weight: 500;
      color: #242424 !important;
      opacity: 1;
    }
    &::-moz-placeholder {
      font-weight: 500;
      color: #242424 !important;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      font-weight: 500;
      color: #242424 !important;
      opacity: 1;
    }
  }

  &__input_icon {
    right: 0px !important;
    left: auto;
    min-height: 28px !important;
  }

  &__calendar_header,
  &__calendar {
    width: 100%;
    @apply border-none text-black text-sm leading-base;
  }

  &__calendar_header_separator {
    @apply h-0;
  }

  &__calendar_row {
    margin: 0 0 10px 0;
  }

  &__active_date {
    @apply bg-accent-color;
  }

  &__today {
    @apply border border-accent-color;
  }

  &__active_date,
  &__today,
  &__date_hover:hover {
    border-radius: 100%;
  }

  &__calendar_item {
    display: flex;
    justify-content: center;
  }

  &__today {
    @apply border-accent-color;
  }

  &__inner_nav {
    @apply border bg-light-background h-[27px] w-[27px] rounded-half;
    &:focus {
      @apply outline-accent-color;
    }
  }

  &__inner_nav svg {
    @apply h-[12px];
    &:focus {
      @apply outline-accent-color;
    }
  }

  &__month_year_select {
    @apply text-font-primary text-black leading-base;
    &:focus {
      @apply outline-accent-color;
    }
  }

  &__calendar_item {
    @apply relative z-0 text-font-primary text-sm leading-base;
    &:focus {
      @apply outline-accent-color;
    }
  }

  &__range_start,
  &__range_end {
    @apply bg-accent-color rounded-half relative;
    color: #ffffff;
    &:focus {
      @apply outline-accent-color;
    }
  }

  &__range_between {
    @apply bg-primary-light relative;
    &:focus {
      @apply outline-accent-color;
    }
  }

  &__range_between_week {
    background-color: #f9f9fb;
    color: #4e4e4e;
    border: none;
  }

  &__date_hover_end {
    @apply rounded-none;
    &:focus {
      @apply outline-accent-color;
    }
  }

  &__overlay_cell_active {
    @apply bg-accent-color;
    &:focus {
      @apply outline-accent-color;
    }
  }

  &__calendar_item:last-child &__range_between {
    @apply rounded-tr-half rounded-br-half;
    &:focus {
      @apply outline-accent-color;
    }
  }

  &__calendar_item:last-child &__range_between::after {
    @apply hidden;
    &:focus {
      @apply outline-accent-color;
    }
  }

  &__calendar_item:first-child .dp__range_between {
    @apply rounded-tl-half rounded-bl-half;
    &:focus {
      @apply outline-accent-color;
    }
  }

  &__calendar_item:first-child .dp__range_between::before {
    @apply block absolute -right-full bg-light-background w-full h-full box-content;
    &:focus {
      @apply outline-accent-color;
    }
  }
  &__theme_light {
    --dp-border-color: #c6c6c6;
    --dp-menu-border-color: #c6c6c6;
    --dp-border-color-hover: var(--accent-color);
    --dp-primary-color: var(--accent-color);
  }
  &__icon {
    @apply stroke-font-primary fill-font-primary;
    width: 20px !important;
    height: 20px !important;
  }
}
.dp_invalid {
  border-color: red !important;
}
</style>
