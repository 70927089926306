import { CustomerType, CustomerShort } from "./customers";
import { Media, OrderType, SidebarPrefix } from "./general";
import { OrderPaymentStatus, OrderStatus } from "./orders";
import { User } from "./users";
import { PaymentMethodGateway } from "./payment";
import { Register, StoreShort } from "./stores";
import { UserType } from "./users";

export enum PosMenuLayout {
  MOBILE = "mobile",
  DESKTOP = "desktop",
  FOOTER = "footer",
}

export enum PosPage {
  QUICK_ADD = "quick-add",
  SEARCH = "search",
  KEYPAD = "keypad",
}

export enum PosActions {
  EDIT = "edit",
  COPY = "copy",
  CUT = "cut",
  DISCOUNT = "discount",
  DELETE = "delete",
}

export enum PosTransitionName {
  SLIDE = "slide",
  SLIDEBACK = "slideback",
}

export enum DigitalType {
  EMAIL = "email",
  MOBILE = "mobile",
}

export enum PriceAdjustType {
  PERCENT = "percent",
  CUSTOM = "custom",
}

export enum CustomerOptionShop {
  SWITCH_CUSTOMER = "switch-customer",
  VIEW_DETAILS = "view-details",
}

// SESSIONS
export interface Session {
  id: number;
  session_type: SessionType;
  opened_at: string;
  opened_by: CustomerShort;
  closed_at: string | null;
  closed_by: CustomerShort;
  session_owner: CustomerShort | Register;
  initial_cash: number;
  final_cash: number;
}

export interface SessionSingle {
  id: number;
  session_type: SessionType;
  opened_at: string;
  opened_by: CustomerShort;
  closed_at: string | null;
  closed_by: CustomerShort;
  session_owner: CustomerShort | Register;
  orders: {
    id: number;
    store: StoreShort;
    type: OrderType;
    is_express: boolean;
    number: string;
    po_number: number;
    status: OrderStatus;
    payment_status: OrderPaymentStatus;
    payment_method: {
      id: number;
      name: string;
      gateway: PaymentMethodGateway;
    };
    subtotal_value: number;
    total_value: number;
    credit_amount: number;
    customer: CustomerShort;
    target_date: string[];
    created_at: string;
  }[];
  summary: {
    orders_paid_amount: {
      Cash: number;
      "In-Person": number;
      "": number;
      Stripe: number;
      Account: number;
    };
    placed_orders: {
      Cash: number;
      "In-Person": number;
      "": number;
      Stripe: number;
      Account: number;
      Card: number;
    };
    register_cash: {
      initial_cash: number;
      final_cash: number;
      cash_payments: {
        in: number;
        petty_in: number;
        out: number;
        petty_out: number;
      };
      manually_calculated: number;
      banknotes: number;
      coins: number;
    };
    discrepancies: {
      register_cash_manucally_calculated: {
        final_cash: number;
        manually_calculated: number;
        dif: number;
      };
      orders_cash_register_cash: {
        orders_with_cash_payment_type: number;
        final_cash: number;
        dif: number;
      };
    };
  };
}

export enum SessionType {
  CUSTOMER = "customer",
  REGISTER = "register",
}

export enum PosCloseStep {
  START = "start",
  COUNT = "count",
  RECONCILIATION = "reconciliation",
  PAYMENTS = "payments",
  SUMMARY = "summary",
  REPORT = "report",
}

export interface OpenSessionPayload {
  session_owner: number;
  cash: number;
  location_id: number;
  opened_by?: number;
}

export interface CloseSessionPayload {
  session_owner: number;
  location_id: number;
  closed_by: number;
  cash: {
    coins: CashCount[];
    banknotes: CashCount[];
  };
}

export interface CashCount {
  denomination: number | string;
  quantity: number;
}

export interface MemberBalances {
  sales_person: {
    id: number;
    type: CustomerType;
    name: string;
    email: string;
    phone: string;
    title: string;
    user: {
      id: number;
      name: string;
      email: string;
      roles: UserType[];
      is_active: boolean;
    };
    image: Media;
  };
  total_sales: number;
  order_avg: number;
  payment_methods: {
    stripe: number;
    cash: number;
    account: number;
    "in-person": number;
    without_method: number;
  };
  orders: {
    id: number;
    store: StoreShort;
    type: OrderType;
    is_express: boolean;
    number: string;
    po_number: string;
    status: OrderStatus;
    payment_method: {
      id: number;
      name: string;
      gateway: PaymentMethodGateway;
    };
    payment_status: OrderPaymentStatus;
    subtotal_value: number;
    total_value: number;
    credit_amount: number;
    target_date: string[];
    created_at: string[];
  }[];
}

// CUSTOM POS
export enum PosTileType {
  CATEGORY = "category",
  TAG = "tag",
  MASTER = "master",
  VARIANT = "variant",
  SIMPLE = "simple",
  SET = "set",
  HOLD_NOTE = "hold-note",
}

export interface PosTileManage {
  label: string;
  color: string;
  position: number;
  use_image: boolean;
  page: number;
  type: PosTileType;
  product_id: number;
  taxonomy_id: number;
  x?: number;
  y?: number;
  w?: number;
  h?: number;
}

export interface PosTile {
  id: number;
  label: string;
  page: number;
  color: string;
  position: number;
  has_modifiers: boolean;
  has_cross_sell_or_upsell: boolean;
  use_image: boolean;
  type: PosTileType;
  product_id?: number;
  x?: number;
  y?: number;
  w?: number;
  h?: number;
  taxonomies?: {
    id: number;
    name: string;
    slug: string;
    taxons: {
      id: number;
      name: string;
      slug: string;
    }[];
  }[];
  image?: Media;
}

// OPTIONS
export enum PosOptionAction {
  CASH_MANAGEMENT = "cash-management",
  SYNC_DATA = "sync-data",
  SYNC_INVENTORY = "sync-inventory",
  CLOCK_ON_OFF = "clock-on-off",
  EXPORT_TIMESHEET = "export-timesheets",
  SELECT_TERMINAL = "select-terminal",
  TEAM_MEMBER_BALANCES = "team-member-balances",
  OPEN_SESSION = "open-session",
  CLOSE_SESSION = "close-session",
  RESET_ENV = "reset-env",
  EDIT_TILE = "edit-tile",
  CHECK_FOR_UPDATES = "check-for-updates",
  REPORT_BUG = "report-bug",
}

// SIDEBAR
export const SidebarPage = {
  POS_TERMINALS: `${SidebarPrefix.POS}-terminals`,
  POS_PRICE: `${SidebarPrefix.POS}-price`,
  POS_MEMBERS: `${SidebarPrefix.POS}-members`,
  POS_MEMBERS_PIN: `${SidebarPrefix.POS}-members-pin`,
  POS_MEMBERS_BALANCES: `${SidebarPrefix.POS}-members-balances`,
  POS_CUSTOMERS_FILTERS: `${SidebarPrefix.POS}-contact-filters`,
  POS_SESSION_OPEN: `${SidebarPrefix.POS}-session-open`,
  POS_SESSION_CLOSE_COUNT: `${SidebarPrefix.POS}-session-close-count`,
  POS_SESSION_CLOSE_RECONCILIATION: `${SidebarPrefix.POS}-session-close-reconciliation`,
  POS_SESSION_CLOSE_PAYMENTS: `${SidebarPrefix.POS}-session-close-payments`,
  POS_SESSION_CLOSE_SUMMARY: `${SidebarPrefix.POS}-session-close-summary`,
  POS_TILE: `${SidebarPrefix.POS}-tile`,
  POS_ORDERS: `${SidebarPrefix.POS}-orders`,
  POS_AMOUNT: `${SidebarPrefix.POS}-amount`,
};
