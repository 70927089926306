<template>
  <div
    class="grid w-full"
    :class="[cols ? colsClass : 'grid-cols-12', gap ? `gap-${gap}` : 'gap-5']"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  cols?: string;
  gap?: string;
}>();

const colsClass = computed(() => {
  switch (props.cols) {
    case "28":
      return "grid-cols-28";
    case "24":
      return "grid-cols-24";
    case "12":
      return "grid-cols-12";
    case "8":
      return "grid-cols-8";
    case "6":
      return "grid-cols-6";
    case "5":
      return "grid-cols-5";
    case "4":
      return "grid-cols-4";
    case "3":
      return "grid-cols-3";
    case "2":
      return "grid-cols-2";
    default:
      return "grid-cols-12";
  }
});
</script>
