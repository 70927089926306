export enum ElectronChannel {
  PRINT_REPORT = "print-report",
  PRINT_REPORT_PDF = "print-report-pdf",
  DOWNLOAD_REPORT = "download-report",
  DOWNLOAD_REPORT_PDF = "download-report-pdf",
  ELECTRON_CONTROLS = "electron-controls",
  GET_OS = "get-os",
  GET_PRINTERS = "get-printers",
  OPEN_CASH_DRAWER = "open-cash-drawer",
  CHECK_FOR_UPDATES = "check-for-updates",
}

export enum ElectronControlAction {
  QUIT = "quit",
  MINIMIZE = "minimize",
  MAXIMIZE = "maximize",

  PRINT = "print",

  ZOOM_IN = "zoom-in",
  ZOOM_OUT = "zoom-out",
  ZOOM_RESET = "zoom-reset",
  ZOOM_SET = "zoom-set",

  RELOAD = "reload",
  FORCE_RELOAD = "force-reload",
  TOGGLE_DEV_TOOLS = "toggle-dev-tools",
}

export enum OperatingSystem {
  WINDOWS = "win32",
  MACOS = "darwin",
  LINUX = "linux",
}
