import { defineStore } from "pinia";

import { Image, ResponseType, Option, Document } from "~/types/general";
import {
  CategoriesAncestorTypes,
  Category,
  TaxonomiesRounding,
  Taxonomy,
  TaxonSingle,
  Taxonomies,
  Tag,
  LevyUnit,
} from "~/types/taxonomies";

export const useTaxonomiesStore = defineStore("taxonomies", () => {
  // Taxonomies
  const taxonomies = ref<Taxonomy[]>([]);
  const isLoading = ref(false);
  const editMode = ref(false);
  const isOpenTaxonomySidebarEdit = ref(false);
  const editTaxonomy = ref<Taxonomy>({} as Taxonomy);

  const page = ref(1);
  const perPage = ref(40);
  const totalPages = ref(null);

  async function getTaxonomies(): Promise<ResponseType<Taxonomy[]>> {
    try {
      const response = (await useVaniloApi("/taxonomies/")) as ResponseType<
        Taxonomy[]
      >;

      taxonomies.value =
        response.meta.current_page === 1
          ? response?.data
          : [...taxonomies.value, ...response?.data];

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getAssociatedItemNumbers(
    params
  ): Promise<ResponseType<Taxonomy[]>> {
    try {
      const response = (await useVaniloApi("/taxonomies/item-number/taxa", {
        params,
      })) as ResponseType<Taxonomy[]>;

      productItemNumbers.value =
        response.meta.current_page === 1
          ? response?.data
          : [...productItemNumbers.value, ...response?.data];

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createTaxonomy(body): Promise<ResponseType<any[]>> {
    try {
      const response = await useVaniloApi("/taxonomies", {
        method: "POST",
        body,
      });

      return response as ResponseType<any[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateTaxonomies(body): Promise<ResponseType<Category>> {
    try {
      const response = await useVaniloApi(`/taxonomies/${body.id}`, {
        method: "POST",
        body,
      });

      return response as ResponseType<Category>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteTaxonomy(id): Promise<ResponseType<any[]>> {
    try {
      const response = await useVaniloApi(`/taxonomies/${id}`, {
        method: "DELETE",
      });

      return response as ResponseType<any[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Categories
  const isOpenCategorySidebar = ref(false);
  const editingCategory = ref<Category>({} as Category);
  const viewingCategory = ref<Category>({} as Category);
  const categories = ref<Category[]>([]);
  const productItemNumbers = ref<Taxonomy[]>([]);
  const categoriesAncestors = ref([
    { name: "Parent", value: CategoriesAncestorTypes.PARENT },
    { name: "Child", value: CategoriesAncestorTypes.CHILD },
    { name: "Grand Child", value: CategoriesAncestorTypes.GRAND_CHILD },
  ]);
  const levyUnits = ref([
    { name: "Kilo", value: LevyUnit.KILO },
    { name: "Ton", value: LevyUnit.TON },
    { name: "Liter", value: LevyUnit.LITER },
    { name: "Box", value: LevyUnit.BOX },
    { name: "Box/tray", value: LevyUnit.BOX_TRAY },
    { name: "Carton", value: LevyUnit.CARTON },
    { name: "Percent from sale price", value: LevyUnit.PERCENT },
    { name: "Square Meters", value: LevyUnit.SQUARE_METERS },
    { name: "Runners", value: LevyUnit.RUNNERS },
  ]);
  const roundingOptions = ref([
    { name: "None", value: null },
    {
      name: "To the nearest 5 cents",
      value: TaxonomiesRounding.NEAREST_5_CENTS,
    },
    {
      name: "Round up to the nearest 50 cents",
      value: TaxonomiesRounding.ROUND_UP_50_NEAREST,
    },
    {
      name: "Round down to the nearest 50 cents",
      value: TaxonomiesRounding.ROUND_DOWN_50_NEAREST,
    },
  ]);
  const categoryImage = ref<Image>({} as Image);
  const categoryFile = ref<Document>({} as Document);

  const categoryOptions = computed(
    () =>
      categories.value?.map((cat) => ({
        name: cat.name,
        value: cat.id,
        ...(cat.image && {
          image: cat.image?.conversions?.thumbnail || cat.image?.url,
        }),
      })) || []
  );

  async function getCategories(payload?): Promise<ResponseType<Category[]>> {
    const params = {
      page: page.value,
      limit: perPage.value,
      ...(payload && payload),
    };

    try {
      const response = (await useVaniloApi(
        `/taxonomies/${Taxonomies.CATEGORIES}/taxa`,
        {
          ...(params && { params }),
        }
      )) as ResponseType<Category[]>;

      const { data, meta } = response;

      categories.value =
        meta.current_page === 1 ? data : [...categories.value, ...data];

      page.value = meta.current_page;
      totalPages.value = meta.last_page;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getFundingTypes(params?): Promise<ResponseType<Category[]>> {
    try {
      const response = await useVaniloApi(
        `/taxonomies/${Taxonomies.CATEGORIES}/taxa`,
        {
          params,
        }
      );

      return response as ResponseType<Category[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createCategory(body): Promise<ResponseType<Category>> {
    try {
      const response = await useVaniloApi(
        `/taxonomies/${Taxonomies.CATEGORIES}/taxa`,
        {
          method: "POST",
          body,
        }
      );

      return response as ResponseType<Category>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateCategory(body): Promise<ResponseType<Category>> {
    try {
      const response = await useVaniloApi(`/taxonomies/taxa/${body.id}`, {
        method: "POST",
        body,
      });

      return response as ResponseType<Category>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteCategory(id): Promise<ResponseType<any[]>> {
    try {
      const response = await useVaniloApi(`/taxonomies/taxa/${id}`, {
        method: "DELETE",
      });

      return response as ResponseType<any[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Tags
  const isOpenTagsSidebarEdit = ref(false);
  const isOpenAttachProducts = ref(false);
  const tags = ref<Tag[]>([]);
  const editTag = ref<Tag>({} as Tag);
  const editModeTags = ref(false);
  const selectedProducts = ref<number[]>([]);

  const tagsOptions = computed(
    () => tags.value?.map((cat) => ({ name: cat.name, value: cat.id })) || []
  );

  async function getTags(params?): Promise<ResponseType<Tag[]>> {
    try {
      const response = (await useVaniloApi(
        `/taxonomies/${Taxonomies.PRODUCT_TAGS}/taxa`,
        {
          ...(params && { params }),
        }
      )) as ResponseType<Tag[]>;

      tags.value =
        response.meta.current_page === 1
          ? response?.data
          : [...tags.value, ...response?.data];

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createTag(body): Promise<ResponseType<Tag>> {
    try {
      const response = await useVaniloApi(
        `/taxonomies/${Taxonomies.PRODUCT_TAGS}/taxa`,
        {
          method: "POST",
          body,
        }
      );

      return response as ResponseType<Tag>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateTag(body): Promise<ResponseType<Tag>> {
    try {
      const response = await useVaniloApi(`/taxonomies/taxa/${body.id}`, {
        method: "POST",
        body,
      });

      return response as ResponseType<Tag>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteTag(id): Promise<ResponseType<any[]>> {
    try {
      const response = await useVaniloApi(`/taxonomies/taxa/${id}`, {
        method: "DELETE",
      });

      return response as ResponseType<any[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Customer Tags
  const customerTags = ref([]);

  const customerTagsOptions = computed(
    () =>
      customerTags.value?.map((cat) => ({ name: cat.name, value: cat.id })) ||
      []
  );

  async function getCustomerTags(params?) {
    try {
      const response = (await useVaniloApi(
        `/taxonomies/${Taxonomies.CUSTOMER_TAGS}/taxa`,
        {
          ...(params && { params }),
        }
      )) as ResponseType<Tag[]>;

      customerTags.value =
        response.meta.current_page === 1
          ? response?.data
          : [...customerTags.value, ...response?.data];

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Store Tags
  const storeTags = ref([]);

  const storeTagsOptions = computed(
    () =>
      storeTags.value?.map((cat) => ({ name: cat.name, value: cat.id })) || []
  );

  async function createStoreTag(body): Promise<ResponseType<Tag>> {
    try {
      const response = await useVaniloApi(
        `/taxonomies/${Taxonomies.STORE_TAGS}/taxa`,
        {
          method: "POST",
          body,
        }
      );

      return response as ResponseType<Tag>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getStoreTags(params?) {
    try {
      const response = (await useVaniloApi(
        `/taxonomies/${Taxonomies.STORE_TAGS}/taxa`,
        {
          ...(params && { params }),
        }
      )) as ResponseType<Tag[]>;

      storeTags.value =
        response.meta.current_page === 1
          ? response?.data
          : [...storeTags.value, ...response?.data];

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Store Categories
  const storeCategories = ref([]);

  const storeCategoryOptions = computed(
    () =>
      storeCategories.value?.map((cat) => ({
        name: cat.name,
        value: cat.id,
        ...(cat.image && {
          image: cat.image?.conversions?.thumbnail || cat.image?.url,
        }),
      })) || []
  );

  async function createStoreCategory(body): Promise<ResponseType<Category>> {
    try {
      const response = await useVaniloApi(
        `/taxonomies/${Taxonomies.STORE_CATEGORIES}/taxa`,
        {
          method: "POST",
          body,
        }
      );

      return response as ResponseType<Category>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getStoreCategories(params?) {
    try {
      const response = (await useVaniloApi(
        `/taxonomies/${Taxonomies.STORE_CATEGORIES}/taxa`,
        {
          ...(params && { params }),
        }
      )) as ResponseType<Category[]>;

      storeCategories.value =
        response.meta.current_page === 1
          ? response?.data
          : [...storeCategories.value, ...response?.data];

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Taxons
  async function getTaxons(taxonomyId): Promise<ResponseType<any[]>> {
    try {
      const response = await useVaniloApi(`/taxonomies/${taxonomyId}/taxa`);

      return response as ResponseType<any[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getTaxon(taxonId): Promise<ResponseType<TaxonSingle>> {
    try {
      const response = await useVaniloApi(`taxonomies/taxa/${taxonId}`);

      return response as ResponseType<TaxonSingle>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createTaxon(
    taxonomyId,
    params
  ): Promise<ResponseType<TaxonSingle>> {
    try {
      const response = await useVaniloApi(`/taxonomies/${taxonomyId}/taxa`, {
        method: "POST",
        params,
      });

      return response as ResponseType<TaxonSingle>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function uploadMediaForTaxon(data?, id?) {
    if (useIsEmpty(data)) {
      return;
    }

    const formData = new FormData();
    formData.append("media", data.media);

    try {
      const response = await useVaniloApi(`/taxonomies/${id}/image`, {
        method: "POST",
        body: formData,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateTaxon(
    taxonId,
    body
  ): Promise<ResponseType<TaxonSingle>> {
    try {
      const response = await useVaniloApi(`/taxonomies/taxa/${taxonId}`, {
        method: "POST",
        body,
      });

      return response as ResponseType<TaxonSingle>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteTaxon(taxonomyId): Promise<ResponseType<any[]>> {
    try {
      const response = await useVaniloApi(`/taxonomies/taxa/${taxonomyId}`, {
        method: "DELETE",
      });

      return response as ResponseType<any[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function uploadFile(taxonId, file) {
    const formData = new FormData();

    formData.append("file", file.file);
    formData.append("file[title]", file.title);

    try {
      const response = await useVaniloApi(
        `taxonomies/item-number/${taxonId}/upload-links`,
        {
          method: "POST",
          body: formData,
        }
      );

      return response as ResponseType<any[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function attachMediaToTaxon(taxonId, mediaId) {
    try {
      const response = await useVaniloApi(
        `/taxonomies/${taxonId}/image/${mediaId}`,
        {
          method: "POST",
        }
      );

      return response as ResponseType<any[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function attachToTaxon(
    taxonId,
    body
  ): Promise<ResponseType<TaxonSingle>> {
    try {
      const response = await useVaniloApi(`/taxonomies/attach/${taxonId}`, {
        method: "POST",
        body,
      });

      return response as ResponseType<TaxonSingle>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function detachFromTaxon(
    taxonId,
    body
  ): Promise<ResponseType<TaxonSingle>> {
    try {
      const response = await useVaniloApi(`/taxonomies/detach/${taxonId}`, {
        method: "POST",
        body,
      });

      return response as ResponseType<TaxonSingle>;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    // Taxonomies
    taxonomies,
    isLoading,
    editMode,
    isOpenTaxonomySidebarEdit,
    editTaxonomy,
    getTaxonomies,
    getAssociatedItemNumbers,
    createTaxonomy,
    updateTaxonomies,
    deleteTaxonomy,
    page,
    perPage,
    totalPages,

    // Categories
    isOpenCategorySidebar,
    editingCategory,
    viewingCategory,
    categories,
    productItemNumbers,
    categoriesAncestors,
    levyUnits,
    roundingOptions,
    categoryImage,
    categoryFile,
    categoryOptions,
    getCategories,
    getFundingTypes,
    createCategory,
    updateCategory,
    deleteCategory,

    // Tags
    isOpenTagsSidebarEdit,
    isOpenAttachProducts,
    tags,
    editTag,
    editModeTags,
    selectedProducts,
    tagsOptions,
    getTags,
    createTag,
    updateTag,
    deleteTag,

    // Customer Tags
    customerTags,
    customerTagsOptions,
    getCustomerTags,

    // Store Tags
    storeTags,
    storeTagsOptions,
    createStoreTag,
    getStoreTags,

    // Store Categories
    storeCategories,
    storeCategoryOptions,
    createStoreCategory,
    getStoreCategories,

    // Taxons
    getTaxons,
    getTaxon,
    createTaxon,
    uploadMediaForTaxon,
    updateTaxon,
    deleteTaxon,
    uploadFile,
    attachMediaToTaxon,
    attachToTaxon,
    detachFromTaxon,
  };
});
