import { CustomerShort } from "./customers";
import { Media, OrderType, SidebarPrefix } from "./general";
import { OrderPaymentStatus, OrderStatus } from "./orders";
import { ProductType } from "./products";

export interface PaymentMethods {
  stripe?: StripeSettings; //online
  cash: PaymentSubsetting;
  account: PaymentAccountSubsetting;
  "in-person": PaymentSubsetting; //card
  loyalty_credit: PaymentSubsetting;
  cheque: PaymentSubsetting;
  "bank-transfer": PaymentSubsetting;
  "send-link"?: PaymentSubsetting; //send payment link
}

export interface StripeSettings {
  name?: string;
  id?: number;
  gateway?: PaymentMethodGateway;
  is_enabled: boolean;
  custom_stripe_client: boolean;
  live_mode: boolean;
  apple_google_pay: boolean;
  logging: boolean;
  saved_cards: boolean;
  charge_method: ChargeMethod;
  public_key: string;
  secret_key: string;
  oauth_client_id: string;
  application_fee: number | string;
  application_fee_fixed: number | string;
  use_in_person_fee: boolean;
  in_person_fee: number | string;
  in_person_fee_fixed: number | string;
  order_status: OrderStatus;
  webhook_secret?: string;
  test_public_key?: string;
  test_secret_key?: string;
  test_oauth_client_id?: string;
  test_webhook_secret?: string;
  show_on_checkout?: boolean;
}

export interface PaymentSubsetting {
  is_enabled: boolean;
  order_status: OrderStatus;
  stores: number[];
  gateway?: PaymentMethodGateway;
  id?: number;
  name?: string;
  show_on_checkout?: boolean;
  round_rule?: string;
  round_base?: string;
}

export interface PaymentAccountSubsetting {
  is_enabled: boolean;
  order_status: OrderStatus;
  stores: number[];
  gateway?: PaymentMethodGateway;
  id?: number;
  name?: string;
  show_on_checkout?: boolean;
}

export enum PaymentMethodGateway {
  CASH = "cash",
  ACCOUNT = "account",
  STRIPE = "stripe", //online
  IN_PERSON = "in-person", //card
  CREDIT = "credit",
  CHEQUE = "cheque",
  TRANSFER = "bank-transfer",
  SEND_LINK = "send-link", //send payment link
  CREDIT_NOTE = "credit-note",
}

export interface PaymentMethodOption {
  id: number;
  name: string;
  value: PaymentMethodGateway;
  order_status: OrderStatus;
}

export interface StripeStatus {
  connected: boolean;
  account: {
    type: string;
    account_id: string;
    name: string;
    email: string;
    country: string;
    is_on_board: boolean;
  };
}

export interface StripeConnect {
  account_id: string;
  country: string;
  email: string;
  is_on_board: boolean;
  name: string;
  onboard_link: string;
  onboard_link_expires_at: string;
  type: string;
  redirect?: string;
}

export enum ChargeMethod {
  DESTINATION = "destination",
  TRANSFERS = "transfers",
}

export enum JafflePaymentsAction {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
}

// SPLIT PAYMENT
export enum SplitPaymentMode {
  AMOUNT = "amount",
  ITEMS = "items",
}

export enum Split {
  HALF = 2,
  THIRD = 3,
  FOURTH = 4,
  CUSTOM = "custom",
}

// totals
export interface TotalsPayload {
  original_order_id?: string | number;
  store_id: number;
  type: string;
  is_express: boolean;
  payment_method_id: number;
  items: {
    product_id: number | string;
    quantity: number | string;
  }[];
  customer_id: number;
  paid_with_other_methods?: string | number;
  with_time_slots?: string;
  with_order_minimum?: boolean;
  with_express_service?: boolean;
  discount?: number;
  discount_percent?: number;
}

export interface Totals {
  id: number;
  type: OrderType;
  status: OrderStatus;
  is_express: boolean;
  subtotal_value: number;
  tax_value: number;
  is_inclusive_tax: boolean;
  total_value: number;
  payment_status: OrderPaymentStatus;
  payment_amount: number;
  credit_amount: number;
  payment_method: {
    id: number;
    name: string;
    gateway: PaymentMethodGateway;
  };
  credit_payment_amount: number;
  paid_with_other_methods: number;
  paid_with_credit: number;
  paid_with_points: number;
  bonus_paid_value: number;
  max_points_to_apply: number;
  items: {
    id: number;
    inventory_id: number;
    product_type: ProductType;
    product_id: number;
    is_custom: boolean;
    is_deleted: boolean;
    quantity: number;
    allocated: number;
    auto_allocated: number;
    lot_id: number;
    picked: number;
    is_picked: boolean;
    price: number;
    old_price?: number;
    base_price?: number;
    old_base_price?: number;
    addons_price: number;
    is_sale_price: boolean;
    price_total: number;
    refunded_quantity: number;
    refunded_value: number;
    refund_by_quantity: boolean;
    area: string;
    notes: string;
    picker_notes: string;
    addons: {
      name: string;
      value: string[];
    }[];
    is_hidden: boolean;
    is_printed: boolean;
    name: string;
    product_parent_id: number;
    ext_title: string;
    properties: any[];
    media: Media[];
  }[];
  adjustments: {
    type: string;
    name: string;
    amount: number;
    data: any[];
  }[];
  unit_checks: {
    lines: number;
    products: number;
    units: any[];
  };
}

export interface PayMultipleRes {
  result: {
    payment_id: number;
    amount: number;
    currency: string;
    payment_completed: boolean;
  };
  invoice: {
    id: number;
    customer: CustomerShort;
    payment_method: {
      id: number;
      name: string;
      gateway: PaymentMethodGateway;
    };
    total_amount: number;
    subtotal_amount: number;
    status: string;
    notes: string;
    metadata: any;
    items: {
      item_type: string;
      item_id: number;
      amount: number;
    }[];
    adjustments: any[];
  };
}

// SIDEBAR
export const SidebarPage = {
  PAYMENT_HISTORY_LOG: `${SidebarPrefix.PAYMENT}-history-log`,
};
