import { useGeneralStore } from "~/store/general";
import { useAppsStore } from "~/store/apps";
import { AuthScreen } from "~/types/auth";
import { useAuthStore } from "~/store/auth";
import { useSettingsStore } from "~/store/settings";
import { useUsersStore } from "~/store/users";
import { RouteRecordName } from "#vue-router";
import { useCustomersStore } from "~/store/customers";
import { usePosStore } from "~/store/pos";
import { useOnboardingStore } from "~/store/onboarding";
import { CustomerStatus } from "~/types/customers";

// pos routes
const posRoutes = [
  useRoutesNames().posShop,
  useRoutesNames().posOrders,
  useRoutesNames().tableOrdering,
  useRoutesNames().posCustomers,
  useRoutesNames().posCheckout,
  useRoutesNames().posCheckoutThankyouId,
  useRoutesNames().printIdTypeReport,
  useRoutesNames().singleOrderModeId,
  useRoutesNames().userTab,
  useRoutesNames().user,
  useRoutesNames().userOrders,
] as string[];

// invoices routes
const invoicesRoutes: RouteRecordName[] = [
  useRoutesNames().singleOrderPayByLinkIdPay,
  useRoutesNames().singleOrderPayByLinkId,
  useRoutesNames().singleOrderPayByLinkIdPaymentCompleted,
  useRoutesNames().printContainerIdInvoice,
  useRoutesNames().printContainerIdStatementType,
  useRoutesNames().printContainerIdReceipt,
  useRoutesNames().printContainerIdBill,
  useRoutesNames().printContainerIdBillReceipt,
];

// onboarding routes
const onboardingRoutes: RouteRecordName[] = [
  useRoutesNames().onboardingType,
  useRoutesNames().onboardingTypeName,
  useRoutesNames().onboardingTypeSuccess,
  useRoutesNames().onboardingTermsAndConditions,
];

// booking routes
const bookingRoutes: RouteRecordName[] = [
  useRoutesNames().bookingType,
  useRoutesNames().bookingTypeName,
  useRoutesNames().booking,
  useRoutesNames().bookingProviderId,
  useRoutesNames().bookingAccountLoginScreen,
];

// guest routes
const guestRoutes = [
  useRoutesNames().review,
  useRoutesNames().loginScreen,
  useRoutesNames().passwordReset,
  useRoutesNames().checkoutNewStepId,
  ...onboardingRoutes,
  ...invoicesRoutes,
  ...bookingRoutes,
] as string[];

// reports routes
const reportsRoutes = [
  useRoutesNames().reportsAnalytics,
  useRoutesNames().reportsAnalyticsTab,
  useRoutesNames().reportsCosts,
  useRoutesNames().reportsCostsTab,
  useRoutesNames().reportsProducts,
  useRoutesNames().reportsProductsTab,
  useRoutesNames().reportsRevenue,
  useRoutesNames().reportsRevenueTab,
] as string[];

export default defineNuxtRouteMiddleware(async (to, from) => {
  const authStore = useAuthStore();
  const settingsStore = useSettingsStore();
  const usersStore = useUsersStore();
  const appsStore = useAppsStore();
  const generalStore = useGeneralStore();
  const customersStore = useCustomersStore();
  const posStore = usePosStore();
  const onboardingStore = useOnboardingStore();

  const restrictedRoutes = [] as string[];
  const restrictedPaths = [] as string[];

  // get OS and physical printers using Electron
  if (useConfig().isElectron) {
    generalStore.os = await useElectron().getOS();

    generalStore.printersPhysical = await useElectron().getPrinters();
  }

  // getting CURRENT USER on server side
  if (
    !bookingRoutes.includes(to.name) &&
    !onboardingRoutes.includes(to.name) &&
    to.name !== useRoutesNames().checkoutNewStepId &&
    (from.name !== useRoutesNames().loginScreen ||
      to.name !== useRoutesNames().loginScreen) &&
    (!usersStore.currentUser || useIsEmpty(usersStore.currentUser)) &&
    useGetToken()
  ) {
    try {
      const { data } = await useAsyncData("profile", () =>
        usersStore.getCurrentUser()
      );

      usersStore.currentUser = data.value?.data;
    } catch (e) {
      console.log(e);
    }
  }

  // getting SETTINGS/PRINT ASSETS/APPS on server side
  if (useGetToken() && !useConfig().isClient) {
    await useAsyncData(
      "settings",
      () => useIsEmpty(settingsStore.settings) && settingsStore.getSettings()
    );

    await useAsyncData(
      "print-assets",
      () =>
        useIsEmpty(generalStore.printAssets) && generalStore.getPrintAssets()
    );

    await useAsyncData(
      "apps",
      () =>
        !appsStore.apps.length &&
        useGetToken() &&
        !useConfig().isClient &&
        appsStore.getAppsList()
    );
  }

  // getting PUBLIC SETTINGS on server side
  if (
    useIsEmpty(settingsStore.publicSettings) &&
    (useConfig().isClient || !useGetToken())
  ) {
    await useAsyncData("public-settings", () =>
      settingsStore.getPublicSettings()
    );
  }

  // get CURRENT CUSTOMER PROFILE on Server Side
  if (useIsEmpty(customersStore.currentUserCustomer) && useGetToken()) {
    await useAsyncData("profile", () =>
      customersStore.getCurrentCustomerProfile()
    );
  }

  if (!onboardingStore.allOnboardsList?.length) {
    await useAsyncData("onboarding", () => onboardingStore.getOnboards());
  }

  //////////////////////////////////////////////////////////////////////

  // RESTRICTING ROUTES
  restrictedRoutesByRole(restrictedRoutes, restrictedPaths, useRoutesNames());
  restrictRoutesBySettings(restrictedRoutes, restrictedPaths, useRoutesNames());

  // if logged user is trying to access '/login' route
  if (
    useGetToken() &&
    customersStore.currentUserCustomer?.status === CustomerStatus.ACTIVE &&
    (to.name === useRoutesNames().loginScreen ||
      to.name === useRoutesNames().passwordReset)
  ) {
    return navigateTo({ name: useRoutesNames().index });
  }

  if (
    !useGetToken() &&
    (to.name === useRoutesNames().booking ||
      to.name === useRoutesNames().bookingProviderId ||
      (to.name === useRoutesNames().bookingType &&
        to.params.type === "provider"))
  ) {
    return navigateTo({
      name: useRoutesNames().bookingAccountLoginScreen,
    });
  }

  // if there is not token saved and the route is NOT GUEST
  if (!useGetToken() && !guestRoutes.includes(to.name?.toString())) {
    authStore.logout();

    return navigateTo({
      name: useRoutesNames().loginScreen,
      params: { screen: AuthScreen.EMAIL },
    });
  }

  // block access to restricted routes
  const homepage =
    settingsStore.homepage === useRoutesNames().posShop &&
    useConfig().isPosQuickOrder
      ? useRoutesNames().posCustomers
      : (settingsStore.homepage as any) || useRoutesNames().index;

  if (
    restrictedRoutes.includes(to.name?.toString()) ||
    restrictedPaths.includes(to.path)
  ) {
    return navigateTo({
      name: !restrictedRoutes.includes(homepage)
        ? homepage
        : useRoutesNames().index,
    });
  }

  // if POS lockscreen is enabled
  if (posStore.isLockscreen && posRoutes.includes(to.name?.toString())) {
    return navigateTo({ name: useRoutesNames().posLockscreen });
  }

  // if the user is a SALES and CASHIER mode is enabled and tries to access restricted routes
  if (
    useConfig().isSales &&
    useConfig().isPosCashier &&
    to.name !== useRoutesNames().posLockscreen &&
    !posRoutes.includes(to.name?.toString())
  ) {
    return navigateTo({ name: useRoutesNames().posShop });
  }

  // ONBOARDING redirect
  if (isRestrictOnboarding(to)) {
    return navigateTo({
      name: useRoutesNames().loginScreen,
      params: { screen: AuthScreen.EMAIL },
    });
  }

  // BOOKING SERVICES redirect
  if (isRestrictBookingServices(to)) {
    return navigateTo({
      name: useRoutesNames().loginScreen,
      params: { screen: AuthScreen.EMAIL },
    });
  }

  if (import.meta.client) {
    const routesHistory = useState("routesHistory", () => ({
      previous: null,
      beforePrevious: null,
    }));
    routesHistory.value.beforePrevious = routesHistory.value.previous;
    routesHistory.value.previous = from;
  }
});

// restrict routes by settings
function restrictRoutesBySettings(restrictedRoutes, restrictedPaths, routes) {
  if (!useConfig().isPos) {
    restrictedRoutes.push(
      routes.posShop,
      routes.posOrders,
      routes.posCustomers,
      routes.posCheckout
    );
  }

  if (!useConfig().isOrders && !useConfig().isParticipant) {
    restrictedRoutes.push(routes.orders);
  }

  if (!useConfig().isReporting) {
    restrictedRoutes.push(routes.reporting);
  }

  if (!useConfig().isPosHospitality) {
    restrictedRoutes.push(routes.tableOrdering);
  }

  if (!useConfig().isAllocations) {
    restrictedRoutes.push(routes.ordersAllocations);
  }

  if (!useConfig().isMultilocation) {
    restrictedRoutes.push(
      routes.managementLocations,
      routes.managementBrands,
      routes.managementSettings
    );
  }
  if (!useConfig().isBookingServices) {
    restrictedRoutes.push(
      routes.booking,
      routes.bookingType,
      routes.bookingTypeName,
      routes.bookingAccount,
      routes.bookingAccountTab,
      routes.bookingAccountLoginScreen
    );
  }
}

// restrict routes by role
function restrictedRoutesByRole(restrictedRoutes, restrictedPaths, routes) {
  if (useConfig().isClient) {
    restrictedRoutes.push(
      routes.ordersAllocations,
      routes.allocationsManageId,
      routes.productsSettings,
      routes.productsSettingsTab,
      routes.posCheckout,
      routes.posCheckoutThankyouId,
      routes.contactsId,
      routes.contactsIdTab,
      routes.contacts,
      routes.contactsOldProfileNew,
      routes.contactsNewTab,
      routes.contactsNew,
      routes.contactsCompanies,
      routes.contactsPeople,
      routes.contactsStaff,
      routes.pickerAccount,
      routes.posShop,
      routes.posOrders,
      routes.products,
      routes.productNew,
      routes.statistics,
      routes.taxonomies,
      routes.posCustomers,
      routes.editOrder,
      routes.inventory,
      routes.productsPriceLists,
      routes.purchaseOrders,
      routes.reporting
    );

    if (!useConfig().isParticipant) {
      restrictedRoutes.push(routes.orders);
    }
  } else {
    restrictedRoutes.push(routes.userOrders);
  }

  if (useConfig().isProfileManager) {
    restrictedRoutes.push(routes.managementLocations);
  }

  if (useConfig().isSalesNotAdmin) {
    restrictedRoutes.push(routes.analytics);
    restrictedPaths.push("/profile/settings");
  }

  if (!useConfig().hasAdminRole) {
    restrictedRoutes.push(...reportsRoutes);
  }
}

function isRestrictOnboarding(to) {
  const routeType = useOnboardingStore()
    .allOnboardsList?.map(
      (item) => item.url?.split("/onboarding/")?.[1]?.split("/")?.[0]
    )
    ?.find((type) => type === to.params?.type?.toString());

  return onboardingRoutes.includes(to.name) && !routeType;
}

function isRestrictBookingServices(to) {
  return bookingRoutes.includes(to.name) && !useConfig().isBookingServices;
}
