import { StorageService } from "~/services/StorageService";
import { useGeneralStore } from "~/store/general";
import { useInventoryStore } from "~/store/inventory";
import { IndexedDBStore } from "~/types/bff";
import { NotificationEvent, NotificationEventType } from "~/types/general";
import { InventoryNotification, InventorySingle } from "~/types/inventory";

export const useUpdateInventory = () => {
  const generalStore = useGeneralStore();

  watch(
    () => generalStore.notificationsList,
    async (notifications: NotificationEvent[]) => {
      const last = notifications?.[notifications.length - 1];

      if (!last || last.type !== NotificationEventType.STOCK_UPDATE) return;

      // update Inventory idb
      try {
        // get existing Inventory item
        const existingInventory = await useInventoryStore().getInventoryByIdBff(
          last.data.id
        );

        if (!existingInventory) return;

        existingInventory.lots?.forEach((lot) => {
          (last.data as InventoryNotification).lots?.forEach((newLot) => {
            if (
              useConfig().isLotsSupplierGrouped
                ? lot.supplier?.id === newLot.supplier_id
                : lot.order?.id === newLot.order_id
            ) {
              lot.base_stock = newLot.base_stock;
              lot.stock = newLot.stock;
              lot.reserved_stock = newLot.reserved_stock;

              lot.lots?.forEach((lotItem) => {
                newLot.lots?.forEach((newLotItem) => {
                  if (lotItem.id === newLotItem.id) {
                    lotItem.stock = newLotItem.stock;
                    lotItem.reserved_stock = newLotItem.reserved_stock;
                  }
                });
              });
            }
          });
        });

        const body: InventorySingle = {
          ...existingInventory,
          stock_status: last.data.stock_status,
          stock: last.data.stock,
          expected_stock: last.data.expected_stock,
          reserved_stock: last.data.reserved_stock,
        };

        const response = await new StorageService(
          useRuntimeConfig()
        ).updateItem<IndexedDBStore.INVENTORY>({
          store: IndexedDBStore.INVENTORY,
          id: last.data.id,
          body,
        });

        // remove notification
        if (response) {
          generalStore.notificationsList =
            generalStore.notificationsList.filter(
              (notification) => notification.data.id !== last.data.id
            );
        }
      } catch (error) {}
    },
    {
      deep: true,
    }
  );
};
