<template>
  <div
    class="border border-dashed p-x5 rounded-x1 relative"
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="dropImage"
    :class="{
      'bg-accent-color/30': active,
      'bg-white': !active,
      'border-border-color': !error,
      '!border-error': error,
    }"
  >
    <div class="flex justify-center mb-x3">
      <img src="~/assets/img/upload-icon.svg" alt="Upload media" />
    </div>
    <label :for="id ? id : 'files-upload'" class="cursor-pointer">
      <span
        class="block text-sm leading-base text-font-primary font-medium text-center mb-x1"
      >
        <span class="text-accent-color">Upload a file</span> or drag and drop
      </span>
      <input
        :id="id ? id : 'files-upload'"
        name="files-upload"
        type="file"
        class="sr-only"
        accept="image/bmp, image/png, image/jpg, image/jpeg,image/gif"
        ref="files"
        @change="uploadImage"
        multiple
      />
      <span
        class="block text-xs leading-base-sm text-font-secondary text-center"
      >
        PNG, JPEG, GIF up to 10 MB
      </span>
    </label>
  </div>
</template>

<script setup lang="ts">
let props = defineProps({
  id: {
    type: String,
    default: "",
  },
  customer: Boolean,
  addNew: { type: Boolean, default: false },
  error: Boolean,
});
const emit = defineEmits(["uploadImage"]);

const active = ref(false);
let media: object = {};

function toggleActive() {
  active.value = !active.value;
}
function dropImage(event) {
  uploadImage(event, true);
}

function uploadImage(event, dropImage = false) {
  let uploadedFiles = dropImage ? event.dataTransfer.files : event.target.files;
  let images = [];
  uploadedFiles.forEach((image) => {
    images.push({
      media: image,
      title: image.name,
    });
  });

  emit("uploadImage", images);
}
</script>
