import { defineStore } from "pinia";

import { Filters, Meta, ResponseType, Option } from "~/types/general";
import { Order } from "~/types/orders";
import { User, UserType } from "~/types/users";
import { useGeneralStore } from "./general";
import { useOrdersStore } from "./orders";

// Users Store
export const useUsersStore = defineStore("users", () => {
  // State

  // Users data
  const users = ref<User[]>([]);
  const currentUser = ref<User>({} as User);

  // Orders data
  const ordersUser = ref<Order[]>([]);
  const ordersMeta = ref<Meta>({} as Meta);

  // Filters
  const ordersFilters = ref<Filters>({
    priority: {
      type: "priority",
      title: "Is Priority",
      options: [],
      chosenOptions: [],
      toggle: false,
      isSingle: true,
    },
    type: {
      type: "type",
      title: "Order Type",
      options: useGeneralStore().orderTypeList,
      chosenOptions: [],
    },
    status: {
      type: "status",
      title: "Status",
      options: useOrdersStore().statusTags,
      chosenOptions: [],
    },
    period: {
      type: "period",
      title: "Period",
      options: useGeneralStore().periods,
      chosenOptions: [],
      isSingle: true,
    },
  });

  // Payment statuses
  const paymentStatuses = ref<Option[]>([
    { name: "Overdue", value: "overdue" },
    { name: "Account", value: "account" },
  ]);

  const selectedPaymentStatuses = ref<Option[]>([]);

  // User types
  const userTypes = ref<Option[]>([
    { name: "None", value: UserType.CLIENT },
    { name: "Picker", value: UserType.PICKER },
    { name: "Admin", value: UserType.ADMIN },
    { name: "Superadmin", value: UserType.SUPERADMIN },
    { name: "Manager", value: UserType.MANAGER },
    { name: "Sales", value: UserType.SALES },
    { name: "Customer Service", value: UserType.CUSTOMER_SERVICE },
    { name: "Dispatch", value: UserType.DISPATCH },
    { name: "Finance", value: UserType.FINANCE },
    { name: "Advisor", value: UserType.ADVISOR },
    { name: "General", value: UserType.GENERAL },
  ]);

  // Geolocation
  const userCoords = ref({ lat: null, lng: null });
  const userGeolocationAddress = ref({});

  // Getters
  const isOverdueSelected = computed(() =>
    selectedPaymentStatuses.value.some((o) => o.value === "overdue")
  );

  const isAccountSelected = computed(() =>
    selectedPaymentStatuses.value.some((o) => o.value === "account")
  );

  // Actions

  // Fetch users
  async function getUsers(): Promise<ResponseType<User[]>> {
    let response: any;
    try {
      response = await useVaniloApi("/users");
    } catch (error) {
      console.error(error);
    }
    return response;
  }

  // Fetch single user
  async function getSingleUser(
    userId?: number | string
  ): Promise<ResponseType<User>> {
    let response: any;
    try {
      response = await useVaniloApi(`/users/${userId}`);
    } catch (error) {
      console.error(error);
    }
    return response;
  }

  // Fetch current user
  async function getCurrentUser(): Promise<ResponseType<User>> {
    let response: any;
    try {
      response = await useVaniloApi(`/user`);
    } catch (error) {
      console.error(error);
    }
    return response;
  }

  // Update user
  async function updateUser(userId, payload?): Promise<ResponseType<User>> {
    let response: any;
    try {
      response = await useVaniloApi(`/users/${userId}`, {
        method: "POST",
        body: { ...payload },
      });
    } catch (error) {
      console.error(error);
    }
    return response;
  }

  // Fetch geolocation address
  async function getGeolocationAddress(): Promise<string | null> {
    let response: any;
    try {
      response = await useGoogleAddress(
        userCoords.value.lat,
        userCoords.value.lng
      );
    } catch (error) {
      console.error(error);
    }

    if (response?.address_components) {
      userGeolocationAddress.value = {
        country: {
          id: response.address_components.find((component) =>
            component.types.includes("country")
          )?.short_name,
          name: response.address_components.find((component) =>
            component.types.includes("country")
          )?.long_name,
        },
        city: response.address_components.find((component) =>
          component.types.includes("locality")
        )?.short_name,
        postalcode: response.address_components.find((component) =>
          component.types.includes("postal_code")
        )?.short_name,
        province: {
          code: response.address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name,
          name: response.address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name,
        },
        address: `${
          response.address_components.find((component) =>
            component.types.includes("street_number")
          )?.short_name ?? ""
        } ${
          response.address_components.find((component) =>
            component.types.includes("route")
          )?.short_name ?? ""
        }`,
        formatted_address: `${
          response.address_components.find((component) =>
            component.types.includes("street_number")
          )?.short_name ?? ""
        } ${
          response.address_components.find((component) =>
            component.types.includes("route")
          )?.short_name ?? ""
        }`,
        name: response.address_components.find((component) =>
          component.types.includes("route")
        )?.short_name,
        lat: userCoords.value.lat,
        lng: userCoords.value.lng,
      };
    }

    return response;
  }

  return {
    // Users data
    users,
    currentUser,

    // Orders data
    ordersUser,
    ordersMeta,

    // Filters
    ordersFilters,
    paymentStatuses,
    selectedPaymentStatuses,
    userTypes,

    // Geolocation
    userCoords,
    userGeolocationAddress,

    // Getters
    isOverdueSelected,
    isAccountSelected,

    // Actions
    getUsers,
    getSingleUser,
    getCurrentUser,
    updateUser,
    getGeolocationAddress,
  };
});
