import { default as index7aPwNYIiNiMeta } from "/vercel/path0/pages/agreements/[id]/index.vue?macro=true";
import { default as _91store_93cPjaPCKV8AMeta } from "/vercel/path0/pages/agreements/[id]/location/[store].vue?macro=true";
import { default as newu1cPLBBip5Meta } from "/vercel/path0/pages/agreements/[id]/location/new.vue?macro=true";
import { default as ai3PXZmDxyPkMeta } from "/vercel/path0/pages/ai.vue?macro=true";
import { default as _91id_93cEQWuiBpYnMeta } from "/vercel/path0/pages/allocations/manage/[id].vue?macro=true";
import { default as analyticsoP7kSnslwZMeta } from "/vercel/path0/pages/analytics.vue?macro=true";
import { default as _91id_93GSHDUG6ab2Meta } from "/vercel/path0/pages/bills/manage/[id].vue?macro=true";
import { default as _91name_93B7S4lQtknnMeta } from "/vercel/path0/pages/booking/[type]/[name].vue?macro=true";
import { default as indexI6mPhnMByqMeta } from "/vercel/path0/pages/booking/[type]/index.vue?macro=true";
import { default as _91tab_93ujzrE2quyQMeta } from "/vercel/path0/pages/booking/account/[tab].vue?macro=true";
import { default as indexujNGX5wYFDMeta } from "/vercel/path0/pages/booking/account/index.vue?macro=true";
import { default as _91_91screen_93_93WFm6KbeEYuMeta } from "/vercel/path0/pages/booking/account/login/[[screen]].vue?macro=true";
import { default as indexylpNiPeXBJMeta } from "/vercel/path0/pages/booking/index.vue?macro=true";
import { default as agreeTA0Qs9FeMMMeta } from "/vercel/path0/pages/booking/order/[id]/agree.vue?macro=true";
import { default as confirmed4Dx8c5Vxc4Meta } from "/vercel/path0/pages/booking/order/[id]/confirmed.vue?macro=true";
import { default as review1NjMGQWj2SMeta } from "/vercel/path0/pages/booking/order/[id]/review.vue?macro=true";
import { default as indexUq1vNUOr6ZMeta } from "/vercel/path0/pages/booking/provider/[id]/index.vue?macro=true";
import { default as indexW7lHihEqqoMeta } from "/vercel/path0/pages/booking/provider/[id]/service/[serviceid]/index.vue?macro=true";
import { default as calendarGNujQtdH8MMeta } from "/vercel/path0/pages/calendar.vue?macro=true";
import { default as _91_91id_93_933b6LLjqpo5Meta } from "/vercel/path0/pages/checkout-new/[[step]]/[[id]].vue?macro=true";
import { default as indexraiq4bUafSMeta } from "/vercel/path0/pages/checkout/index.vue?macro=true";
import { default as index9pRSbEdXWgMeta } from "/vercel/path0/pages/checkout/thankyou/index.vue?macro=true";
import { default as _91_91name_93_93tQjykSeNbkMeta } from "/vercel/path0/pages/composite/[[name]].vue?macro=true";
import { default as newpRvMEoC3GfMeta } from "/vercel/path0/pages/composite/new.vue?macro=true";
import { default as index6vCayIQMOHMeta } from "/vercel/path0/pages/contacts/[id]/[tab]/index.vue?macro=true";
import { default as _91zone_933sF5UjMuNwMeta } from "/vercel/path0/pages/contacts/[id]/[tab]/service-area/[zone].vue?macro=true";
import { default as indexAey67UoOaTMeta } from "/vercel/path0/pages/contacts/[id]/[tab]/service-area/index.vue?macro=true";
import { default as indexKvkoH3o5sWMeta } from "/vercel/path0/pages/contacts/[id]/index.vue?macro=true";
import { default as indexl96lwpMNQrMeta } from "/vercel/path0/pages/contacts/calendar/index.vue?macro=true";
import { default as companies8bpFwjKsCBMeta } from "/vercel/path0/pages/contacts/companies.vue?macro=true";
import { default as customersW4N6y9W12nMeta } from "/vercel/path0/pages/contacts/customers.vue?macro=true";
import { default as indexH8ojxYxOKTMeta } from "/vercel/path0/pages/contacts/index.vue?macro=true";
import { default as _91id_930sVmjNBbQfMeta } from "/vercel/path0/pages/contacts/loyalty-rewards/[id].vue?macro=true";
import { default as indexpbXODs6bzvMeta } from "/vercel/path0/pages/contacts/loyalty-rewards/index.vue?macro=true";
import { default as _91tab_93NGepledei5Meta } from "/vercel/path0/pages/contacts/new/[tab].vue?macro=true";
import { default as indexfJxI16F2j2Meta } from "/vercel/path0/pages/contacts/new/index.vue?macro=true";
import { default as _91id_93ewOos7Hrz3Meta } from "/vercel/path0/pages/contacts/old-profile/[id].vue?macro=true";
import { default as new5nykDFd3KuMeta } from "/vercel/path0/pages/contacts/old-profile/new.vue?macro=true";
import { default as peopleqxHiSGwDwfMeta } from "/vercel/path0/pages/contacts/people.vue?macro=true";
import { default as staffTGi4egtS3WMeta } from "/vercel/path0/pages/contacts/staff.vue?macro=true";
import { default as _91id_93C53tP1s1gaMeta } from "/vercel/path0/pages/counts/[step]/[id].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93wAKjoWsBEQMeta } from "/vercel/path0/pages/invoices/manage/[id].vue?macro=true";
import { default as _91tab_936RMij1xG5UMeta } from "/vercel/path0/pages/location/[tab].vue?macro=true";
import { default as indexJApc38xDjcMeta } from "/vercel/path0/pages/location/index.vue?macro=true";
import { default as _91_91screen_93_93LfNd9wk1rxMeta } from "/vercel/path0/pages/login/[[screen]].vue?macro=true";
import { default as calendardn23o7phgjMeta } from "/vercel/path0/pages/login/google/calendar.vue?macro=true";
import { default as calendarI5Q4fV7aX2Meta } from "/vercel/path0/pages/login/outlook/calendar.vue?macro=true";
import { default as indexPQT1kHli0lMeta } from "/vercel/path0/pages/management/brands/index.vue?macro=true";
import { default as _91tab_93zdmqEbyX08Meta } from "/vercel/path0/pages/management/locations/[id]/[tab].vue?macro=true";
import { default as indexWWlG0NlCMpMeta } from "/vercel/path0/pages/management/locations/[id]/index.vue?macro=true";
import { default as _91zone_93S7B8GBY2IcMeta } from "/vercel/path0/pages/management/locations/[id]/service-area/[zone].vue?macro=true";
import { default as indexyToXZizg4zMeta } from "/vercel/path0/pages/management/locations/[id]/service-area/index.vue?macro=true";
import { default as indexGB9GZAqFjjMeta } from "/vercel/path0/pages/management/locations/index.vue?macro=true";
import { default as _91tab_932hfhkZEJGOMeta } from "/vercel/path0/pages/management/locations/new/[tab].vue?macro=true";
import { default as index12txCA0Y2GMeta } from "/vercel/path0/pages/management/locations/new/index.vue?macro=true";
import { default as _91tab_93hpRxwjvZsnMeta } from "/vercel/path0/pages/management/locations/settings/[tab].vue?macro=true";
import { default as index9DN6WR2B6pMeta } from "/vercel/path0/pages/management/locations/settings/index.vue?macro=true";
import { default as indexXxC8d2U2t6Meta } from "/vercel/path0/pages/management/settings/index.vue?macro=true";
import { default as _91id_93o1IM4YD5s5Meta } from "/vercel/path0/pages/old-location/[id].vue?macro=true";
import { default as _91name_93Wh9EhoTX6mMeta } from "/vercel/path0/pages/onboarding/[type]/[name].vue?macro=true";
import { default as errorQyRSvkH3ijMeta } from "/vercel/path0/pages/onboarding/[type]/error.vue?macro=true";
import { default as indexygltuEsvXiMeta } from "/vercel/path0/pages/onboarding/[type]/index.vue?macro=true";
import { default as successtfY2mKVrJvMeta } from "/vercel/path0/pages/onboarding/[type]/success.vue?macro=true";
import { default as privacy_45policytUDvwwC5AtMeta } from "/vercel/path0/pages/onboarding/privacy-policy.vue?macro=true";
import { default as terms_45and_45conditionslv8lSbgrzOMeta } from "/vercel/path0/pages/onboarding/terms-and-conditions.vue?macro=true";
import { default as indexKLEU9xdHWaMeta } from "/vercel/path0/pages/ordering/index.vue?macro=true";
import { default as allocationszaUWWLGMdXMeta } from "/vercel/path0/pages/orders/allocations.vue?macro=true";
import { default as indexM5jPYUy0O3Meta } from "/vercel/path0/pages/orders/fulfilment/index.vue?macro=true";
import { default as _91id_93FSFUH5BbdxMeta } from "/vercel/path0/pages/orders/fulfilment/manage/freight/[id].vue?macro=true";
import { default as _91id_93BwsqTziA8PMeta } from "/vercel/path0/pages/orders/fulfilment/manage/run/[id].vue?macro=true";
import { default as indexan9ZrZAr6jMeta } from "/vercel/path0/pages/orders/index.vue?macro=true";
import { default as invoicesAqj6NU9iH6Meta } from "/vercel/path0/pages/orders/invoices.vue?macro=true";
import { default as indexco2aqYUKV8Meta } from "/vercel/path0/pages/orders/preparing/index.vue?macro=true";
import { default as _91tab_9315A4VPi1flMeta } from "/vercel/path0/pages/orders/preparing/picking/[id]/[tab].vue?macro=true";
import { default as indexG6oMUecs8gMeta } from "/vercel/path0/pages/orders/preparing/picking/[id]/index.vue?macro=true";
import { default as schedulePxuhB234zYMeta } from "/vercel/path0/pages/orders/schedule.vue?macro=true";
import { default as index6ypXq0qvUDMeta } from "/vercel/path0/pages/orders/statements/index.vue?macro=true";
import { default as _91id_931r9MeQCoklMeta } from "/vercel/path0/pages/orders/statements/manage/[id].vue?macro=true";
import { default as password_45reset6pLgh9teUjMeta } from "/vercel/path0/pages/password-reset.vue?macro=true";
import { default as indexRn4xvtpB7MMeta } from "/vercel/path0/pages/picker-account/index.vue?macro=true";
import { default as index0lDih2sMnmMeta } from "/vercel/path0/pages/pos/checkout/index.vue?macro=true";
import { default as _91_91id_93_93yrcsB5dlK8Meta } from "/vercel/path0/pages/pos/checkout/thankyou/[[id]].vue?macro=true";
import { default as customersldHAab2m5VMeta } from "/vercel/path0/pages/pos/customers.vue?macro=true";
import { default as lockscreenRqxUojRrq9Meta } from "/vercel/path0/pages/pos/lockscreen.vue?macro=true";
import { default as _91id_930VwLnIkfnbMeta } from "/vercel/path0/pages/pos/orders/customer/[id].vue?macro=true";
import { default as indexKVRlGysFkzMeta } from "/vercel/path0/pages/pos/orders/index.vue?macro=true";
import { default as shopeDWfKibu3JMeta } from "/vercel/path0/pages/pos/shop.vue?macro=true";
import { default as index7OINQzwkasMeta } from "/vercel/path0/pages/print-container/[id]/bill/index.vue?macro=true";
import { default as print88mugf5vosMeta } from "/vercel/path0/pages/print-container/[id]/bill/print.vue?macro=true";
import { default as receipt5vvGVKNaj1Meta } from "/vercel/path0/pages/print-container/[id]/bill/receipt.vue?macro=true";
import { default as invoicerRkjtRNMe6Meta } from "/vercel/path0/pages/print-container/[id]/invoice.vue?macro=true";
import { default as receiptbFEznwTHlLMeta } from "/vercel/path0/pages/print-container/[id]/receipt.vue?macro=true";
import { default as _91type_933WLMWD9K4GMeta } from "/vercel/path0/pages/print-container/[id]/statement/[type].vue?macro=true";
import { default as a4sSUVJhsCYeMeta } from "/vercel/path0/pages/print-container/batch-deposit/[id]/a4.vue?macro=true";
import { default as thermalLjjEeGmOehMeta } from "/vercel/path0/pages/print-container/batch-deposit/[id]/thermal.vue?macro=true";
import { default as indexNnLklspdVuMeta } from "/vercel/path0/pages/print-container/confirmation/[id]/index.vue?macro=true";
import { default as a4uY7gZ8RLsbMeta } from "/vercel/path0/pages/print-container/invoice/[id]/a4.vue?macro=true";
import { default as thermalfDEp5CVOMUMeta } from "/vercel/path0/pages/print-container/invoice/[id]/thermal.vue?macro=true";
import { default as _91id_93o0CKF7B8jYMeta } from "/vercel/path0/pages/print-container/manifest/[id].vue?macro=true";
import { default as _91id_93Mfu3JYjJIGMeta } from "/vercel/path0/pages/print-container/order-forms/[id].vue?macro=true";
import { default as _91id_93JezMOXzUX7Meta } from "/vercel/path0/pages/print-container/order-forms/barcode/[id].vue?macro=true";
import { default as _91id_93pUpwhyy8QLMeta } from "/vercel/path0/pages/print-container/picking/[id].vue?macro=true";
import { default as a4TwLOc2S4JGMeta } from "/vercel/path0/pages/print-container/preparation/[id]/a4.vue?macro=true";
import { default as thermalLBXqJYs27ZMeta } from "/vercel/path0/pages/print-container/preparation/[id]/thermal.vue?macro=true";
import { default as _91id_93wYpdbd6IBuMeta } from "/vercel/path0/pages/print-container/statements/[id].vue?macro=true";
import { default as a4CbJZyrWXiaMeta } from "/vercel/path0/pages/print-container/summary/[id]/a4.vue?macro=true";
import { default as thermalBIwAjuYicBMeta } from "/vercel/path0/pages/print-container/summary/[id]/thermal.vue?macro=true";
import { default as _91_91report_93_93htk8KNpwMPMeta } from "/vercel/path0/pages/print/[id]/[type]/[[report]].vue?macro=true";
import { default as _91_91name_93_93BKhc7EUkkWMeta } from "/vercel/path0/pages/product/[[name]].vue?macro=true";
import { default as newzBDu50SXg9Meta } from "/vercel/path0/pages/product/new.vue?macro=true";
import { default as compositesOr7PUFpoBSMeta } from "/vercel/path0/pages/products/composites.vue?macro=true";
import { default as countsXSnrmCAPyGMeta } from "/vercel/path0/pages/products/counts.vue?macro=true";
import { default as indexDJjD9NcZ4YMeta } from "/vercel/path0/pages/products/index.vue?macro=true";
import { default as inventoryGZpGUxxG1MMeta } from "/vercel/path0/pages/products/inventory.vue?macro=true";
import { default as price_45listsqJT5AxQBZkMeta } from "/vercel/path0/pages/products/price-lists.vue?macro=true";
import { default as _91tab_93gZwE8cpNE1Meta } from "/vercel/path0/pages/products/services-inventory/[id]/[tab].vue?macro=true";
import { default as indexHP3wJUckO9Meta } from "/vercel/path0/pages/products/services-inventory/[id]/index.vue?macro=true";
import { default as index42okbnuhkpMeta } from "/vercel/path0/pages/products/services-inventory/index.vue?macro=true";
import { default as servicesk0HKncIXgBMeta } from "/vercel/path0/pages/products/services.vue?macro=true";
import { default as _91tab_93FyCKS5vftXMeta } from "/vercel/path0/pages/products/settings/[tab].vue?macro=true";
import { default as indexZeGElsbXx6Meta } from "/vercel/path0/pages/products/settings/index.vue?macro=true";
import { default as _91tab_93lWBuRhE3JwMeta } from "/vercel/path0/pages/profile/[tab].vue?macro=true";
import { default as indexSsrwCXeuTQMeta } from "/vercel/path0/pages/profile/index.vue?macro=true";
import { default as _91zone_93Yv1tfHeMKdMeta } from "/vercel/path0/pages/profile/service-area/[zone].vue?macro=true";
import { default as indexhN3t6vnglWMeta } from "/vercel/path0/pages/profile/service-area/index.vue?macro=true";
import { default as indexB2UlwywUyOMeta } from "/vercel/path0/pages/purchase-order/pay-by-link/[id]/index.vue?macro=true";
import { default as payW9vpd6yiTRMeta } from "/vercel/path0/pages/purchase-order/pay-by-link/[id]/pay.vue?macro=true";
import { default as payment_45completedrJza82ZUCbMeta } from "/vercel/path0/pages/purchase-order/pay-by-link/[id]/payment-completed.vue?macro=true";
import { default as billsgXQk5m0mTyMeta } from "/vercel/path0/pages/purchase-orders/bills.vue?macro=true";
import { default as indexhcKziDh4wCMeta } from "/vercel/path0/pages/purchase-orders/index.vue?macro=true";
import { default as _91id_93CyIFire6DFMeta } from "/vercel/path0/pages/purchase-orders/manage/[id].vue?macro=true";
import { default as newXZYHUxzuanMeta } from "/vercel/path0/pages/purchase-orders/new.vue?macro=true";
import { default as index0IVTtCLjOvMeta } from "/vercel/path0/pages/purchase-orders/purchase-overview/index.vue?macro=true";
import { default as _91id_93uRnIIW4hOwMeta } from "/vercel/path0/pages/purchase-orders/purchase-overview/manage/[id].vue?macro=true";
import { default as _91id_93th5hTTBNoAMeta } from "/vercel/path0/pages/purchase-orders/receiving/dispatch/[id].vue?macro=true";
import { default as indexzFizLZ4nRGMeta } from "/vercel/path0/pages/purchase-orders/receiving/index.vue?macro=true";
import { default as _91id_930D2oZzIcZ3Meta } from "/vercel/path0/pages/receivables/[mode]/[id].vue?macro=true";
import { default as indexbgDbeinOXZMeta } from "/vercel/path0/pages/receivables/index.vue?macro=true";
import { default as _91tab_93ZwKvBDZkKnMeta } from "/vercel/path0/pages/reports/analytics/[tab].vue?macro=true";
import { default as indexftSC2MJwUhMeta } from "/vercel/path0/pages/reports/analytics/index.vue?macro=true";
import { default as _91tab_938W85kBRvbQMeta } from "/vercel/path0/pages/reports/costs/[tab].vue?macro=true";
import { default as index8xk1BRIVl9Meta } from "/vercel/path0/pages/reports/costs/index.vue?macro=true";
import { default as _91tab_93MeZetdbISdMeta } from "/vercel/path0/pages/reports/products/[tab].vue?macro=true";
import { default as indexhCbXNfYIQAMeta } from "/vercel/path0/pages/reports/products/index.vue?macro=true";
import { default as _91tab_93X6EwlSmZh2Meta } from "/vercel/path0/pages/reports/revenue/[tab].vue?macro=true";
import { default as indexYvOXbvmBknMeta } from "/vercel/path0/pages/reports/revenue/index.vue?macro=true";
import { default as reviewSvxmKIjOfcMeta } from "/vercel/path0/pages/review.vue?macro=true";
import { default as _91_91name_93_93Bxg7puc8GRMeta } from "/vercel/path0/pages/service/[[name]].vue?macro=true";
import { default as newUIVy17PzuwMeta } from "/vercel/path0/pages/service/new.vue?macro=true";
import { default as _91id_93w5hVhs54pwMeta } from "/vercel/path0/pages/single-order/[mode]/[id].vue?macro=true";
import { default as indexs70Kr8xaJwMeta } from "/vercel/path0/pages/single-order/pay-by-link/[id]/index.vue?macro=true";
import { default as payTzwNYMNlN4Meta } from "/vercel/path0/pages/single-order/pay-by-link/[id]/pay.vue?macro=true";
import { default as payment_45completedBUg0hjeJ9VMeta } from "/vercel/path0/pages/single-order/pay-by-link/[id]/payment-completed.vue?macro=true";
import { default as table_45orderinghAddCZmEZ7Meta } from "/vercel/path0/pages/table-ordering.vue?macro=true";
import { default as indexQsj1LCKgA9Meta } from "/vercel/path0/pages/taxonomies/index.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as tiptapyO6Qw3dCUQMeta } from "/vercel/path0/pages/tiptap.vue?macro=true";
import { default as transfersfAIDKlgba8Meta } from "/vercel/path0/pages/transfers.vue?macro=true";
import { default as _91id_93Y9BylcRtYhMeta } from "/vercel/path0/pages/units-overview/manage/[id].vue?macro=true";
import { default as _91tab_93goPKKyZE81Meta } from "/vercel/path0/pages/user/[tab].vue?macro=true";
import { default as indexk3QLtbGbUrMeta } from "/vercel/path0/pages/user/index.vue?macro=true";
import { default as ordersh1vSLUrxYHMeta } from "/vercel/path0/pages/user/orders.vue?macro=true";
export default [
  {
    name: "agreements-id",
    path: "/agreements/:id()",
    meta: index7aPwNYIiNiMeta || {},
    component: () => import("/vercel/path0/pages/agreements/[id]/index.vue")
  },
  {
    name: "agreements-id-location-store",
    path: "/agreements/:id()/location/:store()",
    meta: _91store_93cPjaPCKV8AMeta || {},
    component: () => import("/vercel/path0/pages/agreements/[id]/location/[store].vue")
  },
  {
    name: "agreements-id-location-new",
    path: "/agreements/:id()/location/new",
    meta: newu1cPLBBip5Meta || {},
    component: () => import("/vercel/path0/pages/agreements/[id]/location/new.vue")
  },
  {
    name: "ai",
    path: "/ai",
    component: () => import("/vercel/path0/pages/ai.vue")
  },
  {
    name: "allocations-manage-id",
    path: "/allocations/manage/:id()",
    component: () => import("/vercel/path0/pages/allocations/manage/[id].vue")
  },
  {
    name: "analytics",
    path: "/analytics",
    component: () => import("/vercel/path0/pages/analytics.vue")
  },
  {
    name: "bills-manage-id",
    path: "/bills/manage/:id()",
    component: () => import("/vercel/path0/pages/bills/manage/[id].vue")
  },
  {
    name: "booking-type-name",
    path: "/booking/:type()/:name()",
    meta: _91name_93B7S4lQtknnMeta || {},
    component: () => import("/vercel/path0/pages/booking/[type]/[name].vue")
  },
  {
    name: "booking-type",
    path: "/booking/:type()",
    meta: indexI6mPhnMByqMeta || {},
    component: () => import("/vercel/path0/pages/booking/[type]/index.vue")
  },
  {
    name: "booking-account-tab",
    path: "/booking/account/:tab()",
    component: () => import("/vercel/path0/pages/booking/account/[tab].vue")
  },
  {
    name: "booking-account",
    path: "/booking/account",
    component: () => import("/vercel/path0/pages/booking/account/index.vue")
  },
  {
    name: "booking-account-login-screen",
    path: "/booking/account/login/:screen?",
    meta: _91_91screen_93_93WFm6KbeEYuMeta || {},
    component: () => import("/vercel/path0/pages/booking/account/login/[[screen]].vue")
  },
  {
    name: "booking",
    path: "/booking",
    component: () => import("/vercel/path0/pages/booking/index.vue")
  },
  {
    name: "booking-order-id-agree",
    path: "/booking/order/:id()/agree",
    component: () => import("/vercel/path0/pages/booking/order/[id]/agree.vue")
  },
  {
    name: "booking-order-id-confirmed",
    path: "/booking/order/:id()/confirmed",
    component: () => import("/vercel/path0/pages/booking/order/[id]/confirmed.vue")
  },
  {
    name: "booking-order-id-review",
    path: "/booking/order/:id()/review",
    component: () => import("/vercel/path0/pages/booking/order/[id]/review.vue")
  },
  {
    name: "booking-provider-id",
    path: "/booking/provider/:id()",
    component: () => import("/vercel/path0/pages/booking/provider/[id]/index.vue")
  },
  {
    name: "booking-provider-id-service-serviceid",
    path: "/booking/provider/:id()/service/:serviceid()",
    component: () => import("/vercel/path0/pages/booking/provider/[id]/service/[serviceid]/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/vercel/path0/pages/calendar.vue")
  },
  {
    name: "checkout-new-step-id",
    path: "/checkout-new/:step?/:id?",
    component: () => import("/vercel/path0/pages/checkout-new/[[step]]/[[id]].vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue")
  },
  {
    name: "checkout-thankyou",
    path: "/checkout/thankyou",
    component: () => import("/vercel/path0/pages/checkout/thankyou/index.vue")
  },
  {
    name: "composite-name",
    path: "/composite/:name?",
    component: () => import("/vercel/path0/pages/composite/[[name]].vue")
  },
  {
    name: "composite-new",
    path: "/composite/new",
    component: () => import("/vercel/path0/pages/composite/new.vue")
  },
  {
    name: "contacts-id-tab",
    path: "/contacts/:id()/:tab()",
    component: () => import("/vercel/path0/pages/contacts/[id]/[tab]/index.vue")
  },
  {
    name: "contacts-id-tab-service-area-zone",
    path: "/contacts/:id()/:tab()/service-area/:zone()",
    component: () => import("/vercel/path0/pages/contacts/[id]/[tab]/service-area/[zone].vue")
  },
  {
    name: "contacts-id-tab-service-area",
    path: "/contacts/:id()/:tab()/service-area",
    component: () => import("/vercel/path0/pages/contacts/[id]/[tab]/service-area/index.vue")
  },
  {
    name: "contacts-id",
    path: "/contacts/:id()",
    component: () => import("/vercel/path0/pages/contacts/[id]/index.vue")
  },
  {
    name: "contacts-calendar",
    path: "/contacts/calendar",
    component: () => import("/vercel/path0/pages/contacts/calendar/index.vue")
  },
  {
    name: "contacts-companies",
    path: "/contacts/companies",
    component: () => import("/vercel/path0/pages/contacts/companies.vue")
  },
  {
    name: "contacts-customers",
    path: "/contacts/customers",
    component: () => import("/vercel/path0/pages/contacts/customers.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/vercel/path0/pages/contacts/index.vue")
  },
  {
    name: "contacts-loyalty-rewards-id",
    path: "/contacts/loyalty-rewards/:id()",
    component: () => import("/vercel/path0/pages/contacts/loyalty-rewards/[id].vue")
  },
  {
    name: "contacts-loyalty-rewards",
    path: "/contacts/loyalty-rewards",
    component: () => import("/vercel/path0/pages/contacts/loyalty-rewards/index.vue")
  },
  {
    name: "contacts-new-tab",
    path: "/contacts/new/:tab()",
    component: () => import("/vercel/path0/pages/contacts/new/[tab].vue")
  },
  {
    name: "contacts-new",
    path: "/contacts/new",
    component: () => import("/vercel/path0/pages/contacts/new/index.vue")
  },
  {
    name: "contacts-old-profile-id",
    path: "/contacts/old-profile/:id()",
    component: () => import("/vercel/path0/pages/contacts/old-profile/[id].vue")
  },
  {
    name: "contacts-old-profile-new",
    path: "/contacts/old-profile/new",
    component: () => import("/vercel/path0/pages/contacts/old-profile/new.vue")
  },
  {
    name: "contacts-people",
    path: "/contacts/people",
    component: () => import("/vercel/path0/pages/contacts/people.vue")
  },
  {
    name: "contacts-staff",
    path: "/contacts/staff",
    component: () => import("/vercel/path0/pages/contacts/staff.vue")
  },
  {
    name: "counts-step-id",
    path: "/counts/:step()/:id()",
    meta: _91id_93C53tP1s1gaMeta || {},
    component: () => import("/vercel/path0/pages/counts/[step]/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "invoices-manage-id",
    path: "/invoices/manage/:id()",
    component: () => import("/vercel/path0/pages/invoices/manage/[id].vue")
  },
  {
    name: "location-tab",
    path: "/location/:tab()",
    component: () => import("/vercel/path0/pages/location/[tab].vue")
  },
  {
    name: "location",
    path: "/location",
    component: () => import("/vercel/path0/pages/location/index.vue")
  },
  {
    name: "login-screen",
    path: "/login/:screen?",
    meta: _91_91screen_93_93LfNd9wk1rxMeta || {},
    component: () => import("/vercel/path0/pages/login/[[screen]].vue")
  },
  {
    name: "login-google-calendar",
    path: "/login/google/calendar",
    component: () => import("/vercel/path0/pages/login/google/calendar.vue")
  },
  {
    name: "login-outlook-calendar",
    path: "/login/outlook/calendar",
    component: () => import("/vercel/path0/pages/login/outlook/calendar.vue")
  },
  {
    name: "management-brands",
    path: "/management/brands",
    component: () => import("/vercel/path0/pages/management/brands/index.vue")
  },
  {
    name: "management-locations-id-tab",
    path: "/management/locations/:id()/:tab()",
    component: () => import("/vercel/path0/pages/management/locations/[id]/[tab].vue")
  },
  {
    name: "management-locations-id",
    path: "/management/locations/:id()",
    component: () => import("/vercel/path0/pages/management/locations/[id]/index.vue")
  },
  {
    name: "management-locations-id-service-area-zone",
    path: "/management/locations/:id()/service-area/:zone()",
    component: () => import("/vercel/path0/pages/management/locations/[id]/service-area/[zone].vue")
  },
  {
    name: "management-locations-id-service-area",
    path: "/management/locations/:id()/service-area",
    component: () => import("/vercel/path0/pages/management/locations/[id]/service-area/index.vue")
  },
  {
    name: "management-locations",
    path: "/management/locations",
    component: () => import("/vercel/path0/pages/management/locations/index.vue")
  },
  {
    name: "management-locations-new-tab",
    path: "/management/locations/new/:tab()",
    component: () => import("/vercel/path0/pages/management/locations/new/[tab].vue")
  },
  {
    name: "management-locations-new",
    path: "/management/locations/new",
    component: () => import("/vercel/path0/pages/management/locations/new/index.vue")
  },
  {
    name: "management-locations-settings-tab",
    path: "/management/locations/settings/:tab()",
    component: () => import("/vercel/path0/pages/management/locations/settings/[tab].vue")
  },
  {
    name: "management-locations-settings",
    path: "/management/locations/settings",
    component: () => import("/vercel/path0/pages/management/locations/settings/index.vue")
  },
  {
    name: "management-settings",
    path: "/management/settings",
    component: () => import("/vercel/path0/pages/management/settings/index.vue")
  },
  {
    name: "old-location-id",
    path: "/old-location/:id()",
    component: () => import("/vercel/path0/pages/old-location/[id].vue")
  },
  {
    name: "onboarding-type-name",
    path: "/onboarding/:type()/:name()",
    meta: _91name_93Wh9EhoTX6mMeta || {},
    component: () => import("/vercel/path0/pages/onboarding/[type]/[name].vue")
  },
  {
    name: "onboarding-type-error",
    path: "/onboarding/:type()/error",
    meta: errorQyRSvkH3ijMeta || {},
    component: () => import("/vercel/path0/pages/onboarding/[type]/error.vue")
  },
  {
    name: "onboarding-type",
    path: "/onboarding/:type()",
    meta: indexygltuEsvXiMeta || {},
    component: () => import("/vercel/path0/pages/onboarding/[type]/index.vue")
  },
  {
    name: "onboarding-type-success",
    path: "/onboarding/:type()/success",
    meta: successtfY2mKVrJvMeta || {},
    component: () => import("/vercel/path0/pages/onboarding/[type]/success.vue")
  },
  {
    name: "onboarding-privacy-policy",
    path: "/onboarding/privacy-policy",
    meta: privacy_45policytUDvwwC5AtMeta || {},
    component: () => import("/vercel/path0/pages/onboarding/privacy-policy.vue")
  },
  {
    name: "onboarding-terms-and-conditions",
    path: "/onboarding/terms-and-conditions",
    meta: terms_45and_45conditionslv8lSbgrzOMeta || {},
    component: () => import("/vercel/path0/pages/onboarding/terms-and-conditions.vue")
  },
  {
    name: "ordering",
    path: "/ordering",
    component: () => import("/vercel/path0/pages/ordering/index.vue")
  },
  {
    name: "orders-allocations",
    path: "/orders/allocations",
    component: () => import("/vercel/path0/pages/orders/allocations.vue")
  },
  {
    name: "orders-fulfilment",
    path: "/orders/fulfilment",
    component: () => import("/vercel/path0/pages/orders/fulfilment/index.vue")
  },
  {
    name: "orders-fulfilment-manage-freight-id",
    path: "/orders/fulfilment/manage/freight/:id()",
    component: () => import("/vercel/path0/pages/orders/fulfilment/manage/freight/[id].vue")
  },
  {
    name: "orders-fulfilment-manage-run-id",
    path: "/orders/fulfilment/manage/run/:id()",
    component: () => import("/vercel/path0/pages/orders/fulfilment/manage/run/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/vercel/path0/pages/orders/index.vue")
  },
  {
    name: "orders-invoices",
    path: "/orders/invoices",
    component: () => import("/vercel/path0/pages/orders/invoices.vue")
  },
  {
    name: "orders-preparing",
    path: "/orders/preparing",
    component: () => import("/vercel/path0/pages/orders/preparing/index.vue")
  },
  {
    name: "orders-preparing-picking-id-tab",
    path: "/orders/preparing/picking/:id()/:tab()",
    meta: _91tab_9315A4VPi1flMeta || {},
    component: () => import("/vercel/path0/pages/orders/preparing/picking/[id]/[tab].vue")
  },
  {
    name: "orders-preparing-picking-id",
    path: "/orders/preparing/picking/:id()",
    meta: indexG6oMUecs8gMeta || {},
    component: () => import("/vercel/path0/pages/orders/preparing/picking/[id]/index.vue")
  },
  {
    name: "orders-schedule",
    path: "/orders/schedule",
    component: () => import("/vercel/path0/pages/orders/schedule.vue")
  },
  {
    name: "orders-statements",
    path: "/orders/statements",
    component: () => import("/vercel/path0/pages/orders/statements/index.vue")
  },
  {
    name: "orders-statements-manage-id",
    path: "/orders/statements/manage/:id()",
    component: () => import("/vercel/path0/pages/orders/statements/manage/[id].vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45reset6pLgh9teUjMeta || {},
    component: () => import("/vercel/path0/pages/password-reset.vue")
  },
  {
    name: "picker-account",
    path: "/picker-account",
    component: () => import("/vercel/path0/pages/picker-account/index.vue")
  },
  {
    name: "pos-checkout",
    path: "/pos/checkout",
    meta: index0lDih2sMnmMeta || {},
    component: () => import("/vercel/path0/pages/pos/checkout/index.vue")
  },
  {
    name: "pos-checkout-thankyou-id",
    path: "/pos/checkout/thankyou/:id?",
    meta: _91_91id_93_93yrcsB5dlK8Meta || {},
    component: () => import("/vercel/path0/pages/pos/checkout/thankyou/[[id]].vue")
  },
  {
    name: "pos-customers",
    path: "/pos/customers",
    component: () => import("/vercel/path0/pages/pos/customers.vue")
  },
  {
    name: "pos-lockscreen",
    path: "/pos/lockscreen",
    meta: lockscreenRqxUojRrq9Meta || {},
    component: () => import("/vercel/path0/pages/pos/lockscreen.vue")
  },
  {
    name: "pos-orders-customer-id",
    path: "/pos/orders/customer/:id()",
    component: () => import("/vercel/path0/pages/pos/orders/customer/[id].vue")
  },
  {
    name: "pos-orders",
    path: "/pos/orders",
    component: () => import("/vercel/path0/pages/pos/orders/index.vue")
  },
  {
    name: "pos-shop",
    path: "/pos/shop",
    meta: shopeDWfKibu3JMeta || {},
    component: () => import("/vercel/path0/pages/pos/shop.vue")
  },
  {
    name: "print-container-id-bill",
    path: "/print-container/:id()/bill",
    component: () => import("/vercel/path0/pages/print-container/[id]/bill/index.vue")
  },
  {
    name: "print-container-id-bill-print",
    path: "/print-container/:id()/bill/print",
    component: () => import("/vercel/path0/pages/print-container/[id]/bill/print.vue")
  },
  {
    name: "print-container-id-bill-receipt",
    path: "/print-container/:id()/bill/receipt",
    meta: receipt5vvGVKNaj1Meta || {},
    component: () => import("/vercel/path0/pages/print-container/[id]/bill/receipt.vue")
  },
  {
    name: "print-container-id-invoice",
    path: "/print-container/:id()/invoice",
    component: () => import("/vercel/path0/pages/print-container/[id]/invoice.vue")
  },
  {
    name: "print-container-id-receipt",
    path: "/print-container/:id()/receipt",
    meta: receiptbFEznwTHlLMeta || {},
    component: () => import("/vercel/path0/pages/print-container/[id]/receipt.vue")
  },
  {
    name: "print-container-id-statement-type",
    path: "/print-container/:id()/statement/:type()",
    component: () => import("/vercel/path0/pages/print-container/[id]/statement/[type].vue")
  },
  {
    name: "print-container-batch-deposit-id-a4",
    path: "/print-container/batch-deposit/:id()/a4",
    component: () => import("/vercel/path0/pages/print-container/batch-deposit/[id]/a4.vue")
  },
  {
    name: "print-container-batch-deposit-id-thermal",
    path: "/print-container/batch-deposit/:id()/thermal",
    component: () => import("/vercel/path0/pages/print-container/batch-deposit/[id]/thermal.vue")
  },
  {
    name: "print-container-confirmation-id",
    path: "/print-container/confirmation/:id()",
    meta: indexNnLklspdVuMeta || {},
    component: () => import("/vercel/path0/pages/print-container/confirmation/[id]/index.vue")
  },
  {
    name: "print-container-invoice-id-a4",
    path: "/print-container/invoice/:id()/a4",
    component: () => import("/vercel/path0/pages/print-container/invoice/[id]/a4.vue")
  },
  {
    name: "print-container-invoice-id-thermal",
    path: "/print-container/invoice/:id()/thermal",
    component: () => import("/vercel/path0/pages/print-container/invoice/[id]/thermal.vue")
  },
  {
    name: "print-container-manifest-id",
    path: "/print-container/manifest/:id()",
    meta: _91id_93o0CKF7B8jYMeta || {},
    component: () => import("/vercel/path0/pages/print-container/manifest/[id].vue")
  },
  {
    name: "print-container-order-forms-id",
    path: "/print-container/order-forms/:id()",
    meta: _91id_93Mfu3JYjJIGMeta || {},
    component: () => import("/vercel/path0/pages/print-container/order-forms/[id].vue")
  },
  {
    name: "print-container-order-forms-barcode-id",
    path: "/print-container/order-forms/barcode/:id()",
    meta: _91id_93JezMOXzUX7Meta || {},
    component: () => import("/vercel/path0/pages/print-container/order-forms/barcode/[id].vue")
  },
  {
    name: "print-container-picking-id",
    path: "/print-container/picking/:id()",
    meta: _91id_93pUpwhyy8QLMeta || {},
    component: () => import("/vercel/path0/pages/print-container/picking/[id].vue")
  },
  {
    name: "print-container-preparation-id-a4",
    path: "/print-container/preparation/:id()/a4",
    component: () => import("/vercel/path0/pages/print-container/preparation/[id]/a4.vue")
  },
  {
    name: "print-container-preparation-id-thermal",
    path: "/print-container/preparation/:id()/thermal",
    component: () => import("/vercel/path0/pages/print-container/preparation/[id]/thermal.vue")
  },
  {
    name: "print-container-statements-id",
    path: "/print-container/statements/:id()",
    meta: _91id_93wYpdbd6IBuMeta || {},
    component: () => import("/vercel/path0/pages/print-container/statements/[id].vue")
  },
  {
    name: "print-container-summary-id-a4",
    path: "/print-container/summary/:id()/a4",
    component: () => import("/vercel/path0/pages/print-container/summary/[id]/a4.vue")
  },
  {
    name: "print-container-summary-id-thermal",
    path: "/print-container/summary/:id()/thermal",
    component: () => import("/vercel/path0/pages/print-container/summary/[id]/thermal.vue")
  },
  {
    name: "print-id-type-report",
    path: "/print/:id()/:type()/:report?",
    meta: _91_91report_93_93htk8KNpwMPMeta || {},
    component: () => import("/vercel/path0/pages/print/[id]/[type]/[[report]].vue")
  },
  {
    name: "product-name",
    path: "/product/:name?",
    component: () => import("/vercel/path0/pages/product/[[name]].vue")
  },
  {
    name: "product-new",
    path: "/product/new",
    component: () => import("/vercel/path0/pages/product/new.vue")
  },
  {
    name: "products-composites",
    path: "/products/composites",
    component: () => import("/vercel/path0/pages/products/composites.vue")
  },
  {
    name: "products-counts",
    path: "/products/counts",
    component: () => import("/vercel/path0/pages/products/counts.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/vercel/path0/pages/products/index.vue")
  },
  {
    name: "products-inventory",
    path: "/products/inventory",
    component: () => import("/vercel/path0/pages/products/inventory.vue")
  },
  {
    name: "products-price-lists",
    path: "/products/price-lists",
    component: () => import("/vercel/path0/pages/products/price-lists.vue")
  },
  {
    name: "products-services-inventory-id-tab",
    path: "/products/services-inventory/:id()/:tab()",
    component: () => import("/vercel/path0/pages/products/services-inventory/[id]/[tab].vue")
  },
  {
    name: "products-services-inventory-id",
    path: "/products/services-inventory/:id()",
    component: () => import("/vercel/path0/pages/products/services-inventory/[id]/index.vue")
  },
  {
    name: "products-services-inventory",
    path: "/products/services-inventory",
    component: () => import("/vercel/path0/pages/products/services-inventory/index.vue")
  },
  {
    name: "products-services",
    path: "/products/services",
    component: () => import("/vercel/path0/pages/products/services.vue")
  },
  {
    name: "products-settings-tab",
    path: "/products/settings/:tab()",
    component: () => import("/vercel/path0/pages/products/settings/[tab].vue")
  },
  {
    name: "products-settings",
    path: "/products/settings",
    component: () => import("/vercel/path0/pages/products/settings/index.vue")
  },
  {
    name: "profile-tab",
    path: "/profile/:tab()",
    component: () => import("/vercel/path0/pages/profile/[tab].vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/vercel/path0/pages/profile/index.vue")
  },
  {
    name: "profile-service-area-zone",
    path: "/profile/service-area/:zone()",
    component: () => import("/vercel/path0/pages/profile/service-area/[zone].vue")
  },
  {
    name: "profile-service-area",
    path: "/profile/service-area",
    component: () => import("/vercel/path0/pages/profile/service-area/index.vue")
  },
  {
    name: "purchase-order-pay-by-link-id",
    path: "/purchase-order/pay-by-link/:id()",
    component: () => import("/vercel/path0/pages/purchase-order/pay-by-link/[id]/index.vue")
  },
  {
    name: "purchase-order-pay-by-link-id-pay",
    path: "/purchase-order/pay-by-link/:id()/pay",
    component: () => import("/vercel/path0/pages/purchase-order/pay-by-link/[id]/pay.vue")
  },
  {
    name: "purchase-order-pay-by-link-id-payment-completed",
    path: "/purchase-order/pay-by-link/:id()/payment-completed",
    component: () => import("/vercel/path0/pages/purchase-order/pay-by-link/[id]/payment-completed.vue")
  },
  {
    name: "purchase-orders-bills",
    path: "/purchase-orders/bills",
    component: () => import("/vercel/path0/pages/purchase-orders/bills.vue")
  },
  {
    name: "purchase-orders",
    path: "/purchase-orders",
    component: () => import("/vercel/path0/pages/purchase-orders/index.vue")
  },
  {
    name: "purchase-orders-manage-id",
    path: "/purchase-orders/manage/:id()",
    component: () => import("/vercel/path0/pages/purchase-orders/manage/[id].vue")
  },
  {
    name: "purchase-orders-new",
    path: "/purchase-orders/new",
    component: () => import("/vercel/path0/pages/purchase-orders/new.vue")
  },
  {
    name: "purchase-orders-purchase-overview",
    path: "/purchase-orders/purchase-overview",
    component: () => import("/vercel/path0/pages/purchase-orders/purchase-overview/index.vue")
  },
  {
    name: "purchase-orders-purchase-overview-manage-id",
    path: "/purchase-orders/purchase-overview/manage/:id()",
    component: () => import("/vercel/path0/pages/purchase-orders/purchase-overview/manage/[id].vue")
  },
  {
    name: "purchase-orders-receiving-dispatch-id",
    path: "/purchase-orders/receiving/dispatch/:id()",
    component: () => import("/vercel/path0/pages/purchase-orders/receiving/dispatch/[id].vue")
  },
  {
    name: "purchase-orders-receiving",
    path: "/purchase-orders/receiving",
    component: () => import("/vercel/path0/pages/purchase-orders/receiving/index.vue")
  },
  {
    name: "receivables-mode-id",
    path: "/receivables/:mode()/:id()",
    component: () => import("/vercel/path0/pages/receivables/[mode]/[id].vue")
  },
  {
    name: "receivables",
    path: "/receivables",
    component: () => import("/vercel/path0/pages/receivables/index.vue")
  },
  {
    name: "reports-analytics-tab",
    path: "/reports/analytics/:tab()",
    component: () => import("/vercel/path0/pages/reports/analytics/[tab].vue")
  },
  {
    name: "reports-analytics",
    path: "/reports/analytics",
    component: () => import("/vercel/path0/pages/reports/analytics/index.vue")
  },
  {
    name: "reports-costs-tab",
    path: "/reports/costs/:tab()",
    component: () => import("/vercel/path0/pages/reports/costs/[tab].vue")
  },
  {
    name: "reports-costs",
    path: "/reports/costs",
    component: () => import("/vercel/path0/pages/reports/costs/index.vue")
  },
  {
    name: "reports-products-tab",
    path: "/reports/products/:tab()",
    component: () => import("/vercel/path0/pages/reports/products/[tab].vue")
  },
  {
    name: "reports-products",
    path: "/reports/products",
    component: () => import("/vercel/path0/pages/reports/products/index.vue")
  },
  {
    name: "reports-revenue-tab",
    path: "/reports/revenue/:tab()",
    component: () => import("/vercel/path0/pages/reports/revenue/[tab].vue")
  },
  {
    name: "reports-revenue",
    path: "/reports/revenue",
    component: () => import("/vercel/path0/pages/reports/revenue/index.vue")
  },
  {
    name: "review",
    path: "/review",
    component: () => import("/vercel/path0/pages/review.vue")
  },
  {
    name: "service-name",
    path: "/service/:name?",
    component: () => import("/vercel/path0/pages/service/[[name]].vue")
  },
  {
    name: "service-new",
    path: "/service/new",
    component: () => import("/vercel/path0/pages/service/new.vue")
  },
  {
    name: "single-order-mode-id",
    path: "/single-order/:mode()/:id()",
    component: () => import("/vercel/path0/pages/single-order/[mode]/[id].vue")
  },
  {
    name: "single-order-pay-by-link-id",
    path: "/single-order/pay-by-link/:id()",
    component: () => import("/vercel/path0/pages/single-order/pay-by-link/[id]/index.vue")
  },
  {
    name: "single-order-pay-by-link-id-pay",
    path: "/single-order/pay-by-link/:id()/pay",
    component: () => import("/vercel/path0/pages/single-order/pay-by-link/[id]/pay.vue")
  },
  {
    name: "single-order-pay-by-link-id-payment-completed",
    path: "/single-order/pay-by-link/:id()/payment-completed",
    component: () => import("/vercel/path0/pages/single-order/pay-by-link/[id]/payment-completed.vue")
  },
  {
    name: "table-ordering",
    path: "/table-ordering",
    component: () => import("/vercel/path0/pages/table-ordering.vue")
  },
  {
    name: "taxonomies",
    path: "/taxonomies",
    component: () => import("/vercel/path0/pages/taxonomies/index.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/pages/test.vue")
  },
  {
    name: "tiptap",
    path: "/tiptap",
    meta: tiptapyO6Qw3dCUQMeta || {},
    component: () => import("/vercel/path0/pages/tiptap.vue")
  },
  {
    name: "transfers",
    path: "/transfers",
    component: () => import("/vercel/path0/pages/transfers.vue")
  },
  {
    name: "units-overview-manage-id",
    path: "/units-overview/manage/:id()",
    component: () => import("/vercel/path0/pages/units-overview/manage/[id].vue")
  },
  {
    name: "user-tab",
    path: "/user/:tab()",
    component: () => import("/vercel/path0/pages/user/[tab].vue")
  },
  {
    name: "user",
    path: "/user",
    component: () => import("/vercel/path0/pages/user/index.vue")
  },
  {
    name: "user-orders",
    path: "/user/orders",
    component: () => import("/vercel/path0/pages/user/orders.vue")
  }
]