import { useSettingsStore } from "~/store/settings";
import { ToastType } from "~/types/general";

export const useGoogleAddress = async (latitude, longitude) => {
  if (!latitude || !longitude) {
    useToast(
      {
        message: "We can't determine your geolocation!",
        submessage: "Please check your geolocation settings and try again.",
      },
      {
        type: ToastType.INFO,
        duration: 3000,
      }
    );

    return;
  }

  const apiKey =
    useSettingsStore().settings?.integrations?.google_maps?.key ||
    process.env.NUXT_PUBLIC_GOOGLE_API_KEY;

  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response?.json();

    if (data?.results && data?.results?.length > 0) {
      return data?.results?.[0];
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};
