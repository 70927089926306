<template>
  <div class="o-switch">
    <label class="o-switch__slider-content">
      <input
        class="o-switch__input"
        type="checkbox"
        :checked="value"
        :disabled="disabled"
        :name="name"
        :value="value"
        @change="toggle"
      />
      <span
        class="o-switch__slider o-switch__slider--radius"
        :class="{
          'o-switch__slider--checked': isChecked && !disabled,
          'o-switch__slider--disabled': disabled,
          'to-edit__no-checked': toEdit && !value,
        }"
      ></span>
    </label>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  value: Boolean,
  name: String,
  disabled: Boolean,
  toEdit: { type: Boolean, default: false },
});

const emit = defineEmits(["toggle"]);

const isChecked = ref(props.value);

function toggle(e) {
  emit("toggle", e.target.checked);
  isChecked.value = e.target.checked;
}
</script>

<style lang="scss">
.o-switch {
  @apply flex items-center;

  &__slider-content {
    @apply relative inline-block w-x8 h-22px;
  }

  &__before {
    @apply mr-x3;
  }

  &__after {
    @apply ml-x3;
  }

  &__input {
    @apply hidden;
  }

  &__slider {
    @apply absolute cursor-pointer top-0 left-0 bottom-0 right-0 bg-white border transition-colors duration-500;

    &::before {
      @apply absolute h-3 w-3 left-[4px] bottom-[4.5px] transition-colors bg-grey-200 duration-500;
      content: "";
    }

    &--checked::before {
      @apply bg-grey-200;
    }
    &--disabled::before {
      @apply bg-white;
    }

    &--radius {
      @apply rounded-[34px];

      &::before {
        @apply rounded-half;
      }
    }
  }

  &__input:checked + &__slider {
    @apply bg-accent-color;

    &--primary {
      @apply bg-primary;
    }
  }
  &__input:disabled + &__slider {
    @apply bg-primary-light border-primary-light;

    &--primary {
      @apply bg-white;
    }
  }

  &__input:checked + &__slider::before {
    @apply translate-x-[18px];
  }
  &__input:disabled + &__slider::before {
    @apply translate-x-[18px];
  }
}

.to-edit__no-checked::before {
  transform: translateX(1px) !important;
}
</style>
