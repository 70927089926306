const posRoutes = [
  useRoutesNames().posShop,
  useRoutesNames().posOrders,
  useRoutesNames().tableOrdering,
  useRoutesNames().posCustomers,
  useRoutesNames().posCheckout,
  useRoutesNames().posCheckoutThankyouId,
  useRoutesNames().printIdTypeReport,
] as string[];

export const useGetToken = () => {
  const {
    public: { isElectron },
  } = useRuntimeConfig();

  const route = import.meta.client ? useRoute() : null;
  const isPosRoute = posRoutes.includes(route?.name as string);

  const getToken = (key: string) =>
    isElectron ? localStorage.getItem(key) : useCookie(key)?.value;

  const primaryToken = getToken("token");
  const secondaryToken = getToken("token-secondary");

  return isPosRoute && secondaryToken ? secondaryToken : primaryToken;
};
