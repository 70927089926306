import { CustomerShort } from "./customers";
import { OrderType, SidebarPrefix, SyncStatus } from "./general";
import {Order, XeroInvoiceStatus} from "./orders";
import { PaymentMethodGateway } from "./payment";
import { StoreShort } from "./stores";

export interface Invoice {
  id: number;
  type: OrderType;
  customer: CustomerShort;
  payment_method: {
    id: number;
    name: string;
    gateway: PaymentMethodGateway;
  };
  status: InvoiceStatus;
  created_at: string;
  invoice_due_date: string;
  invoice_number: number;
  paid_value: string;
  total_value: string;
  sync_status: SyncStatus;
  store: StoreShort;
  balance: number;
  reference: string;
}

export interface InvoiceSingle extends Invoice {
  order: Order;
  xero_invoice: null | number;
  sync_status: SyncStatus
}

export enum InvoiceStatus {
  ACTIVE = "active",
  AWAITING_PAYMENT = "awaiting_payment",
  PAID = "paid",
  OVERDUE = "overdue",
  DRAFT = "draft",
  ARCHIVE = "archived",
}

export enum InvoicePaymentStatus {
  UNPAID = "unpaid",
  CREDIT = "credit",
  REFUNDED = "refunded",
  GIFTED = "gifted",
  PAID = "paid",
}
export enum InvoiceDateTypes {
  CREATION = "creation",
  TARGET = "target",
}

export enum InvoiceSync {
  PENDING = "pending",
  ERROR = "error",
  SYNCED = "synced",
}

export const SidebarPage = {
  INVOICE_BATCH_SYNC: `${SidebarPrefix.INVOICES}-batch-sync`,
  INVOICE_FILTERS: `${SidebarPrefix.INVOICES}-filters`,
};
