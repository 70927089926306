import { defineNuxtPlugin } from "#app";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { useSettingsStore } from "~/store/settings";

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { googleApiKey },
  } = useRuntimeConfig();

  nuxtApp.vueApp.use(VueGoogleMaps, {
    load: {
      key:
        useSettingsStore().settings?.integrations?.google_maps?.key ||
        googleApiKey,
      libraries: "places",
    },
  });
});
