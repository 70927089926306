import { defineNuxtPlugin } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
  const clearScrollPositions = () => {
    localStorage.removeItem("scrollPositions");
  };

  if (import.meta.client) {
    window.addEventListener("beforeunload", clearScrollPositions);
  }

  nuxtApp.hook("page:finish", async (a) => {
    const ps = document.querySelector(".ps") as HTMLElement;

    const route = useRoute();

    if (ps) {
      const scrollPositions = JSON.parse(
        localStorage.getItem("scrollPositions") || "{}"
      );
      const prevScrollPosition = scrollPositions[route.fullPath] || 0;
      ps.scrollTop = prevScrollPosition;
    }
  });
});
