const getTimeUntilMidnight = (): number => {
  const now = new Date();
  const midnight = new Date(now);
  midnight.setHours(24, 0, 0, 0);
  return midnight.getTime() - now.getTime();
};

export const useMidnightUpdate = (
  callback?: (date: Date) => void
): { currentDate: Ref<Date> } => {
  const currentDate: Ref<Date> = ref(new Date());
  let intervalId: number | undefined;
  let timeoutId: number | undefined;

  const updateDate = (): void => {
    const newDate = new Date();
    currentDate.value = newDate;

    if (!callback) return;

    if (typeof callback !== "function") {
      console.error("Callback is not a function:", callback);

      return;
    }

    callback(newDate);
  };

  onMounted((): void => {
    // set timeout to trigger update at the next midnight
    timeoutId = setTimeout((): void => {
      updateDate();
      // set interval to update date every 24 hours after the first midnight update
      intervalId = setInterval(
        updateDate,
        24 * 60 * 60 * 1000
      ) as unknown as number;
    }, getTimeUntilMidnight()) as unknown as number;
  });

  onUnmounted((): void => {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId as unknown as number);
    }
    if (intervalId !== undefined) {
      clearInterval(intervalId);
    }
  });

  return { currentDate };
};
