<template>
  <div
    data-modal-open="true"
    class="fixed inset-0 overflow-y-auto flex items-center justify-center bg-modal-layout z-[1000] cursor-pointer"
    @click="emit('close')"
  >
    <div @click.stop="">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(["close"]);
</script>
