import { defineStore } from "pinia";
import {
  Settings,
  SettingsManage,
  Currency,
  TaxesMethod,
  SalesMethod,
  PublicSettings,
  PosType,
} from "~/types/settings";
import {
  Image,
  Media,
  Document,
  ToastType,
  ResponseType,
} from "~/types/general";
import { PaperFormat } from "~/types/reports";

export const useSettingsStore = defineStore("settings", () => {
  // State
  const editMode = ref(false);
  const settings = ref<Settings>({} as Settings);
  const editingSettings = ref<SettingsManage>({} as SettingsManage);
  const publicSettings = ref<PublicSettings>({} as PublicSettings);
  const barcodes_csv = ref("");
  const barcodes = ref<Document | string>({} as Document | string);
  const accountImage = ref<Image>({} as Image);
  const logo = ref<Image | Media>({} as Image | Media);
  const logoHTML = ref("");
  const favIcon = ref<Image | Media>({} as Image | Media);
  const loginLogo = ref<Image | Media>({} as Image | Media);
  const contrastLogo = ref<Image | Media>({} as Image | Media);
  const emailLogo = ref<Image | Media>({} as Media);
  const logoEmailHTML = ref("");
  const isOpenSidebar = ref(false);
  const currencyOptions = ref([
    {
      name: "Australian Dollar ($)",
      value: Currency.AUD,
    },
    {
      name: "United States Dollar ($)",
      value: Currency.USD,
    },
  ]);

  // Getters
  const taxesMethod = computed(
    (): TaxesMethod | boolean =>
      settings.value?.taxes?.enabled && settings.value?.taxes?.method
  );
  const salesMethod = computed(
    (): SalesMethod => settings.value?.pos?.sales_method
  );
  const posType = computed((): PosType => settings.value?.general?.pos_type);
  const metaTitle = computed(
    (): string =>
      publicSettings.value?.general?.meta_title ||
      settings.value?.general?.meta_title
  );
  const homepage = computed((): string => settings.value?.general?.home_page);
  const termsAndConditions = computed(
    (): string =>
      publicSettings.value?.onboarding?.terms_of_service ??
      settings.value?.onboarding?.terms_of_service
  );
  const defaultPaperFormat = computed(
    (): PaperFormat => settings.value?.order?.print_defaults?.default_paper_type
  );

  // Settings Actions
  async function getSettings(): Promise<ResponseType<Settings>> {
    try {
      const response = (await useVaniloApi(
        "/settings"
      )) as ResponseType<Settings>;

      settings.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getPublicSettings(): Promise<ResponseType<PublicSettings>> {
    try {
      const response = (await useVaniloApi(
        "/settings/public"
      )) as ResponseType<PublicSettings>;

      publicSettings.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateSettings(
    payload?: object
  ): Promise<ResponseType<Settings>> {
    const { order, integrations, ...rest } = editingSettings.value;
    const { xero, ...restIntegrations } = integrations;
    const { invoices, bills, ...restXero } = xero;
    const { business, hide_order_status, ...restOrderSettings } = order;

    try {
      const response = (await useVaniloApi("/settings", {
        method: "POST",
        body: payload || {
          ...rest,
          order: { ...restOrderSettings },
          integrations: {
            ...restIntegrations,
            xero: {
              ...restXero,
              invoices: {
                ...(invoices.account_mapping && {
                  account_mapping: { ...invoices.account_mapping },
                }),
                ...(invoices.integration_settings && {
                  integration_settings: { ...invoices.integration_settings },
                }),
                account: {
                  draft_status: invoices?.account?.draft_status || false,
                  inventory_item: invoices?.account?.inventory_item || false,
                  ...(invoices?.account?.invoice_prefix?.length && {
                    invoice_prefix: invoices.account.invoice_prefix,
                  }),
                  ...(invoices?.account?.sequence_number && {
                    sequence_number: invoices.account.sequence_number,
                  }),
                  order_with_zero_totals:
                    invoices?.account?.order_with_zero_totals || false,
                  use_alphabetical_order:
                    invoices?.account?.use_alphabetical_order || false,
                },
              },
              bills: {
                ...(bills.account_mapping && {
                  account_mapping: { ...bills.account_mapping },
                }),
                ...(bills.integration_settings && {
                  integration_settings: { ...bills.integration_settings },
                }),
                account: {
                  draft_status: bills?.account?.draft_status || false,
                  inventory_item: bills?.account?.inventory_item || false,
                  ...(bills?.account?.invoice_prefix?.length && {
                    invoice_prefix: bills.account.invoice_prefix,
                  }),
                  ...(bills?.account?.sequence_number && {
                    sequence_number: bills.account.sequence_number,
                  }),
                  order_with_zero_totals:
                    bills?.account?.order_with_zero_totals || false,
                  use_alphabetical_order:
                    bills?.account?.use_alphabetical_order || false,
                },
              },
            },
          },
        },
      })) as ResponseType<Settings>;

      settings.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function sendTestEmail(email: string) {
    try {
      const response = await useVaniloApi("/settings/test-email", {
        method: "POST",
        body: { email },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function sentTestSms(phone: string) {
    try {
      const response = await useVaniloApi("/settings/test-sms", {
        method: "POST",
        body: { phone },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateWelcomeMessage(body) {
    try {
      const response = await useVaniloApi("/settings/post-welcome-message", {
        method: "POST",
        body,
      });

      useToast(
        {
          message: "Yay! Successfully saved!",
        },
        {
          type: ToastType.SUCCESS,
          duration: 3000,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Account Image Actions
  async function updateAccountImage(): Promise<ResponseType<Media>> {
    const formData = new FormData();
    formData.append("media", accountImage.value.media);

    try {
      const response = (await useVaniloApi(`/settings/account-image`, {
        method: "POST",
        body: formData,
      })) as ResponseType<Media>;

      accountImage.value.media = response?.data.url;
      accountImage.value.title = response?.data.title;
      accountImage.value.description = response?.data.description;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteAccountImage() {
    try {
      const response = await useVaniloApi(`/settings/account-image`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Logo Actions
  async function updateLogo() {
    const formData = new FormData();
    formData.append("media", (logo.value as Image).media);
    formData.append("title", logo.value.title);

    try {
      const response = (await useVaniloApi(`/settings/print-logo`, {
        method: "POST",
        body: formData,
      })) as ResponseType<Image | Media>;

      logo.value = response?.data;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function downloadLogo() {
    try {
      const response = await useVaniloApi(
        `/media/download/${settings.value.printing.logo.id}`
      );

      logoHTML.value = await response?.text();

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteLogo() {
    try {
      const response = await useVaniloApi(`/settings/print-logo`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Email Logo Actions
  async function updateEmailLogo() {
    const formData = new FormData();
    formData.append("media", (emailLogo.value as Image).media);
    formData.append("title", emailLogo.value.title);

    try {
      const response = (await useVaniloApi(`/settings/email-logo`, {
        method: "POST",
        body: formData,
      })) as ResponseType<Image | Media>;

      emailLogo.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function downloadEmailLogo() {
    try {
      const response = await useVaniloApi(
        `/media/download/${settings.value.emailing.logo.id}`
      );

      logoEmailHTML.value = await response?.text();

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteEmailLogo() {
    try {
      const response = await useVaniloApi(`/settings/email-logo`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Fav Icon Actions
  async function updateFavIcon() {
    const formData = new FormData();
    formData.append("media", (favIcon.value as Image).media);
    formData.append("title", favIcon.value.title);

    try {
      const response = (await useVaniloApi(`/settings/fav-icon`, {
        method: "POST",
        body: formData,
      })) as ResponseType<Image | Media>;

      favIcon.value = response?.data;

      return response;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
  }

  async function deleteFavIcon() {
    try {
      const response = await useVaniloApi(`/settings/fav-icon`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Login Logo Actions
  async function updateLoginLogo() {
    const formData = new FormData();
    formData.append("media", (loginLogo.value as Image).media);
    formData.append("title", loginLogo.value.title);

    try {
      const response = await useVaniloApi(`/settings/login-logo`, {
        method: "POST",
        body: formData,
      });

      loginLogo.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteLoginLogo() {
    try {
      const response = await useVaniloApi(`/settings/login-logo`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Contrast Logo Actions
  async function updateContrastLogo() {
    const formData = new FormData();
    formData.append("media", (contrastLogo.value as Image).media);
    formData.append("title", contrastLogo.value.title);

    try {
      const response = await useVaniloApi(`/settings/contrast-logo`, {
        method: "POST",
        body: formData,
      });

      contrastLogo.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteContrastLogo() {
    try {
      const response = await useVaniloApi(`/settings/contrast-logo`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Barcode Actions
  async function getBarcodes() {
    try {
      const response = (await useVaniloApi(`/barcodes/csv`)) as any;

      const csvData = new Blob([response as BlobPart], { type: "text/csv" });
      const csvUrl = URL.createObjectURL(csvData);

      barcodes_csv.value = csvUrl;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function uploadBarcode() {
    const formData = new FormData();
    formData.append("file", (barcodes.value as Document).file);

    try {
      const response = (await useVaniloApi(`/barcodes`, {
        method: "POST",
        body: formData,
      })) as any;

      let csvContent = "data:text/csv;charset=utf-8,";

      for (let i = 0; i < response?.data.length; i++) {
        csvContent += response?.data[0];
      }
      const encodedUri = encodeURI(csvContent);

      barcodes.value = encodedUri;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    // State
    editMode,
    settings,
    editingSettings,
    publicSettings,
    barcodes_csv,
    barcodes,
    accountImage,
    logo,
    logoHTML,
    favIcon,
    loginLogo,
    contrastLogo,
    emailLogo,
    logoEmailHTML,
    isOpenSidebar,
    currencyOptions,

    // Getters
    taxesMethod,
    salesMethod,
    posType,
    metaTitle,
    homepage,
    termsAndConditions,
    defaultPaperFormat,

    // Actions
    getSettings,
    getPublicSettings,
    updateSettings,
    sendTestEmail,
    sentTestSms,
    updateWelcomeMessage,
    updateAccountImage,
    deleteAccountImage,
    updateLogo,
    downloadLogo,
    deleteLogo,
    updateEmailLogo,
    downloadEmailLogo,
    deleteEmailLogo,
    updateFavIcon,
    deleteFavIcon,
    updateLoginLogo,
    deleteLoginLogo,
    updateContrastLogo,
    deleteContrastLogo,
    getBarcodes,
    uploadBarcode,
  };
});
