import { defineStore } from "pinia";
import {
  RequestParams,
  Option,
  ResponseType,
  Filters,
  TabSectionModuleType,
  TabSectionType,
  TabType,
} from "~/types/general";
import {
  ServiceInventoryItem,
  InventoryImporting,
  ServicesInventoryStatus,
  ServiceInventoryFormated,
  TravelCostMethod,
  TravelCostAddress,
} from "~/types/inventory";
import { useProductsStore } from "./products";
import { ref, reactive, computed } from "vue";

// Services Inventory Store
export const useServicesInventoryStore = defineStore(
  "services-inventory",
  () => {
    // State

    // Inventory List
    const inventoryList = ref<ServiceInventoryItem[]>([]);
    const page = ref(1);
    const perPage = ref(40);
    const meta = ref<RequestParams>({});
    const totalItems = ref<number | null>(null);
    const lastPage = ref<number | null>(null);
    const isLoading = ref(false);
    const editMode = ref(false);
    const profileSearch = ref("");
    const previousTab = ref(null);
    const image = ref<any>({});

    // Lists
    const statusList = ref<Option[]>([
      { name: "Available", value: ServicesInventoryStatus.AVAILABLE },
      { name: "Not Available", value: ServicesInventoryStatus.NOT_AVAILABLE },
      { name: "Draft", value: ServicesInventoryStatus.DRAFT },
    ]);

    const travelCostMethods = ref<Option[]>([
      {
        name: "Monash Modified Model",
        value: TravelCostMethod.MONASH_MODIFIED_MODEL,
      },
      { name: "Travel Distance (km)", value: TravelCostMethod.DISTANCE },
      {
        name: "Travel Costs Included",
        value: TravelCostMethod.INCLUDED_TRAVEL_COSTS,
      },
    ]);

    const travelCostAddressTypes = ref<Option[]>([
      { name: "Team Member’s Address", value: TravelCostAddress.MEMBER },
      { name: "Primary Work Location", value: TravelCostAddress.LOCATION },
    ]);

    const editingProfileServiceInventory = ref<ServiceInventoryFormated>(
      {} as ServiceInventoryFormated
    );
    const viewingProfileServiceInventory = ref<ServiceInventoryItem>(
      {} as ServiceInventoryItem
    );
    const importingInventory = ref<InventoryImporting>(
      {} as InventoryImporting
    );

    // Sidebar
    const isOpenSidebar = ref(false);

    // Filters
    const filters = reactive<Filters>({
      taxonomies_categories: {
        type: "taxonomies_categories",
        title: "Category",
        options: [],
        chosenOptions: [],
      },
      status: {
        type: "status",
        title: " Status",
        options: [],
        chosenOptions: [],
      },
      types: {
        type: "types",
        title: "Type",
        options: useProductsStore().serviceAvailabilityTypes,
        chosenOptions: [],
      },
      taxonomies_tags: {
        type: "taxonomies_tags",
        title: "Tag",
        options: [],
        chosenOptions: [],
      },
      tag: {
        type: "tag",
        title: "Inventory Tag",
        options: [],
        chosenOptions: [],
      },
      price: {
        type: "price",
        title: "Price",
        options: [
          { name: "Price From", value: 0 },
          { name: "Price To", value: 0 },
        ],
        chosenOptions: [
          { name: "Price From", value: 0 },
          { name: "Price To", value: 0 },
        ],
        isApplied: false,
      },
    });

    // Table
    const pageTable = ref(1);
    const perPageTable = ref(10);

    // Profile
    const tabs = ref([
      {
        name: "Service",
        tab: null,
        types: null,
        subtypes: null,
        forNew: true,
        sections: [
          {
            section: TabSectionType.DETAILS,
            types: null,
            isAction: true,
            noDivider: false,
            forNew: true,
            title: "Details",
            subtitle:
              "Manage a location profile update important information, review operating hours and service areas and manage important settings.",
            keywords: "details",
          },
          {
            section: TabSectionType.GENERAL_INFO,
            types: null,
            isAction: false,
            noDivider: true,
            forNew: true,
            title: "General Information",
            subtitle:
              "Insert the description of your service package here. This would outline the description of the service, reporting and/or assessments provided, including the indicative number of hours you allocate to each, as part of this package.",
            keywords:
              "general information inventory status hourly rate minimum hours offered description tag",
            modules: [
              {
                module: TabSectionModuleType.SERVICE_INVENTORY_INFO,
                types: null,
                keywords:
                  "general information inventory status hourly rate minimum hours offered description",
              },
              {
                module: TabSectionModuleType.INVENTORY_TAG,
                types: null,
                keywords: "general information tag",
              },
            ],
          },
          {
            section: TabSectionType.ORGANISE_AND_CLASSIFY,
            types: null,
            isAction: false,
            noDivider: true,
            forNew: true,
            title: "Organise and Classify",
            subtitle:
              "Add a personal description, organise the location with categories and tags. This information is shared with platform admins and managers.",
            keywords:
              "organise and classify categories item numbers and classification age range",
            modules: [
              {
                module: TabSectionModuleType.PRODUCT_CATEGORIES,
                types: null,
                keywords: "organise and classify categories",
              },
              {
                module: TabSectionModuleType.ITEM_NUMBERS,
                types: null,
                keywords:
                  "organise and classify item numbers and classification",
              },
              {
                module: TabSectionModuleType.AGE_RANGE,
                types: null,
                keywords: "organise and classify age range",
              },
            ],
          },
          {
            section: TabSectionType.AVAILABILITY,
            types: null,
            isAction: false,
            noDivider: true,
            forNew: true,
            title: "Availability",
            subtitle:
              "Set service delivery options, these options are used to help users search and find applicable services.",
            keywords: "availability service delivery options service options",
            modules: [
              {
                module: TabSectionModuleType.SERVICE_OPTIONS,
                types: null,
                keywords:
                  "availability service delivery options service options",
              },
            ],
          },
        ],
      },
      {
        name: "Team Members",
        tab: TabType.TEAM_MEMBERS,
        types: null,
        subtypes: null,
        forNew: false,
        keywords: "team members assign team members staff",
      },
      {
        name: "Travel Costs",
        tab: TabType.TRAVEL_COSTS,
        types: null,
        subtypes: null,
        forNew: false,
        keywords: "travel costs",
        sections: [
          {
            section: TabSectionType.COST_CALCULATION,
            types: null,
            isAction: false,
            noDivider: false,
            forNew: true,
            title: "Cost Calculation Method",
            subtitle:
              "Applied to mobile services, choose from Travel Distance (cost per km) or the Monash Modified Model. Calculate the cost based on departure location. Team members can set their custom preferred originating address.",
            keywords: "travel costs cost calculation method",
            modules: [
              {
                module: TabSectionModuleType.COST_CALCULATION,
                types: null,
                keywords: "travel costs cost calculation method",
              },
            ],
          },
        ],
      },
    ]);

    // Actions

    // Inventory List Actions
    async function getServiceInventoryList(
      payload?
    ): Promise<ResponseType<ServiceInventoryItem[]>> {
      let response: any;

      const params = {
        page: page.value,
        limit: perPage.value,
        ...(payload && { ...payload }),
      };

      try {
        response = await useVaniloApi("/service-inventories", { params });
      } catch (error) {
        useErrorNotifications(error);
        console.log(error);
      }

      return response;
    }

    async function getServiceInventory(
      id,
      params?
    ): Promise<ResponseType<ServiceInventoryItem[]>> {
      let response: any;

      try {
        response = await useVaniloApi(`/service-inventories/${id}`, {
          params,
          noStoreFilter: true,
        });
      } catch (error) {
        useErrorNotifications(error);
        console.log(error);
      }

      return response;
    }

    async function updateServiceInventory(inventoryId: number, payload: any) {
      let response: any;

      try {
        response = await useVaniloApi(`/service-inventories/${inventoryId}`, {
          method: "POST",
          body: payload,
        });
      } catch (error) {
        useErrorNotifications(error);
      }

      inventoryList.value = inventoryList.value.map((item) =>
        item.id === response?.data.id ? response?.data : item
      );

      return response;
    }

    async function deleteServiceInventory(id: number | string) {
      let response: any;

      try {
        response = await useVaniloApi(`/service-inventories/${id}`, {
          method: "DELETE",
        });

        inventoryList.value = inventoryList.value.filter(
          (item) => item.id !== +id
        );
      } catch (error) {
        useErrorNotifications(error);
        console.log(error);
      }

      return response;
    }

    // Inventory Images Actions
    async function updateInventoryImages(id, image?) {
      let response: any;

      let formData = new FormData();

      formData.append("media", image?.media);
      formData.append("title", image?.title);

      try {
        response = await useVaniloApi(`/service-inventories/${id}/gallery`, {
          method: "POST",
          body: formData,
        });
      } catch (error) {
        useErrorNotifications(error);
        console.log(error);
      }
      return response;
    }

    async function deleteInventoryImage(id) {
      let response: any;
      try {
        response = await useVaniloApi(`/service-inventories/gallery/${id}`, {
          method: "DELETE",
        });
      } catch (error) {
        useErrorNotifications(error);
        console.log(error);
      }

      return response;
    }

    // Bulk Actions
    async function bulkInventoryUpdate(inventoriesIds, updatesValue) {
      let response: any;
      let payload = {};

      if (updatesValue.type === "change-status") {
        payload["status"] = updatesValue.value;
      }

      if (updatesValue.type === "change-tag") {
        payload["tag_id"] = updatesValue.value;
      }

      try {
        response = await useVaniloApi(`/service-inventories/bulk-update`, {
          method: "POST",
          body: {
            ...(inventoriesIds && { ids: inventoriesIds }),
            ...payload,
          },
        });
      } catch (error) {
        useErrorNotifications(error);
        console.log(error);
      }
      return response;
    }

    async function bulkInventoryUpdatePrice(payload) {
      let response: any;
      const body = payload;

      try {
        response = await useVaniloApi(
          `/service-inventories/bulk-update-price`,
          {
            method: "POST",
            body,
          }
        );
      } catch (error) {
        useErrorNotifications(error);
      }

      return response;
    }

    async function bulkCreateInventories(payload) {
      let response: any;

      const body = payload;

      try {
        response = await useVaniloApi(`/service-inventories/bulk-create`, {
          method: "POST",
          body,
        });
      } catch (error) {
        useErrorNotifications(error);
      }

      return response;
    }

    return {
      inventoryList,
      page,
      perPage,
      meta,
      totalItems,
      lastPage,
      isLoading,
      editMode,
      profileSearch,
      previousTab,
      image,
      statusList,
      travelCostMethods,
      travelCostAddressTypes,
      editingProfileServiceInventory,
      viewingProfileServiceInventory,
      importingInventory,
      isOpenSidebar,
      filters,
      pageTable,
      perPageTable,
      tabs,
      getServiceInventoryList,
      getServiceInventory,
      updateServiceInventory,
      deleteServiceInventory,
      updateInventoryImages,
      deleteInventoryImage,
      bulkInventoryUpdate,
      bulkInventoryUpdatePrice,
      bulkCreateInventories,
    };
  }
);
