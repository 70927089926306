// Pinia Setup Store
import { defineStore } from "pinia";
import { useUsersStore } from "./users";

export const usePusherStore = defineStore("pusher", () => {
  // Pusher and Channels
  const pusher = ref(null);
  const channelPrivateDataProcessing = ref(null);
  const channelReports = ref(null);
  const channelNotifications = ref(null);
  const channelOrderActions = ref(null);
  const channelErrors = ref(null);
  const channelPrivateXeroInvoice = ref(null);
  const channelTables = ref(null);

  const usersStore = useUsersStore();

  const channelNameReports = computed(
    () => `private-reports.${usersStore.currentUser?.id?.toString()}`
  );
  const channelNamePrivateDataProcessing = computed(
    () => `private-data-processing.${usersStore.currentUser?.id?.toString()}`
  );
  const channelNameNotifications = computed(() => "private-notifications");
  const channelNameOrderActions = computed(
    () => `private-actions.${usersStore.currentUser?.id?.toString()}`
  );
  const channelNameErrors = computed(
    () => `private-data-error.${usersStore.currentUser?.id?.toString()}`
  );
  const channelNameTables = computed(() => "tables");

  // Order and Event Data
  const orderId = ref(null);
  const dataEvent = ref(null);

  const channelNamePrivateXeroInvoice = computed(
    () => `private-xero-invoice.${orderId.value?.toString()}`
  );

  return {
    // Pusher and Channels
    pusher,
    channelPrivateDataProcessing,
    channelReports,
    channelNotifications,
    channelOrderActions,
    channelErrors,
    channelPrivateXeroInvoice,
    channelTables,
    channelNameReports,
    channelNamePrivateDataProcessing,
    channelNameNotifications,
    channelNameOrderActions,
    channelNameErrors,
    channelNameTables,

    // Order and Event Data
    orderId,
    dataEvent,
    channelNamePrivateXeroInvoice,
  };
});
