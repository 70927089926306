import { defineStore } from "pinia";

import {
  Option,
  ResponseType,
  ToastType,
  Document,
  Media,
} from "~/types/general";
import {
  OrderActionItem,
  OrderActionStatus,
  OrderActionManage,
  OrderActionNotification,
} from "~/types/orders-actions";

export const useOrdersActionsStore = defineStore("orders-actions", () => {
  // State
  const isNewNotifications = ref(false);
  const actions = ref<OrderActionItem[]>([]);
  const actionsList = ref<OrderActionItem[]>([]);
  const actionsNotifications = ref<OrderActionNotification[]>([]);
  const viewingAction = ref<OrderActionItem>({} as OrderActionItem);
  const files = ref<(Document | Media)[]>([]);
  const statusTags = ref<Option[]>([
    {
      name: "No Status",
      value: OrderActionStatus.NO_STATUS,
    },
    {
      name: "Review",
      value: OrderActionStatus.REVIEW,
    },
    {
      name: "Critical",
      value: OrderActionStatus.CRITICAL,
    },
    {
      name: "Done",
      value: OrderActionStatus.DONE,
    },
  ]);

  // Actions related to getting actions
  async function getAllActions(params?: {
    "sort[]"?: string;
    "filter[created-by]"?: number;
    "filter[customer][]"?: number;
  }): Promise<ResponseType<OrderActionItem[]>> {
    let response: any;
    try {
      response = await useVaniloApi(`/orders/actions`, { params });
    } catch (error) {
      console.log(error);
    }
    actions.value = response?.data;
    return response;
  }

  async function getActionsList(
    id,
    payload?
  ): Promise<ResponseType<OrderActionItem[]>> {
    let response: any;
    const params = { ...(payload && payload) };
    try {
      response = await useVaniloApi(`/orders/${id}/actions`, { params });
    } catch (error) {
      console.log(error);
    }
    actions.value = response?.data;
    return response;
  }

  async function getSingleAction(id): Promise<ResponseType<OrderActionItem>> {
    let response: any;
    try {
      response = await useVaniloApi(`/orders/actions/${id}`);
    } catch (error) {
      console.log(error);
    }
    viewingAction.value = response?.data;
    return response;
  }

  // Actions related to managing actions
  async function createAction(
    id,
    body: OrderActionManage
  ): Promise<ResponseType<OrderActionItem>> {
    let response: any;
    try {
      response = await useVaniloApi(`/orders/${id}/actions`, {
        method: "POST",
        body,
      });
    } catch (error: any) {
      console.log(error);
      useErrorNotifications(error);
    }
    return response;
  }

  async function updateAction(
    id,
    body?: OrderActionManage
  ): Promise<ResponseType<OrderActionItem>> {
    let response: any;
    try {
      response = await useVaniloApi(`/orders/actions/${id}`, {
        method: "POST",
        body,
      });
    } catch (error: any) {
      console.log(error);
      if (error) {
        Object.values(error.data.errors).forEach((error) =>
          useToast(error[0], {
            type: ToastType.ERROR,
            duration: 3000,
          })
        );
      }
    }
    return response;
  }

  async function deleteAction(id) {
    let response: any;
    try {
      response = await useVaniloApi(`/orders/actions/${id}`, {
        method: "DELETE",
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    return response;
  }

  // Actions related to documents
  async function uploadActionDocument(id) {
    let response: any;
    let formData = new FormData();
    (files.value as Document[])
      .filter((i) => i.file)
      .forEach((i) => {
        formData.append("media[]", i.file);
        formData.append("meta[][title]", i.title);
      });
    try {
      response = await useVaniloApi(`/actions/${id}/documents`, {
        method: "POST",
        body: formData,
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    return response;
  }

  async function deleteActionDocument(id) {
    let response: any;
    try {
      response = await useVaniloApi(`/actions/documents/${id}`, {
        method: "DELETE",
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    return response;
  }

  // Actions related to notifications
  async function markAsSeen(payload) {
    let response: any;
    try {
      response = await useVaniloApi(`/actions/mark-seen`, {
        method: "POST",
        body: { ...payload },
      });
    } catch (error) {
      console.log(error);
    }
    return response;
  }

  async function getNewCustomerActions(
    id
  ): Promise<ResponseType<OrderActionItem[]>> {
    let response: any;
    const params = {
      "filter[customer][]": +id,
      "filter[seen]": false,
    };
    try {
      response = await useVaniloApi(`/orders/actions`, { params });
    } catch (error) {
      console.log(error);
    }
    if (response?.data?.length > 0) {
      isNewNotifications.value = true;
    }
    return response;
  }

  return {
    isNewNotifications,
    actions,
    actionsList,
    actionsNotifications,
    viewingAction,
    files,
    statusTags,
    getAllActions,
    getActionsList,
    getSingleAction,
    createAction,
    updateAction,
    deleteAction,
    uploadActionDocument,
    deleteActionDocument,
    markAsSeen,
    getNewCustomerActions,
  };
});
