import { useSettingsStore } from "~/store/settings";
import { useUsersStore } from "~/store/users";
import { useCustomersStore } from "~/store/customers";
import { UserType } from "~/types/users";
import { useGeneralStore } from "~/store/general";
import { useAppsStore } from "~/store/apps";
import { OS } from "~/types/general";
import { PrintOrder, ReceiptTemplate } from "~/types/reports";
import { CustomerType } from "~/types/customers";
import { PosType } from "~/types/settings";

export interface Config {
  // ROLES
  isSuperadmin: boolean;
  isAdmin: boolean;
  isManager: boolean;
  hasAdminRole: boolean;
  isPicker: boolean;
  isPickerOnly: boolean;
  isClient: boolean;
  isSales: boolean;
  isSalesNotAdmin: boolean;
  isCustomerService: boolean;
  isDispatch: boolean;

  // USER CUSTOMER TYPES
  isStaff: boolean;

  // CUSTOMERS SUBTYPES
  isClinician: boolean;
  isSupportCoordinator: boolean;
  isServiceProvider: boolean;
  isProfileManager: boolean;
  isParticipant: boolean;

  // FEATURES
  isMultilocation: boolean;
  isPos: boolean;
  isOrders: boolean;
  isReporting: boolean;
  isLocationBrands: boolean;
  isAllocations: boolean;
  isCustomerProtected: boolean;

  // APPLICATION
  isPurchases: boolean;

  // POS
  isPosQuickAdd: boolean;
  isPosSearch: boolean;
  isPosKeypad: boolean;
  isPosDate: boolean;
  isPosResetTeamMember: boolean;
  isPosPin: boolean;
  isPosCashier: boolean;
  isPosQuickOrder: boolean;
  isPosVariations: boolean;
  isPosVariationSearch: boolean;
  isPosLotConfirmation: boolean;
  isPosCustomPrice: boolean;
  isPosModalSwappedInputs: boolean;
  isPosModalDollarInput: boolean;
  isPosTableOrdering: boolean;
  isPosGuestDefault: boolean;
  // type
  posType: PosType;
  isPosWholesale: boolean;
  isPosHospitality: boolean;
  isPosRetail: boolean;
  // rostering
  isPosRostering: boolean;
  isPosClocks: boolean;
  isPosScheduling: boolean;

  // LOYALTY AND REWARDS
  isLoyaltyRewards: boolean;

  // PRINT
  isPrintHideZeroAllocated: boolean;
  isPrintConfirmedUnits: boolean;
  isPrintHideTargetDate: boolean;
  isDefaultReceiptTemplate: boolean;
  isBrandedWholesaleReceiptTemplate: boolean;
  isHideSupplier: boolean;

  // ORDERS
  isSetPriceAllocation: boolean;

  // ORDER SERVICES
  isServices: boolean;

  // INVENTORY
  isCentralisedPricing: boolean;
  isLotsSupplierGrouped: boolean;

  // PRODUCTS
  isProductSecondaryName: boolean;

  // ALLOCATIONS
  isAllocationsDisplayLots: boolean;

  // PURCHASE
  isAutoReceivingStatus: boolean;

  // ONBOARDING
  isOnboarding: boolean;
  isOnboardingCompany: boolean;
  isOnboardingIndividual: boolean;

  // BOOKING
  isBookingServices: boolean;

  // INTEGRATIONS
  isPusher: boolean;
  isFacebookLogin: boolean;
  isGoogleLogin: boolean;
  isGoogleMaps: boolean;
  isMailgun: boolean;
  isTwilio: boolean;
  isXero: boolean;
  isOpenAi: boolean;

  // GENERAL
  isElectron: boolean;
  isWindows: boolean;
  isMacOS: boolean;
  isLinux: boolean;

  // COLOR
  brandColor: string;

  // EMAILING
  emailLogo: string;

  // GROUPS
  isSetDefaultGroup: boolean;

  // LOADING
  isLoading: boolean;

  // CURRENT CUSTOMER
  currentCustomerId: number;

  // CLIENTS
  isConnectorCo: boolean;
}

export const useConfig = (): Config => {
  const settingsStore = useSettingsStore();
  const usersStore = useUsersStore();
  const customersStore = useCustomersStore();
  const generalStore = useGeneralStore();
  const appsStore = useAppsStore();

  const userRoles = usersStore.currentUser?.roles;

  const adminRoles = [UserType.SUPERADMIN, UserType.ADMIN, UserType.MANAGER];

  const hasAdminRole = adminRoles.some((role) => userRoles?.includes(role));

  const notClientRoles = [
    UserType.SUPERADMIN,
    UserType.ADMIN,
    UserType.MANAGER,
    UserType.CUSTOMER_SERVICE,
    UserType.SALES,
    UserType.DISPATCH,
    UserType.FINANCE,
    UserType.ADVISOR,
    UserType.GENERAL,
  ];

  return {
    // USER ROLES
    isSuperadmin: userRoles?.includes(UserType.SUPERADMIN),
    isAdmin: userRoles?.includes(UserType.ADMIN),
    isManager: userRoles?.includes(UserType.MANAGER),
    hasAdminRole,
    isPicker: userRoles?.includes(UserType.PICKER) && !hasAdminRole,
    isPickerOnly:
      userRoles?.includes(UserType.PICKER) &&
      !hasAdminRole &&
      userRoles.length === 1,
    isClient:
      userRoles?.includes(UserType.CLIENT) &&
      !notClientRoles.some((role) => userRoles?.includes(role)),
    isSales: userRoles?.includes(UserType.SALES),
    isSalesNotAdmin: userRoles?.includes(UserType.SALES) && !hasAdminRole,
    isCustomerService:
      userRoles?.includes(UserType.CUSTOMER_SERVICE) && !hasAdminRole,
    isDispatch: userRoles?.includes(UserType.DISPATCH) && !hasAdminRole,

    // USER CUSTOMER TYPES
    isStaff: customersStore.currentUserCustomer?.type === CustomerType.STAFF,

    // CUSTOMERS SUBTYPES
    isClinician:
      customersStore.currentUserCustomer?.type === CustomerType.STAFF &&
      customersStore.currentUserCustomer?.subtypes
        ?.map((subtype) => subtype?.name)
        ?.includes("clinician"),

    isSupportCoordinator:
      customersStore.currentUserCustomer?.type === CustomerType.STAFF &&
      customersStore.currentUserCustomer?.subtypes
        ?.map((subtype) => subtype?.name)
        ?.includes("support coordinator"),

    isServiceProvider:
      customersStore.currentUserCustomer?.type === CustomerType.COMPANY &&
      customersStore.currentUserCustomer?.subtypes
        ?.map((subtype) => subtype?.name)
        ?.includes("service provider"),

    isProfileManager:
      customersStore.currentUserCustomer?.type === CustomerType.STAFF &&
      customersStore.currentUserCustomer?.subtypes
        ?.map((subtype) => subtype?.name)
        ?.includes("profile manager"),

    isParticipant:
      customersStore.currentUserCustomer?.type === CustomerType.INDIVIDUAL &&
      customersStore.currentUserCustomer?.subtypes
        ?.map((subtype) => subtype?.name)
        ?.includes("participant"),

    // FEATURES
    isMultilocation: settingsStore.settings?.general?.features?.multilocation,
    isPos: settingsStore.settings?.pos?.enabled,
    isOrders: settingsStore.settings?.general?.features?.orders,
    isReporting: settingsStore.settings?.general?.features?.reporting,
    isLocationBrands:
      settingsStore.settings?.general?.features?.location_brands,
    isAllocations: settingsStore.settings?.order?.allocations?.enabled,
    isCustomerProtected:
      settingsStore.settings?.customers?.features?.protection,

    // APPLICATION
    isPurchases: appsStore.apps?.find(
      (app) => app.link === `/${useRoutesNames().purchaseOrders}`
    )?.is_active,

    // ALLOCATIONS
    isAllocationsDisplayLots:
      settingsStore.settings?.order?.allocations?.display_lots,

    // INVENTORY
    isCentralisedPricing:
      settingsStore.settings?.inventory?.centralised_pricing,
    isLotsSupplierGrouped:
      settingsStore.settings?.inventory?.group_lots_by_supplier,

    // ORDERS
    isSetPriceAllocation: settingsStore.settings?.order?.set_price_allocation,

    // PRODUCTS
    isProductSecondaryName: settingsStore.settings?.products?.secondary_name,

    // PURCHASE
    isAutoReceivingStatus:
      settingsStore.settings?.purchase_order?.features?.auto_receiving_status,

    // POS
    isPosQuickAdd: settingsStore.settings?.pos?.quick_add,
    isPosSearch: settingsStore.settings?.pos?.search,
    isPosKeypad: settingsStore.settings?.pos?.keypad,
    isPosDate: settingsStore.settings?.pos?.date,
    isPosResetTeamMember: settingsStore.settings?.pos?.reset_team_member,
    isPosPin: settingsStore.settings?.pos?.pin,
    isPosCashier: settingsStore.settings?.pos?.cashier,
    isPosQuickOrder: settingsStore.settings?.pos?.quick_order,
    isPosVariations: settingsStore.settings?.pos?.variations,
    isPosVariationSearch: settingsStore.settings?.pos?.variation_search,
    isPosLotConfirmation: settingsStore.settings?.pos?.lot_confirmation,
    isPosCustomPrice: settingsStore.settings?.pos?.custom_price,
    isPosModalSwappedInputs:
      settingsStore.settings?.pos?.swap_price_and_quantity,
    isPosModalDollarInput: settingsStore.settings?.pos?.dollar_input,
    isPosTableOrdering: settingsStore.settings?.pos?.table_ordering,
    isPosGuestDefault: settingsStore.settings?.pos?.guest_default_customer,
    // type
    posType: settingsStore.settings?.general?.pos_type,
    isPosWholesale:
      settingsStore.settings?.general?.pos_type === PosType.WHOLESALE,
    isPosHospitality:
      settingsStore.settings?.general?.pos_type === PosType.HOSPITALITY,
    isPosRetail: settingsStore.settings?.general?.pos_type === PosType.RETAIL,
    // rostering
    isPosRostering: settingsStore.settings?.rostering?.enabled,
    isPosClocks: settingsStore.settings?.rostering?.clocks,
    isPosScheduling: settingsStore.settings?.rostering?.scheduling,

    // LOYALTY AND REWARDS
    isLoyaltyRewards: settingsStore.settings?.loyalty_reward?.enabled,

    // ORDER SERVICES
    isServices: settingsStore.settings?.order?.services?.enabled,

    // PRINT
    isPrintHideZeroAllocated:
      settingsStore.settings?.order?.print_defaults?.hide_zero_allocated,
    isPrintConfirmedUnits:
      settingsStore.settings?.order?.print_defaults?.confirmed_units,
    isPrintHideTargetDate:
      settingsStore.settings?.order?.print_defaults?.hide_target_date,
    isDefaultReceiptTemplate:
      settingsStore.settings?.order?.print_defaults?.receipt_template_style ===
      ReceiptTemplate.DEFAULT,
    isBrandedWholesaleReceiptTemplate:
      settingsStore.settings?.order?.print_defaults?.receipt_template_style ===
      ReceiptTemplate.BRANDED_WHOLESALE,
    isHideSupplier:
      settingsStore.settings?.order?.print_defaults?.hide_supplier,
    isPrintOrderDesc:
      settingsStore.settings?.order?.print_defaults?.print_order ===
      PrintOrder.DESC,

    // ONBOARDING
    isOnboarding:
      settingsStore.settings?.onboarding?.enabled ??
      settingsStore.publicSettings?.onboarding?.enabled,
    isOnboardingCompany:
      settingsStore.settings?.onboarding?.company?.enabled ??
      settingsStore.publicSettings?.onboarding?.company?.enabled,
    isOnboardingIndividual:
      settingsStore.settings?.onboarding?.individual?.enabled ??
      settingsStore.publicSettings?.onboarding?.individual?.enabled,

    // BOOKING
    isBookingServices:
      settingsStore.settings?.order?.booking_services?.enabled ??
      settingsStore.publicSettings?.order?.booking_services?.enabled,

    // INTEGRATIONS
    isPusher: settingsStore.settings?.integrations?.pusher?.enabled,
    isFacebookLogin:
      settingsStore.settings?.integrations?.facebook_login?.enabled ??
      settingsStore.publicSettings?.integrations?.facebook_login?.enabled,
    isGoogleLogin:
      settingsStore.settings?.integrations?.google_login?.enabled ??
      settingsStore.publicSettings?.integrations?.google_login?.enabled,
    isGoogleMaps:
      settingsStore.settings?.integrations?.google_maps?.enabled ??
      settingsStore.publicSettings?.integrations?.google_maps?.enabled,
    isMailgun:
      settingsStore.settings?.integrations?.mailgun?.enabled ??
      settingsStore.publicSettings?.integrations?.mailgun?.enabled,
    isTwilio:
      settingsStore.settings?.integrations?.twilio?.enabled ??
      settingsStore.publicSettings?.integrations?.twilio?.enabled,
    isXero:
      settingsStore.settings?.integrations?.xero?.enabled ??
      settingsStore.publicSettings?.integrations?.xero?.enabled,
    isOpenAi:
      settingsStore.settings?.integrations?.openai?.enabled ??
      settingsStore.publicSettings?.integrations?.openai?.enabled,

    // GENERAL
    isElectron: !!useRuntimeConfig().public?.isElectron,
    isWindows: generalStore.os === OS.WINDOWS,
    isMacOS: generalStore.os === OS.MACOS,
    isLinux: generalStore.os === OS.LINUX,

    // COLOR
    brandColor:
      settingsStore.settings?.general?.brand_color ??
      settingsStore.publicSettings?.general?.brand_color,

    // EMAILING
    emailLogo: settingsStore.settings?.emailing?.logo?.url,

    // GROUPS
    isSetDefaultGroup: settingsStore.settings?.customers?.set_default_group,

    // LOADING
    isLoading: generalStore.isLoading,

    // CURRENT CUSTOMER
    currentCustomerId: useCustomersStore().currentUserCustomer?.id,

    // CLIENTS
    isConnectorCo: useRuntimeConfig().public?.baseURL?.includes("connector"),
  };
};
