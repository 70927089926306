import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import index_45global from "/vercel/path0/middleware/index.global.ts";
import router_45global from "/vercel/path0/middleware/router.global.ts";
import scroll_45position_45save_45global from "/vercel/path0/middleware/scrollPositionSave.global.ts";
export const globalMiddleware = [
  validate,
  index_45global,
  router_45global,
  scroll_45position_45save_45global
]
export const namedMiddleware = {}