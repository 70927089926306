import { DBSchema } from "idb";
import { Store } from "./stores";
import { PosTile } from "./pos";
import { Product, ProductPos } from "./products";
import { InventorySingle } from "./inventory";
import { CustomerSingle } from "./customers";

export interface RequestPayload<S extends IndexedDBStore> {
  store: IndexedDBStore;
  endpoint?: IndexedDBStore | string;
  force?: boolean;
  noFetchMain?: boolean;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  headers?: Record<string, string>;
  params?: Record<string, string | number | boolean>; // main backend filters params
  filters?: Record<string, string | number | boolean>; // bff filters (by index)
  page?: number;
  limit?: number;
  body?: StoreValue<S>;
  // bff search
  search?: {
    term: string;
    fields: string[];
    filterFn?: (item: StoreValue<S>) => boolean;
  };
}

export interface RequestPayloadItem extends RequestPayload<IndexedDBStore> {
  id: string | number;
}

export interface ResponseList<T> {
  data: T[];
  meta: {
    page: number;
    total_pages: number;
    total_items: number;
  };
}

// IndexedDB
export enum IDBTransactionMode {
  READONLY = "readonly",
  READWRITE = "readwrite",
  VERSIONCHANGE = "versionchange",
}

export enum IndexedDBStore {
  LOCATIONS = "stores",
  POS_TILES = "pos-tiles",
  POS_TILES_CUSTOMER = "pos-tiles-customer",
  POS_TILES_LOCATION = "pos-tiles-location",
  POS_PRODUCTS = "pos-products",
  PRODUCTS = "products",
  INVENTORY = "inventory",
  CUSTOMERS = "customers",
}

export interface IndexRule {
  name: string;
  keyPath: string | string[];
  options: { unique?: boolean; multiEntry?: boolean };
}

// schema for IndexedDB
export interface ElectronDBSchema extends DBSchema {
  [IndexedDBStore.LOCATIONS]: {
    key: string;
    value: Store;
  };
  [IndexedDBStore.POS_TILES]: {
    key: string;
    value: PosTile;
  };
  [IndexedDBStore.POS_TILES_CUSTOMER]: {
    key: string;
    value: PosTile;
  };
  [IndexedDBStore.POS_TILES_LOCATION]: {
    key: string;
    value: PosTile;
  };
  [IndexedDBStore.POS_PRODUCTS]: {
    key: string;
    value: ProductPos;
  };
  [IndexedDBStore.PRODUCTS]: {
    key: string;
    value: Product;
  };
  [IndexedDBStore.INVENTORY]: {
    key: string;
    value: InventorySingle;
  };
  [IndexedDBStore.CUSTOMERS]: {
    key: string;
    value: CustomerSingle;
  };
}

export type StoreValue<S extends IndexedDBStore> = ElectronDBSchema[S]["value"];

// indexes for IndexedDB
export const IndexRules: Partial<Record<IndexedDBStore, IndexRule[]>> = {
  [IndexedDBStore.INVENTORY]: [
    {
      name: "parent_id",
      keyPath: "product.parent_id",
      options: { unique: false },
    },
    {
      name: "product_id",
      keyPath: "product.id",
      options: { unique: false },
    },
    {
      name: "sku",
      keyPath: "product.sku",
      options: { unique: false },
    },
    {
      name: "alias_skus",
      keyPath: "alias_skus",
      options: { unique: false, multiEntry: true },
    },
  ],
  [IndexedDBStore.PRODUCTS]: [
    {
      name: "taxonomy_ids",
      keyPath: "taxonomy_ids",
      options: { unique: false, multiEntry: true },
    },
    {
      name: "type",
      keyPath: "type",
      options: { unique: false },
    },
  ],
  [IndexedDBStore.CUSTOMERS]: [
    {
      name: "type",
      keyPath: "type",
      options: { unique: false },
    },
  ],
};

// data preprocessing
export type DataPreprocessFunction = (data: any) => any;

export const DataPreprocessRules: Partial<
  Record<IndexedDBStore, DataPreprocessFunction>
> = {
  [IndexedDBStore.PRODUCTS]: (data: any) => {
    // check if data is an array and apply the logic accordingly
    if (Array.isArray(data)) {
      return data.map((product) => ({
        ...product,
        taxonomy_ids: product.taxonomies.flatMap((taxonomy) =>
          taxonomy.taxons.flatMap((taxon) => [
            taxon.id,
            ...(taxon.parents ? taxon.parents.map((parent) => parent.id) : []),
          ])
        ),
      }));
    } else {
      // if data is a single item, apply the preprocessing logic directly
      const product = data;
      return {
        ...product,
        taxonomy_ids: product.taxonomies.flatMap((taxonomy) =>
          taxonomy.taxons.flatMap((taxon) => [
            taxon.id,
            ...(taxon.parents ? taxon.parents.map((parent) => parent.id) : []),
          ])
        ),
      };
    }
  },
  [IndexedDBStore.INVENTORY]: (data: any) => {
    if (Array.isArray(data)) {
      return data.map((inventory) => ({
        ...inventory,
        alias_skus: inventory.product.alias_barcodes.map(
          (barcode) => barcode.alias_sku
        ),
      }));
    } else {
      const inventory = data;
      return {
        ...inventory,
        alias_skus: inventory.product.alias_barcodes.map(
          (barcode) => barcode.alias_sku
        ),
      };
    }
  },
};
