export enum AuthScreen {
  EMAIL = "email",
  FORGOT_PASSWORD = "forgot-password",
  CHECK_EMAIL = "check-email",
  SUCCESSFUL_RESET = "successful-reset",
  VERIFY = "verify",
  FACEBOOK = "facebook",
  GOOGLE = "google",
  ELECTRON_BASE_URL = "electron-base-url",
}
