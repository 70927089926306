import { defineStore } from "pinia";
import { ToastType, ResponseType } from "~/types/general";
import {
  ChargeMethod,
  PaymentMethodGateway,
  PaymentMethodOption,
  PaymentMethods,
  PayMultipleRes,
  Split,
  SplitPaymentMode,
  StripeSettings,
  StripeStatus,
  Totals,
  TotalsPayload,
} from "~/types/payment";
import { Register, Terminal } from "~/types/stores";
import { usePosStore } from "./pos";
import { useStoresStore } from "./stores";
import { Order, OrderInvoice, OrderItem, OrderStatus } from "~/types/orders";
import { useOrdersStore } from "./orders";

export const usePaymentStore = defineStore("payment", () => {
  const posStore = usePosStore();
  const ordersStore = useOrdersStore();

  // PAYMENT SETTINGS
  const paymentMethods = ref<PaymentMethods>({} as PaymentMethods);
  const editingPaymentMethods = ref<PaymentMethods>({} as PaymentMethods);
  const viewingInvoice = ref<OrderInvoice>({} as OrderInvoice);
  const paymentDate = ref<string>();
  const chargeMethods = ref([
    {
      name: "Destination",
      value: ChargeMethod.DESTINATION,
    },
    {
      name: "Transfers",
      value: ChargeMethod.TRANSFERS,
    },
  ]);

  const paymentMethodsList = computed<PaymentMethodOption[]>(() =>
    Object.values(paymentMethods.value)?.map((pm) => ({
      name: pm.name,
      value: pm.gateway,
      order_status: pm.order_status,
      id: pm.id,
    }))
  );

  const paymentMethodsPosList = computed<PaymentMethodOption[]>(() =>
    Object.values(paymentMethods.value)
      ?.filter((pm) => pm.is_enabled)
      ?.filter((pm) => pm.show_on_checkout)
      ?.filter((pm) => pm.gateway !== PaymentMethodGateway.STRIPE)
      ?.map((pm) => ({
        name: pm.name,
        value: pm.gateway,
        order_status: pm.order_status,
        id: pm.id,
      }))
  );

  const paymentMethodsListFiltered = computed<PaymentMethodOption[]>(() =>
    Object.values(paymentMethods.value)
      ?.filter((pm) => pm.is_enabled)
      ?.filter((pm) => pm.show_on_checkout)
      ?.map((pm) => ({
        name: pm.name,
        value: pm.gateway,
        order_status: pm.order_status,
        id: pm.id,
      }))
  );

  async function getPaymentMethods(): Promise<ResponseType<PaymentMethods>> {
    try {
      const response = (await useVaniloApi(
        "/payment-methods"
      )) as ResponseType<PaymentMethods>;

      paymentMethods.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updatePaymentMethods() {
    // deleting invalid fields
    // for (const prop in this.editingPaymentMethods) {
    //   if (
    //     prop !== "stripe" &&
    //     Object.values(this.editingPaymentMethods[prop]).some(
    //       (field) => !field || (Array.isArray(field) && !field.length)
    //     )
    //   ) {
    //     delete this.editingPaymentMethods[prop];
    //   }

    // deleting em.valuepty Stripe Settings values
    !(editingPaymentMethods.value.stripe as StripeSettings).stores.length &&
      delete (editingPaymentMethods.value.stripe as StripeSettings).stores;

    if (editingPaymentMethods.value?.stripe?.order_status === null) {
      delete editingPaymentMethods.value?.stripe?.order_status;
    }

    if (!("custom_stripe_client" in editingPaymentMethods.value.stripe)) {
      (
        editingPaymentMethods.value.stripe as StripeSettings
      ).custom_stripe_client = false;
    }
    if (!("order_status" in editingPaymentMethods.value.stripe)) {
      (editingPaymentMethods.value.stripe as StripeSettings).order_status =
        OrderStatus.COMPLETED;
    }

    try {
      const response = (await useVaniloApi("/payment-methods", {
        method: "POST",
        body: editingPaymentMethods.value,
      })) as ResponseType<PaymentMethods>;

      paymentMethods.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // STRIPE
  const url = ref(
    "https://us-central1-jaffle-stripe-terminal-365411.cloudfunctions.net/app"
  );
  const terminals = ref<Terminal[]>([]);
  const registers = ref<Register[]>([]);
  const activeTerminal = ref<Terminal>({} as Terminal);
  const readerId = ref("tmr_E0SwtAFuytqMEu");
  const paymentIntent = ref("");
  const paymentId = ref("");
  const stripeStatus = ref<StripeStatus>({} as StripeStatus);
  const pusher = ref(null);
  const pusherChannel = ref(null);
  const paymentEvent = ref(null);

  const activePaymentMethod = ref<PaymentMethodGateway | null>(null);

  const channelName = computed(
    () => `private-pos.terminal.${activeTerminal.value?.id}`
  );

  async function getTerminals(storeId: number) {
    try {
      const response = await useStoresStore().getSingleStore(storeId);

      registers.value = response?.data?.registers;
      terminals.value = response?.data?.registers
        .filter((register) => register.terminal)
        .map((register) => register.terminal) as Terminal[];

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getStripeStatus() {
    try {
      const response = (await useVaniloApi("/stripe-connect")) as any;

      stripeStatus.value = response;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getStripeStatusStore(storeId: number) {
    try {
      const response = await useVaniloApi(`/stripe-connect/store/${storeId}`);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function connectStripe() {
    try {
      const response = await useVaniloApi("/stripe-connect", {
        method: "POST",
        body: {
          use_oauth: true,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function connectStripeStore(storeId: number) {
    try {
      const response = await useVaniloApi(`/stripe-connect/store/${storeId}`, {
        method: "POST",
        body: {
          use_oauth: true,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function disconnectStripe() {
    try {
      const response = (await useVaniloApi("/stripe-connect", {
        method: "DELETE",
      })) as any;

      stripeStatus.value = response;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function disconnectStripeStore(storeId: number) {
    try {
      const response = await useVaniloApi(`/stripe-connect/store/${storeId}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function cancelPayment() {
    try {
      const response = await useVaniloApi(
        `/payments/${paymentId.value}/cancel`,
        {
          method: "POST",
        }
      );

      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  // PAY BY LINK
  async function getPayByLink(id, token?, deposit?) {
    try {
      const response = await useVaniloApi(
        `/orders/${id}/pay-by-link?token=${encodeURIComponent(token)}${
          deposit ? `&payment_amount=${deposit}` : ""
        }`
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function postPayByLink(id, token, depositAmount?) {
    try {
      const response = await useVaniloApi(
        `/orders/${id}/pay-by-link?token=${encodeURIComponent(token)}`,
        {
          method: "POST",
          body: {
            ...(depositAmount && { amount: +depositAmount }),
          },
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // SPLIT PAYMENT
  const isSplitPayment = ref(false);
  const splitMode = ref<SplitPaymentMode>(SplitPaymentMode.AMOUNT);
  // split options
  const splitOptions = ref([
    { name: "1/2", value: Split.HALF },
    { name: "1/3", value: Split.THIRD },
    { name: "1/4", value: Split.FOURTH },
    { name: "Custom", value: Split.CUSTOM },
  ]);
  const selectedAmountSplit = ref(null);
  const isSelectedAmountSplit = ref(false);
  // split payment methods
  const splitPmOptions = ref([
    { name: "Card", value: PaymentMethodGateway.IN_PERSON },
    { name: "Link", value: PaymentMethodGateway.SEND_LINK },
    { name: "Cash", value: PaymentMethodGateway.CASH },
  ]);
  const selectedSplitPm = ref<PaymentMethodGateway>(null);
  // variables
  const orderTotalSplit = ref(null);
  const remainingSplit = ref(null);
  const isProcessingSplit = ref(false);
  const isCompletedSplit = ref(false);
  const splitPaymentCounter = ref(0);
  const eachCustomerPays = computed(
    () => orderTotalSplit.value / (selectedAmountSplit.value ?? 1)
  );
  const customSplitAmount = ref(0);
  // items mode
  const selectedSplitItems = ref<OrderItem[]>([]);
  const customerPaysSplit = ref("0.00");
  const customerPaysSplitCash = ref("0.00");
  const isInitialCurrencyClick = ref(true);
  const splitOrder = ref<Order>({} as Order);
  const paidSplitOrders = ref<Order[]>([]);
  const isCartEqualsItems = ref(false);

  async function paySplitAmount() {
    useIsLoading(true);
    try {
      const amount =
        selectedAmountSplit.value === Split.CUSTOM
          ? +customSplitAmount.value
          : +eachCustomerPays.value;

      if (!amount) return;

      isSelectedAmountSplit.value = true;

      // change order status
      if (
        [
          OrderStatus.PROCESSING,
          OrderStatus.DRAFT,
          OrderStatus.PREPARING,
        ].includes(posStore.creatingOrder.status) &&
        posStore.creatingOrder.id &&
        posStore.cart?.length
      ) {
        const res = await ordersStore.editOrder(posStore.creatingOrder.id, {
          type: posStore.creatingOrder.type,
          store_id: posStore.creatingOrder.store_id,
          status: posStore.creatingOrder.table_id
            ? OrderStatus.PREPARING
            : OrderStatus.PENDING,
        });

        posStore.creatingOrder.status = res.data.status;
      }

      // process payment
      await processPayment({
        orderId: posStore.creatingOrder.id,
        paymentMethod: selectedSplitPm.value,
        readerId: activeTerminal.value?.id || localStorage.getItem("terminal"),
        ...(selectedAmountSplit.value - splitPaymentCounter.value !== 1 && {
          amount,
        }),
      });

      // if payment method is Card show info message
      if (selectedSplitPm.value === PaymentMethodGateway.IN_PERSON) {
        isProcessingSplit.value = true;

        useToast("Please complete the transaction by card", {
          type: ToastType.INFO,
          duration: 3000,
        });

        return;
      }

      handleSplitAmountSucceed();
    } catch (error) {
      handleSplitAmountDeclined();
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function paySplitItems({
    items,
    pm,
  }: {
    items: number[];
    pm: PaymentMethodGateway;
  }) {
    if (!selectedSplitPm.value) {
      useToast("Please select a payment method", {
        type: ToastType.INFO,
        duration: 3000,
      });
      return;
    }

    useIsLoading(true);
    try {
      let orderId = posStore.creatingOrder.id;
      isCartEqualsItems.value = posStore.cart.length === items.length;

      if (!isCartEqualsItems.value) {
        const res = (await useVaniloApi(`/orders/${orderId}/split`, {
          method: "POST",
          body: {
            items,
          },
        })) as {
          base_order: Order;
          split_order: Order;
        };

        // update orderId to split order id
        orderId = res.split_order.id;

        posStore.viewingOrder = res.base_order;
        posStore.totals = res.base_order;
        posStore.cart = res.base_order.items;

        splitOrder.value = res.split_order;
        paidSplitOrders.value.push(res.split_order);
      } else {
        const res = await ordersStore.editOrder(orderId, {
          type: posStore.creatingOrder.type,
          store_id: posStore.creatingOrder.store_id,
          status: posStore.creatingOrder.table_id
            ? OrderStatus.PREPARING
            : OrderStatus.PENDING,
        });

        posStore.viewingOrder = res.data;
        posStore.totals = res.data;
        paidSplitOrders.value.push(res.data);
      }

      // process payment
      await processPayment({
        orderId,
        paymentMethod: pm,
        readerId: activeTerminal.value?.id || localStorage.getItem("terminal"),
      });

      // if payment method is Card show info message
      if (selectedSplitPm.value === PaymentMethodGateway.IN_PERSON) {
        isProcessingSplit.value = true;

        useToast("Please complete the transaction by card", {
          type: ToastType.INFO,
          duration: 3000,
        });
      }

      if (pm !== PaymentMethodGateway.IN_PERSON) {
        // reset split items values
        splitOrder.value = {} as Order;
        selectedSplitPm.value = null;
        customerPaysSplit.value = "0.00";
        customerPaysSplitCash.value = "0.00";
        selectedSplitItems.value = [];

        if (isCartEqualsItems.value) {
          isCompletedSplit.value = true;
          isProcessingSplit.value = false;

          posStore.completeOrder({
            isPrintInvoice: false,
            isPrintPicklist: false,
            status: OrderStatus.COMPLETED,
          });
        }
      }
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  function resetSplitPaymentValues() {
    selectedAmountSplit.value = null;
    isSelectedAmountSplit.value = false;
    selectedSplitPm.value = null;
    orderTotalSplit.value = null;
    remainingSplit.value = null;
    isProcessingSplit.value = false;
    isCompletedSplit.value = false;
    splitPaymentCounter.value = 0;
    customSplitAmount.value = 0;
    selectedSplitItems.value = [];
    customerPaysSplit.value = "0.00";
    customerPaysSplitCash.value = "0.00";
    isInitialCurrencyClick.value = true;
  }

  // HANDLE SPLIT PAYMENT
  function handleSplitSucceed(data: any) {
    if (paymentId.value !== data.payment_id) return;

    if (splitMode.value === SplitPaymentMode.AMOUNT) {
      handleSplitAmountSucceed(data);
    } else {
      handleSplitItemsSucceed(data);
    }
  }
  function handleSplitDeclined(data: any) {
    if (paymentId.value !== data.payment_id) return;

    if (splitMode.value === SplitPaymentMode.AMOUNT) {
      handleSplitAmountDeclined(data);
    } else {
      handleSplitItemsDeclined(data);
    }
  }
  // AMOUNT
  function handleSplitAmountSucceed(data?: any) {
    // update remaining amount
    remainingSplit.value -=
      selectedAmountSplit.value === Split.CUSTOM
        ? +customSplitAmount.value
        : +eachCustomerPays.value;

    // increment split payment counter
    if (
      selectedAmountSplit.value !== Split.CUSTOM &&
      splitPaymentCounter.value <= selectedAmountSplit.value
    ) {
      splitPaymentCounter.value++;
    }

    if (selectedAmountSplit.value === Split.CUSTOM) {
      orderTotalSplit.value -= +customSplitAmount.value;
    }

    isProcessingSplit.value = false;
    selectedSplitPm.value = null;

    useToast("The payment is successful", {
      type: ToastType.SUCCESS,
      duration: 3000,
    });

    if (selectedAmountSplit.value === splitPaymentCounter.value) {
      isCompletedSplit.value = true;
      isSelectedAmountSplit.value = false;

      posStore.completeOrder({
        isPrintInvoice: false,
        isPrintPicklist: false,
        status: OrderStatus.COMPLETED,
      });
    }
  }
  function handleSplitAmountDeclined(data?: any) {
    isProcessingSplit.value = false;

    useToast("The payment is declined", {
      type: ToastType.ERROR,
      duration: 3000,
    });
  }
  // ITEMS
  function handleSplitItemsSucceed(data: any) {
    isProcessingSplit.value = false;

    splitOrder.value = {} as Order;
    selectedSplitItems.value = [];
    selectedSplitPm.value = null;
    customerPaysSplit.value = "0.00";

    useToast("The payment is successful", {
      type: ToastType.SUCCESS,
      duration: 3000,
    });

    if (isCartEqualsItems.value) {
      isCompletedSplit.value = true;

      posStore.completeOrder({
        isPrintInvoice: false,
        isPrintPicklist: false,
        status: OrderStatus.COMPLETED,
      });
    }
  }
  async function handleSplitItemsDeclined(data: any) {
    isProcessingSplit.value = false;

    useToast("The payment is declined", {
      type: ToastType.ERROR,
      duration: 3000,
    });

    mergeSplitItems();
  }

  async function mergeSplitItems() {
    useIsLoading(true);
    try {
      const res = (await useVaniloApi(
        `/orders/${posStore.creatingOrder.id}/merge/${splitOrder.value?.id}`,
        {
          method: "POST",
        }
      )) as ResponseType<Order>;

      posStore.viewingOrder = res.data;
      posStore.totals = res.data;
      posStore.cart = res.data.items;
      paidSplitOrders.value = paidSplitOrders.value.filter(
        (order) => order.id !== splitOrder.value.id
      );
      splitOrder.value = {} as Order;
    } catch (error) {
      useCatchError(error);
    }
    useIsLoading(false);
  }

  // CHECKOUT
  const customerPaying = ref<string | number>("0.00");
  const isPayed = ref(false);
  const isSucceeded = ref(false);
  const isProcessing = ref(false);
  const isConfirmed = ref(false);
  const isPartialPayment = ref(false);
  const isOpenCheckoutMenu = ref(false);
  const currencyList = [5, 10, 20, 50, 100, 250, 500, 1000];

  const totalValue = computed(() =>
    posStore.isSameCustomerPayBalance
      ? (posStore.totals.total_value || 0) + posStore.totalSelectedAccountOrders
      : posStore.selectedAccountOrders?.length
        ? posStore.totalSelectedAccountOrders
        : posStore.totals.total_value
  );

  const remaining = computed(() =>
    +customerPaying.value &&
    +customerPaying.value * 10 <= +totalValue.value * 10
      ? +totalValue.value - +customerPaying.value
      : ""
  );

  const finalCardAmount = computed(() => +remaining.value || totalValue.value);

  const balance = computed(() =>
    isSucceeded.value ? "0.00" : (+customerPaying.value).toFixed(2)
  );

  const change = computed(() =>
    +customerPaying.value * 10 <= +totalValue.value * 10
      ? ""
      : +customerPaying.value - +totalValue.value
  );

  async function calculateTotals(
    body: TotalsPayload
  ): Promise<ResponseType<Totals>> {
    try {
      const response = await useVaniloApi("/orders/calculate", {
        method: "POST",
        body,
      });

      return response as ResponseType<Totals>;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function processPayment({
    orderId,
    date,
    paymentMethod,
    readerId,
    amount,
  }: {
    orderId: number;
    paymentMethod: PaymentMethodGateway;
    readerId?: number | string;
    date?: string;
    amount?: number | string;
  }) {
    const pm = await useGetPaymentMethod(paymentMethod);

    try {
      const response = await useVaniloApi(`/orders/${orderId}/pay`, {
        method: "POST",
        body: {
          payment_method_id: pm.id,
          ...(paymentMethod === PaymentMethodGateway.IN_PERSON && {
            reader_id: readerId || terminals.value?.[0]?.id,
          }),
          ...(amount && { amount }),
          ...(date && { date }),
        },
      });

      const { result } = response as any;

      if (paymentMethod === PaymentMethodGateway.IN_PERSON) {
        paymentIntent.value = result.intent_id;
        paymentId.value = result.payment_id;

        const terminal = terminals.value.find(
          (terminal) => terminal.id === result.terminal_id
        );

        useToast(
          `Processing payment for $${result.amount.toFixed(2)} on terminal ${
            terminal.name
          }`,
          {
            type: ToastType.INFO,
            duration: 3000,
          }
        );
      }

      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function processPaymentMultiple({
    orders,
    paymentMethod,
    customerId,
    managerId,
    readerId,
    amount,
    date,
    isPO,
  }: {
    orders: string[] | number[];
    paymentMethod: PaymentMethodGateway;
    customerId?: number | string;
    managerId?: number | string;
    readerId?: number | string;
    amount?: number | string;
    date?: string;
    isPO?: boolean;
  }): Promise<PayMultipleRes> {
    const pm = await useGetPaymentMethod(paymentMethod);
    const payment_method_id = pm.id;

    try {
      const response = await useVaniloApi(
        `/${isPO ? "purchase-" : ""}orders/pay-multiple`,
        {
          method: "POST",
          body: {
            orders,
            payment_method_id,
            customer_id: customerId,
            ...(managerId && {
              manager_id: managerId,
            }),
            ...(paymentMethod === PaymentMethodGateway.IN_PERSON && {
              reader_id: readerId || terminals.value[0]?.id,
            }),
            ...(amount && { amount }),
            ...(date && { date }),
          },
        }
      );

      const { result } = response as any;

      if (paymentMethod === PaymentMethodGateway.IN_PERSON) {
        paymentIntent.value = result.intent_id;
        paymentId.value = result.payment_id;

        const terminal = terminals.value.find(
          (terminal) => terminal.id === result.terminal_id
        );

        useToast(
          `Processing payment for $${result.amount.toFixed(2)} on terminal ${
            terminal?.name || ""
          }`,
          {
            type: ToastType.INFO,
            duration: 3000,
          }
        );
      }

      return response as PayMultipleRes;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  // OTHER
  const files = ref<Document[]>([]);
  const isOpenSidebar = ref(false);

  return {
    // PAYMENT SETTINGS
    paymentMethods,
    editingPaymentMethods,
    chargeMethods,
    paymentMethodsList,
    paymentMethodsPosList,
    paymentMethodsListFiltered,
    getPaymentMethods,
    updatePaymentMethods,

    // STRIPE
    url,
    terminals,
    registers,
    activeTerminal,
    readerId,
    paymentIntent,
    paymentId,
    stripeStatus,
    pusher,
    pusherChannel,
    paymentEvent,
    activePaymentMethod,
    channelName,
    getTerminals,
    getStripeStatus,
    getStripeStatusStore,
    connectStripe,
    connectStripeStore,
    disconnectStripe,
    disconnectStripeStore,
    cancelPayment,

    // PAY BY LINK
    getPayByLink,
    postPayByLink,

    // SPLIT PAYMENT
    isSplitPayment,
    splitMode,
    splitOptions,
    selectedAmountSplit,
    isSelectedAmountSplit,
    splitPmOptions,
    selectedSplitPm,
    orderTotalSplit,
    remainingSplit,
    isProcessingSplit,
    isCompletedSplit,
    splitPaymentCounter,
    eachCustomerPays,
    customSplitAmount,
    selectedSplitItems,
    customerPaysSplit,
    customerPaysSplitCash,
    isInitialCurrencyClick,
    splitOrder,
    paidSplitOrders,
    paySplitAmount,
    paySplitItems,
    resetSplitPaymentValues,
    handleSplitSucceed,
    handleSplitDeclined,
    handleSplitAmountSucceed,
    handleSplitAmountDeclined,
    handleSplitItemsSucceed,
    handleSplitItemsDeclined,
    mergeSplitItems,

    // CHECKOUT
    customerPaying,
    isPayed,
    isSucceeded,
    isProcessing,
    isConfirmed,
    isPartialPayment,
    isOpenCheckoutMenu,
    totalValue,
    remaining,
    finalCardAmount,
    balance,
    change,
    currencyList,
    paymentDate,
    viewingInvoice,
    calculateTotals,
    processPayment,
    processPaymentMultiple,

    // OTHER
    files,
    isOpenSidebar,
  };
});
