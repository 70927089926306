export const useRemoveToken = () => {
  const {
    public: { isElectron },
  } = useRuntimeConfig();

  if (isElectron) {
    localStorage.removeItem("token");
    localStorage.removeItem("token-secondary");
  } else {
    useCookie("token").value = null;
    useCookie("token-secondary").value = null;
  }
};
