import { defineStore } from "pinia";
import { useCustomersStore } from "./customers";
import { Customer } from "~/types/customers";
import { ToastType } from "~/types/general";
import { User } from "~/types/users";
import { AuthScreen } from "~/types/auth";
import { useUsersStore } from "./users";
import { useOrdersStore } from "./orders";
import { Order } from "~/types/orders";

export const useAuthStore = defineStore("auth", () => {
  // Authentication state
  const email = ref("");
  const password = ref("");
  const token = ref("");

  async function checkEmail(emailPayload: string): Promise<any> {
    try {
      useIsLoading(true);
      const response = await useVaniloApi(
        `/check-email/${emailPayload || email.value}`
      );

      return response as any;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function socialLogin(social: string, query: any) {
    const cookieToken = useCookie("token");
    const cookieUser = useCookie("user");

    try {
      useIsLoading(true);
      const response = (await useVaniloApi(
        `/social-login/${social}/authorize?code=${query.code}&scope=${query.scope}&authuser=${query.authuser}&hd=${query.hd}&prompt=${query.prompt}`
      )) as any;

      if (response?.token) {
        token.value = response.token;
        cookieToken.value = response.token;

        useUsersStore().currentUser = response.user;
        cookieUser.value = response.user.id;

        useRouter().push({ name: useRoutesNames().index });
      } else {
        useToast("User not found", {
          type: ToastType.ERROR,
          duration: 3000,
        });
        useRouter().push({
          name:
            useRoute().name === useRoutesNames().bookingAccountLoginScreen
              ? useRoutesNames().bookingAccountLoginScreen
              : useRoutesNames().loginScreen,
          params: {
            screen: AuthScreen.EMAIL,
          },
        });
      }

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function signIn(payload?: {
    email: string;
    password: string;
    code?: string;
  }): Promise<any> {
    try {
      const response = await useVaniloApi("/login", {
        method: "POST",
        body: {
          email: payload?.email || email.value,
          password: payload?.password || password.value,
          ...(payload?.code && { code: payload?.code }),
        },
      });

      return response as any;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function signInTeamMember(
    id,
    body?: { pin?: string; device?: string }
  ): Promise<{
    token: string;
    user: User;
  }> {
    try {
      const response = await useVaniloApi(`/customers/${id}/staff-login`, {
        method: "POST",
        body,
      });

      return response as { token: string; user: User };
    } catch (error) {
      useCatchError(error);
    }
  }

  async function logout(options: { redirect?: boolean }) {
    const cookieUser = useCookie("user");
    cookieUser.value = null;

    useRemoveToken();

    useOrdersStore().orders = [] as Order[];

    // remove active customer
    useCustomersStore().activeCustomer = {} as Customer;

    // remove logged user
    useUsersStore().currentUser = {} as User;
    useCustomersStore().currentUserCustomer = {};

    if (options?.redirect) {
      setTimeout(() => {
        useRouter().push({
          name: useRoutesNames().loginScreen,
          params: {
            screen: AuthScreen.EMAIL,
          },
        });
      }, 300);
    }
  }

  async function sendResetLink(email: string, isBooking?: boolean) {
    try {
      await useVaniloApi("/forgot-password", {
        method: "POST",
        body: {
          email,
          ...(isBooking && {
            additional: {
              is_booking: true,
            },
          }),
        },
      });

      return true;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Reset password
  async function resetPassword({
    token,
    email,
    password,
    password_confirmation,
  }: {
    token: string;
    email: string;
    password: string;
    password_confirmation: string;
  }) {
    try {
      const response = await useVaniloApi("/reset-password", {
        method: "POST",
        body: {
          token,
          email,
          password,
          password_confirmation,
        },
      });

      const route =
        useRoute().name === useRoutesNames().bookingAccountLoginScreen ||
        !!useRoute().query?.["additional[is_booking]"]
          ? "booking/account/login"
          : "login";

      window.location.href = `/${route}/successful-reset`;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Change password
  async function changePassword({
    currentPassword,
    newPassword,
    confirmationPassword,
  }: {
    currentPassword: string;
    newPassword: string;
    confirmationPassword: string;
  }) {
    try {
      await useVaniloApi("/change-password", {
        method: "POST",
        body: {
          current_password: currentPassword,
          new_password: newPassword,
          new_password_confirmation: confirmationPassword,
        },
      });

      return true;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    email,
    password,
    token,
    checkEmail,
    socialLogin,
    signIn,
    signInTeamMember,
    logout,
    sendResetLink,
    resetPassword,
    changePassword,
  };
});
