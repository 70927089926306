export enum PusherEventName {
  REPORT_CREATED = "App\\Tasks\\Events\\ReportCreated",
  GENERAL_NOTIFICATION = "App\\Events\\GeneralNotification",
  ORDER_ACTION = "App\\Events\\ActionAssigned",
  DATA_PROCESSING_PROGRESS = "App\\Tasks\\Events\\DataProcessingProgress",
  INVENTORY_REPORT = "App\\Inventories\\Jobs\\InventoryReport",
  SEND_STATEMENTS = "App\\Statements\\Jobs\\SendStatements",
  SEND_PAYBACK_SUMMARY = "App\\Statements\\Jobs\\SendStatements",
  PRINT_PURCHASE_ORDER_LABELS = "App\\Orders\\Jobs\\PrintPurchaseOrderLabels",
  INVOICE_BATCH_SYNC = "App\\Xero\\Jobs\\BatchSyncInvoiceProcess",
  INVOICE_XERO_SYNC_STATUS = "App\\Xero\\Events\\InvoiceWasSynced",
  INVOICE_REPORT = "App\\Orders\\Jobs\\InvoiceReport",
  DATA_ERROR = "App\\Tasks\\Events\\DataError",
  ORDER_DATA_ERROR = "App\\Orders\\Events\\DataError",
}
