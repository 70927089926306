export const useThrottle = <T extends any[]>(
  callback: (...args: T) => void,
  delay: number
) => {
  let isThrottled = false;
  let savedArgs: T | null = null;

  const wrapper = (...args: T) => {
    if (isThrottled) {
      savedArgs = args;
      return;
    }

    callback(...args);
    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;
      if (savedArgs !== null) {
        wrapper(...savedArgs);
        savedArgs = null;
      }
    }, delay);
  };

  return wrapper;
};
