import { defineStore } from "pinia";
import { Channel, ChannelSlug, ChannelOption } from "~/types/channels";
import { ResponseType } from "~/types/general";

export const useChannelsStore = defineStore("channels", () => {
  // Channels state and related functions
  const channels = ref<ChannelOption[]>([]);

  async function getChannelsList(): Promise<ResponseType<Channel[]>> {
    try {
      const res = (await useVaniloApi("/channels")) as ResponseType<Channel[]>;

      channels.value = res?.data?.map((channel) => ({
        ...channel,
        value: channel.slug,
      }));

      return res as ResponseType<Channel[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateChannel(
    id: number,
    body
  ): Promise<ResponseType<Channel>> {
    try {
      const response = (await useVaniloApi(`/channels/${id}`, {
        method: "POST",
        body,
      })) as ResponseType<Channel>;

      channels.value = channels.value.map((app) => {
        if (app.id === response?.data.id) {
          return response?.data;
        }

        return app;
      }) as ChannelOption[];

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createChannel(body: Channel): Promise<ResponseType<Channel>> {
    try {
      const response = (await useVaniloApi("/channels", {
        method: "POST",
        body,
      })) as ResponseType<Channel>;

      channels.value = [
        ...channels.value,
        { ...response?.data, value: response?.data.slug },
      ];

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteChannel(id: number): Promise<any> {
    try {
      const response = await useVaniloApi(`/channels/${id}`, {
        method: "DELETE",
      });

      channels.value = channels.value.filter((app) => app.id !== id);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Third-party channels state and related functions
  const thirdPartyChannels = ref<ChannelOption[]>([]);

  async function getThirdPartyChannelsList() {
    if (channels.value?.length) {
      thirdPartyChannels.value = channels.value
        ?.filter(
          (i) =>
            i.slug !== ChannelSlug.POS &&
            i.slug !== ChannelSlug.ONLINE &&
            i.slug !== ChannelSlug.PLATFORM
        )
        ?.map((channel) => ({
          ...channel,
          value: channel.slug,
        }));

      return channels.value?.filter(
        (i) =>
          i.slug !== ChannelSlug.POS &&
          i.slug !== ChannelSlug.ONLINE &&
          i.slug !== ChannelSlug.PLATFORM
      );
    } else {
      try {
        const response = (await useVaniloApi("/channels")) as ResponseType<
          Channel[]
        >;

        thirdPartyChannels.value = response?.data
          ?.filter(
            (i) =>
              i.slug !== ChannelSlug.POS &&
              i.slug !== ChannelSlug.ONLINE &&
              i.slug !== ChannelSlug.PLATFORM
          )
          ?.map((channel) => ({
            ...channel,
            value: channel.slug,
          }));

        return response?.data?.filter(
          (i) =>
            i.slug !== ChannelSlug.POS &&
            i.slug !== ChannelSlug.ONLINE &&
            i.slug !== ChannelSlug.PLATFORM
        );
      } catch (error) {
        useCatchError(error);
      }
    }
  }

  // Editing channel state and related functions
  const editingChannel = ref<Channel>({} as Channel);

  async function getSingleChannel(id): Promise<ResponseType<Channel>> {
    try {
      const response = (await useVaniloApi(
        `/channels/${id}`
      )) as ResponseType<Channel>;

      editingChannel.value = response?.data;

      return response;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
  }

  return {
    channels,
    thirdPartyChannels,
    editingChannel,
    getChannelsList,
    getThirdPartyChannelsList,
    getSingleChannel,
    updateChannel,
    createChannel,
    deleteChannel,
  };
});
