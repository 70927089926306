import { RuntimeConfig } from "nuxt/schema";
import IndexedDB from "./IndexedDB";
import {
  FetchWorkerEvent,
  ReportResponse,
  ResponseType,
  ToastType,
} from "~/types/general";
import {
  RequestPayload,
  RequestPayloadItem,
  IndexedDBStore,
  StoreValue,
  ResponseList,
  DataPreprocessRules,
} from "~/types/bff";
import { usePusherStore } from "~/store/pusher";
import { PusherEventName } from "~/types/pusher";
import { Channel } from "pusher-js";

interface IStorageService {
  fetchList<S extends IndexedDBStore>(
    payload: RequestPayload<S>
  ): Promise<ResponseList<StoreValue<S>>>;
  fetchItem<S extends IndexedDBStore>(
    payload: RequestPayloadItem
  ): Promise<StoreValue<S> | null>;
  addItem<S extends IndexedDBStore>(
    payload: RequestPayload<S>
  ): Promise<StoreValue<S>>;
  updateItem<S extends IndexedDBStore>(
    payload: RequestPayloadItem
  ): Promise<StoreValue<S> | null>;
  deleteItem(payload: RequestPayloadItem): Promise<boolean>;
}

export class StorageService implements IStorageService {
  private config: RuntimeConfig;
  private static isProcessingCaching: { [key: string]: boolean } = {};
  private taskIdExpandedReport: number | null = null;
  private currentStore: IndexedDBStore | null = null;

  constructor(config: RuntimeConfig) {
    this.config = config;
    this.initPusherWatcher();
  }

  // fetch data from the appropriate source (Electron or SSR)
  async fetchList<S extends IndexedDBStore>(
    payload: RequestPayload<S>
  ): Promise<ResponseList<StoreValue<S>>> {
    const { isElectron } = this.config.public;

    const defaultPayload = {
      ...payload,
      page: payload.page ?? 1,
      limit: payload.limit ?? 50,
    };

    return true
      ? this.fetchElectron<S>(defaultPayload)
      : this.fetchSSR<S>(defaultPayload);
  }

  // fetch a single item from the appropriate source (Electron or SSR)
  async fetchItem<S extends IndexedDBStore>(
    payload: RequestPayloadItem
  ): Promise<StoreValue<S> | null> {
    const { isElectron } = this.config.public;
    return true
      ? this.fetchItemElectron<S>(payload)
      : this.fetchItemSSR<S>(payload);
  }

  // add an item in the appropriate source (Electron or SSR)
  async addItem<S extends IndexedDBStore>(
    payload: RequestPayload<S>
  ): Promise<StoreValue<S>> {
    const { isElectron } = this.config.public;
    return true
      ? this.addItemElectron<S>(payload)
      : this.addItemSSR<S>(payload);
  }

  // update an item in the appropriate source (Electron or SSR)
  async updateItem<S extends IndexedDBStore>(
    payload: RequestPayloadItem
  ): Promise<StoreValue<S> | null> {
    const { isElectron } = this.config.public;
    return true
      ? this.updateItemElectron<S>(payload)
      : this.updateItemSSR<S>(payload);
  }

  // delete an item in the appropriate source (Electron or SSR)
  async deleteItem<S extends IndexedDBStore>(
    payload: RequestPayloadItem
  ): Promise<boolean> {
    const { isElectron } = this.config.public;
    return true
      ? this.deleteItemElectron(payload)
      : this.deleteItemSSR(payload);
  }

  // MAIN
  // fetch data from the main backend
  private async fetchMain<S extends IndexedDBStore>({
    endpoint,
    params,
    page,
    limit,
  }: RequestPayload<S>): Promise<
    ResponseType<StoreValue<S>[]> | StoreValue<S>
  > {
    try {
      const response = await useVaniloApi(endpoint, {
        params: {
          "options[expand]": true,
          page,
          limit,
          ...params,
        },
      });

      return response as ResponseType<StoreValue<S>[]>;
    } catch (error) {
      throw new Error(`Error fetching data from main backend: ${endpoint}`);
    }
  }

  private async fetchItemMain<S extends IndexedDBStore>({
    endpoint,
    id,
    params,
  }: RequestPayloadItem): Promise<ResponseType<StoreValue<S> | null>> {
    try {
      const response = await useVaniloApi(`${endpoint}/${id}`, {
        params,
      });

      return response as ResponseType<StoreValue<S> | null>;
    } catch (error) {
      throw new Error(`Error fetching item from main backend: ${endpoint}`);
    }
  }

  // ELECTRON
  // fetch data from Electron (IndexedDB)
  private async fetchElectron<S extends IndexedDBStore>(
    payload: RequestPayload<S>
  ): Promise<ResponseList<StoreValue<S>>> {
    try {
      const store = payload.store;
      const idbService = await IndexedDB.getInstance();

      // check if data is available in IndexedDB
      const cachedData = await this.fetchIndexedDB<S>(idbService, payload);

      // if data caching not processing and exists in the store and NO FORCE flag, return the data
      if (
        (!StorageService.isProcessingCaching[store] &&
          cachedData.data?.length &&
          !payload.force) ||
        payload?.search ||
        payload?.noFetchMain
      ) {
        return cachedData;
      }

      // // if no fetch from main backend flag, return null
      // if (payload.noFetchMain) return null;

      // if data is not cached or force fetch, start background caching
      if (!StorageService.isProcessingCaching[store] || payload.force) {
        await idbService.clearStore(store);

        if (
          [IndexedDBStore.PRODUCTS, IndexedDBStore.INVENTORY].includes(store)
        ) {
          this.getExpandedData(payload);
        } else {
          this.startCachingWithWorker(payload);
        }
      }

      // Fetch data from the backend to return immediately
      const backendData = (await this.fetchMain<S>(payload)) as ResponseType<
        StoreValue<S>[]
      >;

      return backendData as ResponseList<StoreValue<S>>;
    } catch (error) {
      console.error(`Error fetching data from store: ${payload.store}`, error);
      throw new Error(`Error fetching data from store: ${payload.store}`);
    }
  }

  // fetch expanded data for products and inventory
  private async getExpandedData<S extends IndexedDBStore>(
    payload: RequestPayload<S>
  ): Promise<void> {
    try {
      const res = (await useVaniloApi(
        `/${payload.endpoint}/expanded-report`
      )) as ResponseType<ReportResponse>;

      this.taskIdExpandedReport = res.data?.id;
      this.currentStore = payload.store;
    } catch (error) {
      console.error("Error fetching expanded data:", error);
      throw new Error("Error fetching expanded data");
    }
  }

  // start background caching of data
  private startCachingWithWorker<S extends IndexedDBStore>(
    payload: RequestPayload<S>
  ): void {
    StorageService.isProcessingCaching[payload.store] = true;

    const url = this.config.public.isElectron
      ? localStorage.getItem("electron-base-url")
      : this.config.public.baseURL;

    const worker = new Worker(
      new URL("../assets/workers/fetchWorkerBff.ts", import.meta.url),
      { type: "module" }
    );

    worker.postMessage({
      url: `${url}/api/${payload.endpoint}`,
      store: payload.store,
      limit: payload.limit || 50,
      token: useGetToken(),
    });

    worker.onmessage = (e: MessageEvent) => {
      if (e.data.type === FetchWorkerEvent.COMPLETED) {
        console.log(`Caching completed for ${payload.store}`);
        StorageService.isProcessingCaching[payload.store] = false;

        useToast(
          `${useCapitalize(payload.store)} have been successfully synchronized`,
          {
            type: ToastType.SUCCESS,
            duration: 5000,
          }
        );
      }
    };

    worker.onerror = (error) => {
      console.error("Worker error during caching:", error);
      StorageService.isProcessingCaching[payload.store] = false;
    };
  }

  // fetch a single item from Electron (IndexedDB)
  private async fetchItemElectron<S extends IndexedDBStore>(
    payload: RequestPayloadItem
  ): Promise<StoreValue<S> | null> {
    try {
      const idbService = await IndexedDB.getInstance();

      // check if item is available in IndexedDB
      const data = await idbService.getItem(
        payload.store,
        payload.id as string
      );

      // if data exists in the store and NO FORCE flag, return the data
      if (data && !payload?.force) {
        return data;
      }

      const response = await this.fetchItemMain<S>(payload);

      // if no data found in the main backend, return null
      if (!response) return null;

      return response.data as StoreValue<S>;
    } catch (error) {
      throw new Error(
        `Error fetching item: ${payload.id} from store: ${payload.store}`
      );
    }
  }

  // add an item in Electron (IndexedDB)
  private async addItemElectron<S extends IndexedDBStore>(
    payload: RequestPayload<S>
  ): Promise<StoreValue<S>> {
    try {
      const idbService = await IndexedDB.getInstance();

      // add the item to the store
      await idbService.add(payload.store, payload.body as StoreValue<S>);

      return payload.body as StoreValue<S>;
    } catch (error) {
      throw new Error(`Error adding item to store: ${payload.store}`);
    }
  }

  // update an item in Electron (IndexedDB)
  private async updateItemElectron<S extends IndexedDBStore>(
    payload: RequestPayloadItem
  ): Promise<StoreValue<S> | null> {
    try {
      const idbService = await IndexedDB.getInstance();

      // update the item in the store
      await idbService.updateItem(payload.store, payload.body as StoreValue<S>);

      // fetch the updated item
      const updatedItem = await idbService.getItem(
        payload.store,
        payload.id as string
      );

      return updatedItem;
    } catch (error) {
      throw new Error(
        `Error updating item: ${payload.id} in store: ${payload.store}`
      );
    }
  }

  // delete an item in Electron (IndexedDB)
  private async deleteItemElectron(
    payload: RequestPayloadItem
  ): Promise<boolean> {
    try {
      const idbService = await IndexedDB.getInstance();

      // delete the item from the store
      await idbService.deleteItem(payload.store, payload.id as string);

      return true;
    } catch (error) {
      throw new Error(
        `Error deleting item: ${payload.id} from store: ${payload.store}`
      );
    }
  }

  // fetch data from IndexedDB
  private async fetchIndexedDB<S extends IndexedDBStore>(
    idbService: IndexedDB,
    payload: RequestPayload<S>
  ): Promise<ResponseList<StoreValue<S>>> {
    const {
      store,
      search: { term, fields, filterFn } = {},
      page,
      limit,
    } = payload;

    // if search parameters are provided -- perform the search
    if (payload?.search) {
      return await idbService.search<S>({
        store,
        term,
        fields,
        filterFn,
        page,
        limit,
      });
    }

    // if filtering criteria are specified -- apply the filters
    if (payload?.filters && Object.keys(payload.filters)?.length) {
      const [indexName, queryValue] = Object.entries(payload.filters)[0];
      return await idbService.getItemsByIndex(
        store,
        indexName,
        queryValue,
        page,
        limit
      );
    }

    // if no search or filtering criteria -- fetch all data from the store
    return await idbService.getAll(store, page, limit);
  }

  //////////////////////////////////////
  ///////         PUSHER         ///////
  //////////////////////////////////////
  private initPusherWatcher(): void {
    const pusherStore = usePusherStore();

    if (pusherStore.channelPrivateDataProcessing) {
      this.bindToDataProcessing(pusherStore.channelPrivateDataProcessing);
    } else {
      // watch for changes in the channel subscription
      watch(
        () => pusherStore.channelPrivateDataProcessing,
        (newChannel) => {
          if (newChannel) {
            this.bindToDataProcessing(newChannel);
          }
        }
      );
    }
  }

  // bind to the data processing channel
  private bindToDataProcessing(channel: Channel): void {
    let toast = null;

    channel.bind(PusherEventName.DATA_PROCESSING_PROGRESS, (data) => {
      if (data?.task_id !== this.taskIdExpandedReport) return;

      // PROGRESS
      if (data?.processed < 100) {
        // if no toast exists, create a new one
        if (!toast) {
          toast = useToastPersistent().showToast(
            {
              message: `${useCapitalize(this.currentStore)} syncing started...`,
              submessage: "0% complete",
            },
            { type: ToastType.INFO }
          );
        }

        // update the toast with the progress
        if (toast) {
          toast.updateToast({
            message: `${useCapitalize(this.currentStore)} syncing...`,
            submessage: `${data.processed}% completed`,
          });
        }
      }

      // COMPLETED
      if (
        data?.task_id === this.taskIdExpandedReport &&
        data?.processed === 100 &&
        data?.url
      ) {
        this.fetchAndStoreData(data.url, this.currentStore as IndexedDBStore);

        // remove the toast
        if (toast) {
          toast.removeToast();
          toast = null;
        }
      }
    });
  }

  private async fetchAndStoreData(
    url: string,
    store: IndexedDBStore
  ): Promise<void> {
    try {
      const response = await fetch(url);
      const allItems = await response.json();

      // preprocess data if rules exist for this store
      const preprocess = DataPreprocessRules[store];
      const dataFromMain = preprocess
        ? preprocess(allItems.data)
        : allItems.data;

      const idbService = await IndexedDB.getInstance();
      await idbService.add(store, dataFromMain);

      useToast(`${useCapitalize(store)} have been successfully synchronized`, {
        type: ToastType.SUCCESS,
        duration: 5000,
      });
    } catch (error) {
      console.error(`Error fetching and storing data from ${url}:`, error);
    }
  }

  ///////////////////////////////////
  ///////         SSR         ///////
  ///////////////////////////////////
  // fetch data from SSR (Backend)
  private async fetchSSR<S extends IndexedDBStore>(
    config: RequestPayload<S>
  ): Promise<ResponseList<StoreValue<S>>> {
    // implement your SSR fetch logic here
    return {} as ResponseList<StoreValue<S>>;
  }

  // fetch a single item from SSR (Backend)
  private async fetchItemSSR<S extends IndexedDBStore>({
    endpoint,
    id,
  }: RequestPayloadItem): Promise<StoreValue<S> | null> {
    // implement your SSR fetch item logic here
    return null;
  }

  // add an item in SSR (Backend)
  private async addItemSSR<S extends IndexedDBStore>({
    endpoint,
    body,
  }: RequestPayload<S>): Promise<StoreValue<S>> {
    // implement your SSR add item logic here
    return body as StoreValue<S>;
  }

  // update an item in SSR (Backend)
  private async updateItemSSR<S extends IndexedDBStore>({
    endpoint,
    id,
    body,
  }: RequestPayloadItem): Promise<StoreValue<S> | null> {
    // implement your SSR update item logic here
    return null;
  }

  // delete an item in SSR (Backend)
  private async deleteItemSSR({
    endpoint,
    id,
  }: RequestPayloadItem): Promise<boolean> {
    // implement your SSR delete item logic here
    return false;
  }
}
