import { OrderStatus, OrderPaymentStatus } from "./orders";
import { OrderType, Media } from "./general";
import { Customer } from "./customers";
import { StoreShort } from "./stores";

export interface OrderActionItem {
  id: number;
  status: OrderActionStatus;
  description: string | null;
  note?: string;
  is_new?: boolean;
  order: {
    id: number;
    store: StoreShort;
    type: OrderType;
    is_express: boolean;
    number: string;
    po_number: number | null;
    status: OrderStatus;
    payment_status: OrderPaymentStatus;
    subtotal_value: number;
    total_value: number;
    credit_amount: number;
    target_date: string[];
    created_at: string;
  };
  customer?: Customer;
  documents: Media[];
  created_at: string;
  created_by: {
    id: number;
    name: string;
    image: Media;
  };
  updated_at: string;
}

export interface OrderActionManage {
  id?: number;
  status?: OrderActionStatus;
  note?: string;
  order_id?: number;
  description?: string | null;
  created_at?: string;
  updated_at?: string;
  customer_id?: number;
}

export enum OrderActionStatus {
  NO_STATUS = "no-status",
  REVIEW = "review",
  CRITICAL = "critical",
  DONE = "done",
}

export interface OrderActionNotification {
  id: number;
  text: string;
  status: OrderActionStatus;
  order: number;
}
