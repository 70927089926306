import { defineStore } from "pinia";
import {
  Address,
  Filters,
  Option,
  ResponseType,
  Document,
  PillVariant,
  TabSectionModuleType,
  TabSectionType,
  TabType,
  Note,
  Media,
  ToastType,
} from "~/types/general";
import {
  Customer,
  CustomerFinance,
  CustomerOption,
  SidebarPage,
  SortedCustomerItem,
  AddressType,
  CustomerSingle,
  AddressTag,
  CustomerType,
  CustomerSubtype,
  CustomerSingleAvatar,
  Group,
  GroupManage,
  GroupRulesOption,
  RoundingRulesOption,
  PricingMethodOption,
  CustomerAddressNew,
  DeliveryRun,
  DeliveryRunManage,
  CustomerStatus,
  CustomerCreditTermType,
  CustomerShort,
  CustomerManage,
  CompanySubtype,
  CustomerGender,
  CustomerPlanType,
  CustomerPlanManagement,
  NewCustomerManage,
  Company,
  TeamMember,
} from "~/types/customers";
import { UserType } from "~/types/users";
import { Tag } from "~/types/taxonomies";
import { useGeneralStore } from "~/store/general";
import { useSettingsStore } from "~/store/settings";
import { useTasksStore } from "~/store/tasks";
import { useOrdersStore } from "./orders";
import { StorageService } from "~/services/StorageService";
import { IndexedDBStore, ResponseList } from "~/types/bff";

export const useCustomersStore = defineStore("customers", () => {
  // State
  const customers = ref<Customer[]>([]);
  const isLoading = ref<boolean>(false);
  const page = ref<number>(1);
  const perPage = ref<number>(40);
  const totalContacts = ref<number | null>(null);
  const totalPages = ref<number | null>(null);
  const contactsFilterBySubtype = ref<{ name: string; value: string | null }>({
    name: "All",
    value: null,
  });
  const companiesFilterBySubtype = ref<{ name: string; value: string | null }>({
    name: "All",
    value: null,
  });

  // Profile
  const profileSearch = ref<string>("");
  const searchCustomerProfile = ref<string>("");
  const previousTab = ref<any>(null);
  const selectedCustomerTypeForCreate = ref<CustomerType>(
    CustomerType.INDIVIDUAL
  );
  const editingProfileCustomer = ref<NewCustomerManage>(
    {} as NewCustomerManage
  );
  const viewingProfileCustomer = ref<CustomerSingle>({} as CustomerSingle);
  const quickViewProfileCustomer = ref<CustomerSingle>({} as CustomerSingle);

  // Delivery Runs
  const deliveryRuns = ref<DeliveryRun[]>([]);
  const viewingDeliveryRun = ref<DeliveryRun>({} as DeliveryRun);
  const editingDeliveryRun = ref<DeliveryRunManage>({} as DeliveryRunManage);

  const products = ref<any[]>([]);
  const contactTags = ref<Tag[]>([]);

  const passwordRequest = ref<any>({});

  const contactAddressNew = ref<CustomerAddressNew>({} as CustomerAddressNew);
  const featureRequestForAddCredit = ref<any[]>([]);

  const image = ref<CustomerSingleAvatar>({} as CustomerSingleAvatar);
  const files = ref<Document[]>([]);

  const isCheckShipAddress = ref<boolean>(false);
  const isNewDeliveryAddress = ref<boolean>(false);

  // Filters
  const ordersFilters = ref<Filters>({
    priority: {
      type: "priority",
      title: "Is Priority",
      options: [],
      chosenOptions: [],
      toggle: false,
      isSingle: true,
    },
    tag: {
      type: "tag",
      title: "Custom Tag",
      options: [],
      chosenOptions: [],
    },
    type: {
      type: "type",
      title: "Order Type",
      options: [],
      chosenOptions: [],
    },

    payment_method: {
      type: "payment_method",
      title: "Payment Type",
      options: [],
      chosenOptions: [],
    },
    status: {
      type: "status",
      title: "Status",
      options: useOrdersStore().statusTags,
      chosenOptions: [],
    },
    period: {
      type: "period",
      title: "Period",
      options: useGeneralStore().periods,
      chosenOptions: [],
      isSingle: true,
    },
  });
  const targetDateRange = ref<string[]>([]);
  const selectedPaymentStatuses = ref<Option[]>([]);
  const selectedStatuses = ref<Option[]>([]);
  const paymentStatuses = ref<Option[]>([
    {
      name: "Overdue",
      value: "overdue",
    },
    {
      name: "Account",
      value: "account",
    },
  ]);

  const addressTags = ref<any[]>([
    {
      name: "Home",
      value: AddressTag.HOME,
    },
    {
      name: "Work",
      value: AddressTag.WORK,
    },
    {
      name: "Project",
      value: AddressTag.PROJECT,
    },
    {
      name: "Other",
      value: AddressTag.OTHER,
    },
  ]);
  const genders = ref<any[]>([
    {
      name: "Male",
      value: CustomerGender.MALE,
    },
    {
      name: "Female",
      value: CustomerGender.FEMALE,
    },
    {
      name: "Non-Binary",
      value: CustomerGender.NON_BINARY,
    },
    {
      name: "Prefer Not to Say",
      value: CustomerGender.PREFER_NOT_TO_SAY,
    },
  ]);
  const planTypes = ref<any[]>([
    {
      name: "None",
      value: CustomerPlanType.NONE,
    },
    {
      name: "Aged Care: Home care Package (HCP)",
      value: CustomerPlanType.HCP,
    },
    {
      name: "Aged Care: Commonwealth Home Support Programme (CHSP)",
      value: CustomerPlanType.CHSP,
    },
    {
      name: "National Disability Insurance Scheme (NDIS)",
      value: CustomerPlanType.NDIS,
    },
  ]);

  const planManagementTypes = ref<any[]>([
    {
      name: "None",
      value: null,
    },
    {
      name: "Plan Managed",
      value: CustomerPlanManagement.PLAN_MANAGEMENT,
    },
    {
      name: "Self Managed",
      value: CustomerPlanManagement.SELF_MANAGEMENT,
    },
    {
      name: "Agency Managed",
      value: CustomerPlanManagement.AGENCY_MANAGEMENT,
    },
  ]);
  const addressTypes = ref<any[]>([
    {
      name: "Billing",
      value: AddressType.BILLING,
    },
    {
      name: "Delivery",
      value: AddressType.SHIPPING,
    },
  ]);
  const creditTermTypes = ref<Option[]>([
    { name: "Days", value: CustomerCreditTermType.DAYS },
    {
      name: "Following month",
      value: CustomerCreditTermType.FOLLOWING_MONTH,
    },
    {
      name: "Last day of month",
      value: CustomerCreditTermType.LAST_DAY_OF_MONTH,
    },
  ]);

  // For profile (for delete from customer)
  const attachedContacts = ref<any[]>([]);
  const sidebarAttachedContacts = ref<any[]>([]);
  const attachedLocations = ref<any[]>([]);
  const sidebarAttachedLocations = ref<any[]>([]);
  const deliveryRunsCustomer = ref<DeliveryRun[]>([]);
  const viewingDeliveryRunCustomer = ref<DeliveryRun>({} as DeliveryRun);
  const editingDeliveryRunCustomer = ref<DeliveryRunManage>(
    {} as DeliveryRunManage
  );
  const customerTaxonomies = ref<any[]>([]);
  const supplierProducts = ref<any[]>([]);
  const productsCustomer = ref<any[]>([]);
  const tags = ref<Tag[]>([]);
  const customerAddressNew = ref<CustomerAddressNew>({} as CustomerAddressNew);
  const customer_image = ref<CustomerSingleAvatar>({} as CustomerSingleAvatar);
  const customer_files = ref<Document[]>([]);

  const searchCustomer = ref<string>("");

  const editingCustomer = ref<CustomerSingle>({} as CustomerSingle);
  const viewingCustomer = ref<CustomerSingle | Customer | CustomerManage>(
    {} as CustomerSingle | Customer | CustomerManage
  );
  const viewingCustomerFinance = ref<CustomerFinance>({} as CustomerFinance);

  const currentUserCustomer = ref<CustomerSingle>({} as CustomerSingle);
  const activeCustomer = ref<
    | Customer
    | CustomerSingle
    | CustomerShort
    | {
        id: any;
        name: string;
        type: CustomerType;
      }
  >(
    {} as
      | Customer
      | CustomerSingle
      | CustomerShort
      | {
          id: any;
          name: string;
          type: CustomerType;
        }
  );

  const editMode = ref<boolean>(false);

  // Customer Orders
  const currentCustomer = ref<CustomerSingle>({} as CustomerSingle);

  const customerSubtypes = ref<CustomerSubtype[]>([]);
  const viewingSubtype = ref<CustomerSubtype>({} as CustomerSubtype);

  const customerStatuses = ref<any[]>([
    {
      name: "Active",
      value: CustomerStatus.ACTIVE,
      type: PillVariant.GREEN,
    },
    {
      name: "Application",
      value: CustomerStatus.APPLICATION,
      type: PillVariant.GREEN,
    },
    {
      name: "Pending",
      value: CustomerStatus.PENDING,
      type: PillVariant.GREY,
    },
    {
      name: "Declined",
      value: CustomerStatus.BLOCKED,
      type: PillVariant.RED,
    },
  ]);
  const customerDropdown = ref<Option[]>([
    {
      name: CustomerOption.DETAILS,
      value: SidebarPage.CUSTOMERS_SINGLE_DETAILS,
    },
    {
      name: CustomerOption.ORDERS_HISTORY,
      value: SidebarPage.CUSTOMERS_ORDERS_HISTORY,
    },
    {
      name: CustomerOption.DRAFTED_ORDERS,
      value: SidebarPage.CUSTOMERS_ORDERS_DRAFTED,
    },
    {
      name: CustomerOption.EDIT,
      value: SidebarPage.CUSTOMERS_SINGLE_EDIT,
    },
  ]);

  // Filters
  const filters = ref<Filters>({
    type: {
      type: "type",
      title: "Types",
      options: [],
      chosenOptions: [],
    },
    status: {
      type: "status",
      title: "Status",
      options: [
        {
          name: "Active",
          value: CustomerStatus.ACTIVE,
          type: PillVariant.GREEN,
        },
        {
          name: "Application",
          value: CustomerStatus.APPLICATION,
          type: PillVariant.GREEN,
        },
        {
          name: "Pending",
          value: CustomerStatus.PENDING,
          type: PillVariant.GREY,
        },
        {
          name: "Declined",
          value: CustomerStatus.BLOCKED,
          type: PillVariant.RED,
        },
        {
          name: "Archive",
          value: CustomerStatus.ARCHIVE,
          type: PillVariant.GREY,
        },
      ],
      chosenOptions: [],
    },
    additional_types: {
      type: "additional_types",
      title: "Additional Types",
      options: [],
      chosenOptions: [],
    },
    group: {
      type: "group",
      title: "Groups",
      options: [],
      chosenOptions: [],
    },
    store: {
      type: "store",
      title: "Locations",
      options: [],
      chosenOptions: [],
    },
    taxonomies: {
      type: "taxonomies",
      title: "Tags",
      options: [],
      chosenOptions: [],
    },
    letter: {
      type: "letter",
      title: "Alphabet letter",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
  });

  // Sidebar
  const isOpenSidebar = ref<boolean>(false);
  const isOpenSidebarSettings = ref<boolean>(false);

  // Groups
  const customerGroups = ref<Group[]>([]);
  const viewingGroup = ref<Group>({} as Group);
  const editingGroup = ref<GroupManage>({} as GroupManage);
  const creatingGroup = ref<GroupManage>({} as GroupManage);

  const defaultGroup = ref<any>(null);

  // Options
  const groupPricingMethod = ref<Option[]>([
    {
      name: "Increase",
      value: PricingMethodOption.INCREASE,
    },
    {
      name: "Decrease",
      value: PricingMethodOption.DECREASE,
    },
  ]);
  const priceRules = ref<Option[]>([
    {
      name: "None",
      value: GroupRulesOption.NONE,
    },
    {
      name: "Custom",
      value: GroupRulesOption.CUSTOM,
    },
    {
      name: "Fixed",
      value: GroupRulesOption.FIXED,
    },
    {
      name: "Percentage",
      value: GroupRulesOption.PERCENT,
    },
  ]);
  const roundingRules = ref<Option[]>([
    {
      name: "no round",
      value: null,
    },
    {
      name: "always round up",
      value: RoundingRulesOption.ROUND_UP,
    },
    {
      name: "always round down",
      value: RoundingRulesOption.ROUND_DOWN,
    },
  ]);
  const roundingPercents = ref<Option[]>([
    {
      name: "0",
      value: null,
    },
    {
      name: "0.01",
      value: "0.01",
    },
    {
      name: "0.05",
      value: "0.05",
    },
    {
      name: "0.10",
      value: "0.10",
    },
    {
      name: "0.50",
      value: "0.50",
    },
    {
      name: "1.00",
      value: "1.00",
    },
  ]);

  // Table
  const pageTable = ref<number>(1);
  const perPageTable = ref<number>(10);

  const selectedRowsIds = ref<any[]>([]);
  const baseTableKey = ref<number>(0);

  // Previous
  const previousCustomersPage = ref<any>(null);
  const ordersCustomerName = ref<any>(null);

  const teamMembers = ref<any[]>([]);

  //Tabs
  const tabs = computed(() => [
    {
      name: "Profile",
      tab: null,
      types: null,
      subtypes: null,
      forNew: true,
      sections: [
        // {
        //   section: TabSectionType.PROFILE,
        //   client: true,
        //   types: null,
        //   isAction: true,
        //   noDivider: false,
        //   forNew: true,
        //   title: "[type] Profile",
        //   subtitle:
        //     "Manage their profile update important information, review any order history and manage important settings.",
        //   keywords: "[type] profile",
        //   modules: [
        //     {
        //       module: TabSectionModuleType.PROFILE_ACTIONS,
        //       types: null,
        //       keywords: "[type] profile credit pay balance points",
        //     },
        //   ],
        // },
        {
          section: TabSectionType.GENERAL_INFO,
          client: true,
          types: null,
          isAction: false,
          noDivider: false,
          forNew: true,
          title: "General Information",
          subtitle:
            "This refers to the main profile information information, such as the name and contact details.",
          keywords:
            "general information email website phone mobile contact code group status gender staff roles [type] type",
          modules: [
            {
              module: TabSectionModuleType.CONTACT_INFO,
              types: null,
              keywords:
                "general information email website phone mobile contact code group status gender",
            },
            {
              module: TabSectionModuleType.SUBTYPES,
              types: null,
              keywords: "general information [type] type",
            },
            {
              module: TabSectionModuleType.ROLES,
              types: [CustomerType.STAFF],
              keywords: "general information staff roles",
            },
          ],
        },
        {
          section: TabSectionType.ADDRESS_DETAILS,
          client: true,
          types: [
            CustomerType.COMPANY,
            CustomerType.INDIVIDUAL,
            CustomerType.STAFF,
          ],
          isAction: false,
          noDivider: false,
          forNew: true,
          title: "Address Details",
          subtitle:
            "You can add and manage billing and delivery address details.",
          keywords: "address details billing address delivery address",
          modules: [
            {
              module: TabSectionModuleType.BILLING_ADDRESS,
              types: null,
              keywords: "billing address",
            },
            {
              module: TabSectionModuleType.DELIVERY_ADDRESS,
              types: null,
              keywords: "delivery address",
            },
          ],
        },
        {
          section: TabSectionType.ORGANISE_AND_CLASSIFY,
          client: true,
          types: null,
          isAction: false,
          noDivider: true,
          forNew: true,
          title: "Organise and Classify",
          subtitle:
            "Add a personal profile, organise the contact with tags. This information is shared with platform admins and managers. If the contact has online account access they can update their profile description as well.",
          keywords:
            "organise and classify contact tags profile details description",
          modules: [
            {
              module: TabSectionModuleType.PROFILE_DETAILS,
              types: null,
              keywords: "organise and classify profile details description",
            },
            {
              module: TabSectionModuleType.CONTACT_TAGS,
              types: null,
              keywords: "organise and classify contact tags",
            },
          ],
        },
      ],
    },

    {
      name: "Additional Information",
      tab: TabType.ADDITIONAL_INFORMATION,
      types: null,
      subtypes: null,
      forNew: true,
      sections: [
        {
          section: TabSectionType.CONTACT_NOTES,
          client: true,
          types: null,
          isAction: false,
          noDivider: false,
          title: "[type] Notes",
          subtitle:
            "You can add private notes or notes shared with your contact.",
          keywords: "notes",
          forNew: true,
          modules: [
            {
              module: TabSectionModuleType.NOTES,
              types: null,
              keywords: "notes",
            },
          ],
        },
        {
          section: TabSectionType.STAFF_DETAILS,
          client: true,
          types: [CustomerType.STAFF],
          isAction: false,
          noDivider: true,
          title: "Staff Details",
          subtitle:
            "You can enter or edit staff member banking and superannuation details, please be sure to save any declarations in the attachments section of their profile.",
          keywords: "staff details primary banking account superannuation",
          forNew: true,
          modules: [
            {
              module: TabSectionModuleType.PRIMARY_BANKING_ACCOUNT,
              types: [CustomerType.STAFF],
              keywords: "staff details primary banking account",
            },
            {
              module: TabSectionModuleType.SUPERANNUATION,
              types: [CustomerType.STAFF],
              keywords: "staff details superannuation",
            },
          ],
        },
        ...(useConfig().isConnectorCo
          ? [
              {
                section: TabSectionType.ADDITIONAL_DATA,
                client: true,
                types: [
                  CustomerType.INDIVIDUAL,
                  CustomerType.STAFF,
                  CustomerType.CONTACT,
                ],
                isAction: false,
                noDivider: false,
                title: "Additional Data",
                subtitle:
                  "These fields are platform wide meta fields, they have been added to allow you to categorise information about the contact.",
                keywords: "additional data ndis plan number",
                forNew: true,
                modules: [
                  {
                    module: TabSectionModuleType.ADDITIONAL_DATA,
                    types: null,
                    keywords: "additional data ndis plan number",
                  },
                ],
              },
            ]
          : []),
      ],
    },
    ...(useRoute().name !== useRoutesNames().user
      ? [
          {
            name: "Calendar",
            tab: TabType.CALENDAR,
            types: [
              CustomerType.INDIVIDUAL,
              CustomerType.STAFF,
              CustomerType.CONTACT,
            ],
            subtypes: null,
            forNew: false,
          },
        ]
      : []),
    ...(!useConfig().isConnectorCo ||
    useConfig().isPickerOnly ||
    useConfig().isParticipant
      ? [
          {
            name: useConfig().isParticipant ? "Bookings" : "Orders",
            tab: TabType.ORDERS,
            types: null,
            subtypes: null,
            forNew: false,
          },
        ]
      : []),
    {
      name: "Contact Companies",
      tab: TabType.COMPANIES,
      types: [CustomerType.CONTACT],
      subtypes: null,
      forNew: true,
    },
    {
      name: "Company Locations",
      tab: TabType.LOCATIONS,
      types: [CustomerType.COMPANY],
      subtypes: [CompanySubtype.LOCATION],
      forNew: true,
    },
    {
      name: "Company Contacts",
      tab: TabType.CONTACTS,
      types: [CustomerType.COMPANY],
      subtypes: null,
      forNew: true,
    },
    {
      name: "Attachments",
      tab: TabType.FILES,
      types: null,
      subtypes: null,
      keywords: "attachments documents",
      forNew: true,
      sections: [
        {
          section: TabSectionType.DOCUMENTS,
          client: true,
          types: null,
          title: "Documents and Attachments",
          subtitle:
            "Add important reference documents to this contact. Centralise important admin data.",
          keywords: "attachments documents",
          forNew: true,
          modules: [
            {
              module: TabSectionModuleType.DOCUMENTS,
              types: null,
              keywords: "attachments documents",
            },
          ],
        },
      ],
    },
    {
      name: "Products and Services",
      tab: TabType.PRODUCTS,
      types: [CustomerType.COMPANY],
      subtypes: [CompanySubtype.SUPPLIER],
      forNew: true,
      sections: [
        {
          section: TabSectionType.PRODUCTS,
          client: true,
          types: null,
          title: "Products and Services",
          subtitle: "Add products are services to this contact.",
          keywords: "products and services",
          forNew: true,
          modules: [
            {
              module: TabSectionModuleType.PRODUCTS,
              types: null,
              keywords: "products and services",
            },
          ],
        },
      ],
    },
    {
      name: "Delivery Runs",
      tab: TabType.RUNS,
      types: [CustomerType.COMPANY],
      subtypes: [CompanySubtype.FREIGHT],
      forNew: false,
      sections: [
        {
          section: TabSectionType.RUNS,
          client: true,
          types: null,
          title: "Delivery Runs",
          subtitle: "Add delivery runs to this contact.",
          keywords: "delivery runs",
          forNew: false,
          modules: [
            {
              module: TabSectionModuleType.RUNS,
              types: null,
              keywords: "delivery runs",
            },
          ],
        },
      ],
    },
    {
      name: "Settings",
      tab: TabType.SETTINGS,
      types: null,
      subtypes: null,
      forNew: true,
      sections: [
        ...(useConfig().isXero
          ? [
              {
                section: TabSectionType.XERO,
                client: false,
                types: null,
                isAction: true,
                noDivider: false,
                title: "Sync with Xero",
                subtitle:
                  "You can manually sync this contact to Xero. Additionally, all contacts are synced when and order is sent to Xero.",
                keywords: "sync with xero sync xero",
                forNew: false,
                modules: [
                  {
                    module: TabSectionModuleType.XERO,
                    types: null,
                    keywords: "sync with xero sync xero",
                  },
                ],
              },
            ]
          : []),
        {
          section: TabSectionType.NOTIFICATIONS,
          client: true,
          types: null,
          isAction: false,
          noDivider: false,
          title: "Notifications",
          subtitle:
            "Manage notification options for this user, add additional people that need to be notified.",
          keywords: "order notifications",
          forNew: true,
          modules: [
            {
              module: TabSectionModuleType.NOTIFICATIONS,
              types: null,
              keywords: "order notifications",
            },
          ],
        },
        {
          section: TabSectionType.CONFIGURATION,
          client: false,
          types: null,
          isAction: false,
          noDivider: false,
          title: "Configuration Options",
          subtitle:
            "Customised account access options for financial account management and product type visibility.",
          keywords:
            "configuration options custom account options allow manager balance override display secondary names hide variation names assign to a credit service",
          forNew: true,
          modules: [
            {
              module: TabSectionModuleType.CONFIGURATION,
              types: null,
              keywords:
                "configuration options custom account options allow manager balance override display secondary names hide variation names assign to a credit service",
            },
          ],
        },
        {
          section: TabSectionType.ACCOUNT_ACCESS,
          client: true,
          types: [
            CustomerType.STAFF,
            CustomerType.CONTACT,
            CustomerType.INDIVIDUAL,
          ],
          isAction: false,
          noDivider: false,
          title: "Account Access",
          subtitle:
            "Set new a password and pin for this user, you can also send a reset link.",
          keywords: "account access password pin",
          forNew: true,
          modules: [
            {
              module: TabSectionModuleType.PASSWORD,
              types: null,
              keywords: "account access password",
            },
            {
              module: TabSectionModuleType.PIN,
              types: [CustomerType.STAFF],
              keywords: "account access pin",
            },
          ],
        },
      ],
    },
  ]);

  // Getters
  const companyType = computed(() => viewingCustomer.value?.type);

  const sortedCustomerList = computed(() => {
    const customers: any = [...state.customers].reduce((r, e) => {
      let initial = e.name[0] ? e.name[0].toUpperCase() : e.name[0];

      if (!r[initial]) r[initial] = { initial, items: [e] };
      else r[initial].items.push(e);

      return r;
    }, {});

    return Object.values(customers as SortedCustomerItem[]).sort((a, b) =>
      a.initial === b.initial ? 0 : a.initial < b.initial ? -1 : 1
    );
  });

  const groupOptions = computed(() => [
    { value: null, name: "None" },
    ...customerGroups.value.map((group) => ({
      name: group.name,
      value: group.id,
    })),
  ]);

  const defaultGroupId = computed(() => defaultGroup.value?.id ?? null);

  // Payment status filters
  const isOverdueSelected = computed(
    () => !!selectedPaymentStatuses.value.find((o) => o.value === "overdue")
  );
  const isAccountSelected = computed(
    () => !!selectedPaymentStatuses.value.find((o) => o.value === "account")
  );

  // Pickers
  const pickers = computed(() =>
    teamMembers.value.filter((member) =>
      member.user.roles.includes(UserType.PICKER)
    )
  );

  // Actions

  // Customer Management
  async function createCustomer(body) {
    try {
      const response = (await useVaniloApi("/customers", {
        method: "POST",
        body,
      })) as ResponseType<CustomerSingle>;

      const {
        id,
        email,
        mobile,
        company_name,
        firstname,
        lastname,
        phone,
        subtypes,
        type,
        default_store,
        title,
      } = response?.data;

      // response data model has CustomerSingle type which is different then Customer type from the list
      const newCustomer = {
        id,
        phone,
        name:
          company_name ||
          `${firstname}${lastname?.length ? " " + lastname : ""}`,
        mobile: mobile || null,
        subtypes: subtypes || null,
        type,
        ...(default_store && { default_store }),
        ...(title && { title }),
        ...(email?.length && { email: email }),
      };

      customers.value.push(newCustomer as Customer);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getCustomers(payload?, noStore?) {
    const params = {
      page: page.value,
      limit: perPage.value,
      ...(payload && payload),
    };

    try {
      const response = (await useVaniloApi(
        "/customers",
        { params },
        ...(noStore ? [{ noStoreFilter: true }] : [])
      )) as ResponseType<Customer[]>;

      const { data, meta } = response;

      customers.value =
        meta.current_page === 1 ? data : [...customers.value, ...data];
      page.value = meta.current_page;
      totalContacts.value = meta.total;
      totalPages.value = meta.last_page;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getCustomersBff(payload?: {
    params: any;
    filters: any;
    search: any;
  }): Promise<ResponseList<CustomerSingle>> {
    const { params, filters, search } = payload || {};

    try {
      const response = await new StorageService(
        useRuntimeConfig()
      ).fetchList<IndexedDBStore.CUSTOMERS>({
        endpoint: "customers",
        store: IndexedDBStore.CUSTOMERS,
        params,
        filters,
        search,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getCompanies(payload?) {
    const params = {
      page: page.value,
      limit: perPage.value,
      ...(payload && payload),
    };

    try {
      const response = (await useVaniloApi("/customers/companies", {
        params,
      })) as ResponseType<Customer[]>;

      const { data, meta } = response;

      customers.value =
        meta.current_page === 1 ? data : [...customers.value, ...data];
      page.value = meta.current_page;
      totalContacts.value = meta.total;
      totalPages.value = meta.last_page;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  function resetCustomersList() {
    customers.value = [];
    page.value = 1;
    perPage.value = 40;
    totalContacts.value = null;
    totalPages.value = null;
    pageTable.value = 1;
    perPageTable.value = 10;
  }

  async function getPayableCustomers(payload?, noStore = false) {
    const params = {
      "filter[type][]": [CustomerType.INDIVIDUAL, CustomerType.COMPANY],
      ...(payload && payload),
    };

    try {
      const response = await useVaniloApi(
        "/customers",
        { params },
        ...(noStore ? [{ noStoreFilter: true }] : [])
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getFilteredCustomers(payload?, noStore?) {
    const params = {
      ...(payload && payload),
    };

    try {
      const response = await useVaniloApi(
        "/customers",
        { params },
        ...(noStore ? [{ noStoreFilter: true }] : [])
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSortedCustomers(payload?, noStore?) {
    const params = {
      limit: payload.limit,
      page: payload.page,
    };

    if (payload.modifications.length) {
      payload.modifications.forEach((m) => {
        params[`${m.method}[${m.field}]`] = m.value;
      });
    }

    try {
      const response = await useVaniloApi(
        "/customers",
        { params },
        ...(noStore ? [{ noStoreFilter: true }] : [])
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleCustomer(id) {
    try {
      const response = (await useVaniloApi(
        `/customers/${id}`
      )) as ResponseType<CustomerSingle>;

      viewingCustomer.value = response?.data;
      customerTaxonomies.value = response?.data?.taxonomies?.[0]?.taxons;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getCustomerFinance(id) {
    try {
      const response = (await useVaniloApi(`/customers/${id}/finance`)) as any;

      viewingCustomerFinance.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getCurrentCustomerProfile() {
    let response;

    try {
      response = (await useVaniloApi(`/customers/profile`)) as any;

      currentUserCustomer.value = response?.data || {};
    } catch (error) {
      useCatchError(error);
    }

    return response;
  }

  async function getCustomersTags() {
    try {
      const response = (await useVaniloApi(
        `/taxonomies/customer-tag/taxa`
      )) as any;

      tags.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSubtypes(params?) {
    try {
      const response = (await useVaniloApi(`/subtypes`, {
        params,
      })) as ResponseType<CustomerSubtype[]>;

      customerSubtypes.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleSubtype(id) {
    try {
      const response = (await useVaniloApi(`/subtypes/${id}`)) as any;

      viewingSubtype.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createSubtype(body) {
    try {
      const response = await useVaniloApi(`/subtypes`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateSubtype(id, body) {
    try {
      const response = await useVaniloApi(`/subtypes/${id}`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteSubtype(id) {
    try {
      const response = await useVaniloApi(`/subtypes/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateCustomer(data?) {
    let body;
    let { email, lastname, pin, settings, ...other } = data;

    if (settings?.secondary_name_label?.length) {
      const { secondary_name_label, otherSettings } = settings;

      body = {
        ...other,
        settings: { ...otherSettings },
        ...(lastname && { lastname }),
        ...(email && { email }),
        ...(pin && { pin }),
      };
    } else {
      body = {
        ...other,
        settings: { ...settings },
        ...(lastname && { lastname }),
        ...(email && { email }),
        ...(pin && { pin }),
      };
    }

    try {
      const response = (await useVaniloApi(`/customers/${body.id}`, {
        method: "POST",
        body,
      })) as ResponseType<CustomerSingle>;

      viewingCustomer.value = response?.data;

      if (useRoute().query.sidebar) {
        editMode.value = false;
      }

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function editCustomer(id: number, body) {
    try {
      const response = (await useVaniloApi(`/customers/${id}`, {
        method: "POST",
        body,
      })) as ResponseType<CustomerSingle>;

      viewingCustomer.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateCustomerStatus(
    customerId: number | string,
    status: CustomerStatus,
    message?: string
  ): Promise<ResponseType<CustomerSingle>> {
    try {
      const response = await useVaniloApi(`/customers/${customerId}/status`, {
        method: "POST",
        body: {
          status,
          ...(message && { message }),
        },
      });

      return response as ResponseType<CustomerSingle>;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function verifyCustomerManual(customerId: number | string) {
    try {
      const response = await useVaniloApi(
        `/email/verify-manual/${customerId}`,
        {
          method: "POST",
        }
      );

      useToast(
        {
          message: "Contact verified!",
        },
        {
          type: ToastType.SUCCESS,
          duration: 3000,
        }
      );
      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function updateSupplierProducts(data) {
    try {
      const response = await useVaniloApi(`/customers/${data.id}`, {
        method: "POST",
        body: { ...data },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateStaffPin(body?) {
    try {
      const response = await useVaniloApi(`/customers/${body.id}`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateCustomerAddresses(
    customer,
    addressesList: Address[]
  ): Promise<ResponseType<CustomerSingle>> {
    const addresses = addressesList.map((address) => ({
      ...(address.id && { id: address.id }),
      type: address.type,
      name: address.name,
      province: address.province.code,
      postalcode: address.postalcode,
      country: address.country.id,
      city: address.city,
      address: address.address,
      tag: address.tag,
      ...(address.notes && { notes: address.notes }),
      ...(address.freight_company_id && {
        freight_company_id: address.freight_company_id,
      }),
      ...(address.bay_number &&
        customer.type === CustomerType.COMPANY &&
        useSettingsStore().settings.contacts.features.bay_number && {
          bay_number: address.bay_number,
        }),
      ...(address.hide_address_on_receipt && {
        hide_address_on_receipt: address.hide_address_on_receipt,
      }),
      ...(address.billing_contacts && {
        billing_contacts:
          address.billing_contacts?.map((i) => (i.id ?? i.value) || i) || [],
      }),
      is_default: address.is_default,
      ...(address.billpayer &&
        Object.keys(address.billpayer).length && {
          billpayer:
            {
              ...(address.billpayer.email && {
                email: address.billpayer.email,
              }),
              ...(address.billpayer.phone && {
                phone: customer?.phone
                  ?.split(" ")
                  ?.join("")
                  ?.includes(address.billpayer.phone.split(" ")?.join(""))
                  ? customer.phone.split(" ").join("")
                  : address.billpayer.phone.split(" ")?.join(""),
              }),
              ...(address.billpayer.mobile && {
                mobile: customer?.mobile
                  ?.split(" ")
                  ?.join("")
                  ?.includes(address.billpayer.mobile.split(" ").join(""))
                  ? customer.mobile.split(" ").join("")
                  : address.billpayer.mobile?.split(" ").join(""),
              }),
              ...(address.billpayer.firstname && {
                firstname: address.billpayer.firstname,
              }),
              ...(address.billpayer.lastname && {
                lastname: address.billpayer.lastname,
              }),
              ...(address.billpayer.company_name && {
                company_name: address.billpayer.company_name,
              }),
              ...(address.billpayer.tfn && {
                tfn: address.billpayer.tfn.split(" ").join(""),
              }),
              ...(address.billpayer.abn && {
                abn: address.billpayer.abn.split(" ").join(""),
              }),
              ...(address.billpayer.billing_contact_id && {
                billing_contact_id: address.billpayer.billing_contact_id,
              }),
            } || {},
        }),
    }));

    try {
      const response = (await useVaniloApi(`/customers/${customer.id}`, {
        method: "POST",
        body: {
          addresses,
        },
      })) as ResponseType<CustomerSingle>;

      viewingCustomer.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateCustomerAttachments(id, type, attachments) {
    try {
      const response = await useVaniloApi(`/customers/${id}`, {
        method: "POST",
        body: {
          [type]: attachments,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateCustomerStores(id, stores) {
    try {
      const response = await useVaniloApi(`/customers/${id}`, {
        method: "POST",
        body: {
          stores,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteCustomer(id) {
    try {
      const response = await useVaniloApi(`/customers/${id}`, {
        method: "DELETE",
      });

      const customerIdx = customers.value.findIndex((c) => +c.id === +id);
      customers.value.splice(customerIdx, 1);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateCustomerAvatar(data?, id?) {
    const formData = new FormData();
    formData.append("media", data.media);

    try {
      const response = (await useVaniloApi(`/customers/${id}/image`, {
        method: "POST",
        body: formData,
      })) as ResponseType<Media>;

      image.value.media = response?.data.url;
      image.value.title = response?.data.title;
      image.value.description = response?.data.description;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  function clearFilters() {
    for (let d in filters.value) {
      filters.value[d].chosenOptions = [];
    }
  }

  function clearOrdersFilters() {
    for (let d in ordersFilters.value) {
      ordersFilters.value[d].chosenOptions = [];
    }
  }

  async function getTeamMembers(params?) {
    try {
      const response = (await useVaniloApi(`/customers/team-members`, {
        params,
      })) as ResponseType<any>;

      teamMembers.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Groups
  async function getCustomerGroups() {
    try {
      const response = (await useVaniloApi(`/customer-groups`)) as ResponseType<
        Group[]
      >;

      customerGroups.value = response?.data;
      defaultGroup.value = response?.data?.find((i) => i.is_default);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleGroup(id?: number): Promise<ResponseType<Group>> {
    try {
      const response = await useVaniloApi(
        `/customer-groups/${id || viewingGroup.value.id}`
      );

      return response as ResponseType<Group>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createGroup() {
    try {
      const response = (await useVaniloApi(`/customer-groups`, {
        method: "POST",
        body: creatingGroup.value,
      })) as ResponseType<Group>;

      customerGroups.value.push(response?.data);

      if (response?.data?.is_default) {
        defaultGroup.value = response?.data;
      }

      creatingGroup.value = {} as GroupManage;

      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  async function updateGroup() {
    try {
      const response = (await useVaniloApi(
        `/customer-groups/${editingGroup.value.id}`,
        {
          method: "POST",
          body: {
            ...editingGroup.value,
            id: undefined,
          },
        }
      )) as ResponseType<Group>;

      viewingGroup.value = response?.data;

      if (response?.data?.is_default) {
        defaultGroup.value = response?.data;
      }

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteGroup() {
    try {
      const response = await useVaniloApi(
        `/customer-groups/${editingGroup.value.id}`,
        {
          method: "DELETE",
        }
      );

      if (editingGroup.value?.is_default) {
        defaultGroup.value = null;
      }

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Credit History
  async function getCreditHistory(customerId: number, params?: any) {
    try {
      const response = (await useVaniloApi(`/customers/${customerId}/notes`, {
        params,
      })) as ResponseType<any>;

      return response?.data.filter((i) => i.category === "payment");
    } catch (error) {
      useCatchError(error);
    }
  }

  // Documents
  async function uploadDocument(id, files?) {
    const formData = new FormData();

    if (files.length) {
      files
        .filter((i) => i.file)
        .forEach((i) => {
          formData.append("media[]", i.file);
          formData.append("meta[][title]", i.title);
        });
    } else {
      files.value
        .filter((i) => i.file)
        .forEach((i) => {
          formData.append("media[]", i.file);
          formData.append("meta[][title]", i.title);
        });
    }

    try {
      const response = await useVaniloApi(`/customers/${id}/documents`, {
        method: "POST",
        body: formData,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteDocument(id) {
    try {
      const response = await useVaniloApi(`/customers/documents/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Delivery Runs
  async function getDeliveryRuns(payload?) {
    const params = {
      ...(payload && payload),
    };

    try {
      const response = (await useVaniloApi("/delivery-runs", {
        params,
      })) as any;

      deliveryRuns.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleDeliveryRun(id) {
    try {
      const response = (await useVaniloApi(`/delivery-runs/${id}`)) as any;

      viewingDeliveryRun.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createDeliveryRun(payload?) {
    try {
      const response = (await useVaniloApi(`/delivery-runs`, {
        method: "POST",
        body: payload ?? editingDeliveryRun.value,
      })) as ResponseType<DeliveryRun>;

      deliveryRuns.value.push(response?.data);
      editingDeliveryRun.value = {} as DeliveryRunManage;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateDeliveryRun(id, payload?) {
    try {
      const response = (await useVaniloApi(`/delivery-runs/${id}`, {
        method: "POST",
        body: payload ?? editingDeliveryRun.value,
      })) as ResponseType<DeliveryRun>;

      deliveryRuns.value[
        deliveryRuns.value.indexOf(
          deliveryRuns.value.find((i) => i.id === response?.data.id)
        )
      ] = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteDeliveryRun(id) {
    try {
      const response = await useVaniloApi(`/delivery-runs/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Credit
  async function addCredit(id, body) {
    try {
      const response = await useVaniloApi(`customers/${id}/add-credit`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function subtractCredit(id, body) {
    try {
      const response = await useVaniloApi(`customers/${id}/subtract-credit`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Import/Export
  async function exportContacts(params) {
    try {
      const response = await useVaniloApi(
        `/customers/companies-with-contacts-export`,
        {
          method: "GET",
          params,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function importContacts(body) {
    try {
      const response = await useVaniloApi(
        `/customers/companies-with-contacts-import`,
        {
          method: "POST",
          body,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkContactsUpdateStatus(customersIds, updatesValue) {
    try {
      const response = await useVaniloApi(`/customers/bulk-update-statuses`, {
        method: "POST",
        body: {
          ...(customersIds && { customers: customersIds }),
          status: updatesValue.value.value,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkContactsUpdate(customersIds, updatesValue) {
    const payload = {};

    if (updatesValue.type === "update-tag") {
      payload["taxonomies"] = [updatesValue.value];
    }
    if (updatesValue.type === "assign-group") {
      payload["group_id"] = updatesValue.value;
    }
    if (updatesValue.type === "assign-location") {
      payload["default_store_id"] = updatesValue.value;
    }

    try {
      const response = await useVaniloApi(`/customers/bulk-update`, {
        method: "POST",
        body: {
          ...(customersIds && { customers: customersIds }),
          ...payload,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkContactsSendNotification(payload?, customers?) {
    try {
      const response = await useVaniloApi(`/customers/bulk-notify`, {
        method: "POST",
        body: {
          ...(selectedRowsIds.value.length && {
            customers: selectedRowsIds.value,
          }),
          ...(customers?.length && { customers: customers }),
          notification: payload,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkContactsDelete(customersIds) {
    try {
      const response = await useVaniloApi(`/customers/bulk-delete`, {
        method: "POST",
        body: {
          ...(customersIds && { customers: customersIds }),
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function bulkSyncContactsByIds(body, storeId?: string) {
    try {
      useIsLoading(true);
      const response = (await useVaniloApi(
        `/xero/bulk-sync-contacts/${storeId}`,
        {
          method: "POST",
          body,
        }
      )) as ResponseType<any>;

      useTasksStore().taskId = response.data.id;

      return response;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    } finally {
      useIsLoading(false);
    }
  }

  return {
    // State
    customers,
    isLoading,
    page,
    perPage,
    totalContacts,
    totalPages,
    contactsFilterBySubtype,
    companiesFilterBySubtype,
    tabs,
    profileSearch,
    searchCustomerProfile,
    previousTab,
    selectedCustomerTypeForCreate,
    editingProfileCustomer,
    viewingProfileCustomer,
    quickViewProfileCustomer,
    deliveryRuns,
    viewingDeliveryRun,
    editingDeliveryRun,
    products,
    contactTags,
    passwordRequest,
    contactAddressNew,
    featureRequestForAddCredit,
    image,
    files,
    isCheckShipAddress,
    isNewDeliveryAddress,
    ordersFilters,
    targetDateRange,
    selectedPaymentStatuses,
    selectedStatuses,
    paymentStatuses,
    addressTags,
    genders,
    planTypes,
    planManagementTypes,
    addressTypes,
    creditTermTypes,
    attachedContacts,
    sidebarAttachedContacts,
    attachedLocations,
    sidebarAttachedLocations,
    deliveryRunsCustomer,
    viewingDeliveryRunCustomer,
    editingDeliveryRunCustomer,
    customerTaxonomies,
    supplierProducts,
    productsCustomer,
    tags,
    customerAddressNew,
    customer_image,
    customer_files,
    searchCustomer,
    editingCustomer,
    viewingCustomer,
    viewingCustomerFinance,
    currentUserCustomer,
    activeCustomer,
    editMode,
    currentCustomer,
    customerSubtypes,
    viewingSubtype,
    customerStatuses,
    customerDropdown,
    filters,
    isOpenSidebar,
    isOpenSidebarSettings,
    customerGroups,
    viewingGroup,
    editingGroup,
    creatingGroup,
    defaultGroup,
    groupPricingMethod,
    priceRules,
    roundingRules,
    roundingPercents,
    pageTable,
    perPageTable,
    selectedRowsIds,
    baseTableKey,
    previousCustomersPage,
    ordersCustomerName,
    teamMembers,

    // Getters
    companyType,
    sortedCustomerList,
    groupOptions,
    defaultGroupId,
    isOverdueSelected,
    isAccountSelected,
    pickers,

    // Actions
    createCustomer,
    getCustomers,
    getCustomersBff,
    getCompanies,
    resetCustomersList,
    getPayableCustomers,
    getFilteredCustomers,
    getSortedCustomers,
    getSingleCustomer,
    getCustomerFinance,
    getCurrentCustomerProfile,
    getCustomersTags,
    getSubtypes,
    getSingleSubtype,
    createSubtype,
    updateSubtype,
    deleteSubtype,
    updateCustomer,
    editCustomer,
    updateCustomerStatus,
    updateSupplierProducts,
    updateStaffPin,
    updateCustomerAddresses,
    updateCustomerAttachments,
    updateCustomerStores,
    deleteCustomer,
    updateCustomerAvatar,
    clearFilters,
    clearOrdersFilters,
    getTeamMembers,
    getCustomerGroups,
    getSingleGroup,
    createGroup,
    updateGroup,
    deleteGroup,
    getCreditHistory,
    uploadDocument,
    deleteDocument,
    getDeliveryRuns,
    getSingleDeliveryRun,
    createDeliveryRun,
    updateDeliveryRun,
    deleteDeliveryRun,
    addCredit,
    subtractCredit,
    exportContacts,
    importContacts,
    bulkContactsUpdateStatus,
    bulkContactsUpdate,
    bulkContactsSendNotification,
    bulkContactsDelete,
    bulkSyncContactsByIds,
    verifyCustomerManual,
  };
});
