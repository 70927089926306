import { useGeneralStore } from "~/store/general";
import { ToastType } from "~/types/general";

export default defineNuxtPlugin(() => {
  const generalStore = useGeneralStore();

  function toast(
    toast: { message: string; submessage?: string } | string,
    options?: { type: ToastType; duration?: number }
  ): void {
    const newToast = {
      id: "toast" + Date.now(),
      message: typeof toast === "string" ? toast : toast.message,
      submessage: typeof toast === "string" ? "" : toast.submessage,
      type: options.type || ToastType.SUCCESS,
    };

    generalStore.toastsList.push(newToast);

    setTimeout(() => {
      generalStore.toastsList = generalStore.toastsList.filter(
        (t) => t.id !== newToast.id
      );
    }, options?.duration || 3000);
  }

  return {
    provide: {
      toast,
    },
  };
});
