export const useGetPosItemPayload = (item) => ({
  ...(item.id && { id: item.id }),
  ...(item.product_id && { product_id: item.product_id }),
  ...((useConfig().isPosCustomPrice || !item.product_id) && {
    price: item.old_price ?? item.price,
  }),
  ...(item.addons?.length && { addons: item.addons }),
  ...(item.picker_notes?.length && { picker_notes: item.picker_notes }),
  ...(item.lot_id &&
    !(item.supplier_id || item.supplier) && { lot_id: item.lot_id }),
  ...((item.supplier_id || item.supplier) && {
    supplier_id: item.supplier_id || item.supplier?.id,
  }),
  quantity: item.quantity,
  // allocated: item.quantity,
  // DISCOUNTS
  ...(item.discount && { discount: -Math.abs(item.discount) }),
  ...(item.discount_percent && { discount_percent: item.discount_percent }),
});
