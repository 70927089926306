import moment from "moment-timezone";
import { DateFormatMoment } from "~/types/general";

export const useFormatDate = (date, format?: DateFormatMoment) => {
  // backend timezone (Melbourne)
  const backendTimeZone = "Australia/Melbourne";

  // user's timezone, detected from the browser
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // parse the date in the backend timezone, then convert to the user's timezone
  const targetTimeInUserTimeZone = moment
    .tz(date, backendTimeZone)
    .tz(userTimeZone);

  // get the current date in the user's timezone
  const nowInUserTimeZone = moment().tz(userTimeZone);

  // calculate the difference in milliseconds
  const diffTime = nowInUserTimeZone.diff(targetTimeInUserTimeZone);

  const secondsAgo = moment.duration(diffTime).asSeconds();
  const minutesAgo = moment.duration(diffTime).asMinutes();
  const hoursAgo = moment.duration(diffTime).asHours();
  const daysAgo = moment.duration(diffTime).asDays();

  if (secondsAgo < 1) {
    // if the difference is less than 1 second
    return "just now";
  } else if (secondsAgo < 60) {
    return `${Math.floor(secondsAgo)}s ago`;
  } else if (minutesAgo < 60) {
    return `${Math.floor(minutesAgo)}m ago`;
  } else if (hoursAgo < 24) {
    return `${Math.floor(hoursAgo)}h ago`;
  } else if (daysAgo < 7) {
    return `${Math.ceil(daysAgo)}d ago`;
  } else {
    // for dates beyond the last week, use the given format
    return targetTimeInUserTimeZone.format(
      format || DateFormatMoment["MMMM D, YYYY [at] hh:mmA"]
    );
  }
};
