export interface Discount {
  id: number;
  name: string;
  description: string;
  percent_amount: number;
  fixed_amount: number;
  is_enabled: boolean;
}

export interface DiscountManagePayload {
  name: string;
  description: string;
  percent_amount: number;
  is_enabled: boolean;
}

export enum DiscountType {
  PERCENTAGE = "PERCENTAGE",
  FIXED = "FIXED",
}
