<template>
  <div>
    <!-- SELECTED -->
    <template v-if="selected">
      <div
        class="border mx-x1 my-x1 px-2.5 py-3px text-sm leading-base font-medium text-font-primary gap-2.5 flex items-center bg-light-background cursor-pointer"
        :class="[square ? 'rounded-x1' : 'rounded-x10']"
        @click="emit('click')"
      >
        {{ text }}
        <button @click="emit('click')" v-if="!disabled">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.97445 5.00259L9.79803 1.18074C10.0673 0.909719 10.0673 0.471258 9.79803 0.201968C9.52874 -0.0673226 9.09028 -0.0673226 8.82617 0.201968L5.00259 4.02555L1.18074 0.201968C0.909719 -0.0673226 0.471258 -0.0673226 0.201968 0.201968C-0.0673226 0.471258 -0.0673226 0.909719 0.201968 1.18074L4.02555 5.00259L0.201968 8.82617C-0.0673226 9.09028 -0.0673226 9.52874 0.201968 9.79803C0.471258 10.0673 0.909719 10.0673 1.18074 9.79803L5.00259 5.97445L8.82617 9.79803C9.09028 10.0673 9.52874 10.0673 9.79803 9.79803C10.0673 9.52874 10.0673 9.09028 9.79803 8.82617L5.97445 5.00259Z"
              fill="#B4B4B4"
            />
          </svg>
        </button>
      </div>
    </template>

    <!-- NOT SELECTED -->
    <template v-else>
      <div
        class="border mx-x1 my-x1 px-2.5 py-3px text-sm leading-base font-medium text-font-primary gap-2.5 flex items-center bg-white cursor-pointer"
        :class="[square ? 'rounded-x1' : 'rounded-x10']"
        @click="emit('click')"
      >
        <button @click="emit('click')" v-if="!disabled">
          <svg
            class="transform rotate-45"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.97445 5.00259L9.79803 1.18074C10.0673 0.909719 10.0673 0.471258 9.79803 0.201968C9.52874 -0.0673226 9.09028 -0.0673226 8.82617 0.201968L5.00259 4.02555L1.18074 0.201968C0.909719 -0.0673226 0.471258 -0.0673226 0.201968 0.201968C-0.0673226 0.471258 -0.0673226 0.909719 0.201968 1.18074L4.02555 5.00259L0.201968 8.82617C-0.0673226 9.09028 -0.0673226 9.52874 0.201968 9.79803C0.471258 10.0673 0.909719 10.0673 1.18074 9.79803L5.00259 5.97445L8.82617 9.79803C9.09028 10.0673 9.52874 10.0673 9.79803 9.79803C10.0673 9.52874 10.0673 9.09028 9.79803 8.82617L5.97445 5.00259Z"
              fill="#B4B4B4"
            />
          </svg>
        </button>
        {{ text }}
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  text: String,
  square: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
  },
});

const emit = defineEmits(["click"]);
</script>
