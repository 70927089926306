// @ts-nocheck
// eslint-disable
// ---------------------------------------------------
// 🚗🚦 Generated by nuxt-typed-router. Do not modify !
// ---------------------------------------------------

/**
 * Exhaustive list of all the available route names in the app
 * */
export type RoutesNamesList =
  | 'agreements-id'
  | 'agreements-id-location-store'
  | 'agreements-id-location-new'
  | 'ai'
  | 'allocations-manage-id'
  | 'analytics'
  | 'bills-manage-id'
  | 'booking-type-name'
  | 'booking-type'
  | 'booking-account-tab'
  | 'booking-account'
  | 'booking-account-login-screen'
  | 'booking'
  | 'booking-order-id-agree'
  | 'booking-order-id-confirmed'
  | 'booking-order-id-review'
  | 'booking-provider-id'
  | 'booking-provider-id-service-serviceid'
  | 'calendar'
  | 'checkout-new-step-id'
  | 'checkout'
  | 'checkout-thankyou'
  | 'composite-name'
  | 'composite-new'
  | 'contacts-id-tab'
  | 'contacts-id-tab-service-area-zone'
  | 'contacts-id-tab-service-area'
  | 'contacts-id'
  | 'contacts-calendar'
  | 'contacts-companies'
  | 'contacts-customers'
  | 'contacts'
  | 'contacts-loyalty-rewards-id'
  | 'contacts-loyalty-rewards'
  | 'contacts-new-tab'
  | 'contacts-new'
  | 'contacts-old-profile-id'
  | 'contacts-old-profile-new'
  | 'contacts-people'
  | 'contacts-staff'
  | 'counts-step-id'
  | 'index'
  | 'invoices-manage-id'
  | 'location-tab'
  | 'location'
  | 'login-screen'
  | 'login-google-calendar'
  | 'login-outlook-calendar'
  | 'management-brands'
  | 'management-locations-id-tab'
  | 'management-locations-id'
  | 'management-locations-id-service-area-zone'
  | 'management-locations-id-service-area'
  | 'management-locations'
  | 'management-locations-new-tab'
  | 'management-locations-new'
  | 'management-locations-settings-tab'
  | 'management-locations-settings'
  | 'management-settings'
  | 'old-location-id'
  | 'onboarding-type-name'
  | 'onboarding-type-error'
  | 'onboarding-type'
  | 'onboarding-type-success'
  | 'onboarding-privacy-policy'
  | 'onboarding-terms-and-conditions'
  | 'ordering'
  | 'orders-allocations'
  | 'orders-fulfilment'
  | 'orders-fulfilment-manage-freight-id'
  | 'orders-fulfilment-manage-run-id'
  | 'orders'
  | 'orders-invoices'
  | 'orders-preparing'
  | 'orders-preparing-picking-id-tab'
  | 'orders-preparing-picking-id'
  | 'orders-schedule'
  | 'orders-statements'
  | 'orders-statements-manage-id'
  | 'password-reset'
  | 'picker-account'
  | 'pos-checkout'
  | 'pos-checkout-thankyou-id'
  | 'pos-customers'
  | 'pos-lockscreen'
  | 'pos-orders-customer-id'
  | 'pos-orders'
  | 'pos-shop'
  | 'print-container-id-bill'
  | 'print-container-id-bill-print'
  | 'print-container-id-bill-receipt'
  | 'print-container-id-invoice'
  | 'print-container-id-receipt'
  | 'print-container-id-statement-type'
  | 'print-container-batch-deposit-id-a4'
  | 'print-container-batch-deposit-id-thermal'
  | 'print-container-confirmation-id'
  | 'print-container-invoice-id-a4'
  | 'print-container-invoice-id-thermal'
  | 'print-container-manifest-id'
  | 'print-container-order-forms-id'
  | 'print-container-order-forms-barcode-id'
  | 'print-container-picking-id'
  | 'print-container-preparation-id-a4'
  | 'print-container-preparation-id-thermal'
  | 'print-container-statements-id'
  | 'print-container-summary-id-a4'
  | 'print-container-summary-id-thermal'
  | 'print-id-type-report'
  | 'product-name'
  | 'product-new'
  | 'products-composites'
  | 'products-counts'
  | 'products'
  | 'products-inventory'
  | 'products-price-lists'
  | 'products-services-inventory-id-tab'
  | 'products-services-inventory-id'
  | 'products-services-inventory'
  | 'products-services'
  | 'products-settings-tab'
  | 'products-settings'
  | 'profile-tab'
  | 'profile'
  | 'profile-service-area-zone'
  | 'profile-service-area'
  | 'purchase-order-pay-by-link-id'
  | 'purchase-order-pay-by-link-id-pay'
  | 'purchase-order-pay-by-link-id-payment-completed'
  | 'purchase-orders-bills'
  | 'purchase-orders'
  | 'purchase-orders-manage-id'
  | 'purchase-orders-new'
  | 'purchase-orders-purchase-overview'
  | 'purchase-orders-purchase-overview-manage-id'
  | 'purchase-orders-receiving-dispatch-id'
  | 'purchase-orders-receiving'
  | 'receivables-mode-id'
  | 'receivables'
  | 'reports-analytics-tab'
  | 'reports-analytics'
  | 'reports-costs-tab'
  | 'reports-costs'
  | 'reports-products-tab'
  | 'reports-products'
  | 'reports-revenue-tab'
  | 'reports-revenue'
  | 'review'
  | 'service-name'
  | 'service-new'
  | 'single-order-mode-id'
  | 'single-order-pay-by-link-id'
  | 'single-order-pay-by-link-id-pay'
  | 'single-order-pay-by-link-id-payment-completed'
  | 'table-ordering'
  | 'taxonomies'
  | 'test'
  | 'tiptap'
  | 'transfers'
  | 'units-overview-manage-id'
  | 'user-tab'
  | 'user'
  | 'user-orders';

/**
 * Routes params are only required for the exact targeted route name,
 * vue-router behaviour allow to navigate between children routes without the need to provide all the params every time.
 * So we can't enforce params when navigating between routes, only a `[xxx].vue` page will have required params in the type definition
 *
 * */
export type RoutesParamsRecord = {
  'agreements-id': {
    id: string | number;
  };
  'agreements-id-location-store': {
    id: string | number;
    store: string | number;
  };
  'agreements-id-location-new': {
    id: string | number;
  };
  ai: never;
  'allocations-manage-id': {
    id: string | number;
  };
  analytics: never;
  'bills-manage-id': {
    id: string | number;
  };
  'booking-type-name': {
    type: string | number;
    name: string | number;
  };
  'booking-type': {
    type: string | number;
  };
  'booking-account-tab': {
    tab: string | number;
  };
  'booking-account': never;
  'booking-account-login-screen': {
    screen?: string | number;
  };
  booking: never;
  'booking-order-id-agree': {
    id: string | number;
  };
  'booking-order-id-confirmed': {
    id: string | number;
  };
  'booking-order-id-review': {
    id: string | number;
  };
  'booking-provider-id': {
    id: string | number;
  };
  'booking-provider-id-service-serviceid': {
    id: string | number;
    serviceid: string | number;
  };
  calendar: never;
  'checkout-new-step-id': {
    step?: string | number;
    id?: string | number;
  };
  checkout: never;
  'checkout-thankyou': never;
  'composite-name': {
    name?: string | number;
  };
  'composite-new': never;
  'contacts-id-tab': {
    id: string | number;
    tab: string | number;
  };
  'contacts-id-tab-service-area-zone': {
    id: string | number;
    tab: string | number;
    zone: string | number;
  };
  'contacts-id-tab-service-area': {
    id: string | number;
    tab: string | number;
  };
  'contacts-id': {
    id: string | number;
  };
  'contacts-calendar': never;
  'contacts-companies': never;
  'contacts-customers': never;
  contacts: never;
  'contacts-loyalty-rewards-id': {
    id: string | number;
  };
  'contacts-loyalty-rewards': never;
  'contacts-new-tab': {
    tab: string | number;
  };
  'contacts-new': never;
  'contacts-old-profile-id': {
    id: string | number;
  };
  'contacts-old-profile-new': never;
  'contacts-people': never;
  'contacts-staff': never;
  'counts-step-id': {
    step: string | number;
    id: string | number;
  };
  index: never;
  'invoices-manage-id': {
    id: string | number;
  };
  'location-tab': {
    tab: string | number;
  };
  location: never;
  'login-screen': {
    screen?: string | number;
  };
  'login-google-calendar': never;
  'login-outlook-calendar': never;
  'management-brands': never;
  'management-locations-id-tab': {
    id: string | number;
    tab: string | number;
  };
  'management-locations-id': {
    id: string | number;
  };
  'management-locations-id-service-area-zone': {
    id: string | number;
    zone: string | number;
  };
  'management-locations-id-service-area': {
    id: string | number;
  };
  'management-locations': never;
  'management-locations-new-tab': {
    tab: string | number;
  };
  'management-locations-new': never;
  'management-locations-settings-tab': {
    tab: string | number;
  };
  'management-locations-settings': never;
  'management-settings': never;
  'old-location-id': {
    id: string | number;
  };
  'onboarding-type-name': {
    type: string | number;
    name: string | number;
  };
  'onboarding-type-error': {
    type: string | number;
  };
  'onboarding-type': {
    type: string | number;
  };
  'onboarding-type-success': {
    type: string | number;
  };
  'onboarding-privacy-policy': never;
  'onboarding-terms-and-conditions': never;
  ordering: never;
  'orders-allocations': never;
  'orders-fulfilment': never;
  'orders-fulfilment-manage-freight-id': {
    id: string | number;
  };
  'orders-fulfilment-manage-run-id': {
    id: string | number;
  };
  orders: never;
  'orders-invoices': never;
  'orders-preparing': never;
  'orders-preparing-picking-id-tab': {
    id: string | number;
    tab: string | number;
  };
  'orders-preparing-picking-id': {
    id: string | number;
  };
  'orders-schedule': never;
  'orders-statements': never;
  'orders-statements-manage-id': {
    id: string | number;
  };
  'password-reset': never;
  'picker-account': never;
  'pos-checkout': never;
  'pos-checkout-thankyou-id': {
    id?: string | number;
  };
  'pos-customers': never;
  'pos-lockscreen': never;
  'pos-orders-customer-id': {
    id: string | number;
  };
  'pos-orders': never;
  'pos-shop': never;
  'print-container-id-bill': {
    id: string | number;
  };
  'print-container-id-bill-print': {
    id: string | number;
  };
  'print-container-id-bill-receipt': {
    id: string | number;
  };
  'print-container-id-invoice': {
    id: string | number;
  };
  'print-container-id-receipt': {
    id: string | number;
  };
  'print-container-id-statement-type': {
    id: string | number;
    type: string | number;
  };
  'print-container-batch-deposit-id-a4': {
    id: string | number;
  };
  'print-container-batch-deposit-id-thermal': {
    id: string | number;
  };
  'print-container-confirmation-id': {
    id: string | number;
  };
  'print-container-invoice-id-a4': {
    id: string | number;
  };
  'print-container-invoice-id-thermal': {
    id: string | number;
  };
  'print-container-manifest-id': {
    id: string | number;
  };
  'print-container-order-forms-id': {
    id: string | number;
  };
  'print-container-order-forms-barcode-id': {
    id: string | number;
  };
  'print-container-picking-id': {
    id: string | number;
  };
  'print-container-preparation-id-a4': {
    id: string | number;
  };
  'print-container-preparation-id-thermal': {
    id: string | number;
  };
  'print-container-statements-id': {
    id: string | number;
  };
  'print-container-summary-id-a4': {
    id: string | number;
  };
  'print-container-summary-id-thermal': {
    id: string | number;
  };
  'print-id-type-report': {
    id: string | number;
    type: string | number;
    report?: string | number;
  };
  'product-name': {
    name?: string | number;
  };
  'product-new': never;
  'products-composites': never;
  'products-counts': never;
  products: never;
  'products-inventory': never;
  'products-price-lists': never;
  'products-services-inventory-id-tab': {
    id: string | number;
    tab: string | number;
  };
  'products-services-inventory-id': {
    id: string | number;
  };
  'products-services-inventory': never;
  'products-services': never;
  'products-settings-tab': {
    tab: string | number;
  };
  'products-settings': never;
  'profile-tab': {
    tab: string | number;
  };
  profile: never;
  'profile-service-area-zone': {
    zone: string | number;
  };
  'profile-service-area': never;
  'purchase-order-pay-by-link-id': {
    id: string | number;
  };
  'purchase-order-pay-by-link-id-pay': {
    id: string | number;
  };
  'purchase-order-pay-by-link-id-payment-completed': {
    id: string | number;
  };
  'purchase-orders-bills': never;
  'purchase-orders': never;
  'purchase-orders-manage-id': {
    id: string | number;
  };
  'purchase-orders-new': never;
  'purchase-orders-purchase-overview': never;
  'purchase-orders-purchase-overview-manage-id': {
    id: string | number;
  };
  'purchase-orders-receiving-dispatch-id': {
    id: string | number;
  };
  'purchase-orders-receiving': never;
  'receivables-mode-id': {
    mode: string | number;
    id: string | number;
  };
  receivables: never;
  'reports-analytics-tab': {
    tab: string | number;
  };
  'reports-analytics': never;
  'reports-costs-tab': {
    tab: string | number;
  };
  'reports-costs': never;
  'reports-products-tab': {
    tab: string | number;
  };
  'reports-products': never;
  'reports-revenue-tab': {
    tab: string | number;
  };
  'reports-revenue': never;
  review: never;
  'service-name': {
    name?: string | number;
  };
  'service-new': never;
  'single-order-mode-id': {
    mode: string | number;
    id: string | number;
  };
  'single-order-pay-by-link-id': {
    id: string | number;
  };
  'single-order-pay-by-link-id-pay': {
    id: string | number;
  };
  'single-order-pay-by-link-id-payment-completed': {
    id: string | number;
  };
  'table-ordering': never;
  taxonomies: never;
  test: never;
  tiptap: never;
  transfers: never;
  'units-overview-manage-id': {
    id: string | number;
  };
  'user-tab': {
    tab: string | number;
  };
  user: never;
  'user-orders': never;
};

/**
 * Record resolved used for resolved routes
 *
 * */
export type RoutesParamsRecordResolved = {
  'agreements-id': {
    id: string;
  };
  'agreements-id-location-store': {
    id: string;
    store: string;
  };
  'agreements-id-location-new': {
    id: string;
  };
  ai: never;
  'allocations-manage-id': {
    id: string;
  };
  analytics: never;
  'bills-manage-id': {
    id: string;
  };
  'booking-type-name': {
    type: string;
    name: string;
  };
  'booking-type': {
    type: string;
  };
  'booking-account-tab': {
    tab: string;
  };
  'booking-account': never;
  'booking-account-login-screen': {
    screen?: string;
  };
  booking: never;
  'booking-order-id-agree': {
    id: string;
  };
  'booking-order-id-confirmed': {
    id: string;
  };
  'booking-order-id-review': {
    id: string;
  };
  'booking-provider-id': {
    id: string;
  };
  'booking-provider-id-service-serviceid': {
    id: string;
    serviceid: string;
  };
  calendar: never;
  'checkout-new-step-id': {
    step?: string;
    id?: string;
  };
  checkout: never;
  'checkout-thankyou': never;
  'composite-name': {
    name?: string;
  };
  'composite-new': never;
  'contacts-id-tab': {
    id: string;
    tab: string;
  };
  'contacts-id-tab-service-area-zone': {
    id: string;
    tab: string;
    zone: string;
  };
  'contacts-id-tab-service-area': {
    id: string;
    tab: string;
  };
  'contacts-id': {
    id: string;
  };
  'contacts-calendar': never;
  'contacts-companies': never;
  'contacts-customers': never;
  contacts: never;
  'contacts-loyalty-rewards-id': {
    id: string;
  };
  'contacts-loyalty-rewards': never;
  'contacts-new-tab': {
    tab: string;
  };
  'contacts-new': never;
  'contacts-old-profile-id': {
    id: string;
  };
  'contacts-old-profile-new': never;
  'contacts-people': never;
  'contacts-staff': never;
  'counts-step-id': {
    step: string;
    id: string;
  };
  index: never;
  'invoices-manage-id': {
    id: string;
  };
  'location-tab': {
    tab: string;
  };
  location: never;
  'login-screen': {
    screen?: string;
  };
  'login-google-calendar': never;
  'login-outlook-calendar': never;
  'management-brands': never;
  'management-locations-id-tab': {
    id: string;
    tab: string;
  };
  'management-locations-id': {
    id: string;
  };
  'management-locations-id-service-area-zone': {
    id: string;
    zone: string;
  };
  'management-locations-id-service-area': {
    id: string;
  };
  'management-locations': never;
  'management-locations-new-tab': {
    tab: string;
  };
  'management-locations-new': never;
  'management-locations-settings-tab': {
    tab: string;
  };
  'management-locations-settings': never;
  'management-settings': never;
  'old-location-id': {
    id: string;
  };
  'onboarding-type-name': {
    type: string;
    name: string;
  };
  'onboarding-type-error': {
    type: string;
  };
  'onboarding-type': {
    type: string;
  };
  'onboarding-type-success': {
    type: string;
  };
  'onboarding-privacy-policy': never;
  'onboarding-terms-and-conditions': never;
  ordering: never;
  'orders-allocations': never;
  'orders-fulfilment': never;
  'orders-fulfilment-manage-freight-id': {
    id: string;
  };
  'orders-fulfilment-manage-run-id': {
    id: string;
  };
  orders: never;
  'orders-invoices': never;
  'orders-preparing': never;
  'orders-preparing-picking-id-tab': {
    id: string;
    tab: string;
  };
  'orders-preparing-picking-id': {
    id: string;
  };
  'orders-schedule': never;
  'orders-statements': never;
  'orders-statements-manage-id': {
    id: string;
  };
  'password-reset': never;
  'picker-account': never;
  'pos-checkout': never;
  'pos-checkout-thankyou-id': {
    id?: string;
  };
  'pos-customers': never;
  'pos-lockscreen': never;
  'pos-orders-customer-id': {
    id: string;
  };
  'pos-orders': never;
  'pos-shop': never;
  'print-container-id-bill': {
    id: string;
  };
  'print-container-id-bill-print': {
    id: string;
  };
  'print-container-id-bill-receipt': {
    id: string;
  };
  'print-container-id-invoice': {
    id: string;
  };
  'print-container-id-receipt': {
    id: string;
  };
  'print-container-id-statement-type': {
    id: string;
    type: string;
  };
  'print-container-batch-deposit-id-a4': {
    id: string;
  };
  'print-container-batch-deposit-id-thermal': {
    id: string;
  };
  'print-container-confirmation-id': {
    id: string;
  };
  'print-container-invoice-id-a4': {
    id: string;
  };
  'print-container-invoice-id-thermal': {
    id: string;
  };
  'print-container-manifest-id': {
    id: string;
  };
  'print-container-order-forms-id': {
    id: string;
  };
  'print-container-order-forms-barcode-id': {
    id: string;
  };
  'print-container-picking-id': {
    id: string;
  };
  'print-container-preparation-id-a4': {
    id: string;
  };
  'print-container-preparation-id-thermal': {
    id: string;
  };
  'print-container-statements-id': {
    id: string;
  };
  'print-container-summary-id-a4': {
    id: string;
  };
  'print-container-summary-id-thermal': {
    id: string;
  };
  'print-id-type-report': {
    id: string;
    type: string;
    report?: string;
  };
  'product-name': {
    name?: string;
  };
  'product-new': never;
  'products-composites': never;
  'products-counts': never;
  products: never;
  'products-inventory': never;
  'products-price-lists': never;
  'products-services-inventory-id-tab': {
    id: string;
    tab: string;
  };
  'products-services-inventory-id': {
    id: string;
  };
  'products-services-inventory': never;
  'products-services': never;
  'products-settings-tab': {
    tab: string;
  };
  'products-settings': never;
  'profile-tab': {
    tab: string;
  };
  profile: never;
  'profile-service-area-zone': {
    zone: string;
  };
  'profile-service-area': never;
  'purchase-order-pay-by-link-id': {
    id: string;
  };
  'purchase-order-pay-by-link-id-pay': {
    id: string;
  };
  'purchase-order-pay-by-link-id-payment-completed': {
    id: string;
  };
  'purchase-orders-bills': never;
  'purchase-orders': never;
  'purchase-orders-manage-id': {
    id: string;
  };
  'purchase-orders-new': never;
  'purchase-orders-purchase-overview': never;
  'purchase-orders-purchase-overview-manage-id': {
    id: string;
  };
  'purchase-orders-receiving-dispatch-id': {
    id: string;
  };
  'purchase-orders-receiving': never;
  'receivables-mode-id': {
    mode: string;
    id: string;
  };
  receivables: never;
  'reports-analytics-tab': {
    tab: string;
  };
  'reports-analytics': never;
  'reports-costs-tab': {
    tab: string;
  };
  'reports-costs': never;
  'reports-products-tab': {
    tab: string;
  };
  'reports-products': never;
  'reports-revenue-tab': {
    tab: string;
  };
  'reports-revenue': never;
  review: never;
  'service-name': {
    name?: string;
  };
  'service-new': never;
  'single-order-mode-id': {
    mode: string;
    id: string;
  };
  'single-order-pay-by-link-id': {
    id: string;
  };
  'single-order-pay-by-link-id-pay': {
    id: string;
  };
  'single-order-pay-by-link-id-payment-completed': {
    id: string;
  };
  'table-ordering': never;
  taxonomies: never;
  test: never;
  tiptap: never;
  transfers: never;
  'units-overview-manage-id': {
    id: string;
  };
  'user-tab': {
    tab: string;
  };
  user: never;
  'user-orders': never;
};

/**
 * Discriminated union that will allow to infer params based on route name
 * It's used for programmatic navigation like router.push or <NuxtLink/>
 * */
export type RoutesNamedLocations =
  | {
      name: 'agreements-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'agreements-id-location-store';
      params: {
        id: string | number;
        store: string | number;
      };
    }
  | {
      name: 'agreements-id-location-new';
      params: {
        id: string | number;
      };
    }
  | { name: 'ai' }
  | {
      name: 'allocations-manage-id';
      params: {
        id: string | number;
      };
    }
  | { name: 'analytics' }
  | {
      name: 'bills-manage-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'booking-type-name';
      params: {
        type: string | number;
        name: string | number;
      };
    }
  | {
      name: 'booking-type';
      params: {
        type: string | number;
      };
    }
  | {
      name: 'booking-account-tab';
      params: {
        tab: string | number;
      };
    }
  | { name: 'booking-account' }
  | {
      name: 'booking-account-login-screen';
      params?: {
        screen?: string | number;
      };
    }
  | { name: 'booking' }
  | {
      name: 'booking-order-id-agree';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'booking-order-id-confirmed';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'booking-order-id-review';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'booking-provider-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'booking-provider-id-service-serviceid';
      params: {
        id: string | number;
        serviceid: string | number;
      };
    }
  | { name: 'calendar' }
  | {
      name: 'checkout-new-step-id';
      params?: {
        step?: string | number;
        id?: string | number;
      };
    }
  | { name: 'checkout' }
  | { name: 'checkout-thankyou' }
  | {
      name: 'composite-name';
      params?: {
        name?: string | number;
      };
    }
  | { name: 'composite-new' }
  | {
      name: 'contacts-id-tab';
      params: {
        id: string | number;
        tab: string | number;
      };
    }
  | {
      name: 'contacts-id-tab-service-area-zone';
      params: {
        id: string | number;
        tab: string | number;
        zone: string | number;
      };
    }
  | {
      name: 'contacts-id-tab-service-area';
      params: {
        id: string | number;
        tab: string | number;
      };
    }
  | {
      name: 'contacts-id';
      params: {
        id: string | number;
      };
    }
  | { name: 'contacts-calendar' }
  | { name: 'contacts-companies' }
  | { name: 'contacts-customers' }
  | { name: 'contacts' }
  | {
      name: 'contacts-loyalty-rewards-id';
      params: {
        id: string | number;
      };
    }
  | { name: 'contacts-loyalty-rewards' }
  | {
      name: 'contacts-new-tab';
      params: {
        tab: string | number;
      };
    }
  | { name: 'contacts-new' }
  | {
      name: 'contacts-old-profile-id';
      params: {
        id: string | number;
      };
    }
  | { name: 'contacts-old-profile-new' }
  | { name: 'contacts-people' }
  | { name: 'contacts-staff' }
  | {
      name: 'counts-step-id';
      params: {
        step: string | number;
        id: string | number;
      };
    }
  | { name: 'index' }
  | {
      name: 'invoices-manage-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'location-tab';
      params: {
        tab: string | number;
      };
    }
  | { name: 'location' }
  | {
      name: 'login-screen';
      params?: {
        screen?: string | number;
      };
    }
  | { name: 'login-google-calendar' }
  | { name: 'login-outlook-calendar' }
  | { name: 'management-brands' }
  | {
      name: 'management-locations-id-tab';
      params: {
        id: string | number;
        tab: string | number;
      };
    }
  | {
      name: 'management-locations-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'management-locations-id-service-area-zone';
      params: {
        id: string | number;
        zone: string | number;
      };
    }
  | {
      name: 'management-locations-id-service-area';
      params: {
        id: string | number;
      };
    }
  | { name: 'management-locations' }
  | {
      name: 'management-locations-new-tab';
      params: {
        tab: string | number;
      };
    }
  | { name: 'management-locations-new' }
  | {
      name: 'management-locations-settings-tab';
      params: {
        tab: string | number;
      };
    }
  | { name: 'management-locations-settings' }
  | { name: 'management-settings' }
  | {
      name: 'old-location-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'onboarding-type-name';
      params: {
        type: string | number;
        name: string | number;
      };
    }
  | {
      name: 'onboarding-type-error';
      params: {
        type: string | number;
      };
    }
  | {
      name: 'onboarding-type';
      params: {
        type: string | number;
      };
    }
  | {
      name: 'onboarding-type-success';
      params: {
        type: string | number;
      };
    }
  | { name: 'onboarding-privacy-policy' }
  | { name: 'onboarding-terms-and-conditions' }
  | { name: 'ordering' }
  | { name: 'orders-allocations' }
  | { name: 'orders-fulfilment' }
  | {
      name: 'orders-fulfilment-manage-freight-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'orders-fulfilment-manage-run-id';
      params: {
        id: string | number;
      };
    }
  | { name: 'orders' }
  | { name: 'orders-invoices' }
  | { name: 'orders-preparing' }
  | {
      name: 'orders-preparing-picking-id-tab';
      params: {
        id: string | number;
        tab: string | number;
      };
    }
  | {
      name: 'orders-preparing-picking-id';
      params: {
        id: string | number;
      };
    }
  | { name: 'orders-schedule' }
  | { name: 'orders-statements' }
  | {
      name: 'orders-statements-manage-id';
      params: {
        id: string | number;
      };
    }
  | { name: 'password-reset' }
  | { name: 'picker-account' }
  | { name: 'pos-checkout' }
  | {
      name: 'pos-checkout-thankyou-id';
      params?: {
        id?: string | number;
      };
    }
  | { name: 'pos-customers' }
  | { name: 'pos-lockscreen' }
  | {
      name: 'pos-orders-customer-id';
      params: {
        id: string | number;
      };
    }
  | { name: 'pos-orders' }
  | { name: 'pos-shop' }
  | {
      name: 'print-container-id-bill';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-id-bill-print';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-id-bill-receipt';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-id-invoice';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-id-receipt';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-id-statement-type';
      params: {
        id: string | number;
        type: string | number;
      };
    }
  | {
      name: 'print-container-batch-deposit-id-a4';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-batch-deposit-id-thermal';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-confirmation-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-invoice-id-a4';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-invoice-id-thermal';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-manifest-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-order-forms-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-order-forms-barcode-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-picking-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-preparation-id-a4';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-preparation-id-thermal';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-statements-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-summary-id-a4';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-container-summary-id-thermal';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'print-id-type-report';
      params: {
        id: string | number;
        type: string | number;
        report?: string | number;
      };
    }
  | {
      name: 'product-name';
      params?: {
        name?: string | number;
      };
    }
  | { name: 'product-new' }
  | { name: 'products-composites' }
  | { name: 'products-counts' }
  | { name: 'products' }
  | { name: 'products-inventory' }
  | { name: 'products-price-lists' }
  | {
      name: 'products-services-inventory-id-tab';
      params: {
        id: string | number;
        tab: string | number;
      };
    }
  | {
      name: 'products-services-inventory-id';
      params: {
        id: string | number;
      };
    }
  | { name: 'products-services-inventory' }
  | { name: 'products-services' }
  | {
      name: 'products-settings-tab';
      params: {
        tab: string | number;
      };
    }
  | { name: 'products-settings' }
  | {
      name: 'profile-tab';
      params: {
        tab: string | number;
      };
    }
  | { name: 'profile' }
  | {
      name: 'profile-service-area-zone';
      params: {
        zone: string | number;
      };
    }
  | { name: 'profile-service-area' }
  | {
      name: 'purchase-order-pay-by-link-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'purchase-order-pay-by-link-id-pay';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'purchase-order-pay-by-link-id-payment-completed';
      params: {
        id: string | number;
      };
    }
  | { name: 'purchase-orders-bills' }
  | { name: 'purchase-orders' }
  | {
      name: 'purchase-orders-manage-id';
      params: {
        id: string | number;
      };
    }
  | { name: 'purchase-orders-new' }
  | { name: 'purchase-orders-purchase-overview' }
  | {
      name: 'purchase-orders-purchase-overview-manage-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'purchase-orders-receiving-dispatch-id';
      params: {
        id: string | number;
      };
    }
  | { name: 'purchase-orders-receiving' }
  | {
      name: 'receivables-mode-id';
      params: {
        mode: string | number;
        id: string | number;
      };
    }
  | { name: 'receivables' }
  | {
      name: 'reports-analytics-tab';
      params: {
        tab: string | number;
      };
    }
  | { name: 'reports-analytics' }
  | {
      name: 'reports-costs-tab';
      params: {
        tab: string | number;
      };
    }
  | { name: 'reports-costs' }
  | {
      name: 'reports-products-tab';
      params: {
        tab: string | number;
      };
    }
  | { name: 'reports-products' }
  | {
      name: 'reports-revenue-tab';
      params: {
        tab: string | number;
      };
    }
  | { name: 'reports-revenue' }
  | { name: 'review' }
  | {
      name: 'service-name';
      params?: {
        name?: string | number;
      };
    }
  | { name: 'service-new' }
  | {
      name: 'single-order-mode-id';
      params: {
        mode: string | number;
        id: string | number;
      };
    }
  | {
      name: 'single-order-pay-by-link-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'single-order-pay-by-link-id-pay';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'single-order-pay-by-link-id-payment-completed';
      params: {
        id: string | number;
      };
    }
  | { name: 'table-ordering' }
  | { name: 'taxonomies' }
  | { name: 'test' }
  | { name: 'tiptap' }
  | { name: 'transfers' }
  | {
      name: 'units-overview-manage-id';
      params: {
        id: string | number;
      };
    }
  | {
      name: 'user-tab';
      params: {
        tab: string | number;
      };
    }
  | { name: 'user' }
  | { name: 'user-orders' };

/**
 * Type returned by a resolved Route that will allow to type guard the route name.
 * By default the params are unknown
 * */
export type RoutesNamedLocationsResolved = {
  name: RoutesNamesList;
  params: unknown;
} & (
  | {
      name: 'agreements-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'agreements-id-location-store';
      params: {
        id: string;
        store: string;
      };
    }
  | {
      name: 'agreements-id-location-new';
      params: {
        id: string;
      };
    }
  | { name: 'ai' }
  | {
      name: 'allocations-manage-id';
      params: {
        id: string;
      };
    }
  | { name: 'analytics' }
  | {
      name: 'bills-manage-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'booking-type-name';
      params: {
        type: string;
        name: string;
      };
    }
  | {
      name: 'booking-type';
      params: {
        type: string;
      };
    }
  | {
      name: 'booking-account-tab';
      params: {
        tab: string;
      };
    }
  | { name: 'booking-account' }
  | {
      name: 'booking-account-login-screen';
      params: {
        screen?: string;
      };
    }
  | { name: 'booking' }
  | {
      name: 'booking-order-id-agree';
      params: {
        id: string;
      };
    }
  | {
      name: 'booking-order-id-confirmed';
      params: {
        id: string;
      };
    }
  | {
      name: 'booking-order-id-review';
      params: {
        id: string;
      };
    }
  | {
      name: 'booking-provider-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'booking-provider-id-service-serviceid';
      params: {
        id: string;
        serviceid: string;
      };
    }
  | { name: 'calendar' }
  | {
      name: 'checkout-new-step-id';
      params: {
        step?: string;
        id?: string;
      };
    }
  | { name: 'checkout' }
  | { name: 'checkout-thankyou' }
  | {
      name: 'composite-name';
      params: {
        name?: string;
      };
    }
  | { name: 'composite-new' }
  | {
      name: 'contacts-id-tab';
      params: {
        id: string;
        tab: string;
      };
    }
  | {
      name: 'contacts-id-tab-service-area-zone';
      params: {
        id: string;
        tab: string;
        zone: string;
      };
    }
  | {
      name: 'contacts-id-tab-service-area';
      params: {
        id: string;
        tab: string;
      };
    }
  | {
      name: 'contacts-id';
      params: {
        id: string;
      };
    }
  | { name: 'contacts-calendar' }
  | { name: 'contacts-companies' }
  | { name: 'contacts-customers' }
  | { name: 'contacts' }
  | {
      name: 'contacts-loyalty-rewards-id';
      params: {
        id: string;
      };
    }
  | { name: 'contacts-loyalty-rewards' }
  | {
      name: 'contacts-new-tab';
      params: {
        tab: string;
      };
    }
  | { name: 'contacts-new' }
  | {
      name: 'contacts-old-profile-id';
      params: {
        id: string;
      };
    }
  | { name: 'contacts-old-profile-new' }
  | { name: 'contacts-people' }
  | { name: 'contacts-staff' }
  | {
      name: 'counts-step-id';
      params: {
        step: string;
        id: string;
      };
    }
  | { name: 'index' }
  | {
      name: 'invoices-manage-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'location-tab';
      params: {
        tab: string;
      };
    }
  | { name: 'location' }
  | {
      name: 'login-screen';
      params: {
        screen?: string;
      };
    }
  | { name: 'login-google-calendar' }
  | { name: 'login-outlook-calendar' }
  | { name: 'management-brands' }
  | {
      name: 'management-locations-id-tab';
      params: {
        id: string;
        tab: string;
      };
    }
  | {
      name: 'management-locations-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'management-locations-id-service-area-zone';
      params: {
        id: string;
        zone: string;
      };
    }
  | {
      name: 'management-locations-id-service-area';
      params: {
        id: string;
      };
    }
  | { name: 'management-locations' }
  | {
      name: 'management-locations-new-tab';
      params: {
        tab: string;
      };
    }
  | { name: 'management-locations-new' }
  | {
      name: 'management-locations-settings-tab';
      params: {
        tab: string;
      };
    }
  | { name: 'management-locations-settings' }
  | { name: 'management-settings' }
  | {
      name: 'old-location-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'onboarding-type-name';
      params: {
        type: string;
        name: string;
      };
    }
  | {
      name: 'onboarding-type-error';
      params: {
        type: string;
      };
    }
  | {
      name: 'onboarding-type';
      params: {
        type: string;
      };
    }
  | {
      name: 'onboarding-type-success';
      params: {
        type: string;
      };
    }
  | { name: 'onboarding-privacy-policy' }
  | { name: 'onboarding-terms-and-conditions' }
  | { name: 'ordering' }
  | { name: 'orders-allocations' }
  | { name: 'orders-fulfilment' }
  | {
      name: 'orders-fulfilment-manage-freight-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'orders-fulfilment-manage-run-id';
      params: {
        id: string;
      };
    }
  | { name: 'orders' }
  | { name: 'orders-invoices' }
  | { name: 'orders-preparing' }
  | {
      name: 'orders-preparing-picking-id-tab';
      params: {
        id: string;
        tab: string;
      };
    }
  | {
      name: 'orders-preparing-picking-id';
      params: {
        id: string;
      };
    }
  | { name: 'orders-schedule' }
  | { name: 'orders-statements' }
  | {
      name: 'orders-statements-manage-id';
      params: {
        id: string;
      };
    }
  | { name: 'password-reset' }
  | { name: 'picker-account' }
  | { name: 'pos-checkout' }
  | {
      name: 'pos-checkout-thankyou-id';
      params: {
        id?: string;
      };
    }
  | { name: 'pos-customers' }
  | { name: 'pos-lockscreen' }
  | {
      name: 'pos-orders-customer-id';
      params: {
        id: string;
      };
    }
  | { name: 'pos-orders' }
  | { name: 'pos-shop' }
  | {
      name: 'print-container-id-bill';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-id-bill-print';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-id-bill-receipt';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-id-invoice';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-id-receipt';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-id-statement-type';
      params: {
        id: string;
        type: string;
      };
    }
  | {
      name: 'print-container-batch-deposit-id-a4';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-batch-deposit-id-thermal';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-confirmation-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-invoice-id-a4';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-invoice-id-thermal';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-manifest-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-order-forms-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-order-forms-barcode-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-picking-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-preparation-id-a4';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-preparation-id-thermal';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-statements-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-summary-id-a4';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-container-summary-id-thermal';
      params: {
        id: string;
      };
    }
  | {
      name: 'print-id-type-report';
      params: {
        id: string;
        type: string;
        report?: string;
      };
    }
  | {
      name: 'product-name';
      params: {
        name?: string;
      };
    }
  | { name: 'product-new' }
  | { name: 'products-composites' }
  | { name: 'products-counts' }
  | { name: 'products' }
  | { name: 'products-inventory' }
  | { name: 'products-price-lists' }
  | {
      name: 'products-services-inventory-id-tab';
      params: {
        id: string;
        tab: string;
      };
    }
  | {
      name: 'products-services-inventory-id';
      params: {
        id: string;
      };
    }
  | { name: 'products-services-inventory' }
  | { name: 'products-services' }
  | {
      name: 'products-settings-tab';
      params: {
        tab: string;
      };
    }
  | { name: 'products-settings' }
  | {
      name: 'profile-tab';
      params: {
        tab: string;
      };
    }
  | { name: 'profile' }
  | {
      name: 'profile-service-area-zone';
      params: {
        zone: string;
      };
    }
  | { name: 'profile-service-area' }
  | {
      name: 'purchase-order-pay-by-link-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'purchase-order-pay-by-link-id-pay';
      params: {
        id: string;
      };
    }
  | {
      name: 'purchase-order-pay-by-link-id-payment-completed';
      params: {
        id: string;
      };
    }
  | { name: 'purchase-orders-bills' }
  | { name: 'purchase-orders' }
  | {
      name: 'purchase-orders-manage-id';
      params: {
        id: string;
      };
    }
  | { name: 'purchase-orders-new' }
  | { name: 'purchase-orders-purchase-overview' }
  | {
      name: 'purchase-orders-purchase-overview-manage-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'purchase-orders-receiving-dispatch-id';
      params: {
        id: string;
      };
    }
  | { name: 'purchase-orders-receiving' }
  | {
      name: 'receivables-mode-id';
      params: {
        mode: string;
        id: string;
      };
    }
  | { name: 'receivables' }
  | {
      name: 'reports-analytics-tab';
      params: {
        tab: string;
      };
    }
  | { name: 'reports-analytics' }
  | {
      name: 'reports-costs-tab';
      params: {
        tab: string;
      };
    }
  | { name: 'reports-costs' }
  | {
      name: 'reports-products-tab';
      params: {
        tab: string;
      };
    }
  | { name: 'reports-products' }
  | {
      name: 'reports-revenue-tab';
      params: {
        tab: string;
      };
    }
  | { name: 'reports-revenue' }
  | { name: 'review' }
  | {
      name: 'service-name';
      params: {
        name?: string;
      };
    }
  | { name: 'service-new' }
  | {
      name: 'single-order-mode-id';
      params: {
        mode: string;
        id: string;
      };
    }
  | {
      name: 'single-order-pay-by-link-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'single-order-pay-by-link-id-pay';
      params: {
        id: string;
      };
    }
  | {
      name: 'single-order-pay-by-link-id-payment-completed';
      params: {
        id: string;
      };
    }
  | { name: 'table-ordering' }
  | { name: 'taxonomies' }
  | { name: 'test' }
  | { name: 'tiptap' }
  | { name: 'transfers' }
  | {
      name: 'units-overview-manage-id';
      params: {
        id: string;
      };
    }
  | {
      name: 'user-tab';
      params: {
        tab: string;
      };
    }
  | { name: 'user' }
  | { name: 'user-orders' }
);

export type RoutesNamesListRecord = {
  agreementsId: 'agreements-id';
  agreementsIdLocationStore: 'agreements-id-location-store';
  agreementsIdLocationNew: 'agreements-id-location-new';
  ai: 'ai';
  allocationsManageId: 'allocations-manage-id';
  analytics: 'analytics';
  billsManageId: 'bills-manage-id';
  bookingTypeName: 'booking-type-name';
  bookingType: 'booking-type';
  bookingAccountTab: 'booking-account-tab';
  bookingAccount: 'booking-account';
  bookingAccountLoginScreen: 'booking-account-login-screen';
  booking: 'booking';
  bookingOrderIdAgree: 'booking-order-id-agree';
  bookingOrderIdConfirmed: 'booking-order-id-confirmed';
  bookingOrderIdReview: 'booking-order-id-review';
  bookingProviderId: 'booking-provider-id';
  bookingProviderIdServiceServiceid: 'booking-provider-id-service-serviceid';
  calendar: 'calendar';
  checkoutNewStepId: 'checkout-new-step-id';
  checkout: 'checkout';
  checkoutThankyou: 'checkout-thankyou';
  compositeName: 'composite-name';
  compositeNew: 'composite-new';
  contactsIdTab: 'contacts-id-tab';
  contactsIdTabServiceAreaZone: 'contacts-id-tab-service-area-zone';
  contactsIdTabServiceArea: 'contacts-id-tab-service-area';
  contactsId: 'contacts-id';
  contactsCalendar: 'contacts-calendar';
  contactsCompanies: 'contacts-companies';
  contactsCustomers: 'contacts-customers';
  contacts: 'contacts';
  contactsLoyaltyRewardsId: 'contacts-loyalty-rewards-id';
  contactsLoyaltyRewards: 'contacts-loyalty-rewards';
  contactsNewTab: 'contacts-new-tab';
  contactsNew: 'contacts-new';
  contactsOldProfileId: 'contacts-old-profile-id';
  contactsOldProfileNew: 'contacts-old-profile-new';
  contactsPeople: 'contacts-people';
  contactsStaff: 'contacts-staff';
  countsStepId: 'counts-step-id';
  index: 'index';
  invoicesManageId: 'invoices-manage-id';
  locationTab: 'location-tab';
  location: 'location';
  loginScreen: 'login-screen';
  loginGoogleCalendar: 'login-google-calendar';
  loginOutlookCalendar: 'login-outlook-calendar';
  managementBrands: 'management-brands';
  managementLocationsIdTab: 'management-locations-id-tab';
  managementLocationsId: 'management-locations-id';
  managementLocationsIdServiceAreaZone: 'management-locations-id-service-area-zone';
  managementLocationsIdServiceArea: 'management-locations-id-service-area';
  managementLocations: 'management-locations';
  managementLocationsNewTab: 'management-locations-new-tab';
  managementLocationsNew: 'management-locations-new';
  managementLocationsSettingsTab: 'management-locations-settings-tab';
  managementLocationsSettings: 'management-locations-settings';
  managementSettings: 'management-settings';
  oldLocationId: 'old-location-id';
  onboardingTypeName: 'onboarding-type-name';
  onboardingTypeError: 'onboarding-type-error';
  onboardingType: 'onboarding-type';
  onboardingTypeSuccess: 'onboarding-type-success';
  onboardingPrivacyPolicy: 'onboarding-privacy-policy';
  onboardingTermsAndConditions: 'onboarding-terms-and-conditions';
  ordering: 'ordering';
  ordersAllocations: 'orders-allocations';
  ordersFulfilment: 'orders-fulfilment';
  ordersFulfilmentManageFreightId: 'orders-fulfilment-manage-freight-id';
  ordersFulfilmentManageRunId: 'orders-fulfilment-manage-run-id';
  orders: 'orders';
  ordersInvoices: 'orders-invoices';
  ordersPreparing: 'orders-preparing';
  ordersPreparingPickingIdTab: 'orders-preparing-picking-id-tab';
  ordersPreparingPickingId: 'orders-preparing-picking-id';
  ordersSchedule: 'orders-schedule';
  ordersStatements: 'orders-statements';
  ordersStatementsManageId: 'orders-statements-manage-id';
  passwordReset: 'password-reset';
  pickerAccount: 'picker-account';
  posCheckout: 'pos-checkout';
  posCheckoutThankyouId: 'pos-checkout-thankyou-id';
  posCustomers: 'pos-customers';
  posLockscreen: 'pos-lockscreen';
  posOrdersCustomerId: 'pos-orders-customer-id';
  posOrders: 'pos-orders';
  posShop: 'pos-shop';
  printContainerIdBill: 'print-container-id-bill';
  printContainerIdBillPrint: 'print-container-id-bill-print';
  printContainerIdBillReceipt: 'print-container-id-bill-receipt';
  printContainerIdInvoice: 'print-container-id-invoice';
  printContainerIdReceipt: 'print-container-id-receipt';
  printContainerIdStatementType: 'print-container-id-statement-type';
  printContainerBatchDepositIdA4: 'print-container-batch-deposit-id-a4';
  printContainerBatchDepositIdThermal: 'print-container-batch-deposit-id-thermal';
  printContainerConfirmationId: 'print-container-confirmation-id';
  printContainerInvoiceIdA4: 'print-container-invoice-id-a4';
  printContainerInvoiceIdThermal: 'print-container-invoice-id-thermal';
  printContainerManifestId: 'print-container-manifest-id';
  printContainerOrderFormsId: 'print-container-order-forms-id';
  printContainerOrderFormsBarcodeId: 'print-container-order-forms-barcode-id';
  printContainerPickingId: 'print-container-picking-id';
  printContainerPreparationIdA4: 'print-container-preparation-id-a4';
  printContainerPreparationIdThermal: 'print-container-preparation-id-thermal';
  printContainerStatementsId: 'print-container-statements-id';
  printContainerSummaryIdA4: 'print-container-summary-id-a4';
  printContainerSummaryIdThermal: 'print-container-summary-id-thermal';
  printIdTypeReport: 'print-id-type-report';
  productName: 'product-name';
  productNew: 'product-new';
  productsComposites: 'products-composites';
  productsCounts: 'products-counts';
  products: 'products';
  productsInventory: 'products-inventory';
  productsPriceLists: 'products-price-lists';
  productsServicesInventoryIdTab: 'products-services-inventory-id-tab';
  productsServicesInventoryId: 'products-services-inventory-id';
  productsServicesInventory: 'products-services-inventory';
  productsServices: 'products-services';
  productsSettingsTab: 'products-settings-tab';
  productsSettings: 'products-settings';
  profileTab: 'profile-tab';
  profile: 'profile';
  profileServiceAreaZone: 'profile-service-area-zone';
  profileServiceArea: 'profile-service-area';
  purchaseOrderPayByLinkId: 'purchase-order-pay-by-link-id';
  purchaseOrderPayByLinkIdPay: 'purchase-order-pay-by-link-id-pay';
  purchaseOrderPayByLinkIdPaymentCompleted: 'purchase-order-pay-by-link-id-payment-completed';
  purchaseOrdersBills: 'purchase-orders-bills';
  purchaseOrders: 'purchase-orders';
  purchaseOrdersManageId: 'purchase-orders-manage-id';
  purchaseOrdersNew: 'purchase-orders-new';
  purchaseOrdersPurchaseOverview: 'purchase-orders-purchase-overview';
  purchaseOrdersPurchaseOverviewManageId: 'purchase-orders-purchase-overview-manage-id';
  purchaseOrdersReceivingDispatchId: 'purchase-orders-receiving-dispatch-id';
  purchaseOrdersReceiving: 'purchase-orders-receiving';
  receivablesModeId: 'receivables-mode-id';
  receivables: 'receivables';
  reportsAnalyticsTab: 'reports-analytics-tab';
  reportsAnalytics: 'reports-analytics';
  reportsCostsTab: 'reports-costs-tab';
  reportsCosts: 'reports-costs';
  reportsProductsTab: 'reports-products-tab';
  reportsProducts: 'reports-products';
  reportsRevenueTab: 'reports-revenue-tab';
  reportsRevenue: 'reports-revenue';
  review: 'review';
  serviceName: 'service-name';
  serviceNew: 'service-new';
  singleOrderModeId: 'single-order-mode-id';
  singleOrderPayByLinkId: 'single-order-pay-by-link-id';
  singleOrderPayByLinkIdPay: 'single-order-pay-by-link-id-pay';
  singleOrderPayByLinkIdPaymentCompleted: 'single-order-pay-by-link-id-payment-completed';
  tableOrdering: 'table-ordering';
  taxonomies: 'taxonomies';
  test: 'test';
  tiptap: 'tiptap';
  transfers: 'transfers';
  unitsOverviewManageId: 'units-overview-manage-id';
  userTab: 'user-tab';
  user: 'user';
  userOrders: 'user-orders';
};

export const routesNames = {
  agreementsId: 'agreements-id' as const,
  agreementsIdLocationStore: 'agreements-id-location-store' as const,
  agreementsIdLocationNew: 'agreements-id-location-new' as const,
  ai: 'ai' as const,
  allocationsManageId: 'allocations-manage-id' as const,
  analytics: 'analytics' as const,
  billsManageId: 'bills-manage-id' as const,
  bookingTypeName: 'booking-type-name' as const,
  bookingType: 'booking-type' as const,
  bookingAccountTab: 'booking-account-tab' as const,
  bookingAccount: 'booking-account' as const,
  bookingAccountLoginScreen: 'booking-account-login-screen' as const,
  booking: 'booking' as const,
  bookingOrderIdAgree: 'booking-order-id-agree' as const,
  bookingOrderIdConfirmed: 'booking-order-id-confirmed' as const,
  bookingOrderIdReview: 'booking-order-id-review' as const,
  bookingProviderId: 'booking-provider-id' as const,
  bookingProviderIdServiceServiceid: 'booking-provider-id-service-serviceid' as const,
  calendar: 'calendar' as const,
  checkoutNewStepId: 'checkout-new-step-id' as const,
  checkout: 'checkout' as const,
  checkoutThankyou: 'checkout-thankyou' as const,
  compositeName: 'composite-name' as const,
  compositeNew: 'composite-new' as const,
  contactsIdTab: 'contacts-id-tab' as const,
  contactsIdTabServiceAreaZone: 'contacts-id-tab-service-area-zone' as const,
  contactsIdTabServiceArea: 'contacts-id-tab-service-area' as const,
  contactsId: 'contacts-id' as const,
  contactsCalendar: 'contacts-calendar' as const,
  contactsCompanies: 'contacts-companies' as const,
  contactsCustomers: 'contacts-customers' as const,
  contacts: 'contacts' as const,
  contactsLoyaltyRewardsId: 'contacts-loyalty-rewards-id' as const,
  contactsLoyaltyRewards: 'contacts-loyalty-rewards' as const,
  contactsNewTab: 'contacts-new-tab' as const,
  contactsNew: 'contacts-new' as const,
  contactsOldProfileId: 'contacts-old-profile-id' as const,
  contactsOldProfileNew: 'contacts-old-profile-new' as const,
  contactsPeople: 'contacts-people' as const,
  contactsStaff: 'contacts-staff' as const,
  countsStepId: 'counts-step-id' as const,
  index: 'index' as const,
  invoicesManageId: 'invoices-manage-id' as const,
  locationTab: 'location-tab' as const,
  location: 'location' as const,
  loginScreen: 'login-screen' as const,
  loginGoogleCalendar: 'login-google-calendar' as const,
  loginOutlookCalendar: 'login-outlook-calendar' as const,
  managementBrands: 'management-brands' as const,
  managementLocationsIdTab: 'management-locations-id-tab' as const,
  managementLocationsId: 'management-locations-id' as const,
  managementLocationsIdServiceAreaZone: 'management-locations-id-service-area-zone' as const,
  managementLocationsIdServiceArea: 'management-locations-id-service-area' as const,
  managementLocations: 'management-locations' as const,
  managementLocationsNewTab: 'management-locations-new-tab' as const,
  managementLocationsNew: 'management-locations-new' as const,
  managementLocationsSettingsTab: 'management-locations-settings-tab' as const,
  managementLocationsSettings: 'management-locations-settings' as const,
  managementSettings: 'management-settings' as const,
  oldLocationId: 'old-location-id' as const,
  onboardingTypeName: 'onboarding-type-name' as const,
  onboardingTypeError: 'onboarding-type-error' as const,
  onboardingType: 'onboarding-type' as const,
  onboardingTypeSuccess: 'onboarding-type-success' as const,
  onboardingPrivacyPolicy: 'onboarding-privacy-policy' as const,
  onboardingTermsAndConditions: 'onboarding-terms-and-conditions' as const,
  ordering: 'ordering' as const,
  ordersAllocations: 'orders-allocations' as const,
  ordersFulfilment: 'orders-fulfilment' as const,
  ordersFulfilmentManageFreightId: 'orders-fulfilment-manage-freight-id' as const,
  ordersFulfilmentManageRunId: 'orders-fulfilment-manage-run-id' as const,
  orders: 'orders' as const,
  ordersInvoices: 'orders-invoices' as const,
  ordersPreparing: 'orders-preparing' as const,
  ordersPreparingPickingIdTab: 'orders-preparing-picking-id-tab' as const,
  ordersPreparingPickingId: 'orders-preparing-picking-id' as const,
  ordersSchedule: 'orders-schedule' as const,
  ordersStatements: 'orders-statements' as const,
  ordersStatementsManageId: 'orders-statements-manage-id' as const,
  passwordReset: 'password-reset' as const,
  pickerAccount: 'picker-account' as const,
  posCheckout: 'pos-checkout' as const,
  posCheckoutThankyouId: 'pos-checkout-thankyou-id' as const,
  posCustomers: 'pos-customers' as const,
  posLockscreen: 'pos-lockscreen' as const,
  posOrdersCustomerId: 'pos-orders-customer-id' as const,
  posOrders: 'pos-orders' as const,
  posShop: 'pos-shop' as const,
  printContainerIdBill: 'print-container-id-bill' as const,
  printContainerIdBillPrint: 'print-container-id-bill-print' as const,
  printContainerIdBillReceipt: 'print-container-id-bill-receipt' as const,
  printContainerIdInvoice: 'print-container-id-invoice' as const,
  printContainerIdReceipt: 'print-container-id-receipt' as const,
  printContainerIdStatementType: 'print-container-id-statement-type' as const,
  printContainerBatchDepositIdA4: 'print-container-batch-deposit-id-a4' as const,
  printContainerBatchDepositIdThermal: 'print-container-batch-deposit-id-thermal' as const,
  printContainerConfirmationId: 'print-container-confirmation-id' as const,
  printContainerInvoiceIdA4: 'print-container-invoice-id-a4' as const,
  printContainerInvoiceIdThermal: 'print-container-invoice-id-thermal' as const,
  printContainerManifestId: 'print-container-manifest-id' as const,
  printContainerOrderFormsId: 'print-container-order-forms-id' as const,
  printContainerOrderFormsBarcodeId: 'print-container-order-forms-barcode-id' as const,
  printContainerPickingId: 'print-container-picking-id' as const,
  printContainerPreparationIdA4: 'print-container-preparation-id-a4' as const,
  printContainerPreparationIdThermal: 'print-container-preparation-id-thermal' as const,
  printContainerStatementsId: 'print-container-statements-id' as const,
  printContainerSummaryIdA4: 'print-container-summary-id-a4' as const,
  printContainerSummaryIdThermal: 'print-container-summary-id-thermal' as const,
  printIdTypeReport: 'print-id-type-report' as const,
  productName: 'product-name' as const,
  productNew: 'product-new' as const,
  productsComposites: 'products-composites' as const,
  productsCounts: 'products-counts' as const,
  products: 'products' as const,
  productsInventory: 'products-inventory' as const,
  productsPriceLists: 'products-price-lists' as const,
  productsServicesInventoryIdTab: 'products-services-inventory-id-tab' as const,
  productsServicesInventoryId: 'products-services-inventory-id' as const,
  productsServicesInventory: 'products-services-inventory' as const,
  productsServices: 'products-services' as const,
  productsSettingsTab: 'products-settings-tab' as const,
  productsSettings: 'products-settings' as const,
  profileTab: 'profile-tab' as const,
  profile: 'profile' as const,
  profileServiceAreaZone: 'profile-service-area-zone' as const,
  profileServiceArea: 'profile-service-area' as const,
  purchaseOrderPayByLinkId: 'purchase-order-pay-by-link-id' as const,
  purchaseOrderPayByLinkIdPay: 'purchase-order-pay-by-link-id-pay' as const,
  purchaseOrderPayByLinkIdPaymentCompleted:
    'purchase-order-pay-by-link-id-payment-completed' as const,
  purchaseOrdersBills: 'purchase-orders-bills' as const,
  purchaseOrders: 'purchase-orders' as const,
  purchaseOrdersManageId: 'purchase-orders-manage-id' as const,
  purchaseOrdersNew: 'purchase-orders-new' as const,
  purchaseOrdersPurchaseOverview: 'purchase-orders-purchase-overview' as const,
  purchaseOrdersPurchaseOverviewManageId: 'purchase-orders-purchase-overview-manage-id' as const,
  purchaseOrdersReceivingDispatchId: 'purchase-orders-receiving-dispatch-id' as const,
  purchaseOrdersReceiving: 'purchase-orders-receiving' as const,
  receivablesModeId: 'receivables-mode-id' as const,
  receivables: 'receivables' as const,
  reportsAnalyticsTab: 'reports-analytics-tab' as const,
  reportsAnalytics: 'reports-analytics' as const,
  reportsCostsTab: 'reports-costs-tab' as const,
  reportsCosts: 'reports-costs' as const,
  reportsProductsTab: 'reports-products-tab' as const,
  reportsProducts: 'reports-products' as const,
  reportsRevenueTab: 'reports-revenue-tab' as const,
  reportsRevenue: 'reports-revenue' as const,
  review: 'review' as const,
  serviceName: 'service-name' as const,
  serviceNew: 'service-new' as const,
  singleOrderModeId: 'single-order-mode-id' as const,
  singleOrderPayByLinkId: 'single-order-pay-by-link-id' as const,
  singleOrderPayByLinkIdPay: 'single-order-pay-by-link-id-pay' as const,
  singleOrderPayByLinkIdPaymentCompleted: 'single-order-pay-by-link-id-payment-completed' as const,
  tableOrdering: 'table-ordering' as const,
  taxonomies: 'taxonomies' as const,
  test: 'test' as const,
  tiptap: 'tiptap' as const,
  transfers: 'transfers' as const,
  unitsOverviewManageId: 'units-overview-manage-id' as const,
  userTab: 'user-tab' as const,
  user: 'user' as const,
  userOrders: 'user-orders' as const,
};
