export interface Channel {
  id?: number;
  name: string;
  slug: string;
  is_protected: boolean;
  is_default_for_online?: boolean;
  configuration: {
    hide_creator: boolean;
    surcharge?: number;
    surcharge_percent?: number;
    disable_express_service_rules?: boolean;
    disable_lead_time_rules?: boolean;
  };
  payment_methods?: any[];
}

export interface ChannelOption {
  id?: number;
  name: string;
  value: string;
  slug: string;
  is_protected: boolean;
  is_default_for_online?: boolean;
  configuration: {
    hide_creator: boolean;
    surcharge?: number;
    surcharge_percent?: number;
    disable_express_service_rules?: boolean;
    disable_lead_time_rules?: boolean;
  };
  payment_methods?: any[];
}

export enum ChannelSlug {
  POS = "pos",
  ONLINE = "online",
  PLATFORM = "platform",
  THIRD_PARTY = "3rd-party",
}
