import { ReportPayload, ToastType } from "~/types/general";
import { ElectronChannel } from "~/electron/types";

function checkElectron() {
  if (!(window as any).electron) {
    console.error("Electron is not available.");
    return false;
  }

  return true;
}

export function useElectron() {
  const invoke = async (channel: ElectronChannel, data?: any): Promise<any> => {
    if (!checkElectron()) return;

    return await (window as any).electron.invoke(channel, data);
  };

  const on = (channel: string, callback: (data: any) => void): void => {
    if (!checkElectron()) return;

    (window as any).electron.on(channel, callback);
  };

  const off = (channel: string, callback: (data: any) => void): void => {
    if (!checkElectron()) return;

    (window as any).electron.off(channel, callback);
  };

  // REPORTS LOGIC
  const printReport = async (payload: ReportPayload) => {
    if (!checkElectron()) return;

    const data = useCloneDeep(payload);

    try {
      const res = await (window as any).electron.invoke(
        ElectronChannel.PRINT_REPORT,
        data
      );

      return res;
    } catch (error) {
      console.log(error);
      useToast(`Failed to print ${error}`, {
        type: ToastType.ERROR,
        duration: 10000,
      });
      throw new Error("Failed to print report.", error);
    }
  };

  const printReportPdf = (payload: ReportPayload): void => {
    if (!checkElectron()) return;

    (window as any).electron.invoke(ElectronChannel.PRINT_REPORT_PDF, payload);
  };

  const downloadReport = (payload: ReportPayload): void => {
    if (!checkElectron()) return;

    const data = useCloneDeep(payload);

    (window as any).electron.invoke(ElectronChannel.DOWNLOAD_REPORT, data);
  };

  const downloadReportPdf = (payload: ReportPayload): void => {
    if (!checkElectron()) return;

    (window as any).electron.invoke(
      ElectronChannel.DOWNLOAD_REPORT_PDF,
      payload
    );
  };

  // GET OS
  const getOS = (): string => {
    if (!checkElectron()) return;

    return (window as any).electron.invoke(ElectronChannel.GET_OS);
  };

  // PRINTERS
  const getPrinters = (): any => {
    if (!checkElectron()) return;

    return (window as any).electron.invoke(ElectronChannel.GET_PRINTERS);
  };

  const openCashDrawer = (): void => {
    if (!checkElectron()) return;

    (window as any).electron.invoke(ElectronChannel.OPEN_CASH_DRAWER);
  };

  // CHECK FOR UPDATES
  const checkForUpdates = (): void => {
    if (!checkElectron()) return;

    const {
      public: { ghToken: token },
    } = useRuntimeConfig();

    (window as any).electron.invoke(ElectronChannel.CHECK_FOR_UPDATES, {
      token,
    });
  };

  return {
    invoke,
    on,
    off,
    printReport,
    printReportPdf,
    downloadReport,
    downloadReportPdf,
    getOS,
    getPrinters,
    checkForUpdates,
    openCashDrawer,
  };
}
