import { useGeneralStore } from "~/store/general";
import { ToastType } from "~/types/general";

export const useToastPersistent = () => {
  const generalStore = useGeneralStore();

  const showToast = (
    toast: { message: string; submessage?: string },
    options?: { type?: ToastType }
  ) => {
    const newToast = {
      id: `toast-${Date.now()}`,
      message: toast.message,
      submessage: toast.submessage || "",
      type: options?.type || ToastType.INFO,
    };

    generalStore.toastsList.push(newToast);

    const updateToast = (
      updates: Partial<{ message: string; submessage: string }>
    ) => {
      const toast = generalStore.toastsList.find((t) => t.id === newToast.id);

      if (!toast) return;

      if (updates?.message) {
        toast.message = updates.message;
      }

      if (updates?.submessage) {
        toast.submessage = updates.submessage;
      }
    };

    const removeToast = () => {
      generalStore.toastsList = generalStore.toastsList.filter(
        (t) => t.id !== newToast.id
      );
    };

    return {
      updateToast,
      removeToast,
    };
  };

  return {
    showToast,
  };
};
