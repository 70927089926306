import { Filters } from "~/types/filters";

/**
 * Clear all filters, update route query parameters, return filters list
 * @param filters - The object containing all filter settings to be cleared.
 */
export const useNewClearFilters = (filters: Filters) => {
  Object.entries(filters).forEach(([key, filter]) => {
    if (filter.toggle) {
      filter.toggle = false;
    }

    // For general filters
    filter.chosenOptions = [];
  });

  return useNewSetFiltersQS(filters, {}, true);
};
