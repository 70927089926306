<template>
  <div
    v-if="actions?.length && notification?.text"
    @click="openInventoryActions"
    class="py-x3 px-x5 border rounded-x2 flex justify-between items-center cursor-pointer"
    :class="[
      notification.type === InventoryActionStatus.CRITICAL
        ? 'bg-red-100 text-error'
        : 'text-orange-100 bg-orange-200',
    ]"
  >
    <div class="flex gap-2.5">
      <ExclamationIcon class="w-5 h-5" style="stroke-width: 1.5" />
      <p>{{ notification.text }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ExclamationIcon } from "@heroicons/vue/outline";
import { SidebarPage } from "~/types/inventory";
import { InventoryActionStatus } from "~/types/inventory-actions";
import { useInventoryStore } from "~/store/inventory";

const route = useRoute();

const props = defineProps<{
  actions: any[];
}>();

const inventoryStore = useInventoryStore();

const notification = computed(() =>
  props.actions?.some((i) => i.status === InventoryActionStatus.CRITICAL) &&
  props.actions?.some((i) => i.status === InventoryActionStatus.REVIEW)
    ? {
        type: InventoryActionStatus.CRITICAL,
        text: `${
          props.actions?.filter(
            (i) => i.status === InventoryActionStatus.CRITICAL
          )?.length
        } action is required for this inventory, ${
          props.actions?.filter(
            (i) => i.status === InventoryActionStatus.REVIEW
          )?.length
        } review action ${
          props.actions?.filter(
            (i) => i.status === InventoryActionStatus.REVIEW
          )?.length > 1
            ? "items"
            : "item"
        }`,
      }
    : props.actions?.some((i) => i.status === InventoryActionStatus.REVIEW) &&
        props.actions?.every((i) => i.status !== InventoryActionStatus.CRITICAL)
      ? {
          type: InventoryActionStatus.REVIEW,
          text: `${
            props.actions?.filter(
              (i) => i.status === InventoryActionStatus.REVIEW
            )?.length
          } review action items required for this inventory`,
        }
      : props.actions?.some(
            (i) => i.status === InventoryActionStatus.CRITICAL
          ) &&
          props.actions?.every((i) => i.status !== InventoryActionStatus.REVIEW)
        ? {
            type: InventoryActionStatus.CRITICAL,
            text: `${
              props.actions?.filter(
                (i) => i.status === InventoryActionStatus.CRITICAL
              )?.length
            } critical action items required for this inventory`,
          }
        : {
            type: "",
            text: "",
          }
);

function openInventoryActions() {
  useSetSidebar(SidebarPage.INVENTORY_ACTIONS, {
    "inventory-id": +route.query["inventory-id"],
  });
}
</script>
