import {
  Customer,
  CustomerManage,
  CustomerSingle,
  CustomerShort,
} from "~/types/customers";
import { User } from "~/types/users";

export const useGetCustomerInitials = (
  customer:
    | Customer
    | CustomerSingle
    | CustomerManage
    | CustomerShort
    | User
    | string
) => {
  if (typeof customer === "string") {
    return customer === "Guest" ? "" : customer.slice(0, 2).toUpperCase();
  } else {
    return useGetCustomerName(
      customer as
        | Customer
        | CustomerSingle
        | CustomerManage
        | CustomerShort
        | User
    ) !== "Guest"
      ? useGetCustomerName(
          customer as
            | Customer
            | CustomerSingle
            | CustomerManage
            | CustomerShort
            | User
        )
          ?.split(" ")
          ?.map((n) => n?.slice(0, 1).toUpperCase())
          ?.slice(0, 2)
          ?.join("") ||
          useGetCustomerName(
            customer as
              | Customer
              | CustomerSingle
              | CustomerManage
              | CustomerShort
              | User
          )
            ?.slice(0, 2)
            ?.toUpperCase() ||
          ""
      : "";
  }
};
