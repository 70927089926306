import { Media } from "./general";
import { StoreShort } from "./stores";

export interface User {
  email: string;
  id: number;
  is_active: boolean;
  name: string;
  title?: string;
  stores?: StoreShort[];
  image?: Media;
  roles: UserType[];
}

export enum UserType {
  MANAGER = "manager",
  ADMIN = "admin",
  DRIVER = "driver",
  CONTACT = "contact",
  SUPERADMIN = "superadmin",
  CLIENT = "client",
  API = "api",
  PICKER = "picker",
  SALES = "sales",
  CUSTOMER_SERVICE = "customer_service",
  DISPATCH = "dispatch",
  FINANCE = "finance",
  ADVISOR = "advisor",
  GENERAL = "general_staff",
}
