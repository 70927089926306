import { defineStore } from "pinia";
import { App, AppBulkItem, AppSubnavTabs } from "~/types/apps";
import { ResponseType } from "~/types/general";
import { NavItemTitle } from "~/types/general";
import { useSettingsStore } from "./settings";

export const useAppsStore = defineStore("apps", () => {
  // Navigation
  const productsSettingsTabs = [
    {
      name: "Categories",
      link: `/products/settings/${AppSubnavTabs.CATEGORIES}`,
      route_name: useRoutesNames().productsSettingsTab,
      tab: AppSubnavTabs.CATEGORIES,
    },
    {
      name: "Attributes",
      link: `/products/settings/${AppSubnavTabs.ATTRIBUTES}`,
      route_name: useRoutesNames().productsSettingsTab,
      tab: AppSubnavTabs.ATTRIBUTES,
    },
    {
      name: "Tags",
      link: `/products/settings/${AppSubnavTabs.TAGS}`,
      route_name: useRoutesNames().productsSettingsTab,
      tab: AppSubnavTabs.TAGS,
    },
    {
      name: "Modifiers",
      link: `/products/settings/${AppSubnavTabs.MODIFIERS}`,
      route_name: useRoutesNames().productsSettingsTab,
      tab: AppSubnavTabs.MODIFIERS,
    },
    {
      name: "Settings",
      link: `/products/settings`,
      route_name: useRoutesNames().productsSettings,
      tab: null,
    },
  ];

  const managementLocationsSettingsTabs = [
    {
      name: "Categories",
      link: `/management/locations/settings/${AppSubnavTabs.CATEGORIES}`,
      route_name: useRoutesNames().managementLocationsSettingsTab,
      tab: AppSubnavTabs.CATEGORIES,
    },
    {
      name: "Tags",
      link: `/management/locations/settings/${AppSubnavTabs.TAGS}`,
      route_name: useRoutesNames().managementLocationsSettingsTab,
      tab: AppSubnavTabs.TAGS,
    },
    {
      name: "Settings",
      link: `/management/locations/settings`,
      route_name: useRoutesNames().managementLocationsSettings,
      tab: null,
    },
  ];

  const activeNav = ref(null);
  const activeSubnav = ref(null);
  const activeLink = ref("");

  const navItemsCashier = computed(() => [
    {
      title: NavItemTitle.POS,
      route: useRoutesNames().posShop,
      link: "/pos/shop",
      is_active: true,
    },
    {
      title: NavItemTitle.PROFILE,
      route: useRoutesNames().user,
      link: "/user",
      is_active: true,
    },
  ]);

  const navItemsPicker = computed(() => [
    {
      title: NavItemTitle.ORDERS,
      route: useRoutesNames().orders,
      link: "/orders/preparing",
      is_active: true,
      functions: [
        {
          name: "Preparing",
          link: "/orders/preparing",
        },
        {
          name: "Schedule",
          link: "/orders/schedule",
        },
      ],
    },
  ]);

  const navItemsSales = computed(() => [
    {
      title: useConfig().isClinician
        ? NavItemTitle.BOOKINGS
        : NavItemTitle.ORDERS,
      route: useRoutesNames().orders,
      link: "/orders",
      is_active: true,
    },
    {
      title: NavItemTitle.CONTACTS,
      route: useRoutesNames().contactsPeople,
      link: "/contacts/people",
      is_active: true,
      functions: [
        {
          name: "People",
          link: "/contacts/people",
        },
        {
          name: "Companies",
          link: "/contacts/companies",
        },
        ...(useSettingsStore().settings?.loyalty_reward?.enabled
          ? [
              {
                name: "Loyalty and Rewards",
                link: "/contacts/loyalty-rewards",
              },
            ]
          : []),
      ],
    },
  ]);

  const bookingMenu = computed(() => [
    {
      title: "Booking",
      link: "/booking",
      route: useRoutesNames().booking,
      is_active: true,
    },
    {
      title: "Account",
      link: useGetToken() ? "/" : "/booking/account/login/email",
      route: useGetToken()
        ? useRoutesNames().index
        : useRoutesNames().bookingAccountLoginScreen,
      is_active: true,
    },
  ]);

  const participantsMenu = computed(() => [
    {
      title: "Bookings",
      link: "/orders",
      route: useRoutesNames().orders,
      is_active: true,
    },
  ]);

  // Apps
  const apps = ref<App[]>([]);
  const editingApp = ref<App>({} as App);
  const editingAppFunction = ref<App>({} as App);

  async function getAppsList(): Promise<ResponseType<App[]>> {
    let response: any;

    try {
      response = await useVaniloApi("/applications");

      apps.value = response?.data;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  async function getApp(id: number): Promise<ResponseType<App>> {
    let response: any;

    try {
      response = await useVaniloApi(`/applications/${id}`);
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  async function updateApp(id: number, body): Promise<ResponseType<App>> {
    let response: any;

    try {
      response = await useVaniloApi(`/applications/${id}`, {
        method: "POST",
        body,
      });

      apps.value = apps.value.map((app) => {
        if (app.id === response?.data.id) {
          return response?.data;
        }

        return app;
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  async function updateAppsBulk(
    applications: AppBulkItem[]
  ): Promise<ResponseType<App[]>> {
    let response: any;

    try {
      response = await useVaniloApi("/applications/bulk-update", {
        method: "POST",
        body: {
          applications,
        },
      });

      apps.value = response?.data;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  async function updateAppFunctionsBulk(
    functions: App[]
  ): Promise<ResponseType<App[]>> {
    let response: any;

    try {
      response = await useVaniloApi(`/application-functions/bulk-update`, {
        method: "POST",
        body: {
          application_functions: [
            ...functions.map((item) => ({
              id: item.id,
              is_active: item.is_active,
              position: item.position,
              name: item.name,
            })),
          ],
        },
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  async function createApp(body: App): Promise<ResponseType<App>> {
    let response: any;

    try {
      response = await useVaniloApi("/applications", {
        method: "POST",
        body,
      });

      apps.value = [...apps.value, response?.data];
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  async function deleteApp(id: number): Promise<ResponseType<App>> {
    let response: any;

    try {
      response = await useVaniloApi(`/applications/${id}`, {
        method: "DELETE",
      });

      apps.value = apps.value.filter((app) => app.id !== id);
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  return {
    // Navigation
    productsSettingsTabs,
    managementLocationsSettingsTabs,
    activeNav,
    activeSubnav,
    activeLink,
    navItemsCashier,
    navItemsPicker,
    navItemsSales,
    bookingMenu,
    participantsMenu,

    // Apps
    apps,
    editingApp,
    editingAppFunction,
    getAppsList,
    getApp,
    updateApp,
    updateAppsBulk,
    updateAppFunctionsBulk,
    createApp,
    deleteApp,
  };
});
