import { SidebarPrefix } from "./general";

export enum PickingOption {
  QUICK_VIEW = "Quick View",
  PREPARE = "Prepare",
}

export interface PickingPayload {
  picked_by_id: number;
  order_items: {
    id: number;
    picked: number;
    picker_notes?: string;
  }[];
}

// SIDEBAR
export const SidebarPage = {
  PICKING_PLAYLIST: `${SidebarPrefix.PICKING}-playlist`,
  PICKING_FILTERS: `${SidebarPrefix.PICKING}-filters`,
  PICKING_PRODUCT_ADJUST: `${SidebarPrefix.PICKING}-product-adjust`,
  PICKING_KANBAN_SETTINGS: `${SidebarPrefix.PICKING}-kanban-settings`,
  PICKING_KANBAN_LANE_MANAGE: `${SidebarPrefix.PICKING}-kanban-lane-manage`,
};
