import type { FetchOptions } from "ohmyfetch";

export const useVaniloApi = async (
  path: string,
  opts?: FetchOptions,
  settings?: {
    noStoreFilter?: boolean;
  }
) => {
  const {
    public: { baseURL, isElectron },
  } = useRuntimeConfig();

  // function to transform all boolean values in an object to 1 or 0
  function serializeBooleans(obj: any) {
    for (const key in obj) {
      if (typeof obj[key] === "boolean") {
        obj[key] = obj[key] ? 1 : 0;
      }
    }
    return obj;
  }

  // if params are provided in the options, serialize the boolean values
  if (opts && opts.params) {
    opts.params = serializeBooleans(opts.params);
  }

  // selected location filter
  const selectedLocation = useCookie("selected-location").value as any;

  if (
    !settings?.noStoreFilter &&
    selectedLocation &&
    (!opts?.params?.["filter[store_id][]"] ||
      !opts?.params?.["filter[store][]"])
  ) {
    if (!opts) opts = { params: {} };
    if (!opts?.params) opts.params = {};

    opts.params["filter[store_id][]"] = [selectedLocation?.id];
    opts.params["filter[store][]"] = [selectedLocation?.id];
  }

  return await $fetch(path, {
    baseURL: `${
      isElectron ? localStorage.getItem("electron-base-url") : baseURL
    }/api`,
    headers: {
      ...(useGetToken() && {
        Authorization: `Bearer ${useGetToken()}`,
      }),
      Accept: "application/json",
    },
    ...(opts && { ...opts }),
    onResponseError({ response }) {
      if (response.status === 401) {
        useRemoveToken();
      }

      return;
    },
  });
};
