<template>
  <div>
    <div
      v-if="icon"
      @dragenter.prevent="toggleActive"
      @dragleave.prevent="toggleActive"
      @dragover.prevent
      @drop.prevent="dropFile"
    >
      <label :for="id ? id : 'files-upload'" class="cursor-pointer">
        <PaperClipIcon
          class="w-x6 h-5 stroke-font-primary stroke-1"
          aria-hidden="true"
        />
        <input
          :id="id ? id : 'files-upload'"
          name="files-upload"
          type="file"
          class="sr-only"
          :accept="'application/pdf, image/bmp, image/png, image/jpg, image/jpeg, image/gif'"
          ref="files"
          @change="uploadFile"
          multiple
        />
      </label>
    </div>
    <div
      v-else-if="text?.length"
      @dragenter.prevent="toggleActive"
      @dragleave.prevent="toggleActive"
      @dragover.prevent
      @drop.prevent="dropFile"
    >
      <label :for="id ? id : 'files-upload'" class="cursor-pointer">
        <span
          class="underline text-xs leading-base-sm hover:text-accent-color hover:no-underline text-font-secondary"
          >{{ text }}</span
        >
        <input
          :id="id ? id : 'files-upload'"
          name="files-upload"
          type="file"
          class="sr-only"
          accept="text/csv"
          ref="files"
          @change="uploadFile"
        />
      </label>
    </div>

    <div
      v-else
      class="border p-x5 relative min-h-[180px] flex flex-col items-center justify-center"
      @dragenter.prevent="toggleActive"
      @dragleave.prevent="toggleActive"
      @dragover.prevent
      @drop.prevent="dropFile"
      :class="{
        'bg-accent-color/30': active,
        'bg-white': !active,
        'border-border-color': !error,
        '!border-error': error,
        'border-dashed border-2': dashed,
        'rounded-x1': !rounded2,
        'rounded-x2': rounded2,
      }"
    >
      <div class="flex justify-center mb-5">
        <img
          src="~/assets/img/upload-cloud-new.svg"
          alt="cloud upload icon"
          class="w-5 h-5"
        />
      </div>

      <label :for="id ? id : 'files-upload'" class="cursor-pointer">
        <div class="flex gap-x1 items-center justify-center">
          <span
            class="text-sm cursor-pointer leading-base text-accent-color font-medium text-center"
            >Click to upload</span
          >
          <input
            :id="id ? id : 'files-upload'"
            name="files-upload"
            type="file"
            class="sr-only"
            :accept="
              !type || type === 'pdf'
                ? 'application/pdf'
                : '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            "
            ref="files"
            @change="uploadFile"
            multiple
          />
          <span
            class="text-sm leading-base text-font-primary font-normal text-center"
          >
            or drag and drop
          </span>
        </div>
        <span
          class="block text-xs leading-base-sm text-font-secondary text-center text-font-primary mt-x1"
        >
          only {{ type || "files" }} (max. {{ size }})
        </span>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
//
import { PaperClipIcon } from "@heroicons/vue/outline";

let props = defineProps({
  id: { type: String, default: "" },
  text: { type: String, default: "" },
  customer: Boolean,
  addNew: { type: Boolean, default: false },
  error: Boolean,
  type: String,
  icon: Boolean,
  size: { type: String, default: "10mb" },
  dashed: { type: Boolean, default: false },
  rounded2: { type: Boolean, default: false },
});

const emit = defineEmits(["uploadImage"]);

const active = ref(false);
let media: object = {};

function toggleActive() {
  active.value = !active.value;
}
function dropFile(event) {
  uploadFile(event, true);
}

function uploadFile(event, dropFile = false) {
  let uploadedFiles = dropFile ? event.dataTransfer.files : event.target.files;
  let files = [];
  uploadedFiles.forEach((file) => {
    files.push({
      file: file,
      title: file.name,
    });
  });

  emit("uploadImage", files);
}
</script>
