import { defineStore } from "pinia";

import { ResponseType, Image } from "~/types/general";
import {
  Onboarding,
  OnboardingModule,
  OnboardingPayload,
  OnboardingSection,
  OnboardingSectionCreatePayload,
  OnboardingType,
  Onboard,
  OnboardManage,
  OnboardBulk,
  OnboardQR,
  OnboardQRManage,
  OnboardingSectionName,
} from "~/types/onboarding";
import { useSettingsStore } from "./settings";

export const useOnboardingStore = defineStore("onboarding", () => {
  // State
  const selectedIndexPath = ref("");
  const allOnboardsList = ref([] as Onboard[]);
  const onboardsList = ref([] as Onboard[]);
  const editingOnboard = ref({} as OnboardManage);
  const viewingOnboard = ref({} as Onboard);
  const onboardQR = ref({} as OnboardQR);
  const editingQR = ref({} as OnboardQRManage);
  const sections = ref([] as OnboardingSection[]);
  const editingSection = ref({} as OnboardingSection);
  const creatingSection = ref({} as OnboardingSectionCreatePayload);
  const onboarding = ref({} as Onboarding);
  const image = ref({} as Image);
  const modulesOptions = ref([
    {
      name: "Basic Info",
      value: OnboardingModule.BASIC_INFO,
      types: [
        OnboardingType.INDIVIDUAL,
        OnboardingType.CONTACT,
        OnboardingType.COMPANY,
        OnboardingType.STAFF,
        OnboardingType.LOCATION,
        OnboardingType.SUPPORT,
      ],
    },
    {
      name: "Profile Details",
      value: OnboardingModule.PROFILE_DETAILS,
      types: [
        OnboardingType.INDIVIDUAL,
        OnboardingType.CONTACT,
        OnboardingType.COMPANY,
        OnboardingType.STAFF,
      ],
    },
    {
      name: "Pin",
      value: OnboardingModule.PIN,
      types: [OnboardingType.STAFF],
    },
    {
      name: "Primary Banking Account",
      value: OnboardingModule.PRIMARY_BANKING_ACCOUNT,
      types: [OnboardingType.STAFF],
    },
    {
      name: "Superannuation",
      value: OnboardingModule.SUPERANNUATION,
      types: [OnboardingType.STAFF],
    },
    {
      name: "Billing Address",
      value: OnboardingModule.BILLING,
      types: [
        OnboardingType.INDIVIDUAL,
        OnboardingType.COMPANY,
        OnboardingType.STAFF,
      ],
    },
    {
      name: "Delivery Address",
      value: OnboardingModule.DELIVERY,
      types: [
        OnboardingType.INDIVIDUAL,
        OnboardingType.COMPANY,
        OnboardingType.STAFF,
      ],
    },
    {
      name: "Health Services",
      value: OnboardingModule.PLAN,
      types: [
        OnboardingType.INDIVIDUAL,
        OnboardingType.CONTACT,
        OnboardingType.STAFF,
      ],
    },
    {
      name: "Company Contacts",
      value: OnboardingModule.COMPANY_CONTACTS,
      types: [OnboardingType.COMPANY],
    },
    {
      name: "Locations and Brands",
      value: OnboardingModule.LOCATIONS_AND_BRANDS,
      types: [OnboardingType.LOCATION],
    },
    {
      name: "Location Agent",
      value: OnboardingModule.LOCATION_AGENT,
      types: [OnboardingType.SUPPORT],
    },
    {
      name: "Location Selection",
      description: "Location",
      value: OnboardingModule.LOCATION_SELECTION,
      types: [OnboardingType.BOOKING],
    },
    {
      name: "Service Type",
      description: "Service Type",
      value: OnboardingModule.SERVICE_TYPE,
      types: [OnboardingType.BOOKING],
    },
    {
      name: "Funding",
      description: "Funding Type",
      value: OnboardingModule.FUNDING_TYPE,
      types: [OnboardingType.BOOKING],
    },
    {
      name: "Funding Line",
      description: "Category",
      value: OnboardingModule.FUNDING_LINE,
      types: [OnboardingType.BOOKING],
    },
    {
      name: "Service",
      description: "Service",
      value: OnboardingModule.SERVICE,
      types: [OnboardingType.BOOKING],
    },
    {
      name: "Availability",
      value: OnboardingModule.AVAILABILITY,
      description: "Date Selection",
      types: [OnboardingType.BOOKING],
    },
  ]);
  const isDifferentBillingDetails = ref(false);
  const isTermsAccepted = ref(false);
  const isOpenSidebar = ref(false);
  const verifyCode = ref(null);
  const isNewLocation = ref(false);
  const selectedLocationBrand = ref([]);
  const createLocationStep = ref({
    name: "create-account",
    label: "Login Details",
    queue: 2,
    is_active: true,
    is_protected: true,
    description: "Setup your account login details",
    terms_link: null,
    privacy_link: null,
    use_signature_module: false,
    modules: ["basic-info"],
    notice: null,
  });

  // Getters
  const settingsStore = useSettingsStore();

  const isLocationBrand = computed(
    () =>
      onboardsList.value.find(
        (i) => i.url === `/onboarding/${selectedIndexPath.value}`
      )?.type === OnboardingType.LOCATION
  );

  const selectedOnboard = computed(
    () =>
      onboardsList.value.find(
        (i) =>
          i.url === `/onboarding/${selectedIndexPath.value}` ||
          i.url === `/booking/${selectedIndexPath.value}`
      )?.id
  );

  const isCompany = computed(
    () =>
      onboardsList.value.find(
        (i) => i.url === `/onboarding/${selectedIndexPath.value}`
      )?.type === OnboardingType.COMPANY
  );

  const isStaff = computed(
    () =>
      onboardsList.value.find(
        (i) => i.url === `/onboarding/${selectedIndexPath.value}`
      )?.type === OnboardingType.STAFF
  );

  const isContact = computed(
    () =>
      onboardsList.value.find(
        (i) => i.url === `/onboarding/${selectedIndexPath.value}`
      )?.type === OnboardingType.CONTACT
  );

  const isAgent = computed(
    () =>
      onboardsList.value.find(
        (i) => i.url === `/onboarding/${selectedIndexPath.value}`
      )?.type === OnboardingType.SUPPORT
  );

  const isQuickAgent = computed(
    () =>
      onboardsList.value.find(
        (i) => i.url === `/onboarding/${selectedIndexPath.value}`
      )?.type === OnboardingType.QUICK_SUPPORT
  );

  const isParticipants = computed(
    () =>
      onboardsList.value.find(
        (i) => i.url === `/onboarding/${selectedIndexPath.value}`
      )?.type === OnboardingType.INDIVIDUAL
  );

  const isBooking = computed(
    () =>
      !!onboardsList.value.find(
        (i) => i.url === `/booking/${selectedIndexPath.value}`
      )
  );

  const isDifferent = computed(() => isDifferentBillingDetails.value);

  const companyTypeName = computed(
    () =>
      settingsStore.publicSettings?.onboarding?.company?.qr_code?.name ||
      settingsStore.settings?.onboarding?.company?.qr_code?.name
  );

  const individualTypeName = computed(
    () =>
      settingsStore.publicSettings?.onboarding?.individual?.qr_code?.name ||
      settingsStore.settings?.onboarding?.individual?.qr_code?.name
  );

  // Actions - Email
  async function checkEmail(val) {
    useIsLoading(true);

    let response;

    try {
      response = await useVaniloApi(`/check-email/${val}`);
    } catch (error) {
      console.log(error);
    }

    return response;
  }

  async function createStore(body) {
    let response: any;

    try {
      response = await useVaniloApi("stores/unauthorized", {
        method: "POST",
        body,
      });
    } catch (error) {
      useCatchError(error);
    }

    return response;
  }

  async function register(body) {
    let response;

    const router = useRouter();
    const route = useRoute();

    try {
      response = await useVaniloApi("/customers/registration", {
        method: "POST",
        body,
      });
    } catch (error) {
      useCatchError(error);
    }

    if (response?.data?.[0]?.id) {
      if (settingsStore.publicSettings?.general?.features?.mfa) {
        router.replace({
          name: useRoutesNames().onboardingTypeName,
          params: {
            type: selectedIndexPath.value,
            name: OnboardingSectionName.VERIFICATION,
          },
          query: {
            ...(route.query?.assign && { assign: route.query.assign }),
            ...(response?.data?.[0]?.id && { id: response?.data?.[0]?.id }),
          },
        });
      } else {
        router.replace({
          name: useRoutesNames().onboardingTypeSuccess,
          params: {
            type: selectedIndexPath.value,
          },
          ...(route.query?.isBooking && { isBooking: route.query.isBooking }),
        });
      }
    }

    return response;
  }

  async function verifyEmail(customerId, code) {
    let response;

    const router = useRouter();
    const route = useRoute();

    try {
      response = await useVaniloApi(`/email/verify/${customerId}/${code}`);

      router.replace({
        name: useRoutesNames().onboardingTypeSuccess,
        params: {
          type: selectedIndexPath.value,
        },
        ...(route.query?.isBooking && { isBooking: route.query.isBooking }),
      });
    } catch (error) {
      useCatchError(error);
    }
    return response;
  }

  // Actions - Onboard
  async function getOnboards(params?) {
    let response;

    try {
      response = await useVaniloApi("/onboards", { params });

      allOnboardsList.value = response?.data;

      return response as ResponseType<Onboard[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleOnboard(id) {
    try {
      const response = await useVaniloApi(`/onboards/${id}`);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createOnboard(body) {
    try {
      const response = await useVaniloApi("/onboards", {
        method: "POST",
        body,
      });

      return response as ResponseType<Onboard>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateOnboard(body) {
    try {
      const response = await useVaniloApi(`/onboards/${body.id}`, {
        method: "POST",
        body,
      });

      return response as ResponseType<Onboard>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteOnboard(id) {
    try {
      const response = await useVaniloApi(`/onboards/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateOnboardsBulk(body) {
    try {
      const response = await useVaniloApi(`/onboards/bulk-update`, {
        method: "POST",
        body,
      });

      return response as ResponseType<Onboard[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Actions - QR
  async function getOnboardQr(onboardId) {
    let response;
    try {
      response = await useVaniloApi(`onboards/${onboardId}/qr-codes`);
    } catch (error) {
      useCatchError(error);
    }
    return response;
  }

  async function createOnboardQr(onboardId, body) {
    try {
      const response = await useVaniloApi(`onboards/${onboardId}/qr-codes`, {
        method: "POST",
        body,
      });

      return response as ResponseType<OnboardQR>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateOnboardQr(body) {
    try {
      const response = await useVaniloApi(`onboards/qr-codes/${body.id}`, {
        method: "POST",
        body,
      });

      return response as ResponseType<OnboardQR>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteOnboardQr(id) {
    try {
      const response = await useVaniloApi(`onboards/qr-codes/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Actions - Sections
  async function getSections(params?) {
    let response;
    try {
      response = await useVaniloApi("/onboards/sections", {
        params,
      });
    } catch (error) {
      useCatchError(error);
    }
    return response;
  }

  async function createSection(onboardId, body) {
    try {
      const response = await useVaniloApi(`/onboards/${onboardId}/sections`, {
        method: "POST",
        body,
      });

      return response as ResponseType<OnboardingSection>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateSection(id, body = {}) {
    try {
      const response = await useVaniloApi(`onboards/sections/${id}`, {
        method: "POST",
        body,
      });

      return response as ResponseType<OnboardingSection>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteSection(id) {
    try {
      const response = await useVaniloApi(`onboards/sections/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateSectionsBulk(body) {
    try {
      const response = await useVaniloApi(`/onboards/sections/bulk-update`, {
        method: "POST",
        body,
      });

      return response as ResponseType<OnboardingSection>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getBrandsWithLocations(params) {
    let response;
    try {
      response = await useVaniloApi("/brands/search-brands-with-stores", {
        params,
      });
    } catch (error) {
      useCatchError(error);
    }
    return response;
  }

  return {
    selectedIndexPath,
    allOnboardsList,
    onboardsList,
    editingOnboard,
    viewingOnboard,
    onboardQR,
    editingQR,
    sections,
    editingSection,
    creatingSection,
    onboarding,
    image,
    modulesOptions,
    isDifferentBillingDetails,
    isTermsAccepted,
    isOpenSidebar,
    verifyCode,
    isNewLocation,
    selectedLocationBrand,
    createLocationStep,
    isLocationBrand,
    selectedOnboard,
    isCompany,
    isStaff,
    isContact,
    isAgent,
    isQuickAgent,
    isParticipants,
    isBooking,
    isDifferent,
    companyTypeName,
    individualTypeName,
    checkEmail,
    register,
    verifyEmail,
    getOnboards,
    getSingleOnboard,
    createOnboard,
    updateOnboard,
    deleteOnboard,
    updateOnboardsBulk,
    getOnboardQr,
    createOnboardQr,
    updateOnboardQr,
    deleteOnboardQr,
    getSections,
    createSection,
    updateSection,
    deleteSection,
    updateSectionsBulk,
    getBrandsWithLocations,
    createStore,
  };
});
