<template>
  <div class="flow-root">
    <ul role="list">
      <li v-for="(item, index) in history" :key="item.id">
        <div class="relative pb-8">
          <span
            v-if="index !== history.length - 1"
            class="absolute top-5 left-6 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />

          <div class="relative flex items-start space-x-3">
            <div class="relative">
              <img
                v-if="useGetThumbnail(item?.user?.image)"
                :src="useGetThumbnail(item?.user?.image)"
                alt=""
                class="flex h-x8 w-x8 items-center justify-center object-cover rounded-x1 bg-gray-400 ring-white"
              />
              <div
                v-else-if="
                  useGetCustomerInitials(item?.user?.name)?.length &&
                  !useGetThumbnail(item?.user?.image)
                "
                class="flex rounded-x1 h-x8 w-x8 items-center justify-center bg-grey-500 text-sm"
              >
                <p class="text-white">
                  {{ useGetCustomerInitials(item?.user?.name) }}
                </p>
              </div>
              <img
                v-else
                src="~/assets/img/default-user.png"
                alt=""
                class="flex h-x8 w-x8 items-center justify-center object-cover rounded-x1 bg-gray-400 ring-white"
              />
            </div>

            <div class="min-w-0 flex-1">
              <div>
                <div class="text-sm font-medium text-font-primary">
                  {{ item.user?.name || "System" }}
                </div>

                <p class="mt-0.5 text-xs text-font-secondary">
                  <span v-if="item.type !== InventoryHistoryType.NOTE">
                    {{
                      item.type === InventoryHistoryType.ADJUSTMENT
                        ? "Adjusted"
                        : item.type === InventoryHistoryType.CONVERSION
                          ? "Converted"
                          : ""
                    }}
                    inventory
                  </span>
                  <span v-else>Commented</span>
                  -
                  {{ useFormatDate(item.created_at) }}
                </p>
              </div>

              <div class="mt-2 text-sm text-font-primary">
                <template v-if="item.type !== InventoryHistoryType.NOTE">
                  {{ useCapitalize(item.reason) }} -
                  {{ item.amount > 0 ? "added" : "removed" }}
                  {{ Math.abs(item.amount) }}
                  {{ item.amount === 1 ? "unit" : "units" }} of
                  <template
                    v-if="item.type === InventoryHistoryType.ADJUSTMENT"
                  >
                    <span
                      class="bg-grey-400 text-xs px-2.5 py-[2px] rounded-xl inline-flex gap-x1"
                      v-html="adjustingProductTitle"
                    >
                    </span>
                  </template>
                  <template
                    v-if="item.type === InventoryHistoryType.CONVERSION"
                  >
                    <span
                      class="bg-grey-400 text-xs px-2.5 py-[2px] rounded-xl inline-flex gap-x1"
                      v-html="getConvertingProductTitle(item.from_product)"
                    >
                    </span>
                    to
                    <span
                      class="bg-grey-400 text-xs px-2.5 py-[2px] rounded-xl inline-flex gap-x1"
                      v-html="getConvertingProductTitle(item.to_product)"
                    >
                    </span>
                  </template>
                  -
                  {{ item.text.toLowerCase() }}
                </template>

                <p v-else>{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { useInventoryStore } from "~/store/inventory";
import { InventoryHistory, InventoryHistoryType } from "~/types/inventory";

const route = useRoute();

const inventoryStore = useInventoryStore();

const history = ref<InventoryHistory[]>([] as InventoryHistory[]);

function getVariations(product) {
  const variants = product.properties
    ?.filter((i) => !i.symbol_html?.length)
    ?.map((prop) => prop.title)
    ?.join(" ");

  if (product.ext_title && useConfig().isProductSecondaryName && variants) {
    return `${product.ext_title} ${variants}`;
  } else if (product.ext_title && useConfig().isProductSecondaryName) {
    return product.ext_title;
  } else if (variants) {
    return variants;
  }
}

function getSymbols(product) {
  return (
    product.properties
      ?.filter((i) => i.symbol_html?.length)
      ?.map((prop) => prop.symbol_html)
      ?.join(" ") ?? ""
  );
}

const adjustingProductTitle = computed(
  () =>
    `${inventoryStore.viewingInventory.product.name} ${getSymbols(
      inventoryStore.viewingInventory.product
    )} ${getVariations(inventoryStore.viewingInventory.product)}`
);

function getConvertingProductTitle(product) {
  return `${product.name} ${getSymbols(product)} ${getVariations(product)}`;
}

const historyQueries = ref({
  page: 1,
  limit: 50,
  "sort[]": "date-desc",
});
const historyMeta = ref(null);

async function loadMore() {
  if (historyQueries.value.page < historyMeta.value?.last_page) {
    historyQueries.value.page += 1;

    await getInventoryHistory();
  }
}

async function getInventoryHistory() {
  useIsLoading(true);
  try {
    const response = await inventoryStore.getInventoryHistory(
      +route.query["inventory-id"],
      { ...historyQueries.value }
    );

    history.value =
      response.meta?.current_page === 1
        ? response?.data
        : [...history.value, ...response?.data];

    historyMeta.value = response.meta;
  } catch (error) {}
  useIsLoading(false);
}

defineExpose({
  loadMore,
});

onMounted(() => {
  getInventoryHistory();
});
</script>
