import { defineStore } from "pinia";
import {
  OrderManage,
  OrderItem,
  OrderStatus,
  Order,
  OrderInvoice,
  OrderHoldNote,
} from "~/types/orders";
import { PosTilesLevel, SalesMethod } from "~/types/settings";
import {
  CashCount,
  CloseSessionPayload,
  CustomerOptionShop,
  MemberBalances,
  OpenSessionPayload,
  PosPage,
  PosTransitionName,
  Session,
  SessionSingle,
  SessionType,
  PosTileManage,
  PosTileType,
  PosCloseStep,
  PosTile,
  SidebarPage,
} from "~/types/pos";
import { useOrdersStore } from "./orders";
import {
  Filters,
  Option,
  OrderType,
  ToastType,
  ResponseType,
  Note,
  SyncStatus,
} from "~/types/general";
import { PaymentMethodGateway, PaymentMethodOption } from "~/types/payment";
import { usePaymentStore } from "./payment";
import {
  Customer,
  CustomerType,
  CustomerShort,
  CustomerSingle,
  AddressType,
} from "~/types/customers";
import { useCustomersStore } from "./customers";
import { useGeneralStore } from "./general";
import { useInventoryStore } from "./inventory";
import { Product } from "~/types/products";
import { useSettingsStore } from "./settings";
import { useStoresStore } from "./stores";
import { useNotesStore, NoteLink } from "~/store/notes";
import { useUsersStore } from "~/store/users";
import { Table } from "~/types/table-ordering";
import { PosOptionAction } from "~/types/pos";
import { ReportType } from "~/types/reports";
import { DiscountType } from "~/types/discounts";
import { StorageService } from "~/services/StorageService";
import { IndexedDBStore } from "~/types/bff";
import { useProductsStore } from "./products";
import { ChannelOption } from "~/types/channels";
import { InventorySingle } from "~/types/inventory";
import { FilterType } from "~/types/filters";

export const usePosStore = defineStore("pos", () => {
  const customersStore = useCustomersStore();
  const ordersStore = useOrdersStore();

  // ORDERS LOGIC
  const creatingOrder = ref<OrderManage>({} as OrderManage);
  const viewingOrder = ref<Order>({} as Order);
  const viewingInvoice = ref<OrderInvoice>({} as OrderInvoice);
  const cart = ref<OrderItem[]>([] as OrderItem[]);
  const isThirdPartyChannel = ref(false);
  const selectedChannel = ref<ChannelOption>({} as ChannelOption);
  const totals = ref<Order>({} as Order);
  const selectedAccountOrders = ref<Order[]>([] as Order[]);
  const selectedSalesManagerId = ref<number | null>(null);
  const isPayBalanceClicked = ref(false);
  const selectedOrders = ref<Order[]>([] as Order[]);
  const isShowModalOrder = ref(false);
  const isShowModalOrderUi = ref(false);
  const isShowModalMultipay = ref(false);
  const isShowModalDiscountOrder = ref(false);
  const isShowModalDiscountOrderItem = ref(false);
  const isShowModalPartialPaymentConfirm = ref(false);
  const discountOrderItemId = ref<number | null>(null);
  const editingHold = ref<OrderHoldNote>({} as OrderHoldNote);
  const isShowModalHold = ref(false);
  const currentOrderId = ref<number | null>(null);
  const creatingOrderNotes = ref<{ text?: string; ids?: number[] }>({});
  const customerPaid = ref<number | string | null>(null);
  const change = ref<number | string | null>(null);
  const remaining = ref<number | string | null>(null);
  const finalCardAmount = ref<number | null>(null);
  const discountOrderFactorPercent = ref(1);
  const discountOrderFactorFixed = ref(0);
  const isPosToggle = ref(false);

  const isSameCustomerPayBalance = computed(() =>
    selectedAccountOrders.value?.every(
      (o) => o.customer?.id === creatingOrder.value.customer_id
    )
  );

  const subtotalSelectedAccountOrders = computed(
    () =>
      selectedAccountOrders.value?.reduce(
        (prev, curr) => prev + +curr.subtotal_value,
        0
      ) || 0
  );

  const totalSelectedAccountOrders = computed(
    () =>
      selectedAccountOrders.value?.reduce(
        (prev, curr) => prev + +curr.credit_amount,
        0
      ) || 0
  );

  const taxSelectedAccountOrders = computed(
    () =>
      selectedAccountOrders.value?.reduce(
        (prev, curr) => prev + +curr.tax_value,
        0
      ) || 0
  );

  const notPaidSelectedAccountOrders = computed(() =>
    selectedAccountOrders.value?.filter((o) => o.credit_amount)
  );

  const isDiscountOrderApplied = computed(() =>
    totals.value?.adjustments?.some((a) => a.type === "discount")
  );

  async function createDraft(items): Promise<ResponseType<Order>> {
    creatingOrder.value.status = OrderStatus.DRAFT;

    // reset team member if setting is enabled
    // if (useConfig().isPosResetTeamMember) {
    //   resetTeamMember();

    //   return;
    // }

    try {
      const response = await useOrdersStore().createOrder({
        ...creatingOrder.value,
        ...(items?.length && {
          items,
        }),
      });

      return response as ResponseType<Order>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function editOrderMerge(payload: {
    items?: any[];
    merge?: {
      subtotal?: number;
      tax?: number;
      delete_items?: number[];
    };
  }): Promise<ResponseType<Order>> {
    try {
      useIsLoading(true);
      const res = await useOrdersStore().editOrderMerge({
        id: creatingOrder.value.id,
        store_id: creatingOrder.value.store_id,
        ...payload,
      });

      totals.value = res?.data;
      viewingOrder.value = res?.data;

      cart.value = res?.data?.items.map((item) => ({
        ...item,
        supplier_id: item.supplier?.id,
      }));

      // set discount order factor
      const { adjustments, items } = res?.data || {};

      const discount = adjustments.find((adj) => adj.type === "discount");

      if (discount?.data?.percent_amount) {
        discountOrderFactorPercent.value = discount.data.percent_amount / 100;
      } else if (discount?.data?.fixed_amount) {
        discountOrderFactorFixed.value =
          discount.data.fixed_amount / items.length;
      } else {
        discountOrderFactorPercent.value = 1;
        discountOrderFactorFixed.value = 0;
      }

      // updating cart in localstorage
      localStorage.setItem("cart", JSON.stringify(cart.value));

      // update "recent_purchase_status" for the customer
      updateCustomerRecentPurchaseStatus(res);

      useIsLoading(false);

      return res as ResponseType<Order>;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function editOrder(payload?: {
    items?: any[];
    customer_id?: number;
  }): Promise<ResponseType<Order>> {
    try {
      useIsLoading(true);
      const res = await useOrdersStore().editOrder(creatingOrder.value.id, {
        ...creatingOrder.value,
        ...(payload?.customer_id && { customer_id: payload.customer_id }),
        items:
          payload?.items ||
          cart.value.map((item) => useGetPosItemPayload(item)),
      });

      totals.value = res?.data;
      viewingOrder.value = res?.data;

      // set discount order factor
      const { adjustments, items } = res?.data || {};

      const discount = adjustments.find((adj) => adj.type === "discount");

      if (discount?.data?.percent_amount) {
        discountOrderFactorPercent.value = discount.data.percent_amount / 100;
        discountOrderFactorFixed.value = 0;
      } else if (discount?.data?.fixed_amount) {
        discountOrderFactorFixed.value =
          discount.data.fixed_amount / items.length;
        discountOrderFactorPercent.value = 1;
      } else {
        discountOrderFactorPercent.value = 1;
        discountOrderFactorFixed.value = 0;
      }

      cart.value = items?.map((item) => ({
        ...item,
        supplier_id: item.supplier?.id,
      }));

      // updating cart in localstorage
      localStorage.setItem("cart", JSON.stringify(cart.value));

      // update "recent_purchase_status" for the customer
      updateCustomerRecentPurchaseStatus(res);

      return res as ResponseType<Order>;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function addToCart(item): Promise<ResponseType<Order>> {
    try {
      // create order DRAFT
      if (
        !creatingOrder.value.id &&
        (creatingOrder.value.customer_id ||
          creatingOrder.value.customer_id === null)
      ) {
        const res = await createDraft([item]);
        useAssignPosOrder(res?.data, {
          isAssignCustomer: false,
        });

        return res as ResponseType<Order>;
      }

      // update order
      const res = await editOrderMerge({
        items: [item],
        merge: {},
      });

      return res as ResponseType<Order>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function pasteProducts(items): Promise<ResponseType<Order>> {
    const noIdsItems = items.map((item) => {
      delete item.id;
      return item;
    });

    // create draft if not exists
    if (!creatingOrder.value.id) {
      const res = await createDraft(
        noIdsItems.map((item) => useGetPosItemPayload(item))
      );

      useAssignPosOrder(res?.data, {
        isAssignCustomer: false,
      });

      return res as ResponseType<Order>;
    }

    // update existing order
    const res = await editOrderMerge({
      items: noIdsItems.map((item) => useGetPosItemPayload(item)),
      merge: {},
    });

    useAssignPosOrder(res?.data, {
      isAssignCustomer: false,
    });

    return res as ResponseType<Order>;
  }

  async function addByBarcode(barcode: string) {
    useIsLoading(true);
    try {
      const response = await useInventoryStore().getInventoryListBff({
        search: {
          term: barcode,
          fields: ["product.name", "sku", "alias_skus"],
          filterFn: (item) =>
            item.store?.id === +localStorage.getItem("pos-location"),
        },
      });

      const itemBff = response?.data?.[0];

      let itemMain;

      // if product not found in BFF - search in main
      if (!itemBff) {
        const response =
          await useInventoryStore().getInventoryByBarcode(barcode);

        itemMain = response?.data?.product?.id;
      }

      // show toast if product not found in both
      if (!itemBff && !itemMain) {
        useToast("Product not found", {
          type: ToastType.ERROR,
          duration: 3000,
        });

        return;
      }

      const item = itemBff || itemMain;

      const itemPayload = {
        product_id: item.product?.id,
        quantity: 1,
      };

      // create draft if not exists
      if (!creatingOrder.value.id) {
        const res = await createDraft([itemPayload]);

        useAssignPosOrder(res?.data, {
          isAssignCustomer: false,
        });

        return res as ResponseType<Order>;
      }

      // update existing order
      await editOrderMerge({
        items: [itemPayload],
        merge: {},
      });

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function updateCartItem(
    product: {
      id: number;
      product_id: number;
      quantity: number;
      picker_notes?: string;
      price?: number;
      allocated?: number;
      lot_id?: number;
      supplier_id?: number;
      source_store_id?: number;
      addons?: {
        name: string;
        value: number | string;
      }[];
      composite_items?: any[];
    },
    index: number
  ) {
    cart.value[index] = product as OrderItem;

    return await editOrderMerge({
      items: [product],
      merge: {},
    });
  }

  async function removeProduct(index: number) {
    const product = cart.value[index];

    return await editOrderMerge({
      merge: {
        delete_items: [product.id],
      },
    });
  }

  function clearCart() {
    cart.value = [] as OrderItem[];

    localStorage.removeItem("cart");
  }

  async function clearOrder(payload?: { isDelete?: boolean }) {
    const paymentStore = usePaymentStore();

    clearCart();

    if (payload?.isDelete && creatingOrder.value.id) {
      useIsLoading(true);
      try {
        await useOrdersStore().deleteOrder(creatingOrder.value.id);
      } catch (error) {}
      useIsLoading(false);
    }

    // manage order notes
    if (
      !payload.isDelete &&
      creatingOrder.value.id &&
      creatingOrderNotes.value
    ) {
      try {
        if (creatingOrderNotes.value?.ids?.length) {
          await editOrderNotes(
            creatingOrderNotes.value.text,
            creatingOrderNotes.value.ids
          );
        } else {
          await createOrderNotes(creatingOrderNotes.value.text);
        }
      } catch (error) {}
    }

    const {
      created_at,
      is_express,
      status,
      store_id,
      register_id,
      type,
      channel,
      sales_manager_id,
      target_date,
    } = creatingOrder.value;

    creatingOrder.value = {
      created_at,
      is_express,
      status,
      store_id,
      register_id,
      type,
      channel,
      sales_manager_id,
      target_date,
    } as OrderManage;

    customersStore.activeCustomer = {} as Customer;

    // SET GUEST IF THE SETTING SELECTED
    if (useConfig().isPosGuestDefault) {
      customersStore.activeCustomer = {
        id: null,
        name: "Guest",
        type: CustomerType.INDIVIDUAL,
      } as Customer;

      creatingOrder.value.customer_id = null;
    }

    // reset discount order factor
    discountOrderFactorPercent.value = 1;
    discountOrderFactorFixed.value = 0;

    // set target date
    if (!creatingOrder.value.target_date) {
      creatingOrder.value.target_date = [
        useDateFormatLib(new Date(), "yyyy-mm-dd HH:MM:ss"),
      ];
      datePickerKey.value++;
    }

    totals.value = {} as Order;
    viewingOrder.value = {} as Order;
    customerPaid.value = null;
    change.value = null;
    remaining.value = null;
    finalCardAmount.value = null;
    creatingOrderNotes.value = {};

    paymentStore.paymentIntent = null;
    paymentStore.paymentId = null;
    paymentStore.customerPaying = "0.00";
    paymentStore.isPayed = false;
    paymentStore.isSucceeded = false;
    paymentStore.isProcessing = false;
    paymentStore.isConfirmed = false;
    paymentStore.isPartialPayment = false;

    cart.value = [] as OrderItem[];
    localStorage.removeItem("cart");

    selectedTable.value = null as Table;
    localStorage.removeItem("pos-table");
  }

  async function setOrderType(orderType: OrderType) {
    creatingOrder.value.type = orderType;

    if (!creatingOrder.value?.id) return;

    return await editOrder();
  }

  async function setCustomer(customer?: Customer | CustomerShort) {
    creatingOrder.value.customer_id = customer.id;
    await assignCustomer(customer);

    if (!creatingOrder.value?.id) return;

    return await editOrder();
  }

  async function assignCustomer(customer?: Customer | CustomerShort) {
    if (customer) {
      customersStore.activeCustomer = customer;
    }

    creatingOrder.value.customer_id = customersStore.activeCustomer?.id;

    // get full customer data if NOT 'Guest'
    if (creatingOrder.value.customer_id !== null && customer) {
      try {
        const response = await customersStore.getSingleCustomer(
          creatingOrder.value.customer_id
        );

        customersStore.activeCustomer = response?.data;

        assignAddress(response?.data);
      } catch (error) {}

      return;
    }

    // assign guest customer
    // erasing address data if exists
    if (creatingOrder.value.customer_id === null) {
      creatingOrder.value.billing_address_id = undefined;
      creatingOrder.value.shipping_address_id = undefined;
    }
  }

  function assignAddress(fullCustomer) {
    const billingAddress =
      fullCustomer.addresses.find(
        (address) => address.type === AddressType.BILLING && address.is_default
      ) ||
      fullCustomer.addresses.find(
        (address) => address.type === AddressType.BILLING
      );
    const shippingAddress =
      fullCustomer.addresses.find(
        (address) => address.type === AddressType.SHIPPING && address.is_default
      ) ||
      fullCustomer.addresses.find(
        (address) => address.type === AddressType.SHIPPING
      );

    creatingOrder.value.billing_address_id = billingAddress?.id;
    creatingOrder.value.shipping_address_id = shippingAddress?.id;

    // posStore.creatingOrder.shipping_recipient = {
    //   firstname: fullCustomer.firstname,
    //   lastname: fullCustomer.lastname,
    //   email: fullCustomer.email,
    //   phone: fullCustomer.phone,
    // };
  }

  function updateCustomerRecentPurchaseStatus(res) {
    if (
      creatingOrder.value.customer_id &&
      res?.data?.status !== OrderStatus.DRAFT
    ) {
      const customer = customersList.value?.find(
        (c) => c.id === creatingOrder.value.customer_id
      );

      if (customer) {
        customer.recent_purchase_status = res?.data?.status;
      }
    }
  }

  async function calculatePriceWithAddons(item?: {
    product_id: number;
    quantity: number;
    picker_notes?: string;
    addons?: {
      name: string;
      value: number | string;
    };
  }) {
    if (useIsEmpty(item)) return;

    const body = {
      store_id: creatingOrder.value.store_id,
      type: creatingOrder.value.type || OrderType.IN_PERSON,
      items: [item],
      ...(+redeemedPoints.value && {
        loyalty_reward_type: "points",
        loyalty_points_used: +redeemedPoints.value,
      }),
    };

    return await usePaymentStore().calculateTotals(body);
  }

  async function calculateTotals(
    items?: {
      product_id: number;
      quantity: number;
      picker_notes?: string;
      addons?: {
        name: string;
        value: number | string;
      }[];
      lot_id?: number;
      supplier_id?: number;
      supplier?: {
        id: number;
        name: string;
      };
      discount?: number;
    }[]
  ) {
    const paymentStore = usePaymentStore();
    if (!items?.length && !cart.value.length) return;

    try {
      useIsLoading(true);
      const totalsResponse = await paymentStore.calculateTotals({
        original_order_id:
          creatingOrder.value?.id ||
          (useRoute().query["retreived-order-id"] as string),
        store_id: creatingOrder.value.store_id,
        ...(creatingOrder.value.channel && {
          channel: creatingOrder.value.channel,
        }),
        type: creatingOrder.value.type || OrderType.IN_PERSON,
        is_express: creatingOrder.value.is_express,
        payment_method_id: creatingOrder.value.payment_method_id,
        customer_id: creatingOrder.value.customer_id,
        ...(+paymentStore.customerPaying && {
          paid_with_other_methods: paymentStore.customerPaying,
        }),
        items: items || cart.value.map((item) => useGetPosItemPayload(item)),
        with_express_service: true,
        with_time_slots:
          creatingOrder.value.target_date?.[0]?.split(" ")[0] ??
          creatingOrder.value.created_at.split(" ")[0],
        ...(+redeemedPoints.value && {
          loyalty_reward_type: "points",
          loyalty_points_used: +redeemedPoints.value,
        }),
        ...(isApplyBirthdayBonus.value && {
          loyalty_reward_type: "birthday",
        }),
        // DISCOUNTS
        ...(creatingOrder.value.discount && {
          discount: -Math.abs(creatingOrder.value.discount),
        }),
        ...(creatingOrder.value.discount_percent && {
          discount_percent: creatingOrder.value.discount_percent,
        }),
      });

      totals.value = totalsResponse.data;
      // cart.value = totalsResponse.data?.items?.map((item) => ({
      //   ...item,
      //   supplier_id: item.supplier?.id,
      // }));

      // updating cart in localstorage
      localStorage.setItem("cart", JSON.stringify(cart.value));

      return totalsResponse;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function applyDiscountOrder({
    value,
    type,
  }: {
    value: string | number;
    type: DiscountType;
  }) {
    if (type === DiscountType.PERCENTAGE) {
      creatingOrder.value.discount_percent = value as number;
      delete creatingOrder.value.discount;
    } else {
      creatingOrder.value.discount = -value;
      delete creatingOrder.value.discount_percent;
    }

    isShowModalDiscountOrder.value = false;

    return await editOrder();
  }

  async function applyDiscountOrderItem({
    value,
    type,
  }: {
    value: number | string;
    type: DiscountType;
  }) {
    const cartItem = cart.value.find(
      (item) => item.id === discountOrderItemId.value
    );

    if (type === DiscountType.PERCENTAGE) {
      cartItem.discount_percent = value as number;
      delete cartItem.discount;
    } else {
      cartItem.discount = -value;
      delete cartItem.discount_percent;
    }

    if (!+value) {
      delete cartItem.discount;
    }

    isShowModalDiscountOrderItem.value = false;
    discountOrderItemId.value = null;

    return await editOrder();
  }

  async function removeDiscountOrder() {
    creatingOrder.value.discount = 0;
    creatingOrder.value.discount_percent = 0;

    return await editOrder();
  }

  ////////////////////
  // PAYMENTS LOGIC //
  ////////////////////
  async function pay({
    salesManagerId,
    isPartial,
  }: { salesManagerId?: number; isPartial?: boolean } = {}) {
    const { activePaymentMethod } = usePaymentStore();

    useIsLoading(true);

    // APPLY BONUSES
    if (
      (redeemedPoints.value || isApplyBirthdayBonus.value) &&
      customersStore?.activeCustomer.id &&
      activePaymentMethod !== PaymentMethodGateway.CREDIT_NOTE
    ) {
      await payApplyBonuses();
    }

    // UPDATE ORDER
    await payUpdateOrder(salesManagerId);

    // CASH
    if (activePaymentMethod === PaymentMethodGateway.CASH) {
      await payCash(isPartial, salesManagerId);
    }

    // IN PERSON
    if (activePaymentMethod === PaymentMethodGateway.IN_PERSON) {
      await payInPerson();
    }

    // ACCOUNT
    if (activePaymentMethod === PaymentMethodGateway.ACCOUNT) {
      await payAccount();
    }

    // TRANSFER
    if (activePaymentMethod === PaymentMethodGateway.TRANSFER) {
      await payTransfer(isPartial, salesManagerId);
    }

    // CHEQUE
    if (activePaymentMethod === PaymentMethodGateway.CHEQUE) {
      await payCheque(isPartial, salesManagerId);
    }

    // CREDIT NOTE
    if (activePaymentMethod === PaymentMethodGateway.CREDIT_NOTE) {
      await payCreditNote(isPartial, salesManagerId);
    }

    useIsLoading(false);
  }

  async function payApplyBonuses() {
    try {
      const res = await useOrdersStore().applyBonusToOrder({
        orderId: creatingOrder.value.id,
        ...(isApplyBirthdayBonus.value && {
          loyaltyRewardType: "birthday",
        }),
        ...(redeemedPoints.value && { loyaltyRewardType: "points" }),
        ...(redeemedPoints.value && { amount: redeemedPoints.value }),
      });

      return res;
    } catch (error) {
      useToast(
        {
          message: "Something went wrong with applying bonuses to this order.",
          submessage: "Please, try again",
        },
        {
          type: ToastType.ERROR,
          duration: 3000,
        }
      );

      redeemedPoints.value = 0;

      throw new Error();
    }
  }

  async function payUpdateOrder(sales_manager_id?: number) {
    if (
      [
        OrderStatus.PROCESSING,
        OrderStatus.DRAFT,
        OrderStatus.PREPARING,
      ].includes(creatingOrder.value.status) &&
      creatingOrder.value.id &&
      cart.value.length
    ) {
      try {
        const res = await useOrdersStore().editOrder(creatingOrder.value.id, {
          type: creatingOrder.value.type,
          store_id: creatingOrder.value.store_id,
          ...(sales_manager_id && { sales_manager_id }),
          status: creatingOrder.value.table_id
            ? OrderStatus.PREPARING
            : OrderStatus.PENDING,
        });

        const { status, sales_manager } = res?.data || {};

        creatingOrder.value = {
          ...creatingOrder.value,
          status,
          sales_manager_id: sales_manager?.id,
        };

        // update "recent_purchase_status" for the customer
        updateCustomerRecentPurchaseStatus(res);

        // manage order notes
        try {
          if (creatingOrderNotes.value?.ids?.length) {
            await editOrderNotes(
              creatingOrderNotes.value.text,
              creatingOrderNotes.value.ids
            );
          } else {
            await createOrderNotes(creatingOrderNotes.value.text);
          }
        } catch (error) {}
      } catch (error) {}
    }
  }

  async function payCash(isPartial?: boolean, salesManagerId?: number) {
    // isPartial = NOT confirmed partial payment on the alert

    const paymentStore = usePaymentStore();
    const route = useRoute();

    // if customer pays exact value by cash
    if (!+paymentStore.customerPaying) {
      paymentStore.customerPaying = paymentStore.totalValue;
    }

    // pay for ACCOUNT and/or REGULAR ORDER -- Cash
    try {
      const res = route.query["account-orders"]
        ? ((await paymentStore.processPaymentMultiple({
            orders: [
              ...(notPaidSelectedAccountOrders.value?.length
                ? notPaidSelectedAccountOrders.value?.map((o) => o.id)
                : ((route.query["account-orders"] as string).split(
                    ","
                  ) as string[])),
              ...(isSameCustomerPayBalance.value && creatingOrder.value.id
                ? [creatingOrder.value.id]
                : []),
            ] as string[],
            paymentMethod: PaymentMethodGateway.CASH,
            customerId: route.query["customer-id"] as string,
            ...(salesManagerId && {
              managerId: salesManagerId,
            }),
            ...(paymentStore.paymentDate && {
              date: useDateFormatLib(
                paymentStore.paymentDate,
                "yyyy-mm-dd HH:MM:ss"
              ),
            }),
            ...(+paymentStore.customerPaying &&
              +paymentStore.customerPaying < +paymentStore.totalValue && {
                amount: paymentStore.customerPaying,
              }),
          })) as any)
        : // pay for REGULAR ORDER -- Cash
          ((await paymentStore.processPayment({
            orderId: creatingOrder.value.id,
            paymentMethod: PaymentMethodGateway.CASH,
            ...(+paymentStore.customerPaying &&
              +paymentStore.customerPaying < +paymentStore.totalValue && {
                amount: paymentStore.customerPaying,
              }),
          })) as any);

      if (!isPartial) {
        paymentStore.isPayed = true;
        paymentStore.isSucceeded = true;

        viewingInvoice.value = res?.invoice;
        // complete order
        await completeOrder({
          pmGateway: PaymentMethodGateway.CASH,
          isPrintInvoice: true,
          isPrintPicklist: true,
        });

        return;
      }

      // PARTIAL PAYMENT
      paymentStore.isPartialPayment = true;

      // update selected account orders
      if (
        route.query["account-orders"] &&
        selectedAccountOrders.value?.length
      ) {
        selectedAccountOrders.value?.forEach((o) => {
          const order = res.invoice?.items?.find((i) => i.item_id === o.id);

          if (order) {
            o.credit_amount -= order.amount;
          }
        });
      }

      paymentStore.activePaymentMethod = null;
      paymentStore.isOpenCheckoutMenu = false;
      paymentStore.customerPaying = "0.00";

      // change creating order payment method to IN PERSON
      // if (creatingOrder.value.id) {
      //   creatingOrder.value.payment_method_id =
      //     paymentStore.paymentMethodsPosList.find(
      //       (method) => method.value === PaymentMethodGateway.IN_PERSON
      //     )?.id;

      //   await useOrdersStore().editOrder(creatingOrder.value.id, {
      //     store_id: creatingOrder.value.store_id,
      //     payment_method_id: creatingOrder.value.payment_method_id,
      //   });
      // }
    } catch (error) {}
  }

  async function payInPerson() {
    const paymentStore = usePaymentStore();
    const route = useRoute();

    paymentStore.isProcessing = true;

    // pay for ACCOUNT and REGULAR/DRAFT ORDER -- In-Person
    try {
      route.query["account-orders"]
        ? await paymentStore.processPaymentMultiple({
            orders: [
              ...(notPaidSelectedAccountOrders.value?.length
                ? notPaidSelectedAccountOrders.value?.map((o) => o.id)
                : ((route.query["account-orders"] as string).split(
                    ","
                  ) as string[])),
              ...(isSameCustomerPayBalance.value && creatingOrder.value.id
                ? [creatingOrder.value.id]
                : []),
            ] as string[],
            paymentMethod: PaymentMethodGateway.IN_PERSON,
            customerId: route.query["customer-id"] as string,
            readerId:
              paymentStore.activeTerminal?.id ||
              localStorage.getItem("terminal"),
            ...(paymentStore.paymentDate && {
              date: useDateFormatLib(
                paymentStore.paymentDate,
                "yyyy-mm-dd HH:MM:ss"
              ),
            }),
          })
        : // pay for REGULAR/DRAFT ORDER -- In-Person
          await paymentStore.processPayment({
            orderId: creatingOrder.value.id,
            paymentMethod: PaymentMethodGateway.IN_PERSON,
            readerId:
              paymentStore.activeTerminal?.id ||
              localStorage.getItem("terminal"),
          });

      useToast("Please complete the transaction by card", {
        type: ToastType.INFO,
        duration: 3000,
      });
    } catch (error) {}
  }

  async function payAccount() {
    const paymentStore = usePaymentStore();

    paymentStore.isConfirmed = true;
    paymentStore.isSucceeded = true;
    paymentStore.isProcessing = false;

    // complete order
    await completeOrder({
      pmGateway: PaymentMethodGateway.ACCOUNT,
      isPrintInvoice: true,
      isPrintPicklist: true,
    });
  }

  async function payTransfer(isPartial?: boolean, salesManagerId?: number) {
    // isPartial = NOT confirmed partial payment on the alert

    const paymentStore = usePaymentStore();
    const route = useRoute();

    try {
      const res = route.query["account-orders"]
        ? await paymentStore.processPaymentMultiple({
            orders: [
              ...(notPaidSelectedAccountOrders.value?.length
                ? notPaidSelectedAccountOrders.value?.map((o) => o.id)
                : ((route.query["account-orders"] as string).split(
                    ","
                  ) as string[])),
              ...(isSameCustomerPayBalance.value && creatingOrder.value.id
                ? [creatingOrder.value.id]
                : []),
            ] as string[],
            paymentMethod: PaymentMethodGateway.TRANSFER,
            customerId: route.query["customer-id"] as string,
            ...(salesManagerId && {
              managerId: salesManagerId,
            }),
            ...(+paymentStore.customerPaying &&
              +paymentStore.customerPaying < +paymentStore.totalValue && {
                amount: paymentStore.customerPaying,
              }),
          })
        : // pay for REGULAR ORDER -- Transfer
          await paymentStore.processPayment({
            orderId: creatingOrder.value.id,
            paymentMethod: PaymentMethodGateway.TRANSFER,
            ...(+paymentStore.customerPaying &&
              +paymentStore.customerPaying < +paymentStore.totalValue && {
                amount: paymentStore.customerPaying,
              }),
          });

      if (!isPartial && (res as any).result.payment_completed) {
        paymentStore.isConfirmed = true;
        paymentStore.isSucceeded = true;
        paymentStore.isProcessing = false;

        viewingInvoice.value = res?.invoice;
        // complete order
        await completeOrder({
          pmGateway: PaymentMethodGateway.TRANSFER,
          isPrintInvoice: true,
          isPrintPicklist: true,
        });

        return;
      }

      // PARTIAL PAYMENT
      paymentStore.isPartialPayment = true;

      // update selected account orders
      if (
        route.query["account-orders"] &&
        selectedAccountOrders.value?.length
      ) {
        selectedAccountOrders.value?.forEach((o) => {
          const order = res.invoice?.items?.find((i) => i.item_id === o.id);

          if (order) {
            o.credit_amount -= order.amount;
          }
        });
      }

      paymentStore.activePaymentMethod = null;
      paymentStore.isOpenCheckoutMenu = false;
      paymentStore.customerPaying = "0.00";
    } catch (error) {}
  }

  async function payCheque(isPartial?: boolean, salesManagerId?: number) {
    const paymentStore = usePaymentStore();
    const route = useRoute();

    try {
      const res = route.query["account-orders"]
        ? await paymentStore.processPaymentMultiple({
            orders: [
              ...(notPaidSelectedAccountOrders.value?.length
                ? notPaidSelectedAccountOrders.value?.map((o) => o.id)
                : ((route.query["account-orders"] as string).split(
                    ","
                  ) as string[])),
              ...(isSameCustomerPayBalance.value && creatingOrder.value.id
                ? [creatingOrder.value.id]
                : []),
            ] as string[],
            paymentMethod: PaymentMethodGateway.CHEQUE,
            customerId: route.query["customer-id"] as string,
            ...(salesManagerId && {
              managerId: salesManagerId,
            }),
            ...(paymentStore.paymentDate && {
              date: useDateFormatLib(
                paymentStore.paymentDate,
                "yyyy-mm-dd HH:MM:ss"
              ),
            }),
            ...(+paymentStore.customerPaying &&
              +paymentStore.customerPaying < +paymentStore.totalValue && {
                amount: paymentStore.customerPaying,
              }),
          })
        : await paymentStore.processPayment({
            orderId: creatingOrder.value.id,
            paymentMethod: PaymentMethodGateway.CHEQUE,
            ...(+paymentStore.customerPaying &&
              +paymentStore.customerPaying < +paymentStore.totalValue && {
                amount: paymentStore.customerPaying,
              }),
          });

      if (!isPartial && (res as any).result.payment_completed) {
        paymentStore.isConfirmed = true;
        paymentStore.isSucceeded = true;
        paymentStore.isProcessing = false;

        viewingInvoice.value = res?.invoice;
        // complete order
        await completeOrder({
          pmGateway: PaymentMethodGateway.CHEQUE,
          isPrintInvoice: true,
          isPrintPicklist: true,
        });

        return;
      }

      // PARTIAL PAYMENT
      paymentStore.isPartialPayment = true;

      // update selected account orders
      if (
        route.query["account-orders"] &&
        selectedAccountOrders.value?.length
      ) {
        selectedAccountOrders.value?.forEach((o) => {
          const order = res.invoice?.items?.find((i) => i.item_id === o.id);

          if (order) {
            o.credit_amount -= order.amount;
          }
        });
      }

      paymentStore.activePaymentMethod = null;
      paymentStore.isOpenCheckoutMenu = false;
      paymentStore.customerPaying = "0.00";
    } catch (error) {}
  }

  async function payCreditNote(isPartial?: boolean, salesManagerId?: number) {
    // isPartial = NOT confirmed partial payment on the alert

    const paymentStore = usePaymentStore();
    const route = useRoute();

    try {
      const res = route.query["account-orders"]
        ? await paymentStore.processPaymentMultiple({
            orders: [
              ...(notPaidSelectedAccountOrders.value?.length
                ? notPaidSelectedAccountOrders.value?.map((o) => o.id)
                : ((route.query["account-orders"] as string).split(
                    ","
                  ) as string[])),
              ...(isSameCustomerPayBalance.value && creatingOrder.value.id
                ? [creatingOrder.value.id]
                : []),
            ] as string[],
            paymentMethod: PaymentMethodGateway.CREDIT_NOTE,
            customerId: route.query["customer-id"] as string,
            ...(salesManagerId && {
              managerId: salesManagerId,
            }),
            ...(+paymentStore.customerPaying &&
              +paymentStore.customerPaying < +paymentStore.totalValue && {
                amount: paymentStore.customerPaying,
              }),
          })
        : // pay for REGULAR ORDER -- Credit Note
          await paymentStore.processPayment({
            orderId: creatingOrder.value.id,
            paymentMethod: PaymentMethodGateway.CREDIT_NOTE,
            ...(+paymentStore.customerPaying &&
              +paymentStore.customerPaying < +paymentStore.totalValue && {
                amount: paymentStore.customerPaying,
              }),
          });

      if (!isPartial && (res as any).result.payment_completed) {
        paymentStore.isConfirmed = true;
        paymentStore.isSucceeded = true;
        paymentStore.isProcessing = false;

        viewingInvoice.value = res?.invoice;
        // complete order
        await completeOrder({
          pmGateway: PaymentMethodGateway.CREDIT_NOTE,
          isPrintInvoice: true,
          isPrintPicklist: true,
        });

        return;
      }

      // PARTIAL PAYMENT
      paymentStore.isPartialPayment = true;

      // update selected account orders
      if (
        route.query["account-orders"] &&
        selectedAccountOrders.value?.length
      ) {
        selectedAccountOrders.value?.forEach((o) => {
          const order = res.invoice?.items?.find((i) => i.item_id === o.id);

          if (order) {
            o.credit_amount -= order.amount;
          }
        });
      }

      paymentStore.activePaymentMethod = null;
      paymentStore.isOpenCheckoutMenu = false;
      paymentStore.customerPaying = "0.00";
    } catch (error) {}
  }
  ////////////////////

  async function modifyQuickOrder(
    orderId: number | string,
    reportType: ReportType
  ) {
    try {
      if (
        reportType === ReportType.PREPARATION &&
        creatingOrder.value.status !== OrderStatus.PREPARING
      ) {
        // change status to PREPARING
        creatingOrder.value.status = OrderStatus.PREPARING;
      }

      // set payment method to ACCOUNT
      const accountPaymentMethod = await useGetPaymentMethod(
        PaymentMethodGateway.ACCOUNT
      );

      if (
        creatingOrder.value.payment_method_id !== accountPaymentMethod?.id &&
        (useCustomersStore().activeCustomer as CustomerSingle)?.credit
      ) {
        creatingOrder.value.payment_method_id = accountPaymentMethod?.id;
      }

      const { id, type, store_id, status, payment_method_id } =
        creatingOrder.value || {};

      await useOrdersStore().editOrder(orderId ?? id, {
        type,
        store_id,
        status,
        payment_method_id,
      });
    } catch (error) {
      useCatchError(error);
    }
  }

  // ORDER NOTES
  async function getOrderNotes() {
    try {
      const res = await useNotesStore().getNotes(
        NoteLink.ORDERS,
        (useRoute() as any).params.id || creatingOrder.value.id
      );

      const notes = res?.data;

      const orderNote = notes?.filter((i) => !i.category)?.[0];
      const pickingNote = notes?.filter((i) => i.category === "picking")?.[0];

      if (orderNote) {
        creatingOrderNotes.value = {
          text: orderNote?.text,
          ids: [orderNote?.id, pickingNote?.id],
        };
      }
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createOrderNotes(text: string) {
    if (!text) return;

    const note = {
      text,
      is_pinned: false,
      is_private: false,
      created_at: new Date().toString(),
      user: {
        id: useUsersStore().currentUser.id,
        name: useUsersStore().currentUser.name,
      },
    };

    const pickerNote = {
      ...note,
      category: "picking",
    };

    try {
      await useNotesStore().createNote(
        NoteLink.ORDERS,
        creatingOrder.value.id,
        note
      );

      await useNotesStore().createNote(
        NoteLink.ORDERS,
        creatingOrder.value.id,
        pickerNote
      );
    } catch (error) {}
  }

  async function editOrderNotes(text: string, ids: number[]) {
    if (!text) return;

    try {
      const promises = ids.map((id) =>
        useNotesStore().updateNote(NoteLink.ORDERS, id, {
          text,
        })
      );

      await Promise.all(promises);
    } catch (error) {}
  }

  async function completeOrder(payload: {
    isPrintInvoice: boolean;
    isPrintPicklist: boolean;
    pmGateway?: PaymentMethodGateway;
    status?: OrderStatus;
  }) {
    const paymentStore = usePaymentStore();
    const ordersStore = useOrdersStore();

    // assigning summary values
    currentOrderId.value = creatingOrder.value?.id;
    customerPaid.value = paymentStore.customerPaying;
    change.value = paymentStore.change;
    remaining.value = paymentStore.remaining;
    finalCardAmount.value = paymentStore.finalCardAmount;

    // UPDATE EXISTING ORDER by preselected payment method status
    if (creatingOrder.value.id) {
      const paymentMethod = payload?.pmGateway
        ? await useGetPaymentMethod(payload?.pmGateway)
        : ({} as PaymentMethodOption);

      useIsLoading(true);
      try {
        const res = await ordersStore.editOrder(creatingOrder.value.id, {
          ...creatingOrder.value,
          status:
            payload?.status ||
            paymentMethod?.order_status ||
            OrderStatus.COMPLETED,
          table_id: creatingOrder.value.table_id && undefined,
        });

        // save last pos order id in localstorage
        localStorage.setItem("last-pos-order-id", res?.data?.id?.toString());

        // update "recent_purchase_status" for the customer
        updateCustomerRecentPurchaseStatus(res);
      } catch (error) {}
      useIsLoading(false);
    }

    // erasing values
    creatingOrder.value = {} as OrderManage;
    cart.value = [] as OrderItem[];
    viewingOrder.value = {} as Order;
    totals.value = {} as Order;
    selectedTable.value = null as Table;
    creatingOrderNotes.value = {};
    localStorage.removeItem("cart");
    localStorage.removeItem("pos-table");

    // reset discount order factor
    discountOrderFactorPercent.value = 1;
    discountOrderFactorFixed.value = 0;

    selectedCategory.value = null;
    selectedChannel.value = {} as ChannelOption;
    isThirdPartyChannel.value = false;

    paymentStore.paymentIntent = null;
    paymentStore.paymentId = null;
    paymentStore.paymentDate = null;

    paymentStore.resetSplitPaymentValues();

    useRouter().push({
      name: useRoutesNames().posCheckoutThankyouId,
      params: {
        ...(!paymentStore.paidSplitOrders?.length &&
          currentOrderId.value && {
            id: currentOrderId.value?.toString(),
          }),
      },
      query: {
        "account-orders": useRoute().query["account-orders"],
        ...(paymentStore.paidSplitOrders?.length && {
          "split-orders": paymentStore.paidSplitOrders
            .map((o) => o.id)
            .join(","),
        }),
        ...(payload?.isPrintInvoice && { "print-invoice": "true" }),
        ...(payload?.isPrintPicklist && { "print-picklist": "true" }),
      },
    });
  }

  async function syncDataBff() {
    try {
      getTilesList({ force: true });
      useInventoryStore().getInventoryListBff({
        limit: 50,
        params: {
          "options[expand]": true,
        },
        force: true,
      });

      useProductsStore().getProductsBff({
        limit: 50,
        params: {
          "options[expand]": true,
        },
        force: true,
      });
      useStoresStore().getStoresBff({ force: true });
      // await useProductsStore().getProductsPosBff({
      //   params: {
      //     limit: 100,
      //     store_id: 1,
      //     "filter[type][]": [ProductType.SIMPLE, ProductType.MASTER],
      //   },
      //   force: true,
      // });
    } catch (error) {
      useCatchError(error);
    }
  }

  // SESSIONS LOGIC
  const sessions = ref<Session[]>([] as Session[]);
  const viewingSession = ref<SessionSingle>({} as SessionSingle);
  const endDayReport = ref<any>({});
  const cardPaymentsReport = ref<any>({});
  const isAlreadyOpened = ref(false);
  const isMemberAndRegisterSelected = ref(false);
  const selectedLocation = ref<number | null>(null);
  const selectedRegister = ref<number | null>(null);
  const openRegisters = ref<Option[]>([] as Option[]);
  const selectedRegisterTeamMember = ref<Option | null>(null);
  const openRegisterTeamMembers = ref<Option[]>([] as Option[]);
  const isCloseProcess = ref(false);
  const closeStep = ref(PosCloseStep.START);
  const closingTeamMember = ref<Customer | null>(null);
  const closingRegister = ref<Option | null>(null);
  const closingLocation = ref<Option | null>(null);
  const coinsCount = ref<CashCount[]>([
    {
      denomination: 0.05,
      quantity: 0,
    },
    {
      denomination: 0.1,
      quantity: 0,
    },
    {
      denomination: 0.2,
      quantity: 0,
    },
    {
      denomination: 0.5,
      quantity: 0,
    },
    {
      denomination: 1,
      quantity: 0,
    },
    {
      denomination: 2,
      quantity: 0,
    },
  ]);
  const notesCount = ref<CashCount[]>([
    {
      denomination: 5,
      quantity: 0,
    },
    {
      denomination: 10,
      quantity: 0,
    },
    {
      denomination: 20,
      quantity: 0,
    },
    {
      denomination: 50,
      quantity: 0,
    },
    {
      denomination: 100,
      quantity: 0,
    },
  ]);
  const isAutomated = ref(false);
  const automatedCount = ref(0);

  const hasOpenedSessions = computed(() =>
    sessions.value.some(
      (s) => !s.closed_at && s.session_type === SessionType.CUSTOMER
    )
  );

  const hasOpenedRegisters = computed(() =>
    sessions.value.some(
      (s) => !s.closed_at && s.session_type === SessionType.REGISTER
    )
  );

  const openedSessions = computed(() =>
    sessions.value.filter(
      (s) => !s.closed_at && s.session_type === SessionType.CUSTOMER
    )
  );

  const openedRegisters = computed(() =>
    sessions.value.filter(
      (s) => !s.closed_at && s.session_type === SessionType.REGISTER
    )
  );

  const isClosedViewingSession = computed(
    () => !!viewingSession.value?.closed_at
  );

  const coinsTotal = computed(() =>
    coinsCount.value.reduce(
      (prev, curr) => prev + +curr.quantity * +curr.denomination,
      0
    )
  );

  const notesTotal = computed(() =>
    notesCount.value.reduce(
      (prev, curr) => prev + +curr.quantity * +curr.denomination,
      0
    )
  );

  const cashCounted = computed(() =>
    isAutomated.value
      ? automatedCount.value
      : +coinsTotal.value + +notesTotal.value
  );

  const cashRecords = computed(
    () =>
      viewingSession.value?.summary?.register_cash?.cash_payments?.in +
      viewingSession.value?.summary?.register_cash?.cash_payments?.petty_in -
      (viewingSession.value?.summary?.register_cash?.cash_payments?.out +
        viewingSession.value?.summary?.register_cash?.cash_payments?.petty_out)
  );

  const cashExpected = computed(
    () =>
      +viewingSession.value?.summary?.register_cash?.initial_cash +
      +(viewingSession.value?.summary?.orders_paid_amount?.Cash ?? 0) +
      +cashRecords.value
  );

  const cashVariance = computed(() =>
    (+cashCounted.value - +cashExpected.value).toFixed(2)
  );

  const sessionTotal = computed(() => {
    const accountTotal =
      viewingSession.value?.summary?.placed_orders?.Account ?? 0;
    const inPersonTotal =
      viewingSession.value?.summary?.placed_orders?.Card ?? 0;
    const cashTotal =
      viewingSession.value?.summary?.orders_paid_amount?.Cash ?? 0;
    return accountTotal + inPersonTotal + cashTotal;
  });

  async function getSessions(params?: {
    limit?: number;
    "filter[latest]"?: boolean;
    "sort[]"?: string;
  }): Promise<ResponseType<Session[]>> {
    try {
      const response: any = await useVaniloApi("/pos-sessions", {
        params,
      });

      sessions.value = response?.data as Session[];

      return response as ResponseType<Session[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleSession(
    id: number
  ): Promise<ResponseType<SessionSingle>> {
    try {
      useIsLoading(true);
      const response = (await useVaniloApi(
        `/pos-sessions/${id}`
      )) as ResponseType<SessionSingle>;

      viewingSession.value = response?.data;

      return response as ResponseType<SessionSingle>;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function openSession(body: OpenSessionPayload) {
    try {
      const response = (await useVaniloApi("/pos-sessions/open", {
        method: "POST",
        body,
      })) as any;

      sessions.value.push(response?.data);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function closeSession(body: CloseSessionPayload) {
    try {
      const response = (await useVaniloApi("/pos-sessions/close", {
        method: "POST",
        body,
      })) as any;

      viewingSession.value = response?.data;
      sessions.value = sessions.value.map((session) =>
        session.id === response?.data.id ? response?.data : session
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function printEndDayReport(id): Promise<ResponseType<any>> {
    try {
      const response = await useVaniloApi(
        `/pos-sessions/${id}/end-of-day-report`,
        {
          method: "POST",
        }
      );
      return response as ResponseType<any>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function printCardPaymentsReport(id): Promise<ResponseType<any>> {
    try {
      const response = await useVaniloApi(
        `/pos-sessions/${id}/card-payment-report`,
        {
          method: "POST",
        }
      );
      return response as ResponseType<any>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSessionOrders(
    sessionId: number,
    params?: {
      page?: number;
      "filter[search]"?: string;
      "filter[payment_method][]"?: PaymentMethodGateway[];
    }
  ): Promise<ResponseType<Order[]>> {
    try {
      const response = await useVaniloApi(`/orders/pos-sessions/${sessionId}`, {
        params,
      });

      return response as ResponseType<Order[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  function clearPosClose() {
    closeStep.value = PosCloseStep.START;
    viewingSession.value = {} as SessionSingle;
    closingTeamMember.value = null;
    closingRegister.value = null;
    closingLocation.value = null;
    isAutomated.value = false;
    automatedCount.value = 0;
    coinsCount.value.map((coin) => (coin.quantity = 0));
    notesCount.value.map((note) => (note.quantity = 0));
  }

  // TILES LOGIC
  const tiles = ref<PosTile[]>([] as PosTile[]);
  const editModeTile = ref(false);
  const selectedTile = ref<PosTileManage | PosTile | null>(null);
  const tilePositions = ref<any[]>([]);
  const currentLayoutPage = ref(1);
  const layoutPagesTotals = ref(2);
  const tilesForDelete = ref<any[]>([]);
  const inProcessUpdatingGrid = ref(false);
  const viewingTile = ref<PosTile>({} as PosTile);
  const editingTile = ref<PosTileManage>({} as PosTileManage);
  const tileTypes = ref<Option[]>([
    { name: "Product Category", value: PosTileType.CATEGORY },
    { name: "Product Tag", value: PosTileType.TAG },
    { name: "Master Product", value: PosTileType.MASTER },
    { name: "Variant Product", value: PosTileType.VARIANT },
    { name: "Simple Product", value: PosTileType.SIMPLE },
    { name: "Composite Product", value: PosTileType.SET },
    { name: "Hold Note", value: PosTileType.HOLD_NOTE },
  ]);
  const gsKey = ref(0);

  async function getTilesList(payload?: {
    params?: any;
    noFetchMain?: boolean;
    force?: boolean;
  }) {
    useIsLoading(true);
    try {
      const endpoint = {
        [PosTilesLevel.USER]: `customers/${
          useCustomersStore().currentUserCustomer.id
        }/pos-tiles`,
        [PosTilesLevel.LOCATION]: `stores/${
          posLocation.value ?? useStoresStore().stores?.[0]?.id
        }/pos-tiles`,
        [PosTilesLevel.PLATFORM]: `pos-tiles`,
      }[useSettingsStore().settings?.pos.tiles_layout_level];

      const store = {
        [PosTilesLevel.USER]: IndexedDBStore.POS_TILES_CUSTOMER,
        [PosTilesLevel.LOCATION]: IndexedDBStore.POS_TILES_LOCATION,
        [PosTilesLevel.PLATFORM]: IndexedDBStore.POS_TILES,
      }[useSettingsStore().settings?.pos.tiles_layout_level];

      const { params, noFetchMain, force } = payload || {};

      const response = await new StorageService(
        useRuntimeConfig()
      ).fetchList<IndexedDBStore.POS_TILES>({
        endpoint,
        store,
        params,
        noFetchMain,
        force,
      });

      tiles.value = response.data;

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function getSingleTile(id) {
    useIsLoading(true);
    try {
      const endpoint = {
        [PosTilesLevel.USER]: `/customers/pos-tiles/${id}`,
        [PosTilesLevel.LOCATION]: `/stores/pos-tiles/${id}`,
        [PosTilesLevel.PLATFORM]: ` /pos-tiles/${id}`,
      }[useSettingsStore().settings.pos.tiles_layout_level];

      const response = await useVaniloApi(endpoint);

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function createTile(body) {
    useIsLoading(true);
    try {
      const endpoint = {
        [PosTilesLevel.USER]: `/customers/${
          useCustomersStore().currentUserCustomer.id
        }/pos-tiles`,
        [PosTilesLevel.LOCATION]: `/stores/${
          posLocation.value ?? useStoresStore().stores?.[0]?.id
        }/pos-tiles`,
        [PosTilesLevel.PLATFORM]: `/pos-tiles`,
      }[useSettingsStore().settings.pos.tiles_layout_level];

      const response = await useVaniloApi(endpoint, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function updateTile(body) {
    useIsLoading(true);
    try {
      const endpoint = {
        [PosTilesLevel.USER]: `/customers/pos-tiles/${body.id}`,
        [PosTilesLevel.LOCATION]: `/stores/pos-tiles/${body.id}`,
        [PosTilesLevel.PLATFORM]: `/pos-tiles/${body.id}`,
      }[useSettingsStore().settings.pos.tiles_layout_level];

      const response = await useVaniloApi(endpoint, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function deleteTile(id) {
    useIsLoading(true);
    try {
      const endpoint = {
        [PosTilesLevel.USER]: `/customers/pos-tiles/${id}`,
        [PosTilesLevel.LOCATION]: `/stores/pos-tiles/${id}`,
        [PosTilesLevel.PLATFORM]: `/pos-tiles/${id}`,
      }[useSettingsStore().settings.pos.tiles_layout_level];

      const response = await useVaniloApi(endpoint, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function updateTilesGrid(
    pos_tiles: {
      id: number;
      x: number;
      y: number;
      w: number;
      h: number;
      position: number;
    }[]
  ) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi(`/pos-tiles/update-grid`, {
        method: "POST",
        body: {
          pos_tiles,
        },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  // POS ENVIRONMENT LOGIC
  const posLocation = ref<number | string | null>(null);
  const posRegister = ref<number | string | null>(null);
  const posTeamMember = ref<Customer | null>(null);
  const isSetEnvProcess = ref(false);
  const isLocationSelected = ref(false);

  function clearPosEnv() {
    posLocation.value = null;
    posRegister.value = null;
    activeTeamMember.value = null;
    usePaymentStore().activeTerminal = null;
    creatingOrder.value.sales_manager_id = null;
    creatingOrder.value.store_id = null;
    isAlreadyOpened.value = false;
    isMemberAndRegisterSelected.value = false;
    isLocationSelected.value = false;
    selectedLocation.value = null;
    selectedRegister.value = null;
    openRegisters.value = [] as Option[];
    selectedRegisterTeamMember.value = null;
    openRegisterTeamMembers.value = [] as Option[];
    localStorage.removeItem("pos-location");
    localStorage.removeItem("pos-location-name");
    localStorage.removeItem("pos-register");
    localStorage.removeItem("team-member");
    localStorage.removeItem("terminal");
  }

  // TEAM MEMBERS LOGIC
  const teamMembers = ref<Customer[]>([] as Customer[]);
  const selectedTeamMember = ref<Customer | null>(null);
  const activeTeamMember = ref<Customer | null>(null);
  const isShowModalStaffSelect = ref(false);
  const isShowModalStaffShift = ref(false);
  const isShowModalStaffTimesheet = ref(false);
  const isShowModalStaffLockscreen = ref(false);

  async function getTeamMembers(
    storeId?: number | string,
    payload?
  ): Promise<ResponseType<Customer[]>> {
    try {
      useIsLoading(true);
      const response = (await useVaniloApi("/customers", {
        params: {
          "filter[type][]": CustomerType.STAFF,
          ...(storeId && { "filter[staff_store][]": storeId }),
          ...(payload && { ...payload }),
        },
      })) as ResponseType<Customer[]>;

      teamMembers.value = response?.data;

      return response as ResponseType<Customer[]>;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function checkStaffPin(id, pin) {
    try {
      const response = await useVaniloApi(`/customers/${id}/pin-check`, {
        method: "POST",
        body: { pin },
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  function resetTeamMember() {
    creatingOrder.value.sales_manager_id = null;
    activeTeamMember.value = null as Customer;
    localStorage.removeItem("team-member");
    isShowModalStaffSelect.value = true;
  }

  // OTHER LOGIC
  const customerOptionsShop = ref<Option[]>([
    {
      name: "Switch Customer",
      value: CustomerOptionShop.SWITCH_CUSTOMER,
    },
    { name: "View Details", value: CustomerOptionShop.VIEW_DETAILS },
  ]);
  const isOpenSidebar = ref(false);
  const posPages = ref<Option[]>([
    {
      name: "Quick Add",
      value: PosPage.QUICK_ADD,
    },
    {
      name: "Search",
      value: PosPage.SEARCH,
    },
    {
      name: "Keypad",
      value: PosPage.KEYPAD,
    },
  ]);
  const selectedTable = ref<Table | null>(null);
  const transitionName = ref(PosTransitionName.SLIDE);
  const datePickerKey = ref(0);
  const activePosPage = ref<PosPage | null>(null);
  const activePosProduct = ref<Product>({} as Product);
  const isShowModalProduct = ref(false);
  const isResetTeamMember = ref(false);
  const isLockscreen = ref(false);
  const customersList = ref<Customer[]>([] as Customer[]);
  const posProductNote = ref("");
  const editingProduct = ref<OrderItem>({} as OrderItem);
  const editingProductIndex = ref<number | null>(null);
  const isEditingProduct = ref(false);
  const posSearch = ref("");
  const posSearchProducts = ref<InventorySingle[]>([] as InventorySingle[]);
  const summaryNotes = ref("");
  const viewingBalancesMember = ref<Customer>({} as Customer);
  const viewingBalances = ref<MemberBalances>({} as MemberBalances);
  const selectedCategory = ref<number | null>(null);
  const filtersInventory = ref<Filters>({
    type: {
      type: "type",
      title: "Status",
      options: useGeneralStore().orderTypeList,
      chosenOptions: [],
    },
    store: {
      type: "store",
      title: "Locations",
      options: [],
      chosenOptions: [],
    },
  });
  const filtersAddCustomer = ref<Filters>({
    type: {
      type: "type",
      title: "Types",
      options: [],
      chosenOptions: [],
    },
    group: {
      type: "group",
      title: "Groups",
      options: [],
      chosenOptions: [],
    },
    store: {
      type: "store",
      title: "Locations",
      options: [],
      chosenOptions: [],
    },
    taxonomies: {
      type: "taxonomies",
      title: "Tags",
      options: [],
      chosenOptions: [],
    },
  });
  // Filters Logic
  const filtersOrders = ref<Filters>({
    tag: {
      type: FilterType.DEFAULT,
      title: "Custom Tag",
      options: [],
      chosenOptions: [],
    },
    type: {
      type: FilterType.DEFAULT,
      title: "Order Type",
      options: [],
      chosenOptions: [],
    },
    fulfilmentStatus: {
      type: FilterType.DEFAULT,
      title: "Fulfilment Status",
      options: ordersStore.fulfilmentStatuses,
      chosenOptions: [],
    },
    ...(!useConfig().isParticipant && {
      customer: {
        type: FilterType.DEFAULT,
        title: "Customers",
        options: [],
        chosenOptions: [],
      },
    }),
    payment_method: {
      type: FilterType.DEFAULT,
      title: "Payment Type",
      options: [],
      chosenOptions: [],
    },
    status: {
      type: FilterType.TAG_LIST,
      title: "Status",
      options: ordersStore.statusTags,
      chosenOptions: [],
    },
    period: {
      type: FilterType.PERIOD,
      title: "Period",
      options: useGeneralStore().periods,
      chosenOptions: [],
      isSingle: true,
    },
    sync_statuses: {
      type: FilterType.TAG_LIST,
      title: "Sync Status",
      options: [
        { name: "Pending", value: SyncStatus.PENDING },
        { name: "Synced", value: SyncStatus.SYNCED },
        { name: "Error", value: SyncStatus.ERROR },
      ],
      chosenOptions: [],
    },
    creditService: {
      type: FilterType.SWITCHER,
      title: "Credit Service",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    date: {
      type: FilterType.MAIN_DATE,
      title: "Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    paymentCondition: {
      type: FilterType.DEFAULT,
      title: "Payment Status",
      subtitle: "Select the payment status you would like included",
      options: [
        { name: "Paid", value: "paid" },
        { name: "Refunded", value: "refunded" },
        { name: "Partial Payment", value: "partially_paid" },
        { name: "Overdue", value: "overdue" },
      ],
      chosenOptions: [],
    },
    target_date: {
      type: FilterType.DATE_RANGE,
      title: "Target Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    payment_date_range: {
      type: FilterType.NEW_DATE_RANGE,
      title: "Payment Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    letter: {
      type: FilterType.LETTER,
      title: "Alphabet letter",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    search: {
      type: FilterType.SEARCH,
      title: "Search",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
  });
  const filtersOrdersCustomer = ref<Filters>({
    tag: {
      type: FilterType.DEFAULT,
      title: "Custom Tag",
      options: [],
      chosenOptions: [],
    },
    type: {
      type: FilterType.DEFAULT,
      title: "Order Type",
      options: [],
      chosenOptions: [],
    },
    fulfilmentStatus: {
      type: FilterType.DEFAULT,
      title: "Fulfilment Status",
      options: ordersStore.fulfilmentStatuses,
      chosenOptions: [],
    },
    payment_method: {
      type: FilterType.DEFAULT,
      title: "Payment Type",
      options: [],
      chosenOptions: [],
    },
    status: {
      type: FilterType.TAG_LIST,
      title: "Status",
      options: ordersStore.statusTags,
      chosenOptions: [],
    },
    period: {
      type: FilterType.PERIOD,
      title: "Period",
      options: useGeneralStore().periods,
      chosenOptions: [],
      isSingle: true,
    },
    sync_statuses: {
      type: FilterType.TAG_LIST,
      title: "Sync Status",
      options: [
        { name: "Pending", value: SyncStatus.PENDING },
        { name: "Synced", value: SyncStatus.SYNCED },
        { name: "Error", value: SyncStatus.ERROR },
      ],
      chosenOptions: [],
    },
    creditService: {
      type: FilterType.SWITCHER,
      title: "Credit Service",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    date: {
      type: FilterType.MAIN_DATE,
      title: "Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    paymentCondition: {
      type: FilterType.DEFAULT,
      title: "Payment Status",
      subtitle: "Select the payment status you would like included",
      options: [
        { name: "Paid", value: "paid" },
        { name: "Refunded", value: "refunded" },
        { name: "Partial Payment", value: "partially_paid" },
        { name: "Overdue", value: "overdue" },
      ],
      chosenOptions: [],
    },
    target_date: {
      type: FilterType.DATE_RANGE,
      title: "Target Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    payment_date_range: {
      type: FilterType.NEW_DATE_RANGE,
      title: "Payment Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    letter: {
      type: FilterType.LETTER,
      title: "Alphabet letter",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    search: {
      type: FilterType.SEARCH,
      title: "Search",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
  });

  const isGuestFilter = ref(false);

  const selectedDayOrders = ref<string | null>(null);
  const selectedDayCustomsers = ref<string | null>(null);
  const letterOrders = ref<string | null>(null);
  const letterCustomers = ref<string | null>(null);
  const redeemedPoints = ref(0);
  const isApplyBirthdayBonus = ref(false);

  const posPagesFiltered = computed(() =>
    posPages.value.filter(
      (item) =>
        !(item.value === PosPage.QUICK_ADD && !useConfig().isPosQuickAdd) &&
        !(item.value === PosPage.SEARCH && !useConfig().isPosSearch) &&
        !(item.value === PosPage.KEYPAD && !useConfig().isPosKeypad)
    )
  );

  const productsQuantity = computed(
    () =>
      // cart.value.reduce((prev, curr) => prev + curr.quantity, 0)
      viewingOrder.value?.unit_checks?.lines
  );

  const isTableOrder = computed(
    () =>
      useConfig().isPosHospitality &&
      useConfig().isPosTableOrdering &&
      creatingOrder.value.table_id &&
      selectedTable.value
  );

  const savedMoneyByPointsPaying = computed(() => {
    let res = 0;
    if (
      redeemedPoints.value &&
      useSettingsStore().settings?.loyalty_reward.points_rate
    ) {
      res =
        redeemedPoints.value *
        useSettingsStore().settings?.loyalty_reward.points_rate;
    }
    return useFormatPrice(res ? res : 0);
  });

  async function getMemberBalances(body: {
    session_owner: number;
    sales_person_id?: number;
    created_from: string;
    created_to: string;
  }): Promise<ResponseType<MemberBalances>> {
    try {
      const response = (await useVaniloApi("/pos-sessions/balance", {
        method: "POST",
        body,
      })) as ResponseType<MemberBalances>;

      viewingBalances.value = response?.data;

      return response as ResponseType<MemberBalances>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // OPTIONS LOGIC
  const options = computed(() => [
    // always included option
    { name: "Cash Management", value: PosOptionAction.CASH_MANAGEMENT },
    // sync BFF data
    { name: "Sync Data", value: PosOptionAction.SYNC_DATA },
    { name: "Sync Inventory", value: PosOptionAction.SYNC_INVENTORY },
    // include Clock options if isPosClocks is true
    ...(useConfig().isPosClocks
      ? [
          { name: "Clock On/Off", value: PosOptionAction.CLOCK_ON_OFF },
          {
            name: "Export Timesheet",
            value: PosOptionAction.EXPORT_TIMESHEET,
          },
        ]
      : []),
    // include Select Terminal option if not in multi-location mode or not admin/superadmin
    ...(!useConfig().isMultilocation &&
    !(useConfig().isSuperadmin || useConfig().isAdmin)
      ? [{ name: "Select Terminal", value: PosOptionAction.SELECT_TERMINAL }]
      : []),
    // include Close Register or Close Session based on sales method and opened registers/sessions
    ...(useSettingsStore().salesMethod === SalesMethod.REGISTER &&
    hasOpenedRegisters.value
      ? [{ name: "Close Register", value: PosOptionAction.CLOSE_SESSION }]
      : hasOpenedSessions.value
        ? [{ name: "Close Session", value: PosOptionAction.CLOSE_SESSION }]
        : []),
    // include Edit Tiles option if no Category is selected, the route is /pos/shop, the mode is not active already and not it POS Env Set process
    ...(!useRoute().query["filter[taxonomies][]"] &&
    useRoute().name === useRoutesNames().posShop &&
    !editModeTile.value &&
    !isSetEnvProcess.value
      ? [{ name: "Edit Tiles", value: PosOptionAction.EDIT_TILE }]
      : []),
    // always included option
    {
      name: "Team Member Balances",
      value: PosOptionAction.TEAM_MEMBER_BALANCES,
    },
    {
      name: "Report a Bug",
      value: PosOptionAction.REPORT_BUG,
    },
    // include Reset POS or Switch Location based on multi-location mode and user role
    ...(!useConfig().isMultilocation
      ? [{ name: "Reset POS", value: PosOptionAction.RESET_ENV }]
      : useConfig().isMultilocation &&
          (useConfig().isSuperadmin || useConfig().isAdmin) &&
          !isSetEnvProcess.value
        ? [{ name: "Switch Location", value: PosOptionAction.RESET_ENV }]
        : []),
    // check for Electron updates
    ...(useConfig().isElectron
      ? [
          {
            name: "Check for Updates",
            value: PosOptionAction.CHECK_FOR_UPDATES,
          },
        ]
      : []),
  ]);

  async function selectOption(option: Option) {
    switch (option.value) {
      case PosOptionAction.CASH_MANAGEMENT:
        useRouter().push({ name: useRoutesNames().transfers });
        break;
      case PosOptionAction.SYNC_DATA:
        syncDataBff();
        break;
      case PosOptionAction.SYNC_INVENTORY:
        useInventoryStore().getInventoryListBff({
          limit: 50,
          params: {
            "options[expand]": true,
          },
          force: true,
        });

        break;
      case PosOptionAction.CLOCK_ON_OFF:
        isShowModalStaffShift.value = true;
        break;
      case PosOptionAction.EXPORT_TIMESHEET:
        isShowModalStaffTimesheet.value = true;
        break;
      case PosOptionAction.SELECT_TERMINAL:
        isOpenSidebar.value = true;
        useSetSidebar(SidebarPage.POS_TERMINALS);
        break;
      case PosOptionAction.TEAM_MEMBER_BALANCES:
        isOpenSidebar.value = true;
        useSetSidebar(SidebarPage.POS_MEMBERS_BALANCES);
        break;
      case PosOptionAction.REPORT_BUG:
        useReportBug();
        break;
      case PosOptionAction.OPEN_SESSION:
        isOpenSidebar.value = true;
        useSetSidebar(SidebarPage.POS_SESSION_OPEN);
        break;
      case PosOptionAction.CLOSE_SESSION:
        isCloseProcess.value = true;
        break;
      case PosOptionAction.EDIT_TILE: {
        editModeTile.value = true;
        break;
      }
      case PosOptionAction.RESET_ENV:
        isSetEnvProcess.value = true;
      case PosOptionAction.CHECK_FOR_UPDATES:
        useElectron().checkForUpdates();
    }
  }

  return {
    // ORDERS LOGIC
    creatingOrder,
    viewingOrder,
    viewingInvoice,
    cart,
    isThirdPartyChannel,
    selectedChannel,
    totals,
    selectedAccountOrders,
    selectedSalesManagerId,
    isPayBalanceClicked,
    selectedOrders,
    isShowModalOrder,
    isShowModalOrderUi,
    isShowModalMultipay,
    isShowModalDiscountOrder,
    isShowModalDiscountOrderItem,
    isShowModalPartialPaymentConfirm,
    discountOrderItemId,
    editingHold,
    isShowModalHold,
    currentOrderId,
    creatingOrderNotes,
    customerPaid,
    change,
    remaining,
    finalCardAmount,
    discountOrderFactorPercent,
    discountOrderFactorFixed,
    isSameCustomerPayBalance,
    subtotalSelectedAccountOrders,
    totalSelectedAccountOrders,
    taxSelectedAccountOrders,
    notPaidSelectedAccountOrders,
    isDiscountOrderApplied,
    createDraft,
    editOrderMerge,
    editOrder,
    addToCart,
    pasteProducts,
    addByBarcode,
    updateCartItem,
    removeProduct,
    clearCart,
    clearOrder,
    setOrderType,
    setCustomer,
    assignCustomer,
    updateCustomerRecentPurchaseStatus,
    calculatePriceWithAddons,
    calculateTotals,
    applyDiscountOrder,
    applyDiscountOrderItem,
    removeDiscountOrder,
    pay,
    modifyQuickOrder,
    getOrderNotes,
    createOrderNotes,
    editOrderNotes,
    completeOrder,
    syncDataBff,
    isPosToggle,

    // SESSIONS LOGIC
    sessions,
    viewingSession,
    endDayReport,
    cardPaymentsReport,
    isAlreadyOpened,
    isMemberAndRegisterSelected,
    selectedLocation,
    selectedRegister,
    openRegisters,
    selectedRegisterTeamMember,
    openRegisterTeamMembers,
    isCloseProcess,
    closeStep,
    closingTeamMember,
    closingRegister,
    closingLocation,
    coinsCount,
    notesCount,
    isAutomated,
    automatedCount,
    hasOpenedSessions,
    hasOpenedRegisters,
    openedSessions,
    openedRegisters,
    isClosedViewingSession,
    coinsTotal,
    notesTotal,
    cashCounted,
    cashRecords,
    cashExpected,
    cashVariance,
    sessionTotal,
    getSessions,
    getSingleSession,
    openSession,
    closeSession,
    printEndDayReport,
    printCardPaymentsReport,
    getSessionOrders,
    clearPosClose,

    // TILES LOGIC
    tiles,
    editModeTile,
    selectedTile,
    tilePositions,
    currentLayoutPage,
    layoutPagesTotals,
    tilesForDelete,
    inProcessUpdatingGrid,
    viewingTile,
    editingTile,
    tileTypes,
    gsKey,
    getTilesList,
    getSingleTile,
    createTile,
    updateTile,
    deleteTile,
    updateTilesGrid,

    // POS ENVIRONMENT LOGIC
    posLocation,
    posRegister,
    posTeamMember,
    isSetEnvProcess,
    isLocationSelected,
    clearPosEnv,

    // TEAM MEMBERS LOGIC
    teamMembers,
    selectedTeamMember,
    activeTeamMember,
    isShowModalStaffSelect,
    isShowModalStaffShift,
    isShowModalStaffTimesheet,
    isShowModalStaffLockscreen,
    getTeamMembers,
    checkStaffPin,
    resetTeamMember,

    // OTHER LOGIC
    customerOptionsShop,
    isOpenSidebar,
    posPages,
    selectedTable,
    transitionName,
    datePickerKey,
    activePosPage,
    activePosProduct,
    isShowModalProduct,
    isResetTeamMember,
    isLockscreen,
    customersList,
    posProductNote,
    editingProduct,
    editingProductIndex,
    isEditingProduct,
    posSearch,
    posSearchProducts,
    summaryNotes,
    viewingBalancesMember,
    viewingBalances,
    selectedCategory,
    filtersInventory,
    filtersAddCustomer,
    filtersOrders,
    filtersOrdersCustomer,
    isGuestFilter,
    selectedDayOrders,
    selectedDayCustomsers,
    letterOrders,
    letterCustomers,
    redeemedPoints,
    isApplyBirthdayBonus,
    posPagesFiltered,
    productsQuantity,
    isTableOrder,
    savedMoneyByPointsPaying,
    options,
    getMemberBalances,
    selectOption,
  };
});
