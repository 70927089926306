import { SidebarPrefix } from "./general";

export interface Onboard {
  id: number;
  name: string;
  is_protected: boolean;
  order: number;
  url: string;
  type: OnboardingType;
}

export interface OnboardManage {
  id?: number;
  name: string;
  label?: string;
  is_active: boolean;
  queue: number;
  url?: string;
  type: OnboardingType;
  is_protected: boolean;
}

export interface OnboardBulk {
  id: number;
  order: number;
}

export interface OnboardQR {
  id: number;
  url: string;
  scans: number;
  name: string;
  color: string;
  background_color: string;
  display_logo: boolean;
  display_label: boolean;
  qr_code_url: string;
}

export interface OnboardQRManage {
  id?: number;
  url: string;
  name: string;
  color: string;
  background_color: string;
  display_logo: boolean;
  display_label: boolean;
}

export interface Onboarding {
  type: OnboardingType;
  email: string;
  password: string;
  plan_type?: string;
  plan_number?: string;
  plan_start_date?: string;
  plan_end_date?: string;
  phone: string;
  mobile: string;
  password_confirmation: string;
  firstname: string;
  lastname: string;
  company: OnboardingCompany;
  birth_date?: string;
  tfn?: string;
  pin?: string;
  pin_confirmation?: string;
  store_id?: number;
  brand_id?: number;
  title?: string;
  addresses: {
    type: string;
    name: string;
    province: {
      id?: number;
      code: string;
      name: string;
    };
    country: {
      id: string;
      name: string;
    };
    postalcode: string;
    city: string;
    address: string;
    is_default: boolean;
    billpayer?: {
      firstname?: string;
      lastname?: string;
      phone?: string;
      mobile?: string;
      email?: string;
    };
  }[];
  media: File;
}

export interface OnboardingPayload {
  type: OnboardingType;
  email: string;
  password: string;
  phone: string;
  mobile: string;
  plan_type?: string;
  plan_number?: string;
  plan_start_date?: string;
  plan_end_date?: string;
  password_confirmation: string;
  firstname: string;
  lastname: string;
  company: OnboardingCompany;
  team_member_in_store_id?: number;
  companies?: number[];
  tfn: string;
  birth_date: string;
  store_id?: number;
  brand_id?: number;
  title?: string;
  addresses: {
    type: string;
    name: string;
    province: string;
    country: string;
    postalcode: string;
    city: string;
    address: string;
    is_default: boolean;
    billpayer?: {
      firstname?: string;
      lastname?: string;
      phone?: string;
      mobile?: string;
      email?: string;
    };
  }[];
}

export interface OnboardingCompany {
  company_name: string;
  website: string;
  abn: string;
  subtypes: number[];
  email: string;
  firstname: string;
  lastname: string;
  contacts: OnboardingCompanyContact[];
}

export interface OnboardingCompanyContact {
  email: string;
  title: string;
  firstname: string;
  lastname: string;
}

export interface OnboardingStep {
  id: number;
  title: string;
  subtitle: string;
  name: string;
  is_done: boolean;
}

// 'id' => Builder::sometimes()->required()->integer(),
// 'type' => Builder::required()->string()->in([CustomerType::INDIVIDUAL, CustomerType::COMPANY]),
// 'is_active' => Builder::boolean(),
// 'queue' => Builder::required()->integer()->min(1),
// 'name' => Builder::required()->string()->max(255),
// 'label' => Builder::required()->string()->max(255),
// 'description' => Builder::string(),
// 'notice' => Builder::string(),
export interface OnboardingSection {
  id?: number;
  name: string;
  label: string;
  queue?: number;
  is_active: boolean;
  is_protected?: boolean;
  description: string;
  terms_link: null | string;
  privacy_link: null | string;
  service_agreement_link?: null | string;
  use_signature_module?: boolean;
  modules: string[];
  notice: string;

  onboard_id?: number;

  is_done?: boolean;
}

export enum OnboardingType {
  COMPANY = "company",
  INDIVIDUAL = "individual",
  CONTACT = "contact",
  STAFF = "staff",
  LOCATION = "location",
  SUPPORT = "support",
  QUICK_SUPPORT = "quick-support",
  BOOKING = "booking",
}

export enum OnboardingSectionName {
  CREATE_ACCOUNT = "create-account",
  COMPANY_INFORMATION = "company-information",
  BILLING_INFORMATION = "billing-information",
  DELIVERY_INFORMATION = "delivery-information",
  ADDITIONAL_CONTACTS = "additional-information",
  SUMMARY_AND_SUBMIT = "summary-submit",
  YOUR_LOCATION = "your-location",

  // if different billing details
  BILLPAYER = "billpayer",
  VERIFICATION = "verification",
}

export interface OnboardingSectionCreatePayload {
  name: string;
  label: string;
  type: OnboardingType;
  is_active: boolean;
  description?: string;
  notice?: string;
  queue?: number;
  modules?: string[];
}

export enum OnboardingModule {
  // START_PAGE = "start-page",

  BASIC_INFO = "basic-info",
  PROFILE_DETAILS = "profile-details",
  BILLING = "billing",
  DELIVERY = "delivery",
  PIN = "pin",
  PRIMARY_BANKING_ACCOUNT = "primary-banking-account",
  SUPERANNUATION = "superannuation",
  COMPANY_CONTACTS = "company-contacts",
  PLAN = "plan",
  LOCATIONS_AND_BRANDS = "locations-and-brands",
  LOCATION_AGENT = "location-agent",

  // booking
  LOCATION_SELECTION = "location-selection",
  SERVICE_TYPE = "service-type",
  FUNDING_TYPE = "funding-type",
  FUNDING_LINE = "funding-line",
  SERVICE = "service",
  AVAILABILITY = "availability",
}

export const SidebarPage = {
  ONBOARDING_CONTACT: `${SidebarPrefix.ONBOARDING}-contact`,
};
