import { SidebarPrefix, Media } from "./general";

export interface Taxonomy {
  id: number;
  name: string;
  slug: string;
  taxons?: Taxon[];
}
export interface Taxon {
  id: number;
  name: string;
  slug: string;
  parents?: Taxon[];
}

interface TaxonListType {
  id?: number;
  name: string;
  slug?: string;
  parent_id?: number | null;
  parents?: { id: number; name: string; slug: string }[];
  priority?: number | null;
  image?: Media;
  products_count?: number;
  settings?: {
    auto_pricing?: { enabled: boolean };
    show_online?: boolean;
    levies: {
      enabled: boolean;
      rate?: number;
      units?: LevyUnit;
    }
  };
  linked_taxonomies?: Taxonomy[];
}

export interface TaxonSingle extends TaxonListType {
  ext_title?: string;
  description?: string | null;
  meta_keywords?: string;
  meta_description?: string;
}

export interface Category extends TaxonSingle {
  image?: Media;
}
export interface Tag extends TaxonSingle {}

export interface CategoryFilterOption {
  name: string;
  value: string | number;
}

export enum CategoriesAncestorTypes {
  PARENT = "parent",
  CHILD = "child",
  GRAND_CHILD = "grand-child",
}

export enum Taxonomies {
  CUSTOMER_TAGS = "customer-tag",
  PRODUCT_TAGS = "tag",
  CATEGORIES = "category",
  STORE_TAGS = "store-tag",
  STORE_CATEGORIES = "store-category",
  ITEM_NUMBER = "item-number",
}

export enum TaxonomiesRounding {
  NEAREST_5_CENTS = 1,
  ROUND_UP_50_NEAREST = 2,
  ROUND_DOWN_50_NEAREST = 3,
}

export enum LevyUnit {
  KILO = "kg",
  TON = "t",
  LITER = "l",
  BOX = "box",
  BOX_TRAY = "box_tray",
  CARTON = "carton",
  PERCENT = "percent",
  SQUARE_METERS = "m2",
  RUNNERS = "runners",
}

export const SidebarPage = {
  TAXONOMIES_CATEGORY: `${SidebarPrefix.TAXONOMIES}-category`,
};
