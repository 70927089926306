<template>
  <Slider
    :value="values"
    @input="emit('input', $event)"
    :min="+minMax[0]"
    :max="+minMax[1]"
    :tooltips="false"
    ref="slider"
    @change="onChange"
  ></Slider>
</template>

<script setup lang="ts">
import Slider from "@vueform/slider";

const slider = ref(null);

const props = defineProps<{
  values: (number | string)[];
  minMax: (number | string)[];
}>();

const emit = defineEmits(["change", "input"]);

function onChange(newVal: number[]) {
  emit("change", newVal);
}
</script>
