import * as Sentry from "@sentry/nuxt";
import { useGeneralStore } from "~/store/general";

export async function useReportBug() {
  const { reportBugForm } = storeToRefs(useGeneralStore());

  if (!reportBugForm.value) {
    const feedback = Sentry.getFeedback();
    reportBugForm.value = await feedback.createForm();
    reportBugForm.value.appendToDom();
  }

  reportBugForm.value.open();
}
