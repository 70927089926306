import { useGeneralStore } from "~/store/general";

export const useSetSidebar = (page: string | boolean, payload?: object) => {
  const router = useRouter();
  const route = useRoute();
  const generalStore = useGeneralStore();

  generalStore.previousSidebarPage = route.query.sidebar;

  // filter queries
  const filterQueries = Object.fromEntries(
    Object.entries(route.query).filter(([key]) => key.startsWith("filter"))
  );

  if (page) {
    router.push({
      name: route.name,
      query: { sidebar: page.toString(), ...payload, ...filterQueries },
    });
  } else {
    router.push({
      path: route.path,
      query: { ...payload, sidebar: undefined, ...filterQueries },
    });
  }
};
