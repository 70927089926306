import { defineStore } from "pinia";
import {
  Brand,
  OrderType,
  Period,
  Toast,
  Option,
  NavItemTitle,
  NavItem,
  NavItemKey,
  DatePickerMode,
  NotificationEvent,
  PrinterPhysical,
  PrintAssets,
  ResponseType,
} from "~/types/general";
import { CalendarViewOptions } from "~/types/general";
import { useSettingsStore } from "~/store/settings";

export const useGeneralStore = defineStore("general", () => {
  // Nav Items
  const activeNav = ref({});
  const activeSubNav = ref({});
  const activeSubNavTab = ref({});
  const isOpenTabsSidebar = ref(false);
  const isOpenContactMenu = ref(false);

  const navItems = computed(
    () =>
      [
        {
          title: NavItemTitle.ORDERS,
          key: NavItemKey.ORDERS,
          route: useRoutesNames().orders,
          functions: [
            {
              name: "Overview",
              link: `/orders`,
            },
            {
              name: "Preparing",
              link: `/orders/preparing`,
            },
            ...(useConfig().isAllocations
              ? [
                  {
                    name: "Allocations",
                    link: `/orders/allocations`,
                  },
                ]
              : []),
            {
              name: "Fulfilment",
              link: "/orders/fulfilment",
            },
            {
              name: "Schedule",
              link: "/orders/schedule",
            },
            {
              name: "Statements",
              link: "/orders/statements",
            },
            {
              name: "Invoices",
              link: `/orders/invoices`,
            },
          ],
        },
        {
          title: NavItemTitle.CONTACTS,
          key: NavItemKey.CONTACTS,
          route: useRoutesNames().contactsCompanies,
          functions: [
            {
              name: "People",
              link: "/contacts/people",
            },
            {
              name: "Companies",
              link: "/contacts/companies",
            },
            ...(useSettingsStore().settings?.loyalty_reward?.enabled
              ? [
                  {
                    name: "Loyalty and Rewards",
                    link: "/contacts/loyalty-rewards",
                  },
                ]
              : []),
          ],
        },
        {
          title: NavItemTitle.PURCHASING,
          key: NavItemKey.PURCHASING,
          route: useRoutesNames().purchaseOrders,
          functions: [
            {
              name: "Overview",
              link: `/purchase-orders`,
            },
            {
              name: "Receiving",
              link: `/purchase-orders/receiving`,
            },
            {
              name: "Purchase Overview",
              link: "/purchase-orders/purchase-overview",
            },
            {
              name: "Bills",
              link: `/purchase-orders/bills`,
            },
          ],
        },
        {
          title: NavItemTitle.PRODUCTS,
          key: NavItemKey.PRODUCTS,
          route: useRoutesNames().products,
          functions: [
            {
              name: "Products",
              link: `/products`,
            },
            {
              name: "Services",
              link: `/products/services`,
            },
            {
              name: "Inventory",
              link: `/products/inventory`,
            },
            {
              name: "Count",
              link: `/products/counts`,
            },
            {
              name: "Price Lists",
              link: `/products/price-lists`,
            },
          ],
        },
        {
          title: NavItemTitle.REPORTS,
          key: NavItemKey.REPORTS,
          functions: [
            {
              name: "Analytics",
              link: `/reports/analytics`,
            },
            {
              name: "Revenue",
              link: `/reports/customers`,
            },
            {
              name: "Costs",
              link: `/reports/costs`,
            },
            {
              name: "Products",
              link: `/reports/products`,
            },
          ],
        },
        {
          title: NavItemTitle.MANAGEMENT,
          key: NavItemKey.MANAGEMENT,
          functions: [
            {
              name: "Locations",
              link: `/management/locations`,
            },

            {
              name: "Brands",
              link: `/management/brands`,
            },
            {
              name: "Platform Settings",
              link: `/settings`,
            },
          ],
        },
      ] as NavItem[]
  );

  // Order Types
  const orderTypeList = ref([
    { name: "Delivery", value: OrderType.DELIVERY },
    { name: "Pick Up", value: OrderType.PICKUP },
    { name: "In Person", value: OrderType.IN_PERSON },
    { name: "Unknown", value: OrderType.UNKNOWN },
    {
      name: "Mobile",
      value: OrderType.MOBILE,
    },
    { name: "Virtual", value: OrderType.VIRTUAL },
  ]);

  const orderTypes = computed(() =>
    orderTypeList.value.filter(
      (type) =>
        (useSettingsStore().settings?.order?.booking_services?.enabled &&
          type.value === OrderType.MOBILE) ||
        type.value !== OrderType.MOBILE
    )
  );

  // User Preferences and Toasts
  const userPreferences = ref({});
  const toastsList = ref([] as Toast[]);

  // Loading States
  const isLoading = ref(false);
  const isOpenDropdownSearch = ref({
    id: null,
    value: false,
  });
  const isOpenSubitemsMenu = ref(false);

  // Brands
  const brands = ref([] as Brand[]);

  const brandsList = computed(() =>
    brands.value.map(({ name, id }) => ({
      name,
      value: id,
    }))
  );

  async function getBrands(params?): Promise<ResponseType<Brand[]>> {
    try {
      const response = (await useVaniloApi("/brands", {
        params,
      })) as ResponseType<Brand[]>;

      brands.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Periods and Date Picker Modes
  const periods = ref([
    {
      name: "Week",
      value: Period.WEEK,
    },
    {
      name: "Yesterday",
      value: Period.YESTERDAY,
    },
    {
      name: "Recent",
      value: Period.RECENT,
    },
  ] as Option[]);

  const datePickerModes = ref([
    {
      name: "All",
      value: DatePickerMode.ALL,
    },
    {
      name: "Year",
      value: DatePickerMode.YEAR,
    },
    {
      name: "Month",
      value: DatePickerMode.MONTH,
    },
    {
      name: "Week",
      value: DatePickerMode.WEEK,
    },
    {
      name: "Day",
      value: DatePickerMode.DAY,
    },
  ]);

  // OS
  const os = ref(null as string | null);

  // Printers and Print Assets
  const printersPhysical = ref([] as PrinterPhysical[]);
  const printAssets = ref({} as PrintAssets);

  const printersPhysicalOptions = computed(() =>
    printersPhysical.value?.map((printer) => ({
      name: printer.displayName,
      value: printer.name,
    }))
  );

  async function getPrintAssets() {
    try {
      const response = (await useVaniloApi(`/settings/print-assets`)) as any;

      printAssets.value = response.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // REPORT BUG
  const reportBugForm = ref(null);

  // Notifications
  const notificationsList = ref([] as NotificationEvent[]);
  const isNotificationOpen = ref<boolean>(false);

  // Sidebar
  const previousSidebarPage = ref(null);
  const cameFromPosOrders = ref(false);
  const calendarMode = ref({
    view: {
      name:
        CalendarViewOptions.MONTH.charAt(0).toUpperCase() +
        CalendarViewOptions.MONTH.slice(1),
      value: CalendarViewOptions.MONTH,
    },
    weekends: true,
    date: null,
    dateRange: null,
  });

  // Modals
  const isShowModalLocation = ref(false);

  return {
    activeNav,
    activeSubNav,
    activeSubNavTab,
    isOpenTabsSidebar,
    navItems,
    orderTypeList,
    orderTypes,
    userPreferences,
    toastsList,
    isLoading,
    isOpenDropdownSearch,
    isOpenSubitemsMenu,
    isNotificationOpen,
    reportBugForm,
    brands,
    brandsList,
    getBrands,
    periods,
    datePickerModes,
    os,
    printersPhysical,
    printAssets,
    printersPhysicalOptions,
    getPrintAssets,
    notificationsList,
    previousSidebarPage,
    cameFromPosOrders,
    calendarMode,
    isShowModalLocation,
    isOpenContactMenu,
  };
});
