import { useGeneralStore } from "~/store/general";
import { useOrdersActionsStore } from "~/store/orders-actions";
import { usePusherStore } from "~/store/pusher";
import { useSettingsStore } from "~/store/settings";
import { useTasksStore } from "~/store/tasks";
import { ToastType } from "~/types/general";
import { PusherEventName } from "~/types/pusher";
import { useInventoryActionsStore } from "~/store/inventory-actions";

export function usePusherInit(): void {
  const pusherStore = usePusherStore();
  const tasksStore = useTasksStore();
  const generalStore = useGeneralStore();
  const ordersActionsStore = useOrdersActionsStore();
  const inventoryActionsStore = useInventoryActionsStore();

  // INITIALIZE PUSHER
  if (
    !useConfig().isClient &&
    useConfig().isPusher &&
    !pusherStore.pusher &&
    !pusherStore.channelPrivateDataProcessing &&
    !pusherStore.channelReports &&
    !pusherStore.channelNotifications &&
    !pusherStore.channelOrderActions &&
    !pusherStore.channelErrors
  ) {
    pusherStore.pusher = usePusher();

    // data processing channel subscribe
    pusherStore.channelPrivateDataProcessing = pusherStore.pusher.subscribe(
      pusherStore.channelNamePrivateDataProcessing
    );
    pusherStore.channelPrivateDataProcessing.bind(
      PusherEventName.DATA_PROCESSING_PROGRESS,
      (data) => {
        tasksStore.dataEvent = data;
      }
    );
    pusherStore.channelPrivateDataProcessing.bind(
      PusherEventName.INVENTORY_REPORT,
      (data) => {
        tasksStore.dataEvent = data;
      }
    );
    pusherStore.channelPrivateDataProcessing.bind(
      PusherEventName.INVOICE_BATCH_SYNC,
      (data) => {
        tasksStore.dataEvent = data;
      }
    );

    // reports channel subscribe
    pusherStore.channelReports = pusherStore.pusher.subscribe(
      pusherStore.channelNameReports
    );
    pusherStore.channelReports.bind(PusherEventName.REPORT_CREATED, (data) => {
      tasksStore.taskEvent = data;
    });

    // notifications channel subscribe
    pusherStore.channelNotifications = pusherStore.pusher.subscribe(
      pusherStore.channelNameNotifications
    );
    pusherStore.channelNotifications.bind(
      PusherEventName.GENERAL_NOTIFICATION,
      (data) => {
        generalStore.notificationsList.push(data);
      }
    );

    // order actions channel subscribe
    pusherStore.channelOrderActions = pusherStore.pusher.subscribe(
      pusherStore.channelNameOrderActions
    );
    pusherStore.channelOrderActions.bind(
      PusherEventName.ORDER_ACTION,
      (data) => {
        if (data.inventory) {
          inventoryActionsStore.actionsNotifications.push(data);
          if (inventoryActionsStore.actionsNotifications?.length) {
            inventoryActionsStore.isNewNotifications = true;
          }
          return;
        }
        ordersActionsStore.actionsNotifications.push(data);
        if (ordersActionsStore.actionsNotifications?.length) {
          ordersActionsStore.isNewNotifications = true;
        }
      }
    );

    // errors channel subscribe
    pusherStore.channelErrors = pusherStore.pusher.subscribe(
      pusherStore.channelNameErrors
    );
    pusherStore.channelErrors.bind(PusherEventName.DATA_ERROR, (data) => {
      tasksStore.bulkActionsErrors.push(data.error);
      useToast(data.error, {
        type: ToastType.ERROR,
      });
    });

    pusherStore.channelErrors.bind(PusherEventName.ORDER_DATA_ERROR, (data) => {
      useToast(data.error, {
        type: ToastType.ERROR,
      });
    });
  }
}
