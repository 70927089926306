import { UserType } from "./users";

export interface App {
  id?: number;
  name: string;
  link: string;
  is_active: boolean;
  is_protected: boolean;
  permissions?: UserType[];
  position: number;
  functions?: App[];
}

export interface AppBulkItem {
  id: number;
  name: string;
  link: string;
}

export enum AppSubnavTabs {
  // REPORTS
  // analytics
  PROFIT_AND_LOSS = "profit-and-loss",
  // revenue
  CUSTOMERS = "customers",
  LOCATIONS = "locations",
  PAYMENTS = "payments",
  // products
  CATEGORIES = "categories",
  INVENTORY = "inventory",
  ATTRIBUTES = "attributes",
  TAGS = "tags",
  MODIFIERS = "modifiers",

  // costs
  TRANSPORT_UNITS = "transport-units",
  UNITS_OVERVIEW = "units-overview",
  STAFF = "staff",

  // PRODUCTS SETTINGS

}
