import { defineStore } from "pinia";
import {
  StoreSingle,
  StoreManage,
  StorePrinterManage,
  StorePrinter,
  QRCode,
  Store,
  NewStoreManage,
  StoreType,
} from "~/types/stores";
import { StorageService } from "~/services/StorageService";
import {
  ResponseType,
  Image,
  TabSectionType,
  TabSectionModuleType,
  TabType,
  Document,
  Media,
} from "~/types/general";
import { useSettingsStore } from "./settings";
import { LeadRule } from "~/types/settings-lead-times";
import { OrderExpressService } from "~/types/settings-order-express-services";
import { usePaymentStore } from "~/store/payment";
import { IndexedDBStore, ResponseList } from "~/types/bff";
import { Table } from "~/types/table-ordering";
import { CustomerPlanManagement } from "~/types/customers";

export const useStoresStore = defineStore("stores", () => {
  // State
  const stores = ref<Store[]>([]);
  const editMode = ref(false);
  const isOpenSidebar = ref(false);
  const viewingStore = ref<StoreSingle>({} as StoreSingle);
  const editingStore = ref<StoreManage>({} as StoreManage);
  const creatingStore = ref<StoreManage>({} as StoreManage);
  const locationTeamMembers = ref<any[]>([]);

  const editingStorePrinter = ref<StorePrinterManage>({} as StorePrinterManage);
  const viewingStorePrinter = ref<StorePrinter>({} as StorePrinter);
  const imageStore = ref<Image>({} as Image);
  const imageId = ref<number | null>(null);
  const qrCodes = ref<QRCode[]>([]);

  const profileSearch = ref("");
  const previousTab = ref(null);
  const image = ref<any>({});
  const files = ref<Document[]>([]);

  const editingProfileLocation = ref<NewStoreManage>({} as NewStoreManage);
  const viewingProfileLocation = ref<StoreSingle>({} as StoreSingle);
  const profileLoctionBlockouts = ref<any[]>([]);
  const quickViewProfileLocation = ref<StoreSingle>({} as StoreSingle);

  const locationQRcodes = ref<any[]>([]);
  const editingQR = ref<any>({});
  const profileLeadTimesRules = ref<LeadRule[]>([]);
  const profileImmediateOrderingList = ref<OrderExpressService[]>([]);
  const profileMinimumOrderList = ref<any[]>([]);
  const profileTables = ref<Table[]>([]);
  const profileTableTags = ref<any[]>([]);

  const editingPrinter = ref<any>({});
  const viewingPrinter = ref<any>({});

  const locationTypes = ref([
    {
      name: "Default",
      value: StoreType.DEFAULT,
    },
    {
      name: "Agent",
      value: StoreType.AGENT,
    },
  ]);

  const planManagementTypes = ref<any[]>([
    {
      name: "Plan Managed",
      value: CustomerPlanManagement.PLAN_MANAGEMENT,
    },
    {
      name: "Self Managed",
      value: CustomerPlanManagement.SELF_MANAGEMENT,
    },
    {
      name: "Agency Managed",
      value: CustomerPlanManagement.AGENCY_MANAGEMENT,
    },
  ]);

  const openingHoursOptions = ref([
    {
      name: "Monday",
      value: false,
      day: 1,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
    {
      name: "Tuesday",
      value: false,
      day: 2,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
    {
      name: "Wednesday",
      value: false,
      day: 3,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
    {
      name: "Thursday",
      value: false,
      day: 4,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
    {
      name: "Friday",
      value: false,
      day: 5,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
    {
      name: "Saturday",
      value: false,
      day: 6,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
    {
      name: "Sunday",
      value: false,
      day: 7,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
  ]);

  const deliveryDays = ref([
    {
      name: "Monday",
      value: false,
      day: 1,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
    {
      name: "Tuesday",
      value: false,
      day: 2,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
    {
      name: "Wednesday",
      value: false,
      day: 3,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
    {
      name: "Thursday",
      value: false,
      day: 4,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
    {
      name: "Friday",
      value: false,
      day: 5,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
    {
      name: "Saturday",
      value: false,
      day: 6,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
    {
      name: "Sunday",
      value: false,
      day: 7,
      isBreak: false,
      is_round_the_clock: false,
      time: [],
      cut_off_time: [],
    },
  ]);

  // Getters
  const tabs = computed(() => [
    {
      name: "Profile",
      tab: null,
      types: null,
      subtypes: null,
      forNew: true,
      sections: [
        // {
        //   section: TabSectionType.PROFILE,
        //   types: null,
        //   isAction: true,
        //   noDivider: false,
        //   forNew: true,
        //   title: "Location Profile",
        //   subtitle:
        //     "Manage a location profile update important information, review operating hours and service areas and manage important settings.",
        //   keywords: "location profile",
        // },
        {
          section: TabSectionType.GENERAL_INFO,
          types: null,
          isAction: false,
          noDivider: false,
          forNew: true,
          title: "General Information",
          subtitle:
            "This refers to the main profile information information, such as the location name and contact details.",
          keywords:
            "general information email abn regional code short name location name group status phone mobile website invoice and payment methods brands primary address location type",
          modules: [
            {
              module: TabSectionModuleType.LOCATION_INFO,
              types: null,
              keywords:
                "general information email abn regional code short name location name group status phone mobile website",
            },
            {
              module: TabSectionModuleType.INVOICE_AND_PAYMENT_DETAILS,
              types: null,
              keywords: "general information invoice and payment methods",
            },
            {
              module: TabSectionModuleType.LOCATION_TYPE,
              types: null,
              keywords: "general information location type",
            },
            {
              module: TabSectionModuleType.BRANDS,
              types: null,
              keywords: "general information brands",
            },
          ],
        },
        {
          section: TabSectionType.ADDRESS_DETAILS,
          types: null,
          isAction: false,
          noDivider: false,
          forNew: true,
          title: "Primary Address",
          subtitle:
            "This is the primary operating address of this location. Customer will visit this address to purchase products and receive services.",
          keywords: "primary address longitude latitude",
          modules: [
            {
              module: TabSectionModuleType.LOCATION_ADDRESS,
              types: null,
              keywords: "primary address longitude latitude",
            },
          ],
        },
        {
          section: TabSectionType.ORGANISE_AND_CLASSIFY,
          types: null,
          isAction: false,
          noDivider: true,
          forNew: true,
          title: "Organise and Classify",
          subtitle:
            "Add a personal description, organise the location with categories and tags. This information is shared with platform admins and managers. ",
          keywords: "organise and classify description categories tags",
          modules: [
            {
              module: TabSectionModuleType.PROFILE_DETAILS,
              types: null,
              keywords: "organise and classify description",
            },
            {
              module: TabSectionModuleType.LOCATION_CATEGORIES,
              types: null,
              keywords: "organise and classify categories",
            },
            {
              module: TabSectionModuleType.LOCATION_TAGS,
              types: null,
              keywords: "organise and classify tags",
            },
            {
              module: TabSectionModuleType.LOCATION_PLAN_MANAGEMENT,
              types: null,
              keywords: "organise and classify plan management",
            },
            {
              module: TabSectionModuleType.LOCATION_AREAS,
              types: null,
              keywords: "organise and classify areas",
            },
          ],
        },
      ],
    },
    {
      name: "Additional Information",
      tab: TabType.ADDITIONAL_INFORMATION,
      types: null,
      subtypes: null,
      forNew: false,
      sections: [
        {
          section: TabSectionType.QR_CODE,
          types: null,
          isAction: false,
          noDivider: false,
          forNew: false,
          title: "QR codes",
          subtitle:
            "Add a QR Codes for your location, use these code to link back to your website, or app - manage them centrally and review statistics.",
          keywords:
            "additional information qr codes background colour qr code label",
          modules: [
            {
              module: TabSectionModuleType.QR_CODE,
              types: null,
              keywords:
                "additional information qr codes background colour qr code label",
            },
          ],
        },
        {
          section: TabSectionType.TABLES,
          types: null,
          isAction: false,
          noDivider: false,
          forNew: false,
          title: "Tables",
          subtitle: "",
          keywords: "additional information tables",
          modules: [
            {
              module: TabSectionModuleType.TABLES,
              types: null,
              keywords: "additional information tables",
            },
            {
              module: TabSectionModuleType.TABLES_TAGS,
              types: null,
              keywords: "additional information tables tags",
            },
          ],
        },
        ...(useSettingsStore().settings?.order?.target_date?.location_specific
          ? [
              {
                section: TabSectionType.LEAD_TIMES_AND_TARGETS,
                types: null,
                isAction: false,
                noDivider: false,
                forNew: false,
                title: "Lead Times and Targets",
                subtitle:
                  "You can set custom lead times and target dates for customers buying online. This helps your team manage orders and keep customer’s happy.",
                keywords: "additional information lead times and targets",
                modules: [
                  {
                    module: TabSectionModuleType.LEAD_TIME_RULES,
                    types: null,
                    keywords:
                      "additional information lead times and targets lead time rules",
                  },
                  {
                    module: TabSectionModuleType.INTERVALS,
                    types: null,
                    keywords:
                      "additional information lead times and targets intervals",
                  },
                ],
              },
            ]
          : []),
      ],
    },
    {
      name: "Operating Hours",
      tab: TabType.OPENING_HOURS,
      types: null,
      subtypes: null,
      forNew: true,
      keywords:
        "open time schedule operating hours schedule  opening hours schedule open hours schedule time zone monday tuesday wednesday thursday friday saturday sunday time zone open 24 hours closed to public",
      sections: [
        {
          section: TabSectionType.OPENING_HOURS,
          types: null,
          isAction: false,
          noDivider: false,
          forNew: true,
          title: "Opening Hours",
          subtitle:
            "Set your usual business operating hours, these times are used in platform and will sync with your website.  Adjust your time zone and set whether you are closed to public. If you offer pick up services your operating times will be used as order options.",
          keywords:
            "open time schedule operating hours schedule  opening hours schedule open hours schedule time zone monday tuesday wednesday thursday friday saturday sunday time zone open 24 hours closed to public",
          modules: [
            {
              module: TabSectionModuleType.OPENING_HOURS,
              types: null,
              keywords:
                "open time schedule operating hours schedule  opening hours schedule open hours schedule time zone monday tuesday wednesday thursday friday saturday sunday time zone open 24 hours closed to public",
            },
          ],
        },
        {
          section: TabSectionType.BLOCK_OUT_PERIODS_CLOSED,
          types: null,
          isAction: false,
          noDivider: false,
          forNew: false,
          title: "Block Out Periods/Closed",
          subtitle:
            "Set your holidays, special operating hours and block out days to prevent customer’s from ordering.",
          keywords:
            "open time schedule operating hours schedule block out periods closed",
          modules: [
            {
              module: TabSectionModuleType.DATE_OVERRIDES,
              types: null,
              keywords:
                "open time schedule operating hours schedule block out periods closed schedule date overrides",
            },
          ],
        },
      ],
    },
    {
      name: "Service Areas",
      tab: TabType.SERVICE_AREAS,
      types: null,
      subtypes: null,
      forNew: false,
      keywords:
        "service areas enable delivery hours delivery zones delivery schedule monday tuesday wednesday thursday friday saturday sunday 24/7 delivery",
      sections: [
        {
          section: TabSectionType.SERVICE_AREAS,
          types: null,
          isAction: false,
          noDivider: false,
          forNew: false,
          title: "Service Areas",
          subtitle:
            "Set your usual business operating hours, these times are used in platform and will sync with your website.  Adjust your time zone and set whether you are closed to public.",
          keywords: "service areas enable delivery hours delivery zones",
          modules: [
            {
              module: TabSectionModuleType.SERVICE_AREAS,
              types: null,
              keywords: "service areas enable delivery hours delivery zones",
            },
          ],
        },
        {
          section: TabSectionType.SERVICE_SCHEDULE,
          types: null,
          isAction: false,
          noDivider: false,
          forNew: false,
          title: "Service Schedule",
          subtitle:
            "If you have a different service/delivery schedule you can configure your operating times here.",
          keywords:
            "service areas service schedule delivery schedule monday tuesday wednesday thursday friday saturday sunday 24/7 delivery",
          modules: [
            {
              module: TabSectionType.SERVICE_SCHEDULE,
              types: null,
              keywords:
                "service areas service schedule delivery hours delivery schedule monday tuesday wednesday thursday friday saturday sunday 24/7 delivery",
            },
          ],
        },
      ],
    },
    {
      name: "Team Members",
      tab: TabType.TEAM_MEMBERS,
      types: null,
      subtypes: null,
      forNew: false,
      keywords: "team members invite team members staff invite staff",
    },
    {
      name: "Files",
      tab: TabType.FILES,
      types: null,
      subtypes: null,
      keywords: "attachments documents",
      forNew: true,
      sections: [
        {
          section: TabSectionType.DOCUMENTS,
          types: null,
          title: "Documents and Attachments",
          subtitle:
            "Add important reference documents to this location. Centralise important admin data.",
          keywords: "attachments documents",
          forNew: true,
          modules: [
            {
              module: TabSectionModuleType.DOCUMENTS,
              types: null,
              keywords: "attachments documents",
            },
          ],
        },
        {
          section: TabSectionType.AGREEMENTS,
          types: null,
          title: "Agreements",
          subtitle:
            "Manage location specific agreements and set requirements for agreements to be signed.",
          keywords: "attachments documents",
          forNew: false,
          modules: [
            {
              module: TabSectionModuleType.LOCATION_FILES,
              types: null,
              keywords: "agreements",
            },
          ],
        },
      ],
    },
    {
      name: "Settings",
      tab: TabType.SETTINGS,
      types: null,
      subtypes: null,
      forNew: false,
      keywords:
        "settings configurations in-person in person registers printers",
      sections: [
        {
          section: TabSectionType.CONFIGURATION,
          types: null,
          isAction: false,
          noDivider: false,
          forNew: false,
          title: "Configuration Options",
          subtitle: "",
          keywords: "settings configuration options hide from directory",
          modules: [
            {
              module: TabSectionModuleType.CONFIGURATION,
              types: null,
              keywords: "settings configuration options hide from directory",
            },
          ],
        },
        ...(useConfig().isPos
          ? [
              {
                section: TabSectionType.POINT_OF_SALE_REGISTERS,
                types: null,
                isAction: false,
                noDivider: false,
                forNew: false,
                title: "Point of Sale Registers",
                subtitle:
                  "Add registers to your location and set up the Jaffle payment configuration.",
                keywords:
                  "settings configurations in-person in person registers",
                modules: [
                  {
                    module: TabSectionModuleType.POINT_OF_SALE_REGISTERS,
                    types: null,
                    keywords:
                      "settings configurations in-person in person registers",
                  },
                ],
              },
              {
                section: TabSectionType.PRINTER_RULES,
                types: null,
                isAction: false,
                noDivider: false,
                forNew: false,
                title: "Printer Rules",
                subtitle:
                  "You can enable receipt printers, add print routing rules, associated with categories and product tags.",
                keywords: "settings configurations printers",
                modules: [
                  {
                    module: TabSectionModuleType.PRINTER_RULES,
                    types: null,
                    keywords: "settings configurations printers",
                  },
                ],
              },
            ]
          : []),
        ...(useSettingsStore().settings?.general?.features?.online_sales
          ? [
              {
                section: TabSectionType.ONLINE_ORDERING,
                types: null,
                isAction: false,
                noDivider: false,
                forNew: false,
                title: "Online Ordering",
                subtitle:
                  "If you have a different service/delivery schedule you can configure your operating times here.",
                keywords: "settings configurations limit pick up orders",
                modules: [
                  {
                    module: TabSectionModuleType.ONLINE_ORDERING,
                    types: null,
                    keywords: "settings configurations limit pick up orders",
                  },
                ],
              },
            ]
          : []),
        ...(usePaymentStore().paymentMethods?.stripe?.is_enabled
          ? [
              {
                section: TabSectionType.JAFFLE_PAYMENTS,
                types: [TabSectionType.JAFFLE_PAYMENTS],
                isAction: false,
                noDivider: false,
                forNew: false,
                title: "Jaffle Payments",
                subtitle:
                  "Connect or create a Jaffle Payments Accounts to your platform",
                keywords: "jaffle payments",
                modules: [
                  {
                    module: TabSectionModuleType.JAFFLE_PAYMENTS,
                    types: null,
                    keywords: "settings configurations jaffle payments",
                  },
                ],
              },
            ]
          : []),

        {
          section: TabSectionType.DELETE_LOCATION,
          types: null,
          isAction: false,
          noDivider: false,
          forNew: false,
          title: "Delete Location",
          subtitle:
            "In the event you need to delete a location, we have added this delete button. But please use it with caution.",
          keywords: "delete location",
          modules: [
            {
              module: TabSectionModuleType.DELETE_LOCATION,
              types: null,
              keywords: "settings configurations delete location",
            },
          ],
        },
      ],
    },
  ]);

  // Store Management
  async function getStores(
    params?: {
      lat?: number;
      lng?: number;
      page?: number;
      "filter[name]"?: string;
    },
    options?: {
      noAssign?: boolean;
    }
  ): Promise<ResponseType<Store[]>> {
    try {
      const response = (await useVaniloApi("/stores", {
        params,
      })) as ResponseType<Store[]>;

      if (!options?.noAssign) {
        stores.value = response?.data;
      }

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }
  async function getStoresBff(
    payload?: {
      filters?: any;
      search?: any;
      params?: any;
      noFetchMain?: boolean;
      force?: boolean;
    },
    options?: {
      noAssign?: boolean;
    }
  ): Promise<ResponseList<Store>> {
    const { filters, search, params, noFetchMain, force } = payload || {};

    try {
      const response = await new StorageService(
        useRuntimeConfig()
      ).fetchList<IndexedDBStore.LOCATIONS>({
        endpoint: "stores",
        store: IndexedDBStore.LOCATIONS,
        filters,
        search,
        params,
        noFetchMain,
        force,
      });

      if (!options?.noAssign) {
        stores.value = response.data;
      }

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleStore(id): Promise<ResponseType<StoreSingle>> {
    try {
      const response = await useVaniloApi(`/stores/${id}`);

      return response as ResponseType<StoreSingle>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function editStore(body): Promise<ResponseType<StoreSingle>> {
    try {
      useIsLoading(true);
      const response = await useVaniloApi(`/stores/${body.id}`, {
        method: "POST",
        body,
      });

      return response as ResponseType<StoreSingle>;
    } catch (error: any) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function updateStoreInformation(payload) {
    try {
      useIsLoading(true);
      const response = (await useVaniloApi(`/stores/${payload.id}`, {
        method: "POST",
        body: payload,
      })) as ResponseType<StoreSingle>;

      viewingStore.value = response?.data;

      editingStore.value = useCloneDeep({
        ...viewingStore.value,
        addresses: viewingStore.value.addresses.map((address) => ({
          province: address.province.code,
          postalcode: address.postalcode,
          city: address.city,
          address: address.address,
          country: address.country.id,
        })),
        registers: viewingStore.value.registers.map((register) => ({
          id: register.id,
          name: register.name,
          is_terminal_enabled: !!register.terminal,
          ...(register.terminal && {
            terminal: {
              id: register.terminal.id,
              name: register.terminal.name,
              is_enabled: register.terminal.is_enabled,
              reg_code: register.terminal.reg_code,
            },
          }),
        })),
      });

      return response;
    } catch (error: any) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function updateStore(payload?: any) {
    let openingHours: any[] = [];
    let deliveryHours: any[] = [];

    if (!payload) {
      // remove empty fields
      for (const prop in editingStore.value) {
        !editingStore.value[prop] && delete editingStore.value[prop];
      }

      // removing unnecessary 'null' values in delivery zone objects
      if (editingStore.value.delivery_zones?.length) {
        editingStore.value.delivery_zones.forEach((zone) => {
          Object.keys(zone).forEach((key) => {
            if (zone[key] === null) {
              delete zone[key];
            }
          });
        });
      }

      // removing unnecessary 'null' values in store settings
      if (!editingStore.value.settings.order?.pick_up_fee?.value) {
        delete editingStore.value.settings.order.pick_up_fee;
      }
      if (!editingStore.value.settings.order?.pick_up_limit?.max) {
        delete editingStore.value.settings.order.pick_up_limit;
      }

      if (editingStore.value.hours.length) {
        openingHours = editingStore.value.hours?.map((h) => {
          const { cut_off_time, time, ...rest } = h;
          const newTime = time?.filter((i) => i !== null) ?? [];
          const newCutOffTime = cut_off_time?.filter((i) => i !== null) ?? [];
          return {
            ...rest,
            ...(newCutOffTime?.length && { cut_off_time: newCutOffTime }),
            ...(newTime?.length && { time: newTime }),
          };
        });
      }
      if (editingStore.value.delivery_hours.length) {
        deliveryHours = editingStore.value.delivery_hours?.map((h) => {
          const { cut_off_time, time, ...rest } = h;
          const newCutOffTime = cut_off_time?.filter((i) => i !== null) ?? [];
          const newTime = time?.filter((i) => i !== null) ?? [];
          return {
            ...rest,
            ...(newCutOffTime?.length && { cut_off_time: newCutOffTime }),
            ...(newTime?.length && { time: newTime }),
          };
        });
      }
    }

    const {
      printers,
      hours,
      taxonomies,
      brands,
      delivery_hours,
      agreements,
      ...restStoreData
    } = editingStore.value;

    try {
      useIsLoading(true);
      const response = (await useVaniloApi(`/stores/${editingStore.value.id}`, {
        method: "POST",
        body: payload || {
          ...restStoreData,
          hours: [...openingHours],
          delivery_hours: [...deliveryHours],
          taxonomies:
            taxonomies.map((i) => i.taxons.map((taxon) => taxon.id)).flat() ||
            [],
          agreements: agreements.map((agreement) => {
            const { created_at, ...restAgreement } = agreement;
            return restAgreement;
          }),
          brands: brands.map((i) => i.id) || [],
        },
      })) as ResponseType<StoreSingle>;

      viewingStore.value = response?.data;

      // cloning 'viewingStore' to 'editingStore' and format to proper editing model
      editingStore.value = useCloneDeep({
        ...viewingStore.value,
        addresses: viewingStore.value.addresses.map((address) => ({
          province: address.province.code,
          postalcode: address.postalcode,
          city: address.city,
          address: address.address,
          country: address.country.id,
        })),
        registers: viewingStore.value.registers.map((register) => ({
          id: register.id,
          name: register.name,
          is_terminal_enabled: !!register.terminal,
          ...(register.terminal && {
            terminal: {
              id: register.terminal.id,
              name: register.terminal.name,
              is_enabled: register.terminal.is_enabled,
              reg_code: register.terminal.reg_code,
            },
          }),
        })),
      });

      return response;
    } catch (error: any) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function createStore(): Promise<ResponseType<StoreSingle>> {
    // remove empty fields
    for (const prop in creatingStore.value) {
      !creatingStore.value[prop] && delete creatingStore.value[prop];
    }

    try {
      useIsLoading(true);
      const response = (await useVaniloApi("/stores", {
        method: "POST",
        body: creatingStore.value,
      })) as ResponseType<StoreSingle>;

      stores.value.push(response?.data as Store);

      creatingStore.value = {} as StoreManage;

      return response;
    } catch (error: any) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function deleteStore(storeId?: number | string) {
    try {
      useIsLoading(true);
      const response = await useVaniloApi(
        `/stores/${storeId || editingStore.value.id}`,
        {
          method: "DELETE",
        }
      );

      viewingStore.value = {} as StoreSingle;
      editingStore.value = {} as StoreManage;

      return response;
    } catch (error: any) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  // Import/Export
  async function exportStores(params) {
    try {
      const response = await useVaniloApi(`/stores/export`, {
        method: "GET",
        params,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function importStores(body) {
    try {
      const response = await useVaniloApi(`/stores/import`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Terminal Management
  async function removeTerminal(terminalId: number) {
    // remove empty fields
    for (const prop in editingStore.value) {
      !editingStore.value[prop] && delete editingStore.value[prop];
    }

    try {
      useIsLoading(true);
      const response = (await useVaniloApi(`/stores/${editingStore.value.id}`, {
        method: "POST",
      })) as ResponseType<StoreSingle>;

      viewingStore.value = response?.data;

      // cloning 'viewingStore' to 'editingStore' and format to proper editing model
      editingStore.value = useCloneDeep({
        ...viewingStore.value,
        addresses: viewingStore.value.addresses.map((address) => ({
          province: address.province.code,
          postalcode: address.postalcode,
          city: address.city,
          address: address.address,
          country: address.country.id,
        })),
        registers: viewingStore.value.registers.map((register) => ({
          id: register.id,
          name: register.name,
          is_terminal_enabled: !!register.terminal,
          ...(register.terminal && {
            terminal: {
              id: register.terminal.id,
              name: register.terminal.name,
              is_enabled: register.terminal.is_enabled,
              reg_code: register.terminal.reg_code,
            },
          }),
        })),
      });

      return response;
    } catch (error: any) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  // Store Avatar
  async function updateStoreAvatar() {
    const formData = new FormData();
    formData.append("media", image.value.media);

    try {
      const response = (await useVaniloApi(`/stores/${imageId.value}/image`, {
        method: "POST",
        body: formData,
      })) as ResponseType<Media>;

      image.value.media = response?.data.url;
      image.value.title = response?.data.title;
      image.value.description = response?.data.description;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // QR Codes
  async function getQRCodesList(storeId) {
    try {
      useIsLoading(true);
      const response = (await useVaniloApi(
        `/stores/${storeId || viewingStore.value.id}/qr-codes`
      )) as ResponseType<QRCode[]>;

      qrCodes.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function downloadQRCode(qrCodeUrl: string, name: string) {
    const link = document.createElement("a");
    link.href = qrCodeUrl;
    link.setAttribute("target", "_blank");
    link.click();
  }

  async function createQRCode(payload, id?) {
    try {
      useIsLoading(true);
      const response = (await useVaniloApi(
        `/stores/${id || viewingStore.value.id}/qr-codes`,
        {
          method: "POST",
          body: payload,
        }
      )) as ResponseType<QRCode>;

      qrCodes.value = [response?.data];

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function updateQRCode(payload) {
    try {
      useIsLoading(true);
      const response = (await useVaniloApi(`/stores/qr-codes/${payload.id}`, {
        method: "POST",
        body: payload,
      })) as ResponseType<QRCode>;

      const index = qrCodes.value.findIndex(
        (qrCode) => qrCode.id === response?.data.id
      );
      qrCodes.value.splice(index, 1, response?.data);

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function resetQRCodeScansQuantity(payloadId) {
    try {
      useIsLoading(true);
      const response = (await useVaniloApi(`/stores/qr-codes/${payloadId}`, {
        method: "POST",
        body: { reset: true },
      })) as ResponseType<QRCode>;

      const index = qrCodes.value.findIndex(
        (qrCode) => qrCode.id === response?.data.id
      );
      qrCodes.value.splice(index, 1, response?.data);

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteQRCode(qrCodeId: number) {
    try {
      useIsLoading(true);
      const response = await useVaniloApi(`/stores/qr-codes/${qrCodeId}`, {
        method: "DELETE",
      });

      return response;
    } catch (error: any) {
      useCatchError(error);
    }
  }

  // Printer Jobs
  async function clearPrinterJobsList(id) {
    try {
      useIsLoading(true);
      const response = await useVaniloApi(`/printer-jobs/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error: any) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  // Documents
  async function uploadDocument(id, files?) {
    const formData = new FormData();

    if (files?.length) {
      files
        .filter((i) => i.file)
        .forEach((i) => {
          formData.append("media[]", i.file);
          formData.append("meta[][title]", i.title);
        });
    } else {
      files.value
        .filter((i) => i.file)
        .forEach((i) => {
          formData.append("media[]", i.file);
          formData.append("meta[][title]", i.title);
        });
    }

    try {
      const response = await useVaniloApi(`/stores/${id}/documents`, {
        method: "POST",
        body: formData,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteDocument(id) {
    try {
      const response = await useVaniloApi(`/stores/documents/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    // State
    stores,
    editMode,
    isOpenSidebar,
    viewingStore,
    editingStore,
    creatingStore,
    locationTeamMembers,
    editingStorePrinter,
    viewingStorePrinter,
    imageStore,
    imageId,
    qrCodes,
    profileSearch,
    previousTab,
    image,
    files,
    editingProfileLocation,
    viewingProfileLocation,
    profileLoctionBlockouts,
    quickViewProfileLocation,
    locationQRcodes,
    editingQR,
    profileLeadTimesRules,
    profileImmediateOrderingList,
    profileMinimumOrderList,
    profileTables,
    profileTableTags,
    editingPrinter,
    viewingPrinter,
    locationTypes,
    planManagementTypes,
    openingHoursOptions,
    deliveryDays,

    // Getters
    tabs,

    // Actions
    getStores,
    getStoresBff,
    getSingleStore,
    editStore,
    updateStoreInformation,
    updateStore,
    createStore,
    deleteStore,
    exportStores,
    importStores,
    removeTerminal,
    updateStoreAvatar,
    getQRCodesList,
    downloadQRCode,
    createQRCode,
    updateQRCode,
    resetQRCodeScansQuantity,
    deleteQRCode,
    clearPrinterJobsList,
    uploadDocument,
    deleteDocument,
  };
});
